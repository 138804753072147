import {
  CREATE_RISK_AREA_REQUEST,
  CREATE_RISK_AREA_SUCCESS,
  CREATE_RISK_AREA_FAIL,

  RISK_AREA_REQUEST,
  RISK_AREA_SUCCESS,
  RISK_AREA_FAIL,

  FIND_RISK_AREA_REQUEST,
  FIND_RISK_AREA_SUCCESS,
  FIND_RISK_AREA_FAIL,

  UPDATE_RISK_AREA_REQUEST,
  UPDATE_RISK_AREA_SUCCESS,
  UPDATE_RISK_AREA_FAIL,

  DELETE_RISK_AREA_REQUEST,
  DELETE_RISK_AREA_SUCCESS,
  DELETE_RISK_AREA_FAIL,

  RISK_AREA_CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskAreaConstant";

export const getRiskAreasReducer = (state = { riskAreas: [] }, action) => {
  switch (action.type) {
    case RISK_AREA_REQUEST:
    case CREATE_RISK_AREA_REQUEST:
    case UPDATE_RISK_AREA_REQUEST:
    case DELETE_RISK_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RISK_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAreas: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAreas: [...state.riskAreas, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAreas: state.riskAreas.map((item) =>
          (item.riskAreaId === action.payload.riskAreaId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAreas: action?.payload?.isDelete ?
          state.riskAreas.filter((item) =>
            item.riskAreaId !== action.payload.riskAreaId
          )
          :
          state.riskAreas.map((item) =>
            (item.riskAreaId === action.payload.riskAreaId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_AREA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_AREA_FAIL:
    case UPDATE_RISK_AREA_FAIL:
    case DELETE_RISK_AREA_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case RISK_AREA_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskAreaReducer = (state = { riskArea: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_AREA_REQUEST:
      return {
        riskArea: {},
        loading: true,
      };

    case FIND_RISK_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskArea: action.payload,
      };

    case FIND_RISK_AREA_FAIL:
      return {
        ...state,
        loading: false,
        riskArea: {},
        error: action.payload,
      };

    case "EMPTY_RISK_AREA":
      return {
        riskArea: {},
      };

    case RISK_AREA_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};  