
export const CREATE_GROUP_DEPOSIT_SLIP_REQUEST = "CREATE_GROUP_DEPOSIT_SLIP_REQUEST";
export const CREATE_GROUP_DEPOSIT_SLIP_SUCCESS = "CREATE_GROUP_DEPOSIT_SLIP_SUCCESS";
export const CREATE_GROUP_DEPOSIT_SLIP_FAIL = "CREATE_GROUP_DEPOSIT_SLIP_FAIL";

export const GROUP_DEPOSIT_SLIP_REQUEST = "GROUP_DEPOSIT_SLIP_REQUEST";
export const GROUP_DEPOSIT_SLIP_SUCCESS = "GROUP_DEPOSIT_SLIP_SUCCESS";
export const GROUP_DEPOSIT_SLIP_FAIL = "GROUP_DEPOSIT_SLIP_FAIL";


export const FIND_GROUP_DEPOSIT_SLIP_REQUEST = "FIND_GROUP_DEPOSIT_SLIP_REQUEST";
export const FIND_GROUP_DEPOSIT_SLIP_SUCCESS = "FIND_GROUP_DEPOSIT_SLIP_SUCCESS";
export const FIND_GROUP_DEPOSIT_SLIP_FAIL = "FIND_GROUP_DEPOSIT_SLIP_FAIL";


export const UPDATE_GROUP_DEPOSIT_SLIP_REQUEST = "UPDATE_GROUP_DEPOSIT_SLIP_REQUEST";
export const UPDATE_GROUP_DEPOSIT_SLIP_SUCCESS = "UPDATE_GROUP_DEPOSIT_SLIP_SUCCESS";
export const UPDATE_GROUP_DEPOSIT_SLIP_FAIL = "UPDATE_GROUP_DEPOSIT_SLIP_FAIL";



export const CLEAR_GROUP_DEPOSIT_SLIP_ERROR = "CLEAR_GROUP_DEPOSIT_SLIP_ERROR";