import {
    CREATE_TARGET_TYPE_FAIL,
    CREATE_TARGET_TYPE_REQUEST,
    CREATE_TARGET_TYPE_SUCCESS,
    DELETE_TARGET_TYPE_FAIL,
    DELETE_TARGET_TYPE_REQUEST,
    DELETE_TARGET_TYPE_SUCCESS,
    TARGET_TYPE_CLEAR_ERROR,
    TARGET_TYPE_FAIL,
    TARGET_TYPE_REQUEST,
    TARGET_TYPE_SUCCESS,
    FIND_TARGET_TYPE_FAIL,
    FIND_TARGET_TYPE_REQUEST,
    FIND_TARGET_TYPE_SUCCESS,
    UPDATE_TARGET_TYPE_FAIL,
    UPDATE_TARGET_TYPE_REQUEST,
    UPDATE_TARGET_TYPE_SUCCESS,
  } from "../../constants/OthersMaster/TargetTypeConstant";
  
  // get all data of list
  export const getTargetTypeReducer = (
    state = { targetTypes: [] },
    action
  ) => {
    switch (action.type) {
      case TARGET_TYPE_REQUEST:
      case CREATE_TARGET_TYPE_REQUEST:
      case UPDATE_TARGET_TYPE_REQUEST:
      case DELETE_TARGET_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
      case TARGET_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          targetTypes: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_TARGET_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          targetTypes: [...state.targetTypes, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_TARGET_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          targetTypes: [
            ...state.targetTypes.filter(
              (item) => item.targetTypeId !== action.payload.targetTypeId
            ),
            action.payload,
          ].sort((pre, post) => pre.targetTypeId - post.targetTypeId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_TARGET_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          targetTypes: [
            ...state.targetTypes.filter(
              (item) => item.targetTypeId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  
      case "FUTURE_DELETE_TARGET_TYPE_SUCCESS":
        return {
          loading: false,
          targetTypes: [
            ...state.targetTypes.filter(
              (item) => item.targetTypeId !== action.payload.targetTypeId
            ),
            action.payload,
          ].sort((pre, post) => pre.targetTypeId - post.targetTypeId),
          message: action.message,
          status: action.status,
        };
  
      case TARGET_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          targetTypes: [...state.targetTypes],
          error: action.payload,
        };
  
      case CREATE_TARGET_TYPE_FAIL:
      case UPDATE_TARGET_TYPE_FAIL:
      case DELETE_TARGET_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          targetTypes: [...state.targetTypes],
          message: action.message,
          status: action.status,
        };
  
      case TARGET_TYPE_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findTargetTypeReducer = (
    state = { targetType: {} },
    action
  ) => {
    switch (action.type) {
      case FIND_TARGET_TYPE_REQUEST:
        return {
          targetType: {},
          loading: true,
        };
  
      case FIND_TARGET_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          targetType: action.payload,
        };
  
      case FIND_TARGET_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          targetType: {},
          error: action.payload,
        };
  
      case "EMPTY_TARGET_TYPE":
        return {
          ...state,
          targetType: {},
        };
  
      case TARGET_TYPE_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  