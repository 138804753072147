// create/post
export const CREATE_RISK_SUB_AREA_REQUEST = "CREATE_RISK_SUB_AREA_REQUEST";
export const CREATE_RISK_SUB_AREA_SUCCESS = "CREATE_RISK_SUB_AREA_SUCCESS";
export const CREATE_RISK_SUB_AREA_FAIL = "CREATE_RISK_SUB_AREA_FAIL"

// get
export const RISK_SUB_AREA_REQUEST = "RISK_SUB_AREA_REQUEST";
export const RISK_SUB_AREA_SUCCESS = "RISK_SUB_AREA_SUCCESS";
export const RISK_SUB_AREA_FAIL = "RISK_SUB_AREA_FAIL"

// find
export const FIND_RISK_SUB_AREA_REQUEST = "FIND_RISK_SUB_AREA_REQUEST";
export const FIND_RISK_SUB_AREA_SUCCESS = "FIND_RISK_SUB_AREA_SUCCESS";
export const FIND_RISK_SUB_AREA_FAIL = "FIND_RISK_SUB_AREA_FAIL"

// update/put
export const UPDATE_RISK_SUB_AREA_REQUEST = "UPDATE_RISK_SUB_AREA_REQUEST";
export const UPDATE_RISK_SUB_AREA_SUCCESS = "UPDATE_RISK_SUB_AREA_SUCCESS";
export const UPDATE_RISK_SUB_AREA_FAIL = "UPDATE_RISK_SUB_AREA_FAIL"

// delete
export const DELETE_RISK_SUB_AREA_REQUEST = "DELETE_RISK_SUB_AREA_REQUEST";
export const DELETE_RISK_SUB_AREA_SUCCESS = "DELETE_RISK_SUB_AREA_SUCCESS";
export const DELETE_RISK_SUB_AREA_FAIL = "DELETE_RISK_SUB_AREA_FAIL"

export const RISK_SUB_AREA_CLEAR_ERROR = "RISK_SUB_AREA_CLEAR_ERROR"