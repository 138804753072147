// create/post
export const CREATE_RISK_TRANSACTION_PROCESS_REQUEST = "CREATE_RISK_TRANSACTION_PROCESS_REQUEST";
export const CREATE_RISK_TRANSACTION_PROCESS_SUCCESS = "CREATE_RISK_TRANSACTION_PROCESS_SUCCESS";
export const CREATE_RISK_TRANSACTION_PROCESS_FAIL = "CREATE_RISK_TRANSACTION_PROCESS_FAIL";

// get
export const RISK_TRANSACTION_PROCESS_REQUEST = "RISK_TRANSACTION_PROCESS_REQUEST";
export const RISK_TRANSACTION_PROCESS_SUCCESS = "RISK_TRANSACTION_PROCESS_SUCCESS";
export const RISK_TRANSACTION_PROCESS_FAIL = "RISK_TRANSACTION_PROCESS_FAIL";

// find
export const FIND_RISK_TRANSACTION_PROCESS_REQUEST = "FIND_RISK_TRANSACTION_PROCESS_REQUEST";
export const FIND_RISK_TRANSACTION_PROCESS_SUCCESS = "FIND_RISK_TRANSACTION_PROCESS_SUCCESS";
export const FIND_RISK_TRANSACTION_PROCESS_FAIL = "FIND_RISK_TRANSACTION_PROCESS_FAIL";

// update/put
export const UPDATE_RISK_TRANSACTION_PROCESS_REQUEST = "UPDATE_RISK_TRANSACTION_PROCESS_REQUEST";
export const UPDATE_RISK_TRANSACTION_PROCESS_SUCCESS = "UPDATE_RISK_TRANSACTION_PROCESS_SUCCESS";
export const UPDATE_RISK_TRANSACTION_PROCESS_FAIL = "UPDATE_RISK_TRANSACTION_PROCESS_FAIL";

// update/put
export const DELETE_RISK_TRANSACTION_PROCESS_REQUEST = "DELETE_RISK_TRANSACTION_PROCESS_REQUEST";
export const DELETE_RISK_TRANSACTION_PROCESS_SUCCESS = "DELETE_RISK_TRANSACTION_PROCESS_SUCCESS";
export const DELETE_RISK_TRANSACTION_PROCESS_FAIL = "DELETE_RISK_TRANSACTION_PROCESS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";