// create
export const CREATE_USER_DATA_REQUEST = "CREATE_USER_DATA_REQUEST";
export const CREATE_USER_DATA_SUCCESS = "CREATE_USER_DATA_SUCCESS";
export const CREATE_USER_DATA_FAIL = "CREATE_USER_DATA_FAIL";

// get
export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";

// find
export const FIND_USER_DATA_REQUEST = "FIND_USER_DATA_REQUEST";
export const FIND_USER_DATA_SUCCESS = "FIND_USER_DATA_SUCCESS";
export const FIND_USER_DATA_FAIL = "FIND_USER_DATA_FAIL";

// update/put
export const UPDATE_USER_DATA_REQUEST = "UPDATE_USER_DATA_REQUEST";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAIL = "UPDATE_USER_DATA_FAIL";

// update/put
export const LOCK_USER_DATA_REQUEST = "LOCK_USER_DATA_REQUEST";
export const LOCK_USER_DATA_SUCCESS = "LOCK_USER_DATA_SUCCESS";
export const LOCK_USER_DATA_FAIL = "LOCK_USER_DATA_FAIL";

//change
export const CHANGE_USER_STATUS_SUCCESS = "UPDATE_USER_DATA_SUCCESS";

// delete
// export const DELETE_USER_DATA_REQUEST = "DELETE_USER_DATA_REQUEST";
export const DELETE_USER_DATA_SUCCESS = "DELETE_USER_DATA_SUCCESS";
// export const DELETE_USER_DATA_FAIL = "DELETE_USER_DATA_FAIL";

export const USER_DATA_CLEAR_ERROR = "USER_DATA_CLEAR_ERROR"