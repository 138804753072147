import {
    CREATE_NETTING_OFF_REQUEST,
    CREATE_NETTING_OFF_SUCCESS,
    CREATE_NETTING_OFF_FAIL,

    NETTING_OFF_REQUEST,
    NETTING_OFF_SUCCESS,
    NETTING_OFF_FAIL,

    FIND_NETTING_OFF_REQUEST,
    FIND_NETTING_OFF_SUCCESS,
    FIND_NETTING_OFF_FAIL,

    UPDATE_NETTING_OFF_REQUEST,
    UPDATE_NETTING_OFF_SUCCESS,
    UPDATE_NETTING_OFF_FAIL,

    DELETE_NETTING_OFF_REQUEST,
    DELETE_NETTING_OFF_SUCCESS,
    DELETE_NETTING_OFF_FAIL,

    FIND_NETTING_OFF_DATA_REQUEST,
    FIND_NETTING_OFF_DATA_SUCCESS,
    FIND_NETTING_OFF_DATA_FAIL,

    SELECTED_NETTING_OFF_GL_HEADS_REQUEST,
    SELECTED_NETTING_OFF_GL_HEADS_SUCCESS,
    SELECTED_NETTING_OFF_GL_HEADS_FAIL,

    CLEAR_ERROR,
} from "../../constants_Phase_2/NettingOff/NettingOffConstant";

export const getNettingOffRequestsReducer = (state = { nettingOffRequests: [] }, action) => {
    switch (action.type) {
        case NETTING_OFF_REQUEST:
            return {
                ...state,
                nettingOffRequests: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case NETTING_OFF_SUCCESS:
            return {
                ...state,
                loading: false,
                nettingOffRequests: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case NETTING_OFF_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// search
export const findNettingOffDataReducer = (state = { nettingOffData: {} }, action) => {
    switch (action.type) {
        case FIND_NETTING_OFF_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FIND_NETTING_OFF_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                nettingOffData: action.payload,
            };

        case FIND_NETTING_OFF_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "EMPTY_NETTING_OFF_DATA":
            return {
                nettingOffData: {}
            }

        default:
            return state;
    }
};

// find
export const getSelectedNettingOffGlHeads = (state = { receivableGlHeadList: [], refundableGlHeadList: [] }, action) => {
    switch (action.type) {
        case SELECTED_NETTING_OFF_GL_HEADS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SELECTED_NETTING_OFF_GL_HEADS_SUCCESS:
            return {
                ...state,
                loading: false,
                receivableGlHeadList: action?.receivableGlHeadList?.map(item => ({
                    ...item,
                    paymentReceiptGlHeadList: [
                        ...(item?.paymentAdviceGlHeadList || []),
                        ...(item?.paymentReceiptGlHeadList || [])
                    ],
                })) || [],
                refundableGlHeadList: action?.refundableGlHeadList,
            };

        case "SELECTED_NETTING_OFF_RECEIVABLE_GL_HEADS_SUCCESS":
            return {
                ...state,
                loading: false,
                receivableGlHeadList: action?.receivableGlHeadList?.map(item => ({
                    ...item,
                    paymentReceiptGlHeadList: [
                        ...(item?.paymentAdviceGlHeadList || []),
                        ...(item?.paymentReceiptGlHeadList || [])
                    ],
                })) || [],
            };

        case "SELECTED_NETTING_OFF_REFUNDABLE_GL_HEADS_SUCCESS":
            return {
                ...state,
                loading: false,
                refundableGlHeadList: action?.refundableGlHeadList,
            };

        case SELECTED_NETTING_OFF_GL_HEADS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "EMPTY_SELECTED_NETTING_OFF_RECEIVABLE_GL_HEADS":
            return {
                ...state,
                receivableGlHeadList: [],
            }

        case "EMPTY_SELECTED_NETTING_OFF_REFUNDABLE_GL_HEADS":
            return {
                ...state,
                refundableGlHeadList: [],
            }

        default:
            return state;
    }
};

// create, get, update,delete
export const getNettingOffRequestResponseReducer = (state = { nettingOffRequestResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_NETTING_OFF_REQUEST:
        case UPDATE_NETTING_OFF_REQUEST:
        case DELETE_NETTING_OFF_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_NETTING_OFF_SUCCESS:
        case UPDATE_NETTING_OFF_SUCCESS:
        case DELETE_NETTING_OFF_SUCCESS:
            return {
                ...state,
                loading: false,
                nettingOffRequestResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_NETTING_OFF_FAIL:
        case UPDATE_NETTING_OFF_FAIL:
        case DELETE_NETTING_OFF_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_NETTING_OFF_RESPONSE":
            return {
                ...state,
                nettingOffRequestResponse: {}
            }

        default:
            return state;
    }
};

// find one
export const findNettingOffRequestReducer = (state = { nettingOffRequest: {} }, action) => {
    switch (action.type) {
        case FIND_NETTING_OFF_REQUEST:
            return {
                nettingOffRequest: {},
                loading: true,
            };

        case FIND_NETTING_OFF_SUCCESS:
            return {
                ...state,
                loading: false,
                nettingOffRequest: action.payload,
            };

        case FIND_NETTING_OFF_FAIL:
            return {
                ...state,
                loading: false,
                nettingOffRequest: {},
                error: action.payload
            };

        case "EMPTY_NETTING_OFF":
            return {
                nettingOffRequest: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
