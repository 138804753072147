import {
  CREATE_TAX_PROCESS_REQUEST,
  CREATE_TAX_PROCESS_SUCCESS,
  CREATE_TAX_PROCESS_FAIL,
  TAX_PROCESS_REQUEST,
  TAX_PROCESS_SUCCESS,
  TAX_PROCESS_FAIL,
  FIND_TAX_PROCESS_REQUEST,
  FIND_TAX_PROCESS_SUCCESS,
  FIND_TAX_PROCESS_FAIL,
  UPDATE_TAX_PROCESS_REQUEST,
  UPDATE_TAX_PROCESS_SUCCESS,
  UPDATE_TAX_PROCESS_FAIL,
  DELETE_TAX_PROCESS_REQUEST,
  DELETE_TAX_PROCESS_SUCCESS,
  DELETE_TAX_PROCESS_FAIL,
  TAX_PROCESS_CLEAR_ERROR,
} from "../../constants/OthersMaster/TaxProcessConstant";

export const getTaxProcessReducer = (state = { taxProcesses: [] }, action) => {
  switch (action.type) {
    case TAX_PROCESS_REQUEST:
    case CREATE_TAX_PROCESS_REQUEST:
    case UPDATE_TAX_PROCESS_REQUEST:
    case DELETE_TAX_PROCESS_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case TAX_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        taxProcesses: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TAX_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        taxProcesses: [...state.taxProcesses, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TAX_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        taxProcesses: [
          ...state.taxProcesses.filter(
            (item) => item.taxProcessId !== action.payload.taxProcessId
          ),
          action.payload,
        ].sort((pre, post) => pre.taxProcessId - post.taxProcessId),
        message: action.message,
        status: action.status,
      };

    case DELETE_TAX_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        taxProcesses: [
          ...state.taxProcesses.filter(
            (item) => item.taxProcessId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_TAX_PROCESS_SUCCESS":
      return {
        loading: false,
        taxProcesses: [
          ...state.taxProcesses.filter(
            (item) => item.taxProcessId !== action.payload.taxProcessId
          ),
          action.payload,
        ].sort((pre, post) => pre.taxProcessId - post.taxProcessId),
        message: action.message,
        status: action.status,
      };

    case TAX_PROCESS_FAIL:
      return {
        ...state,
        loading: false,
        taxProcesses: [...state.taxProcesses],
        error: action.payload,
      };

    case CREATE_TAX_PROCESS_FAIL:
    case UPDATE_TAX_PROCESS_FAIL:
    case DELETE_TAX_PROCESS_FAIL:
      return {
        ...state,
        loading: false,
        taxProcesses: [...state.taxProcesses],
        message: action.message,
        status: action.status,
      };

    case TAX_PROCESS_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };
    default:
      return state;
  }
};

// find one
export const findTaxProcessReducer = (state = { taxProcess: {} }, action) => {
  switch (action.type) {
    case FIND_TAX_PROCESS_REQUEST:
      return {
        taxProcess: {},
        loading: true,
      };

    case FIND_TAX_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        taxProcess: action.payload,
      };

    case FIND_TAX_PROCESS_FAIL:
      return {
        ...state,
        loading: false,
        taxProcess: {},
        error: action.payload,
      };

    case "EMPTY_TAX_PROCESS":
      return {
        ...state,
        taxProcess: {},
      };

    case TAX_PROCESS_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };
    default:
      return state;
  }
};
