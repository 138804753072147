import {
    CREATE_PAYMENT_VOUCHER_REQUEST,
    CREATE_PAYMENT_VOUCHER_SUCCESS,
    CREATE_PAYMENT_VOUCHER_FAIL,

    PAYMENT_VOUCHER_REQUEST,
    PAYMENT_VOUCHER_SUCCESS,
    PAYMENT_VOUCHER_FAIL,

    FIND_PAYMENT_VOUCHER_REQUEST,
    FIND_PAYMENT_VOUCHER_SUCCESS,
    FIND_PAYMENT_VOUCHER_FAIL,

    UPDATE_PAYMENT_VOUCHER_REQUEST,
    UPDATE_PAYMENT_VOUCHER_SUCCESS,
    UPDATE_PAYMENT_VOUCHER_FAIL,

    DELETE_PAYMENT_VOUCHER_REQUEST,
    DELETE_PAYMENT_VOUCHER_SUCCESS,
    DELETE_PAYMENT_VOUCHER_FAIL,
    CLEAR_ERROR,
} from "../../constants_Phase_2/RefundAndDisbursement/PaymentVoucherConstant";

export const getPaymentVouchersReducer = (state = { paymentVouchers: [] }, action) => {
    switch (action.type) {
        case PAYMENT_VOUCHER_REQUEST:
            return {
                ...state,
                paymentVouchers: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case PAYMENT_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentVouchers: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case PAYMENT_VOUCHER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// create, get, update,delete
export const getPaymentVoucherResponseReducer = (state = { paymentVoucherResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_PAYMENT_VOUCHER_REQUEST:
        case UPDATE_PAYMENT_VOUCHER_REQUEST:
        case DELETE_PAYMENT_VOUCHER_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_PAYMENT_VOUCHER_SUCCESS:
        case UPDATE_PAYMENT_VOUCHER_SUCCESS:
        case DELETE_PAYMENT_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentVoucherResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_PAYMENT_VOUCHER_FAIL:
        case UPDATE_PAYMENT_VOUCHER_FAIL:
        case DELETE_PAYMENT_VOUCHER_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_PAYMENT_VOUCHER_RESPONSE":
            return {
                ...state,
                paymentVoucherResponse: {}
            }

        default:
            return state;
    }
};

// find one
export const findPaymentVoucherReducer = (state = { paymentVoucher: {} }, action) => {
    switch (action.type) {
        case FIND_PAYMENT_VOUCHER_REQUEST:
            return {
                paymentVoucher: {},
                loading: true,
            };

        case FIND_PAYMENT_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentVoucher: action.payload,
            };

        case FIND_PAYMENT_VOUCHER_FAIL:
            return {
                ...state,
                loading: false,
                paymentVoucher: {},
                error: action.payload
            };

        case "EMPTY_PAYMENT_VOUCHER":
            return {
                ...state,
                paymentVoucher: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
