import {
  MAIN_MENU_REQUEST,
  MAIN_MENU_SUCCESS,
  MAIN_MENU_FAIL,

  CREATE_MAIN_MENU_REQUEST,
  CREATE_MAIN_MENU_SUCCESS,
  CREATE_MAIN_MENU_FAIL,

  FIND_MAIN_MENU_REQUEST,
  FIND_MAIN_MENU_SUCCESS,
  FIND_MAIN_MENU_FAIL,

  UPDATE_MAIN_MENU_REQUEST,
  UPDATE_MAIN_MENU_SUCCESS,
  UPDATE_MAIN_MENU_FAIL,

  DELETE_MAIN_MENU_REQUEST,
  DELETE_MAIN_MENU_SUCCESS,
  DELETE_MAIN_MENU_FAIL,

  MAIN_MENU_CLEAR_ERROR,
} from "../../constants/Menu/MainMenuConstant";

// create, update, delete and get all
export const mainMenuReducer = (state = { mainMenus: [] }, action) => {
  switch (action.type) {
    case MAIN_MENU_REQUEST:
    case CREATE_MAIN_MENU_REQUEST:
    case UPDATE_MAIN_MENU_REQUEST:
    case DELETE_MAIN_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null
      };
    case MAIN_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        mainMenus: action.payload,
        message: null,
        status: null,
      };

    case CREATE_MAIN_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        mainMenus: [...state.mainMenus, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_MAIN_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        mainMenus: [
          ...state.mainMenus.filter(
            (item) => item.menuId !== action.payload.menuId
          ),
          action.payload,
        ].sort((pre, post) => pre.menuId - post.menuId),
        message: action.message,
        status: action.status,
      };

    case DELETE_MAIN_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        mainMenus: [
          ...state.mainMenus.filter((item) => item.menuId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case MAIN_MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_MAIN_MENU_FAIL:
    case UPDATE_MAIN_MENU_FAIL:
      case DELETE_MAIN_MENU_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };


    case MAIN_MENU_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findMenuReducer = (state = { mainMenu: {} }, action) => {
  switch (action.type) {
    case FIND_MAIN_MENU_REQUEST:
      return {
        loading: true,
        mainMenu: {},
      };
    case FIND_MAIN_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        mainMenu: action.payload,
      };

    case FIND_MAIN_MENU_FAIL:
      return {
        ...state,
        loading: false,
        mainMenu: {},
        error: action.payload,
      };

    case "EMPTY_MAIN_MENU":
      return {
        ...state,
        mainMenu: {}
      };

    case MAIN_MENU_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};