// create
export const CREATE_RAMIS_REV_TYPE_REQUEST = "CREATE_RAMIS_REV_TYPE_REQUEST";
export const CREATE_RAMIS_REV_TYPE_SUCCESS = "CREATE_RAMIS_REV_TYPE_SUCCESS";
export const CREATE_RAMIS_REV_TYPE_FAIL = "CREATE_RAMIS_REV_TYPE_FAIL";

// get
export const RAMIS_REV_TYPE_REQUEST = "RAMIS_REV_TYPE_REQUEST";
export const RAMIS_REV_TYPE_SUCCESS = "RAMIS_REV_TYPE_SUCCESS";
export const RAMIS_REV_TYPE_FAIL = "RAMIS_REV_TYPE_FAIL";

// find
export const FIND_RAMIS_REV_TYPE_REQUEST = "FIND_RAMIS_REV_TYPE_REQUEST";
export const FIND_RAMIS_REV_TYPE_SUCCESS = "FIND_RAMIS_REV_TYPE_SUCCESS";
export const FIND_RAMIS_REV_TYPE_FAIL = "FIND_RAMIS_REV_TYPE_FAIL";

// update
export const UPDATE_RAMIS_REV_TYPE_REQUEST = "UPDATE_RAMIS_REV_TYPE_REQUEST";
export const UPDATE_RAMIS_REV_TYPE_SUCCESS = "UPDATE_RAMIS_REV_TYPE_SUCCESS";
export const UPDATE_RAMIS_REV_TYPE_FAIL = "UPDATE_RAMIS_REV_TYPE_FAIL";

// delete
export const DELETE_RAMIS_REV_TYPE_REQUEST = "DELETE_RAMIS_REV_TYPE_REQUEST";
export const DELETE_RAMIS_REV_TYPE_SUCCESS = "DELETE_RAMIS_REV_TYPE_SUCCESS";
export const DELETE_RAMIS_REV_TYPE_FAIL = "DELETE_RAMIS_REV_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
