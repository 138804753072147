// Country create
export const CREATE_COUNTRY_REQUEST = "CREATE_COUNTRY_REQUEST";
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS";
export const CREATE_COUNTRY_FAIL = "CREATE_COUNTRY_FAIL"
export const CREATE_COUNTRY_CONFLICT = "CREATE_COUNTRY_CONFLICT"

// Country get
export const COUNTRY_REQUEST = "COUNTRY_REQUEST";
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_FAIL = "COUNTRY_FAIL"


// Country find
export const FIND_COUNTRY_REQUEST = "FIND_COUNTRY_REQUEST";
export const FIND_COUNTRY_SUCCESS = "FIND_COUNTRY_SUCCESS";
export const FIND_COUNTRY_FAIL = "FIND_COUNTRY_FAIL"

//Country update/put
export const UPDATE_COUNTRY_REQUEST = "UPDATE_COUNTRY_REQUEST";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIL"

// Country delete
export const DELETE_COUNTRY_REQUEST = "DELETE_COUNTRY_REQUEST";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_FAIL = "DELETE_COUNTRY_FAIL"


export const COUNTRY_CLEAR_ERROR = "COUNTRY_CLEAR_ERROR"
