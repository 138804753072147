import {
  FIND_AMEND_PRINT_REQUEST,
  FIND_AMEND_PRINT_SUCCESS,
  FIND_AMEND_PRINT_FAIL,

  AMEND_PRINT_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/AmendPrintConstant";

export const findAmendPrintReducer = (state = { receiptAmendPrint: {} }, action) => {
  switch (action.type) {
    case FIND_AMEND_PRINT_REQUEST:
      return {
        loading: true,
        receiptAmendPrint: {},
      };
    case FIND_AMEND_PRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAmendPrint: action.payload,
      };

    case FIND_AMEND_PRINT_FAIL:
      return {
        ...state,
        loading: false,
        receiptAmendPrint: {},
        error: action.payload,
      };
    case "EMPTY_AMEND_PRINT":
      return {
        ...state,
        receiptAmendPrint: {},
      };

    case AMEND_PRINT_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};