import {
  CREATE_REPLENISHMENT_REQUEST,
  CREATE_REPLENISHMENT_SUCCESS,
  CREATE_REPLENISHMENT_FAIL,

  REPLENISHMENT_REQUEST,
  REPLENISHMENT_SUCCESS,
  REPLENISHMENT_FAIL,

  FIND_REPLENISHMENT_REQUEST,
  FIND_REPLENISHMENT_SUCCESS,
  FIND_REPLENISHMENT_FAIL,

  UPDATE_REPLENISHMENT_REQUEST,
  UPDATE_REPLENISHMENT_SUCCESS,
  UPDATE_REPLENISHMENT_FAIL,

  DELETE_REPLENISHMENT_REQUEST,
  DELETE_REPLENISHMENT_SUCCESS,
  DELETE_REPLENISHMENT_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RefundAndDisbursement/ReplenishmentConstant";

// create
export const getReplenishmentRequestsReducer = (state = { replenishmentRequests: [] }, action) => {
  switch (action.type) {
    case REPLENISHMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        replenishmentRequests: [],
        status: null,
        error: null,
      };

    case REPLENISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        replenishmentRequests: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case REPLENISHMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// create
export const replenishmentResponseReducer = (state = { replenishmentResponse: {} }, action) => {
  switch (action.type) {
    case CREATE_REPLENISHMENT_REQUEST:
    case UPDATE_REPLENISHMENT_REQUEST:
    case DELETE_REPLENISHMENT_REQUEST:
      return {
        ...state,
        replenishmentResponse: {},
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_REPLENISHMENT_SUCCESS:
    case UPDATE_REPLENISHMENT_SUCCESS:
    case DELETE_REPLENISHMENT_SUCCESS:
      return {
        ...state,
        replenishmentResponse: action.payload,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CREATE_REPLENISHMENT_FAIL:
    case UPDATE_REPLENISHMENT_FAIL:
    case DELETE_REPLENISHMENT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_REPLENISHMENT_RESPONSE":
      return {
        ...state,
        replenishmentResponse: {}
      }

    default:
      return state;
  }
};

// find one
export const findReplenishmentReducer = (state = { replenishment: {} }, action) => {
  switch (action.type) {
    case FIND_REPLENISHMENT_REQUEST:
      return {
        replenishment: {},
        loading: true,
      };

    case FIND_REPLENISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        replenishment: action.payload,
      };

    case FIND_REPLENISHMENT_FAIL:
      return {
        ...state,
        loading: false,
        replenishment: {},
        error: action.payload
      };

    case "EMPTY_REPLENISHMENT":
      return {
        ...state,
        replenishment: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};