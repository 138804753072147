import {
    CREATE_PAYMENT_VOUCHER_MAINTAIN_REQUEST,
    CREATE_PAYMENT_VOUCHER_MAINTAIN_SUCCESS,
    CREATE_PAYMENT_VOUCHER_MAINTAIN_FAIL,

    PAYMENT_VOUCHER_MAINTAIN_REQUEST,
    PAYMENT_VOUCHER_MAINTAIN_SUCCESS,
    PAYMENT_VOUCHER_MAINTAIN_FAIL,

    FIND_PAYMENT_VOUCHER_MAINTAIN_REQUEST,
    FIND_PAYMENT_VOUCHER_MAINTAIN_SUCCESS,
    FIND_PAYMENT_VOUCHER_MAINTAIN_FAIL,

    UPDATE_PAYMENT_VOUCHER_MAINTAIN_REQUEST,
    UPDATE_PAYMENT_VOUCHER_MAINTAIN_SUCCESS,
    UPDATE_PAYMENT_VOUCHER_MAINTAIN_FAIL,

    DELETE_PAYMENT_VOUCHER_MAINTAIN_REQUEST,
    DELETE_PAYMENT_VOUCHER_MAINTAIN_SUCCESS,
    DELETE_PAYMENT_VOUCHER_MAINTAIN_FAIL,
    CLEAR_ERROR,
} from "../../constants_Phase_2/RefundAndDisbursement/PaymentVoucherMaintainConstant";

export const getPaymentVoucherMaintainReducer = (state = { paymentVouchersMaintain: [] }, action) => {
    switch (action.type) {
        case PAYMENT_VOUCHER_MAINTAIN_REQUEST:
            return {
                ...state,
                paymentVouchersMaintain: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case PAYMENT_VOUCHER_MAINTAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentVouchersMaintain: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case PAYMENT_VOUCHER_MAINTAIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// create, get, update,delete
export const getPaymentVoucherMaintainResponseReducer = (state = { paymentVoucherMaintainResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_PAYMENT_VOUCHER_MAINTAIN_REQUEST:
        case UPDATE_PAYMENT_VOUCHER_MAINTAIN_REQUEST:
        case DELETE_PAYMENT_VOUCHER_MAINTAIN_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_PAYMENT_VOUCHER_MAINTAIN_SUCCESS:
        case UPDATE_PAYMENT_VOUCHER_MAINTAIN_SUCCESS:
        case DELETE_PAYMENT_VOUCHER_MAINTAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentVoucherMaintainResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_PAYMENT_VOUCHER_MAINTAIN_FAIL:
        case UPDATE_PAYMENT_VOUCHER_MAINTAIN_FAIL:
        case DELETE_PAYMENT_VOUCHER_MAINTAIN_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_PAYMENT_VOUCHER_MAINTAIN_RESPONSE":
            return {
                ...state,
                paymentVoucherMaintainResponse: {}
            }

        default:
            return state;
    }
};

// find one
export const findPaymentVoucherMaintainReducer = (state = { paymentVoucherMaintain: {} }, action) => {
    switch (action.type) {
        case FIND_PAYMENT_VOUCHER_MAINTAIN_REQUEST:
            return {
                paymentVoucherMaintain: {},
                loading: true,
            };

        case FIND_PAYMENT_VOUCHER_MAINTAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentVoucherMaintain: action.payload,
            };

        case FIND_PAYMENT_VOUCHER_MAINTAIN_FAIL:
            return {
                ...state,
                loading: false,
                paymentVoucherMaintain: {},
                error: action.payload
            };

        case "EMPTY_PAYMENT_VOUCHER_MAINTAIN":
            return {
                ...state,
                paymentVoucherMaintain: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
