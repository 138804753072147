// AGENCY_REG create
export const CREATE_AGENCY_REG_REQUEST = "CREATE_AGENCY_REG_REQUEST";
export const CREATE_AGENCY_REG_SUCCESS = "CREATE_AGENCY_REG_SUCCESS";
export const CREATE_AGENCY_REG_FAIL = "CREATE_AGENCY_REG_FAIL"
export const CREATE_AGENCY_REG_CONFLICT = "CREATE_AGENCY_REG_CONFLICT"

// AGENCY_REG get
export const AGENCY_REG_REQUEST = "AGENCY_REG_REQUEST";
export const AGENCY_REG_SUCCESS = "AGENCY_REG_SUCCESS";
export const AGENCY_REG_FAIL = "AGENCY_REG_FAIL"

// AGENCY_REG find
export const FIND_AGENCY_REG_REQUEST = "FIND_AGENCY_REG_REQUEST";
export const FIND_AGENCY_REG_SUCCESS = "FIND_AGENCY_REG_SUCCESS";
export const FIND_AGENCY_REG_FAIL = "FIND_AGENCY_REG_FAIL"

//AGENCY_REG update/put
export const UPDATE_AGENCY_REG_REQUEST = "UPDATE_AGENCY_REG_REQUEST";
export const UPDATE_AGENCY_REG_SUCCESS = "UPDATE_AGENCY_REG_SUCCESS";
export const UPDATE_AGENCY_REG_FAIL = "UPDATE_AGENCY_REG_FAIL"

// AGENCY_REG delete
export const DELETE_AGENCY_REG_REQUEST = "DELETE_AGENCY_REG_REQUEST";
export const DELETE_AGENCY_REG_SUCCESS = "DELETE_AGENCY_REG_SUCCESS";
export const DELETE_AGENCY_REG_FAIL = "DELETE_AGENCY_REG_FAIL"

export const AGENCY_REG_CLEAR_ERROR = "AGENCY_REG_CLEAR_ERROR"

//FIND AGENCY TYPE LIST IN FORM
export const AGENCY_TYPE_LIST_REQUEST = "AGENCY_TYPE_LIST_REQUEST";
export const AGENCY_TYPE_LIST_SUCCESS = "AGENCY_TYPE_LIST_SUCCESS";
export const AGENCY_TYPE_LIST_FAIL = "AGENCY_TYPE_LIST_FAIL"

//FIND SUB AGENCY LIST IN FORM
export const SUB_AGENCY_LIST_REQUEST = "SUB_AGENCY_LIST_REQUEST";
export const SUB_AGENCY_LIST_SUCCESS = "SUB_AGENCY_LIST_SUCCESS";
export const SUB_AGENCY_LIST_FAIL = "SUB_AGENCY_LIST_FAIL"

//AGENCY_NAME IN FORM
export const AGENCY_NAME_REQUEST = "AGENCY_NAME_REQUEST";
export const AGENCY_NAME_SUCCESS = "AGENCY_NAME_SUCCESS";
export const AGENCY_NAME_FAIL = "AGENCY_NAME_FAIL"