import {
  CREATE_FINANCIAL_YEAR_FAIL,
  CREATE_FINANCIAL_YEAR_REQUEST,
  CREATE_FINANCIAL_YEAR_SUCCESS,
  DELETE_FINANCIAL_YEAR_FAIL,
  DELETE_FINANCIAL_YEAR_REQUEST,
  DELETE_FINANCIAL_YEAR_SUCCESS,
  FINANCIAL_YEAR_CLEAR_ERROR,
  FINANCIAL_YEAR_FAIL,
  FINANCIAL_YEAR_REQUEST,
  FINANCIAL_YEAR_SUCCESS,
  FIND_FINANCIAL_YEAR_FAIL,
  FIND_FINANCIAL_YEAR_REQUEST,
  FIND_FINANCIAL_YEAR_SUCCESS,
  UPDATE_FINANCIAL_YEAR_FAIL,
  UPDATE_FINANCIAL_YEAR_REQUEST,
  UPDATE_FINANCIAL_YEAR_SUCCESS,
} from "../../constants/OthersMaster/FinancialYearsConstant";

// get all data of list
export const getFinancialYearReducer = (
  state = { financialYears: [] },
  action
) => {
  switch (action.type) {
    case FINANCIAL_YEAR_REQUEST:
    case CREATE_FINANCIAL_YEAR_REQUEST:
    case UPDATE_FINANCIAL_YEAR_REQUEST:
    case DELETE_FINANCIAL_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        financialYears: action.payload,
        message: null,
        status: null,
      };

    case CREATE_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        financialYears: [...state.financialYears, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        financialYears: [
          ...state.financialYears.filter(
            (item) => item.financialYearId !== action.payload.financialYearId
          ),
          action.payload,
        ].sort((pre, post) => pre.financialYearId - post.financialYearId),
        message: action.message,
        status: action.status,
      };

    case DELETE_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        financialYears: [
          ...state.financialYears.filter(
            (item) => item.financialYearId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_FINANCIAL_YEAR_SUCCESS":
      return {
        loading: false,
        financialYears: [
          ...state.financialYears.filter(
            (item) => item.financialYearId !== action.payload.financialYearId
          ),
          action.payload,
        ].sort((pre, post) => pre.financialYearId - post.financialYearId),
        message: action.message,
        status: action.status,
      };

    case FINANCIAL_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        financialYears: [...state.financialYears],
        error: action.payload,
      };

    case CREATE_FINANCIAL_YEAR_FAIL:
    case UPDATE_FINANCIAL_YEAR_FAIL:
    case DELETE_FINANCIAL_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        financialYears: [...state.financialYears],
        message: action.message,
        status: action.status,
      };

    case FINANCIAL_YEAR_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findFinancialYearReducer = (
  state = { financialYear: {} },
  action
) => {
  switch (action.type) {
    case FIND_FINANCIAL_YEAR_REQUEST:
      return {
        financialYear: {},
        loading: true,
      };

    case FIND_FINANCIAL_YEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        financialYear: action.payload,
      };

    case FIND_FINANCIAL_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        financialYear: {},
        error: action.payload,
      };

    case "EMPTY_FINANCIAL_YEAR":
      return {
        ...state,
        financialYear: {},
      };

    case FINANCIAL_YEAR_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
