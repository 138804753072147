import {
  CREATE_BTC_HEADING_REQUEST,
  CREATE_BTC_HEADING_SUCCESS,
  CREATE_BTC_HEADING_FAIL,
  BTC_HEADING_REQUEST,
  BTC_HEADING_SUCCESS,
  BTC_HEADING_FAIL,
  FIND_BTC_HEADING_REQUEST,
  FIND_BTC_HEADING_SUCCESS,
  FIND_BTC_HEADING_FAIL,
  UPDATE_BTC_HEADING_REQUEST,
  UPDATE_BTC_HEADING_SUCCESS,
  UPDATE_BTC_HEADING_FAIL,
  DELETE_BTC_HEADING_REQUEST,
  DELETE_BTC_HEADING_SUCCESS,
  DELETE_BTC_HEADING_FAIL,
  BTC_HEADING_CLEAR_ERROR,
} from "../../constants/BtcMaster/HeadingConstant";

// get all data of list
export const getBtcHeadingsReducer = (state = { btcHeadings: [] }, action) => {
  switch (action.type) {
    case BTC_HEADING_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        btcHeadings: [],
        status: null,
        error: null,
      };

    case CREATE_BTC_HEADING_REQUEST:
    case UPDATE_BTC_HEADING_REQUEST:
    case DELETE_BTC_HEADING_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case BTC_HEADING_SUCCESS:
      return {
        ...state,
        loading: false,
        btcHeadings: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case CREATE_BTC_HEADING_SUCCESS:
    case UPDATE_BTC_HEADING_SUCCESS:
    case DELETE_BTC_HEADING_SUCCESS:
      return {
        ...state,
        loading: false,
        btcHeadings: [...state.btcHeadings, action.payload],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BTC_HEADING_SUCCESS":
      return {
        loading: false,
        btcHeadings: [
          ...state.btcHeadings.filter(
            (item) => item.headingId !== action.payload.headingId
          ),
          action.payload,
        ].sort((pre, post) => pre.headingId - post.headingId),
        message: action.message,
        status: action.status,
      };

    case BTC_HEADING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_BTC_HEADING_FAIL:
    case UPDATE_BTC_HEADING_FAIL:
    case DELETE_BTC_HEADING_FAIL:
      return {
        ...state,
        loading: false,
        btcHeadings: [...state.btcHeadings],
        message: action.message,
        status: action.status,
      };

    case BTC_HEADING_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBtcHeadingReducer = (state = { btcHeading: {} }, action) => {
  switch (action.type) {
    case FIND_BTC_HEADING_REQUEST:
      return {
        btcHeading: {},
        loading: true,
      };

    case FIND_BTC_HEADING_SUCCESS:
      return {
        ...state,
        loading: false,
        btcHeading: action.payload,
      };

    case FIND_BTC_HEADING_FAIL:
      return {
        ...state,
        loading: false,
        btcHeading: {},
        error: action.payload,
      };

    case "EMPTY_BTC_HEADING":
      return {
        ...state,
        btcHeading: {},
      };

    case BTC_HEADING_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
