//CREATE
export const CREATE_MENU_TITLE_REQUEST = "CREATE_MENU_TITLE_REQUEST";
export const CREATE_MENU_TITLE_SUCCESS = "CREATE_MENU_TITLE_SUCCESS";
export const CREATE_MENU_TITLE_FAIL = "CREATE_MENU_TITLE_FAIL"
//GET ALL
export const MENU_TITLE_REQUEST = "MENU_TITLE_REQUEST";
export const MENU_TITLE_SUCCESS = "MENU_TITLE_SUCCESS";
export const MENU_TITLE_FAIL = "MENU_TITLE_FAIL"
//FIND ONE
export const FIND_MENU_TITLE_REQUEST = "FIND_MENU_TITLE_REQUEST";
export const FIND_MENU_TITLE_SUCCESS = "FIND_MENU_TITLE_SUCCESS";
export const FIND_MENU_TITLE_FAIL = "FIND_MENU_TITLE_FAIL"
//UPDATE ONE
export const UPDATE_MENU_TITLE_REQUEST = "UPDATE_MENU_TITLE_REQUEST";
export const UPDATE_MENU_TITLE_SUCCESS = "UPDATE_MENU_TITLE_SUCCESS";
export const UPDATE_MENU_TITLE_FAIL = "UPDATE_MENU_TITLE_FAIL"
//DELETE ONE
export const DELETE_MENU_TITLE_REQUEST = "DELETE_MENU_TITLE_REQUEST";
export const DELETE_MENU_TITLE_SUCCESS = "DELETE_MENU_TITLE_SUCCESS";
export const DELETE_MENU_TITLE_FAIL = "DELETE_MENU_TITLE_FAIL"

export const MENU_TITLE_CLEAR_ERROR = "CLEAR_ERROR"