// create
export const CREATE_REPLENISHMENT_REQUEST = "CREATE_REPLENISHMENT_REQUEST";
export const CREATE_REPLENISHMENT_SUCCESS = "CREATE_REPLENISHMENT_SUCCESS";
export const CREATE_REPLENISHMENT_FAIL = "CREATE_REPLENISHMENT_FAIL";

// get
export const REPLENISHMENT_REQUEST = "REPLENISHMENT_REQUEST";
export const REPLENISHMENT_SUCCESS = "REPLENISHMENT_SUCCESS";
export const REPLENISHMENT_FAIL = "REPLENISHMENT_FAIL";

//find
export const FIND_REPLENISHMENT_REQUEST = "FIND_REPLENISHMENT_REQUEST";
export const FIND_REPLENISHMENT_SUCCESS = "FIND_REPLENISHMENT_SUCCESS";
export const FIND_REPLENISHMENT_FAIL = "FIND_REPLENISHMENT_FAIL";

// edit
export const UPDATE_REPLENISHMENT_REQUEST = "UPDATE_REPLENISHMENT_REQUEST";
export const UPDATE_REPLENISHMENT_SUCCESS = "UPDATE_REPLENISHMENT_SUCCESS";
export const UPDATE_REPLENISHMENT_FAIL = "UPDATE_REPLENISHMENT_FAIL";

// delete
export const DELETE_REPLENISHMENT_REQUEST = "DELETE_REPLENISHMENT_REQUEST";
export const DELETE_REPLENISHMENT_SUCCESS = "DELETE_REPLENISHMENT_SUCCESS";
export const DELETE_REPLENISHMENT_FAIL = "DELETE_REPLENISHMENT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";