
export const CREATE_REIMBURSEMENT_REQUEST = "CREATE_REIMBURSEMENT_REQUEST";
export const CREATE_REIMBURSEMENT_SUCCESS = "CREATE_REIMBURSEMENT_SUCCESS";
export const CREATE_REIMBURSEMENT_FAIL = "CREATE_REIMBURSEMENT_FAIL";

export const REIMBURSEMENT_REQUEST = "REIMBURSEMENT_REQUEST";
export const REIMBURSEMENT_SUCCESS = "REIMBURSEMENT_SUCCESS";
export const REIMBURSEMENT_FAIL = "REIMBURSEMENT_FAIL";


export const FIND_REIMBURSEMENT_REQUEST = "FIND_REIMBURSEMENT_REQUEST";
export const FIND_REIMBURSEMENT_SUCCESS = "FIND_REIMBURSEMENT_SUCCESS";
export const FIND_REIMBURSEMENT_FAIL = "FIND_REIMBURSEMENT_FAIL";


export const UPDATE_REIMBURSEMENT_REQUEST = "UPDATE_REIMBURSEMENT_REQUEST";
export const UPDATE_REIMBURSEMENT_SUCCESS = "UPDATE_REIMBURSEMENT_SUCCESS";
export const UPDATE_REIMBURSEMENT_FAIL = "UPDATE_REIMBURSEMENT_FAIL";



export const CLEAR_REIMBURSEMENT_ERROR = "CLEAR_REIMBURSEMENT_ERROR";