//get all pa grouping data

export const PA_GROUPING_MAINTENANCE_REQUEST = "PA_GROUPING_MAINTENANCE_REQUEST";
export const PA_GROUPING_MAINTENANCE_SUCCESS = "PA_GROUPING_MAINTENANCE_SUCCESS";
export const PA_GROUPING_MAINTENANCE_FAIL = "PA_GROUPING_MAINTENANCE_FAIL";

// find
export const FIND_PA_GROUPING_MAINTENANCE_REQUEST = "FIND_PA_GROUPING_MAINTENANCE_REQUEST";
export const FIND_PA_GROUPING_MAINTENANCE_SUCCESS = "FIND_PA_GROUPING_MAINTENANCE_SUCCESS";
export const FIND_PA_GROUPING_MAINTENANCE_FAIL = "FIND_PA_GROUPING_MAINTENANCE_FAIL";

// DELETE
export const PA_GROUPING_MAINTENANCE_CANCEL_REQUEST = "PA_GROUPING_MAINTENANCE_CANCEL_REQUEST";
export const PA_GROUPING_MAINTENANCE_CANCEL_SUCCESS = "PA_GROUPING_MAINTENANCE_CANCEL_SUCCESS";
export const PA_GROUPING_MAINTENANCE_CANCEL_FAIL = "PA_GROUPING_MAINTENANCE_CANCEL_FAIL";

// unmap
export const PA_GROUPING_MAINTENANCE_UNMAP_REQUEST = "PA_GROUPING_MAINTENANCE_UNMAP_REQUEST";
export const PA_GROUPING_MAINTENANCE_UNMAP_SUCCESS = "PA_GROUPING_MAINTENANCE_UNMAP_SUCCESS";
export const PA_GROUPING_MAINTENANCE_UNMAP_FAIL = "PA_GROUPING_MAINTENANCE_UNMAP_FAIL";

export const PA_GROUPING_MAINTENANCE_CLEAR_ERROR = "PA_GROUPING_MAINTENANCE_CLEAR_ERROR";