// create
export const CREATE_BANK_BRANCH_REQUEST = "CREATE_BANK_BRANCH_REQUEST";
export const CREATE_BANK_BRANCH_SUCCESS = "CREATE_BANK_BRANCH_SUCCESS";
export const CREATE_BANK_BRANCH_FAIL = "CREATE_BANK_BRANCH_FAIL";

// get
export const BANK_BRANCH_REQUEST = "BANK_BRANCH_REQUEST";
export const BANK_BRANCH_SUCCESS = "BANK_BRANCH_SUCCESS";
export const BANK_BRANCH_FAIL = "BANK_BRANCH_FAIL";

// find
export const FIND_BANK_BRANCH_REQUEST = "FIND_BANK_BRANCH_REQUEST";
export const FIND_BANK_BRANCH_SUCCESS = "FIND_BANK_BRANCH_SUCCESS";
export const FIND_BANK_BRANCH_FAIL = "FIND_BANK_BRANCH_FAIL";

// update
export const UPDATE_BANK_BRANCH_REQUEST = "UPDATE_BANK_BRANCH_REQUEST";
export const UPDATE_BANK_BRANCH_SUCCESS = "UPDATE_BANK_BRANCH_SUCCESS";
export const UPDATE_BANK_BRANCH_FAIL = "UPDATE_BANK_BRANCH_FAIL";

// delete
export const DELETE_BANK_BRANCH_REQUEST = "DELETE_BANK_BRANCH_REQUEST";
export const DELETE_BANK_BRANCH_SUCCESS = "DELETE_BANK_BRANCH_SUCCESS";
export const DELETE_BANK_BRANCH_FAIL = "DELETE_BANK_BRANCH_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
