// STATUS create
export const CREATE_STATUS_REQUEST = "CREATE_STATUS_REQUEST";
export const CREATE_STATUS_SUCCESS = "CREATE_STATUS_SUCCESS";
export const CREATE_STATUS_FAIL = "CREATE_STATUS_FAIL"

// STATUS get
export const STATUS_REQUEST = "STATUS_REQUEST";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAIL = "STATUS_FAIL"


// STATUS find
export const FIND_STATUS_REQUEST = "FIND_STATUS_REQUEST";
export const FIND_STATUS_SUCCESS = "FIND_STATUS_SUCCESS";
export const FIND_STATUS_FAIL = "FIND_STATUS_FAIL"

//STATUS update/put
export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAIL = "UPDATE_STATUS_FAIL"

// STATUS delete
export const DELETE_STATUS_REQUEST = "DELETE_STATUS_REQUEST";
export const DELETE_STATUS_SUCCESS = "DELETE_STATUS_SUCCESS";
export const DELETE_STATUS_FAIL = "DELETE_STATUS_FAIL"

export const STATUS_CLEAR_ERROR = "STATUS_CLEAR_ERROR"
