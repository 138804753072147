// BTC_CHAPTER create
export const CREATE_BTC_CHAPTER_REQUEST = "CREATE_BTC_CHAPTER_REQUEST";
export const CREATE_BTC_CHAPTER_SUCCESS = "CREATE_BTC_CHAPTER_SUCCESS";
export const CREATE_BTC_CHAPTER_FAIL = "CREATE_BTC_CHAPTER_FAIL";
export const CREATE_BTC_CHAPTER_CONFLICT = "CREATE_BTC_CHAPTER_CONFLICT";

// BTC_CHAPTER get
export const BTC_CHAPTER_REQUEST = "BTC_CHAPTER_REQUEST";
export const BTC_CHAPTER_SUCCESS = "BTC_CHAPTER_SUCCESS";
export const BTC_CHAPTER_FAIL = "BTC_CHAPTER_FAIL";

// BTC_CHAPTER find
export const FIND_BTC_CHAPTER_REQUEST = "FIND_BTC_CHAPTER_REQUEST";
export const FIND_BTC_CHAPTER_SUCCESS = "FIND_BTC_CHAPTER_SUCCESS";
export const FIND_BTC_CHAPTER_FAIL = "FIND_BTC_CHAPTER_FAIL";

//BTC_CHAPTER update/put
export const UPDATE_BTC_CHAPTER_REQUEST = "UPDATE_BTC_CHAPTER_REQUEST";
export const UPDATE_BTC_CHAPTER_SUCCESS = "UPDATE_BTC_CHAPTER_SUCCESS";
export const UPDATE_BTC_CHAPTER_FAIL = "UPDATE_BTC_CHAPTER_FAIL";

// BTC_CHAPTER delete
export const DELETE_BTC_CHAPTER_REQUEST = "DELETE_BTC_CHAPTER_REQUEST";
export const DELETE_BTC_CHAPTER_SUCCESS = "DELETE_BTC_CHAPTER_SUCCESS";
export const DELETE_BTC_CHAPTER_FAIL = "DELETE_BTC_CHAPTER_FAIL";

export const BTC_CHAPTER_CLEAR_ERROR = "BTC_CHAPTER_CLEAR_ERROR";
