import {
    CREATE_USER_PROFILE_REQUEST,
    CREATE_USER_PROFILE_SUCCESS,
    CREATE_USER_PROFILE_FAIL,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    FIND_USER_PROFILE_REQUEST,
    FIND_USER_PROFILE_SUCCESS,
    FIND_USER_PROFILE_FAIL,
    UPDATE_USER_PROFILE_REQUEST,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAIL,
    // DELETE_USER_PROFILE_REQUEST,
    // DELETE_USER_PROFILE_SUCCESS,
    // DELETE_USER_PROFILE_FAIL,
    USER_PROFILE_CLEAR_ERROR,
} from "../../constants/User/UserProfileConstant";

export const getUserProfileReducer = (state = { userProfiles: [] }, action) => {
    switch (action.type) {
        case USER_PROFILE_REQUEST:
        case CREATE_USER_PROFILE_REQUEST:
        case UPDATE_USER_PROFILE_REQUEST:
            //   case DELETE_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
            };

        case USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfiles: action.payload,
                message: null,
                status: null,
            };

        case CREATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfiles: [...state.userProfiles, action.payload],
                message: action.message,
                status: action.status,
            };

        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfiles: [
                    ...state.userProfiles.filter(
                        (item) => item.profileId !== action.payload.profileId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.profileId - post.profileId),
                message: action.message,
                status: action.status,
            };

        //   case DELETE_USER_PROFILE_SUCCESS:
        //     return {
        //       ...state,
        //       loading: false,
        //       userProfiles: [
        //         ...state.userProfiles.filter(
        //           (item) => item.profileId !== action.payload
        //         ),
        //       ],
        //       message: action.message,
        //       status: action.status,
        //     };

        case USER_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
            };

        case CREATE_USER_PROFILE_FAIL:
        case UPDATE_USER_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        //   case DELETE_USER_PROFILE_FAIL:
        //     return {
        //       ...state,
        //       loading: false,
        //       error: action.payload,
        //     };

        case USER_PROFILE_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findUserProfileReducer = (state = { userProfile: {} }, action) => {
    switch (action.type) {
        case FIND_USER_PROFILE_REQUEST:
            return {
                userProfile: {},
                loading: true,
            };

        case FIND_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userProfile: action.payload,
            };

        case FIND_USER_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                userProfile: {},
                error: action.payload
            };

        case "EMPTY_USER_PROFILE":
            return {
                ...state,
                userProfile: {}
            }

        case USER_PROFILE_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
