import {
    CREATE_COA_DRC_BANK_MAP_REQUEST,
    CREATE_COA_DRC_BANK_MAP_SUCCESS,
    CREATE_COA_DRC_BANK_MAP_FAIL,
    COA_DRC_BANK_MAP_REQUEST,
    COA_DRC_BANK_MAP_SUCCESS,
    COA_DRC_BANK_MAP_FAIL,
    FIND_COA_DRC_BANK_MAP_REQUEST,
    FIND_COA_DRC_BANK_MAP_SUCCESS,
    FIND_COA_DRC_BANK_MAP_FAIL,
    UPDATE_COA_DRC_BANK_MAP_REQUEST,
    UPDATE_COA_DRC_BANK_MAP_SUCCESS,
    UPDATE_COA_DRC_BANK_MAP_FAIL,
    DELETE_COA_DRC_BANK_MAP_REQUEST,
    DELETE_COA_DRC_BANK_MAP_SUCCESS,
    DELETE_COA_DRC_BANK_MAP_FAIL,
    COA_DRC_BANK_MAP_CLEAR_ERROR,
  } from "../../constants/CoaMapping/CoaDrcBankMapConstant";
  
  export const getCoaDrcBankMapReducer = (state = { coaDrcBankMaps: [] }, action) => {
    switch (action.type) {
      case COA_DRC_BANK_MAP_REQUEST:
      case CREATE_COA_DRC_BANK_MAP_REQUEST:
      case UPDATE_COA_DRC_BANK_MAP_REQUEST:
      case DELETE_COA_DRC_BANK_MAP_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
        };
  
      case COA_DRC_BANK_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDrcBankMaps: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_COA_DRC_BANK_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDrcBankMaps: [...state.coaDrcBankMaps, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_COA_DRC_BANK_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDrcBankMaps: [
            ...state.coaDrcBankMaps.filter(
              (item) => item.drcBankAccountMappingId !== action.payload.drcBankAccountMappingId
            ),
            action.payload,
          ].sort((pre, post) => pre.drcBankAccountMappingId - post.drcBankAccountMappingId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_DRC_BANK_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDrcBankMaps: [
            ...state.coaDrcBankMaps.filter(
              (item) => item.drcBankAccountMappingId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  
      case COA_DRC_BANK_MAP_FAIL:
        return {
          ...state,
          loading: false,
        };
  
      case CREATE_COA_DRC_BANK_MAP_FAIL:
      case UPDATE_COA_DRC_BANK_MAP_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_DRC_BANK_MAP_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case COA_DRC_BANK_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findCoaDrcBankMapReducer = (state = { coaDrcBankMap: {} }, action) => {
    switch (action.type) {
      case FIND_COA_DRC_BANK_MAP_REQUEST:
        return {
          coaDrcBankMap: {},
          loading: true,
        };
  
      case FIND_COA_DRC_BANK_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDrcBankMap: action.payload,
        };
  
      case FIND_COA_DRC_BANK_MAP_FAIL:
        return {
          ...state,
          loading: false,
          coaDrcBankMap: {},
          error: action.payload
        };
  
      case "EMPTY_COA_DRC_BANK_MAP":
        return {
          coaDrcBankMap: {}
        }
  
      case COA_DRC_BANK_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  