import {
  CREATE_COUNTRY_REQUEST,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_FAIL,
  COUNTRY_REQUEST,
  COUNTRY_SUCCESS,
  COUNTRY_FAIL,
  FIND_COUNTRY_REQUEST,
  FIND_COUNTRY_SUCCESS,
  FIND_COUNTRY_FAIL,
  UPDATE_COUNTRY_REQUEST,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_FAIL,
  DELETE_COUNTRY_REQUEST,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_FAIL,
  COUNTRY_CLEAR_ERROR,
} from "../../constants/OthersMaster/CountryConstant";

// get all data of list
export const getCountryReducer = (state = { countries: [] }, action) => {
  switch (action.type) {
    case COUNTRY_REQUEST:
    case CREATE_COUNTRY_REQUEST:
    case UPDATE_COUNTRY_REQUEST:
    case DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: [...state.countries, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: [
          ...state.countries.filter(
            (item) => item.countryId !== action.payload.countryId
          ),
          action.payload,
        ].sort((pre, post) => pre.countryId - post.countryId),
        message: action.message,
        status: action.status,
      };

    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: [
          ...state.countries.filter(
            (item) => item.countryId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_COUNTRY_SUCCESS":
      return {
        loading: false,
        countries: [
          ...state.countries.filter(
            (item) => item.countryId !== action.payload.countryId
          ),
          action.payload,
        ].sort((pre, post) => pre.countryId - post.countryId),
        message: action.message,
        status: action.status,
      };

    case COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        countries: [...state.countries],
        error: action.payload,
      };

    case CREATE_COUNTRY_FAIL:
    case UPDATE_COUNTRY_FAIL:
    case DELETE_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        countries: [...state.countries],
        message: action.message,
        status: action.status,
      };

    case COUNTRY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findCountryReducer = (state = { country: {} }, action) => {
  switch (action.type) {
    case FIND_COUNTRY_REQUEST:
      return {
        country: {},
        loading: true,
      };

    case FIND_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        country: action.payload,
      };

    case FIND_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        country: {},
        error: action.payload,
      };

    case "EMPTY_COUNTRY":
      return {
        ...state,
        country: {},
      };

    case COUNTRY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
