import {
    CREATE_ACCOUNT_TYPE_FAIL,
    CREATE_ACCOUNT_TYPE_REQUEST,
    CREATE_ACCOUNT_TYPE_SUCCESS,
    DELETE_ACCOUNT_TYPE_FAIL,
    DELETE_ACCOUNT_TYPE_REQUEST,
    DELETE_ACCOUNT_TYPE_SUCCESS,
    ACCOUNT_TYPE_CLEAR_ERROR,
    ACCOUNT_TYPE_FAIL,
    ACCOUNT_TYPE_REQUEST,
    ACCOUNT_TYPE_SUCCESS,
    FIND_ACCOUNT_TYPE_FAIL,
    FIND_ACCOUNT_TYPE_REQUEST,
    FIND_ACCOUNT_TYPE_SUCCESS,
    UPDATE_ACCOUNT_TYPE_FAIL,
    UPDATE_ACCOUNT_TYPE_REQUEST,
    UPDATE_ACCOUNT_TYPE_SUCCESS,
  } from "../../constants/OthersMaster/AccountTypeConstant";
  
  // get all data of list
  export const getAccountTypeReducer = (
    state = { accountTypes: [] },
    action
  ) => {
    switch (action.type) {
      case ACCOUNT_TYPE_REQUEST:
      case CREATE_ACCOUNT_TYPE_REQUEST:
      case UPDATE_ACCOUNT_TYPE_REQUEST:
      case DELETE_ACCOUNT_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
      case ACCOUNT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          accountTypes: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_ACCOUNT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          accountTypes: [...state.accountTypes, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_ACCOUNT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          accountTypes: [
            ...state.accountTypes.filter(
              (item) => item.accountTypeId !== action.payload.accountTypeId
            ),
            action.payload,
          ].sort((pre, post) => pre.accountTypeId - post.accountTypeId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_ACCOUNT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          accountTypes: [
            ...state.accountTypes.filter(
              (item) => item.accountTypeId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  
      case "FUTURE_DELETE_ACCOUNT_TYPE_SUCCESS":
        return {
          loading: false,
          accountTypes: [
            ...state.accountTypes.filter(
              (item) => item.accountTypeId !== action.payload.accountTypeId
            ),
            action.payload,
          ].sort((pre, post) => pre.accountTypeId - post.accountTypeId),
          message: action.message,
          status: action.status,
        };
  
      case ACCOUNT_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          accountTypes: [...state.accountTypes],
          error: action.payload,
        };
  
      case CREATE_ACCOUNT_TYPE_FAIL:
      case UPDATE_ACCOUNT_TYPE_FAIL:
      case DELETE_ACCOUNT_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          accountTypes: [...state.accountTypes],
          message: action.message,
          status: action.status,
        };
  
      case ACCOUNT_TYPE_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findAccountTypeReducer = (
    state = { accountType: {} },
    action
  ) => {
    switch (action.type) {
      case FIND_ACCOUNT_TYPE_REQUEST:
        return {
          accountType: {},
          loading: true,
        };
  
      case FIND_ACCOUNT_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          accountType: action.payload,
        };
  
      case FIND_ACCOUNT_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          accountType: {},
          error: action.payload,
        };
  
      case "EMPTY_ACCOUNT_TYPE":
        return {
          ...state,
          accountType: {},
        };
  
      case ACCOUNT_TYPE_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  