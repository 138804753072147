import {
  CREATE_BTC_CHAPTER_REQUEST,
  CREATE_BTC_CHAPTER_SUCCESS,
  CREATE_BTC_CHAPTER_FAIL,
  BTC_CHAPTER_REQUEST,
  BTC_CHAPTER_SUCCESS,
  BTC_CHAPTER_FAIL,
  FIND_BTC_CHAPTER_REQUEST,
  FIND_BTC_CHAPTER_SUCCESS,
  FIND_BTC_CHAPTER_FAIL,
  UPDATE_BTC_CHAPTER_REQUEST,
  UPDATE_BTC_CHAPTER_SUCCESS,
  UPDATE_BTC_CHAPTER_FAIL,
  DELETE_BTC_CHAPTER_REQUEST,
  DELETE_BTC_CHAPTER_SUCCESS,
  DELETE_BTC_CHAPTER_FAIL,
  BTC_CHAPTER_CLEAR_ERROR,
} from "../../constants/BtcMaster/ChapterConstant";

// get all data of list
export const getBtcChaptersReducer = (state = { btcChapters: [] }, action) => {
  switch (action.type) {
    case BTC_CHAPTER_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        btcChapters: [],
        status: null,
        error: null,
      };

    case UPDATE_BTC_CHAPTER_REQUEST:
    case CREATE_BTC_CHAPTER_REQUEST:
    case DELETE_BTC_CHAPTER_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case BTC_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
        btcChapters: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case CREATE_BTC_CHAPTER_SUCCESS:
    case UPDATE_BTC_CHAPTER_SUCCESS:
    case DELETE_BTC_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BTC_CHAPTER_SUCCESS":
      return {
        loading: false,
        btcChapters: [
          ...state.btcChapters.filter(
            (item) => item.chapterId !== action.payload.chapterId
          ),
          action.payload,
        ].sort((pre, post) => pre.chapterId - post.chapterId),
        message: action.message,
        status: action.status,
      };

    case BTC_CHAPTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_BTC_CHAPTER_FAIL:
    case UPDATE_BTC_CHAPTER_FAIL:
    case DELETE_BTC_CHAPTER_FAIL:
      return {
        ...state,
        loading: false,
        btcChapters: [...state.btcChapters],
        message: action.message,
        status: action.status,
      };

    case BTC_CHAPTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBtcChapterReducer = (state = { btcChapter: {} }, action) => {
  switch (action.type) {
    case FIND_BTC_CHAPTER_REQUEST:
      return {
        btcChapter: {},
        loading: true,
      };

    case FIND_BTC_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
        btcChapter: action.payload,
      };

    case FIND_BTC_CHAPTER_FAIL:
      return {
        ...state,
        loading: false,
        btcChapter: {},
        error: action.payload,
      };

    case "EMPTY_BTC_CHAPTER":
      return {
        ...state,
        btcChapter: {},
      };

    case BTC_CHAPTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
