// find payment advice
export const FIND_PAYMENT_ADVICE_REQUEST = "FIND_PAYMENT_ADVICE_REQUEST";
export const FIND_PAYMENT_ADVICE_SUCCESS = "FIND_PAYMENT_ADVICE_SUCCESS";
export const FIND_PAYMENT_ADVICE_FAIL = "FIND_PAYMENT_ADVICE_FAIL";

//get service and glheads
export const GLHEADS_WITH_SERVICES_REQUEST = "GLHEADS_WITH_SERVICES_REQUEST";
export const GLHEADS_WITH_SERVICES_SUCCESS = "GLHEADS_WITH_SERVICES_SUCCESS";
export const GLHEADS_WITH_SERVICES_FAIL = "GLHEADS_WITH_SERVICES_FAIL";

//PAYER_TYPE get
export const PAYER_TYPE_REQUEST = "PAYER_TYPE_REQUEST";
export const PAYER_TYPE_SUCCESS = "PAYER_TYPE_SUCCESS";
export const PAYER_TYPE_FAIL = "PAYER_TYPE_FAIL";

//Clear Error
export const SERVICE_AND_GLHEADS_CLEAR_ERROR = "SERVICE_AND_GLHEADS_CLEAR_ERROR";
export const PAYER_TYPE_CLEAR_ERROR = "PAYER_TYPE_CLEAR_ERROR";