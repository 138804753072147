// BTC_SECTION create
export const CREATE_BTC_SECTION_REQUEST = "CREATE_BTC_SECTION_REQUEST";
export const CREATE_BTC_SECTION_SUCCESS = "CREATE_BTC_SECTION_SUCCESS";
export const CREATE_BTC_SECTION_FAIL = "CREATE_BTC_SECTION_FAIL";
export const CREATE_BTC_SECTION_CONFLICT = "CREATE_BTC_SECTION_CONFLICT";

// BTC_SECTION get
export const BTC_SECTION_REQUEST = "BTC_SECTION_REQUEST";
export const BTC_SECTION_SUCCESS = "BTC_SECTION_SUCCESS";
export const BTC_SECTION_FAIL = "BTC_SECTION_FAIL";

// BTC_SECTION find
export const FIND_BTC_SECTION_REQUEST = "FIND_BTC_SECTION_REQUEST";
export const FIND_BTC_SECTION_SUCCESS = "FIND_BTC_SECTION_SUCCESS";
export const FIND_BTC_SECTION_FAIL = "FIND_BTC_SECTION_FAIL";

//BTC_SECTION update/put
export const UPDATE_BTC_SECTION_REQUEST = "UPDATE_BTC_SECTION_REQUEST";
export const UPDATE_BTC_SECTION_SUCCESS = "UPDATE_BTC_SECTION_SUCCESS";
export const UPDATE_BTC_SECTION_FAIL = "UPDATE_BTC_SECTION_FAIL";

// BTC_SECTION delete
export const DELETE_BTC_SECTION_REQUEST = "DELETE_BTC_SECTION_REQUEST";
export const DELETE_BTC_SECTION_SUCCESS = "DELETE_BTC_SECTION_SUCCESS";
export const DELETE_BTC_SECTION_FAIL = "DELETE_BTC_SECTION_FAIL";

export const BTC_SECTION_CLEAR_ERROR = "BTC_SECTION_CLEAR_ERROR";
