import {
  CREATE_NOTIFICATION_LEVEL_FAIL,
  CREATE_NOTIFICATION_LEVEL_REQUEST,
  CREATE_NOTIFICATION_LEVEL_SUCCESS,
  NOTIFICATION_LEVEL_CLEAR_ERROR,
  NOTIFICATION_LEVEL_FAIL,
  NOTIFICATION_LEVEL_REQUEST,
  NOTIFICATION_LEVEL_SUCCESS,
  FIND_NOTIFICATION_LEVEL_FAIL,
  FIND_NOTIFICATION_LEVEL_REQUEST,
  FIND_NOTIFICATION_LEVEL_SUCCESS,
  UPDATE_NOTIFICATION_LEVEL_FAIL,
  UPDATE_NOTIFICATION_LEVEL_REQUEST,
  UPDATE_NOTIFICATION_LEVEL_SUCCESS,
  NOTIFY_TO_REQUEST,
  NOTIFY_TO_SUCCESS,
  NOTIFY_TO_FAIL
} from "../../constants_Phase_2/ArrearManagement/NotificationLevelConstant";

// get all data of list
export const getNotificationLevelReducer = (state = { notificationLevels: [] }, action) => {
  switch (action.type) {
    case NOTIFICATION_LEVEL_REQUEST:
    case CREATE_NOTIFICATION_LEVEL_REQUEST:
    case UPDATE_NOTIFICATION_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case NOTIFICATION_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationLevels: action.payload,
        message: null,
        status: null,
      };

    case CREATE_NOTIFICATION_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationLevels: [...state.notificationLevels, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_NOTIFICATION_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationLevels: [
          ...state.notificationLevels.filter(
            (item) => item.notificationLevelId !== action.payload.notificationLevelId
          ),
          action.payload,
        ].sort((pre, post) => pre.notificationLevelId - post.notificationLevelId),
        message: action.message,
        status: action.status,
      };

    case NOTIFICATION_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        notificationLevels: [...state.notificationLevels],
        error: action.payload,
      };

    case CREATE_NOTIFICATION_LEVEL_FAIL:
    case UPDATE_NOTIFICATION_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        notificationLevels: [...state.notificationLevels],
        message: action.message,
        status: action.status,
      };

    case NOTIFICATION_LEVEL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const getNotifyToReducer = (state = { notifyToList: [] }, action) => {
  switch (action.type) {
    case NOTIFY_TO_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case NOTIFY_TO_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyToList: action.payload,
        message: null,
        status: null,
      };

    case NOTIFY_TO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION_LEVEL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findNotificationLevelReducer = (state = { notificationLevel: {} }, action) => {
  switch (action.type) {
    case FIND_NOTIFICATION_LEVEL_REQUEST:
      return {
        notificationLevel: {},
        loading: true,
      };

    case FIND_NOTIFICATION_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationLevel: action.payload,
      };

    case FIND_NOTIFICATION_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        notificationLevel: {},
        error: action.payload,
      };

    case "EMPTY_NOTIFICATION_LEVEL":
      return {
        ...state,
        notificationLevel: {},
      };

    case NOTIFICATION_LEVEL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
