// create
export const CREATE_REVERSAL_REQUEST = "CREATE_REVERSAL_REQUEST";
export const CREATE_REVERSAL_SUCCESS = "CREATE_REVERSAL_SUCCESS";
export const CREATE_REVERSAL_FAIL = "CREATE_REVERSAL_FAIL";

// find
export const FIND_REVERSAL_REQUEST = "FIND_REVERSAL_REQUEST";
export const FIND_REVERSAL_SUCCESS = "FIND_REVERSAL_SUCCESS";
export const FIND_REVERSAL_FAIL = "FIND_REVERSAL_FAIL";

// update
export const UPDATE_REVERSAL_REQUEST = "UPDATE_REVERSAL_REQUEST";
export const UPDATE_REVERSAL_SUCCESS = "UPDATE_REVERSAL_SUCCESS";
export const UPDATE_REVERSAL_FAIL = "UPDATE_REVERSAL_FAIL";

export const REVERSAL_CLEAR_ERROR = "REVERSAL_CLEAR_ERROR";