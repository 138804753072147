import {
    CREATE_RECEIPT_VOUCHER_REQUEST,
    CREATE_RECEIPT_VOUCHER_SUCCESS,
    CREATE_RECEIPT_VOUCHER_FAIL,
    RECEIPT_VOUCHER_REQUEST,
    RECEIPT_VOUCHER_SUCCESS,
    RECEIPT_VOUCHER_FAIL,
    FIND_RECEIPT_VOUCHER_REQUEST,
    FIND_RECEIPT_VOUCHER_SUCCESS,
    FIND_RECEIPT_VOUCHER_FAIL,
    UPDATE_RECEIPT_VOUCHER_REQUEST,
    UPDATE_RECEIPT_VOUCHER_SUCCESS,
    UPDATE_RECEIPT_VOUCHER_FAIL,
    CLEAR_RECEIPT_VOUCHER_ERROR,
  } from "../../constants_Phase_2/ReceiptVoucher/ReceiptVoucherConstant";
  
  // create
  export const receiptVouchersReducer = ( state = { receiptVouchers: [] }, action ) => {
    switch (action.type) {
      case RECEIPT_VOUCHER_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          receiptVouchers: [],
          status: null,
          error: null,
        };
  
      case CREATE_RECEIPT_VOUCHER_REQUEST:
      case UPDATE_RECEIPT_VOUCHER_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case RECEIPT_VOUCHER_SUCCESS:
        return {
          ...state,
          loading: false,
          receiptVouchers: action.payload,
          totalData: action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };
  
      case CREATE_RECEIPT_VOUCHER_SUCCESS:
      case UPDATE_RECEIPT_VOUCHER_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case RECEIPT_VOUCHER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_RECEIPT_VOUCHER_FAIL:
      case UPDATE_RECEIPT_VOUCHER_FAIL:
        return {
          ...state,
          loading: false,
          receiptVouchers: [...state.receiptVouchers],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_RECEIPT_VOUCHER_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findReceiptVoucherReducer = ( state = { receiptVoucher: {} }, action ) => {
  
    switch (action.type) {
      case FIND_RECEIPT_VOUCHER_REQUEST:
        return {
          receiptVoucher: {},
          loading: true,
        };
  
      case FIND_RECEIPT_VOUCHER_SUCCESS:
        return {
          ...state,
          loading: false,
          receiptVoucher: action.payload,
        };
  
      case FIND_RECEIPT_VOUCHER_FAIL:
        return {
          ...state,
          loading: false,
          receiptVoucher: {},
          error: action.payload,
        };
  
      case "EMPTY_RECEIPT_VOUCHER":
        return {
          ...state,
          receiptVoucher: {},
        };
  
      case CLEAR_RECEIPT_VOUCHER_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  