import {
    FIND_PAYMENT_ADVICE_REQUEST,
    FIND_PAYMENT_ADVICE_SUCCESS,
    FIND_PAYMENT_ADVICE_FAIL,

    GLHEADS_WITH_SERVICES_REQUEST,
    GLHEADS_WITH_SERVICES_SUCCESS,
    GLHEADS_WITH_SERVICES_FAIL,

    PAYER_TYPE_REQUEST,
    PAYER_TYPE_SUCCESS,
    PAYER_TYPE_FAIL,
} from "../../constants/PaymentAdvice/PaymentAdviceConstant";


export const findPaymentAdviceReducer = (state = { paymentAdvice: {} }, action) => {
    switch (action.type) {
        case FIND_PAYMENT_ADVICE_REQUEST:
            return {
                loading: true,
                paymentAdvice: {},
                message: null,
            };

        case FIND_PAYMENT_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentAdvice: action.payload,
                message: action.message,
            };

        case FIND_PAYMENT_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                paymentAdvice: {},
                error: action.payload,
            };

        case "EMPTY_PAYMENT_ADVICE":
            return {
                ...state,
                paymentAdvice: {},
                message: null,
            };

        default:
            return state;
    }
};


//get list of service and glheads
export const getGlheadsWithServicesReducer = (state = { bankAccountWithGlHeads: [], glHeadsWithServices: [], glHeadsWithGstRate: [] }, action) => {
    switch (action.type) {
        case GLHEADS_WITH_SERVICES_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case GLHEADS_WITH_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                bankAccountWithGlHeads: action.payload?.coaBankAccountList,
                glHeadsWithServices: action.payload?.coaGlHeadList,
                glHeadsWithGstRate: action.payload?.coaNonTaxGstList,
                message: null,
                status: null,
            };

        case GLHEADS_WITH_SERVICES_FAIL:
            return {
                ...state,
                loading: false,
                bankAccountWithGlHeads: [...state.bankAccountWithGlHeads],
                glHeadsWithServices: [...state.glHeadsWithServices],
                glHeadsWithGstRate: [...state.glHeadsWithGstRate],
                error: action.payload,
            };

        case "EMPTY_GLHEADS_WITH_SERVICES":
            return {
                ...state,
                bankAccountWithGlHeads: [],
                glHeadsWithServices: [],
                glHeadsWithGstRate: []
            }

        default:
            return state;
    }
};


export const getPayerTypeReducer = (state = { payerTypes: [] }, action) => {
    switch (action.type) {
        case PAYER_TYPE_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case PAYER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                payerTypes: action.payload,
                message: null,
                status: null,
            };

        case PAYER_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                payerTypes: [...state.payerTypes],
                error: action.payload,
            };

        case "EMPTY_PAYER_TYPE":
            return {
                ...state,
                payerTypes: []
            }

        default:
            return state;
    }
};