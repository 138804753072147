import {
  //department create
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAIL,
  //update department
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,
  //delete department
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAIL,
  //get department list by ministry id
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAIL,
  //get all department list 
  DEPARTMENT_REQUEST,
  DEPARTMENT_SUCCESS,
  DEPARTMENT_FAIL,

  //find
  FIND_DEPARTMENT_REQUEST,
  FIND_DEPARTMENT_SUCCESS,
  FIND_DEPARTMENT_FAIL,

  AGENCY_MASTER_CLEAR_ERROR

} from "../../constants/AgencyMaster/AgencyMasterConstant";


//create department 
export const departmentReducer = (state = { departmentMaster: [] }, action) => {
  switch (action.type) {
    case DEPARTMENT_REQUEST:
    case CREATE_DEPARTMENT_REQUEST:
    case UPDATE_DEPARTMENT_REQUEST:
    case DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };
    case DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentMaster: action.payload,
        message: null,
        status: null,
      };
    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentMaster: [...state.departmentMaster, action.payload],
        message: action.message,
        status: action.status
      };
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentMaster: [...state.departmentMaster.filter(
          (item) => item.id !== action.payload.id
        ),
        action.payload,
        ].sort((pre, post) => pre.id - post.id),
        message: action.message,
        status: action.status
      };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentMaster: [
          ...state.departmentMaster.filter(
            (item) => item.id !== action.payload
          ),
        ],
        message: action.message,
        status: action.status
      };
    case "FUTURE_DELETE_DEPARTMENT_SUCCESS":
      return {
        loading: false,
        departmentMaster: [
          ...state.departmentMaster.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload,
        ].sort((pre, post) => pre.id - post.id),
        message: action.message,
        status: action.status,
      };


    case DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        departmentMaster: [...state.departmentMaster],
        error: action.payload,
      };

    case CREATE_DEPARTMENT_FAIL:
    case UPDATE_DEPARTMENT_FAIL:
    case DELETE_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        departmentMaster: [...state.departmentMaster],
        message: action.message,
        status: action.status,
      };
    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


//get department by ministry id
export const getDepartmentReducer = (state = { departmentById: [] }, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };
    case GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        departmentById: action.payload,
        message: null,
        status: null,
      };

    case GET_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        departmentById: [...state.departmentById],
        error: action.payload,
      };

      case "EMPTY_GET_DEPARTMENT":
        return {
          ...state,
          departmentById: [],
        };


    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


// find one
export const findDepartmentReducer = (state = { singleDepartmentMaster: {} }, action) => {
  switch (action.type) {
    case FIND_DEPARTMENT_REQUEST:
      return {
        singleDepartmentMaster: {},
        loading: true,
      };

    case FIND_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        singleDepartmentMaster: action.payload,
      };

    case FIND_DEPARTMENT_FAIL:
      return {
        ...state,
        loading: false,
        singleDepartmentMaster: {},
        error: action.payload,
      };

    case "EMPTY_AGENCY_DEPARTMENT":
      return {
        ...state,
        singleDepartmentMaster: {},
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};