import {
  PREPAYMENT_TYPE_REQUEST,
  PREPAYMENT_TYPE_SUCCESS,
  PREPAYMENT_TYPE_FAIL,
  CREATE_PREPAYMENT_TYPE_REQUEST,
  CREATE_PREPAYMENT_TYPE_SUCCESS,
  CREATE_PREPAYMENT_TYPE_FAIL,
  FIND_PREPAYMENT_TYPE_REQUEST,
  FIND_PREPAYMENT_TYPE_SUCCESS,
  FIND_PREPAYMENT_TYPE_FAIL,
  UPDATE_PREPAYMENT_TYPE_REQUEST,
  UPDATE_PREPAYMENT_TYPE_SUCCESS,
  UPDATE_PREPAYMENT_TYPE_FAIL,
  DELETE_PREPAYMENT_TYPE_REQUEST,
  DELETE_PREPAYMENT_TYPE_SUCCESS,
  DELETE_PREPAYMENT_TYPE_FAIL,
  CLEAR_ERROR,
} from "../../constants/OthersMaster/PrepaymentTypeConstant";

// create, update, delete and get all
export const getPrepaymentTypeReducer = (
  state = { prepaymentTypes: [] },
  action
) => {
  switch (action.type) {
    case PREPAYMENT_TYPE_REQUEST:
    case CREATE_PREPAYMENT_TYPE_REQUEST:
    case UPDATE_PREPAYMENT_TYPE_REQUEST:
    case DELETE_PREPAYMENT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case PREPAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        prepaymentTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_PREPAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        prepaymentTypes: [...state.prepaymentTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_PREPAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        prepaymentTypes: [
          ...state.prepaymentTypes.filter(
            (item) => item.prepaymentTypeId !== action.payload.prepaymentTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.prepaymentTypeId - post.prepaymentTypeId),
        message: action.message,
        status: action.status,
      };

    case DELETE_PREPAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        prepaymentTypes: [
          ...state.prepaymentTypes.filter(
            (item) => item.prepaymentTypeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_PREPAYMENT_TYPE_SUCCESS":
      return {
        loading: false,
        prepaymentTypes: [
          ...state.prepaymentTypes.filter(
            (item) => item.prepaymentTypeId !== action.payload.prepaymentTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.prepaymentTypeId - post.prepaymentTypeId),
        message: action.message,
        status: action.status,
      };

    case PREPAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        prepaymentTypes: [...state.prepaymentTypes],
        error: action.payload,
      };

    case CREATE_PREPAYMENT_TYPE_FAIL:
    case UPDATE_PREPAYMENT_TYPE_FAIL:
    case DELETE_PREPAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        prepaymentTypes: [...state.prepaymentTypes],
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findPrepaymentTypeReducer = (
  state = { prepaymentType: {} },
  action
) => {
  switch (action.type) {
    case FIND_PREPAYMENT_TYPE_REQUEST:
      return {
        prepaymentType: {},
        loading: true,
      };

    case FIND_PREPAYMENT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        prepaymentType: action.payload,
      };

    case FIND_PREPAYMENT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        prepaymentType: {},
        error: action.payload,
      };

    case "EMPTY_PREPAYMENT_TYPE":
      return {
        ...state,
        prepaymentType: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
