import {
  DEPOSIT_MAINTENANCE_REQUEST,
  DEPOSIT_MAINTENANCE_SUCCESS,
  DEPOSIT_MAINTENANCE_FAIL,

  FIND_DEPOSIT_MAINTENANCE_REQUEST,
  FIND_DEPOSIT_MAINTENANCE_SUCCESS,
  FIND_DEPOSIT_MAINTENANCE_FAIL,

  DEPOSIT_MAINTENANCE_CANCEL_REQUEST,
  DEPOSIT_MAINTENANCE_CANCEL_SUCCESS,
  DEPOSIT_MAINTENANCE_CANCEL_FAIL,

  DEPOSIT_MAINTENANCE_UNMAP_REQUEST,
  DEPOSIT_MAINTENANCE_UNMAP_SUCCESS,
  DEPOSIT_MAINTENANCE_UNMAP_FAIL,


  DEPOSIT_MAINTENANCE_CLEAR_ERROR
} from "../../constants/DepositMaintenance/DepositMaintenanceConstant";

export const getDepositMaintenanceReducer = (state = { depositMaintenances: [] }, action) => {
  switch (action.type) {
    case DEPOSIT_MAINTENANCE_REQUEST:
    case DEPOSIT_MAINTENANCE_CANCEL_REQUEST:
      return {
        ...state,
        depositMaintenances: [],
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case DEPOSIT_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        depositMaintenances: action.payload,
        totalData: action.totalData,
        message: null,
        status: null,
      };


    case DEPOSIT_MAINTENANCE_CANCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case DEPOSIT_MAINTENANCE_FAIL:
    case DEPOSIT_MAINTENANCE_CANCEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
        status: action.status,
      };

    case "EMPTY_DEPOSIT_MAINTENANCE":
      return {
        ...state,
        depositMaintenances: [],
      };

    case DEPOSIT_MAINTENANCE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


export const findDepositMaintenanceReducer = (state = { depositMaintenance: {} }, action) => {
  switch (action.type) {
    case FIND_DEPOSIT_MAINTENANCE_REQUEST:
      return {
        loading: true,
        depositMaintenance: {},
      };
    case FIND_DEPOSIT_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        depositMaintenance: action.payload,
      };

    case FIND_DEPOSIT_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        depositMaintenance: {},
        error: action.payload,
      };
    case "EMPTY_DEPOSIT_MAINTENANCE":
      return {
        ...state,
        depositMaintenance: {},
      };

    case DEPOSIT_MAINTENANCE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

// unmap
export const unmapDepositMaintenanceReducer = (state = { unmapDepositMaintenance: {} }, action) => {
  switch (action.type) {
    case DEPOSIT_MAINTENANCE_UNMAP_REQUEST:
      return {
        loading: true,
        error: null,
        unmapDepositMaintenance: {},
      };
    case DEPOSIT_MAINTENANCE_UNMAP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
        unmapDepositMaintenance: action.payload,
      };

    case DEPOSIT_MAINTENANCE_UNMAP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: action.message,
        status: action.status,
      };
    case "EMPTY_DEPOSIT_MAINTENANCE_UNMAP":
      return {
        unmapDepositMaintenance: {},
      };

    case DEPOSIT_MAINTENANCE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};