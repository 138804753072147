import { 
  CREATE_RAMIS_MAJOR_GROUP_REQUEST,
  CREATE_RAMIS_MAJOR_GROUP_SUCCESS,
  CREATE_RAMIS_MAJOR_GROUP_FAIL,
  RAMIS_MAJOR_GROUP_REQUEST,
  RAMIS_MAJOR_GROUP_SUCCESS,
  RAMIS_MAJOR_GROUP_FAIL,
  FIND_RAMIS_MAJOR_GROUP_REQUEST,
  FIND_RAMIS_MAJOR_GROUP_SUCCESS,
  FIND_RAMIS_MAJOR_GROUP_FAIL,
  UPDATE_RAMIS_MAJOR_GROUP_REQUEST,
  UPDATE_RAMIS_MAJOR_GROUP_SUCCESS,
  UPDATE_RAMIS_MAJOR_GROUP_FAIL,
  DELETE_RAMIS_MAJOR_GROUP_REQUEST,
  DELETE_RAMIS_MAJOR_GROUP_SUCCESS,
  DELETE_RAMIS_MAJOR_GROUP_FAIL,
  CLEAR_ERROR,
} from "../../constants/COARamis/RamisMajorGroupConstant";

export const ramisMajorGroupReducer = (state = { ramisMajorGroups: [] }, action) => {
  switch (action.type) {
    case RAMIS_MAJOR_GROUP_REQUEST:
    case CREATE_RAMIS_MAJOR_GROUP_REQUEST:
    case UPDATE_RAMIS_MAJOR_GROUP_REQUEST:
    case DELETE_RAMIS_MAJOR_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RAMIS_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisMajorGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RAMIS_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisMajorGroups: [...state.ramisMajorGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RAMIS_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisMajorGroups: [
          ...state.ramisMajorGroups.filter(
            (item) => item.majorGroupId !== action.payload.majorGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.majorGroupId - post.majorGroupId),
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisMajorGroups: [
          ...state.ramisMajorGroups.filter(
            (item) => item.majorGroupId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case RAMIS_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_RAMIS_MAJOR_GROUP_FAIL:
    case UPDATE_RAMIS_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRamisMajorGroupReducer = (state = { ramisMajorGroup: {} }, action) => {
  switch (action.type) {
    case FIND_RAMIS_MAJOR_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIND_RAMIS_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisMajorGroup: action.payload,
      };

    case FIND_RAMIS_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        ramisMajorGroup: {},
        error: action.payload
      };

    case "EMPTY_RAMIS_MAJOR_GROUP":
      return {
        ...state,
        ramisMajorGroup: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
