import {
  RISK_PROFILE_ENTITY_REQUEST,
  RISK_PROFILE_ENTITY_SUCCESS,
  RISK_PROFILE_ENTITY_FAIL,

  CREATE_RISK_PROFILE_ENTITY_REQUEST,
  CREATE_RISK_PROFILE_ENTITY_SUCCESS,
  CREATE_RISK_PROFILE_ENTITY_FAIL,

  FIND_RISK_PROFILE_ENTITY_REQUEST,
  FIND_RISK_PROFILE_ENTITY_SUCCESS,
  FIND_RISK_PROFILE_ENTITY_FAIL,

  UPDATE_RISK_PROFILE_ENTITY_REQUEST,
  UPDATE_RISK_PROFILE_ENTITY_SUCCESS,
  UPDATE_RISK_PROFILE_ENTITY_FAIL,

  DELETE_RISK_PROFILE_ENTITY_REQUEST,
  DELETE_RISK_PROFILE_ENTITY_SUCCESS,
  DELETE_RISK_PROFILE_ENTITY_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskProfileEntityConstant";

// create, update, delete and get all
export const getRiskProfileEntitiesReducer = (state = { riskProfileEntities: [] }, action) => {
  switch (action.type) {
    case RISK_PROFILE_ENTITY_REQUEST:
    case CREATE_RISK_PROFILE_ENTITY_REQUEST:
    case UPDATE_RISK_PROFILE_ENTITY_REQUEST:
    case DELETE_RISK_PROFILE_ENTITY_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_PROFILE_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfileEntities: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_PROFILE_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfileEntities: [...state.riskProfileEntities, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_PROFILE_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfileEntities: state.riskProfileEntities.map((item) =>
          (item.riskProfileEntityId === action.payload.riskProfileEntityId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_PROFILE_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfileEntities: action?.payload?.isDelete ?
          state.riskProfileEntities.filter((item) =>
            item.riskProfileEntityId !== action.payload.riskProfileEntityId
          )
          :
          state.riskProfileEntities.map((item) =>
            (item.riskProfileEntityId === action.payload.riskProfileEntityId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_PROFILE_ENTITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_PROFILE_ENTITY_FAIL:
    case UPDATE_RISK_PROFILE_ENTITY_FAIL:
    case DELETE_RISK_PROFILE_ENTITY_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskProfileEntityReducer = (state = { riskProfileEntity: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_PROFILE_ENTITY_REQUEST:
      return {
        loading: true,
        riskProfileEntity: {},
      };

    case FIND_RISK_PROFILE_ENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfileEntity: action.payload,
      };

    case FIND_RISK_PROFILE_ENTITY_FAIL:
      return {
        ...state,
        loading: false,
        riskProfileEntity: {},
        error: action.payload,
      };

    case "EMPTY_RISK_PROFILE_ENTITY":
      return {
        riskProfileEntity: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
