
export const CREATE_VENDOR_REGISTRATION_REQUEST = "CREATE_VENDOR_REGISTRATION_REQUEST";
export const CREATE_VENDOR_REGISTRATION_SUCCESS = "CREATE_VENDOR_REGISTRATION_SUCCESS";
export const CREATE_VENDOR_REGISTRATION_FAIL = "CREATE_VENDOR_REGISTRATION_FAIL";

export const VENDOR_REGISTRATION_REQUEST = "VENDOR_REGISTRATION_REQUEST";
export const VENDOR_REGISTRATION_SUCCESS = "VENDOR_REGISTRATION_SUCCESS";
export const VENDOR_REGISTRATION_FAIL = "VENDOR_REGISTRATION_FAIL";


export const FIND_VENDOR_REGISTRATION_REQUEST = "FIND_VENDOR_REGISTRATION_REQUEST";
export const FIND_VENDOR_REGISTRATION_SUCCESS = "FIND_VENDOR_REGISTRATION_SUCCESS";
export const FIND_VENDOR_REGISTRATION_FAIL = "FIND_VENDOR_REGISTRATION_FAIL";


export const UPDATE_VENDOR_REGISTRATION_REQUEST = "UPDATE_VENDOR_REGISTRATION_REQUEST";
export const UPDATE_VENDOR_REGISTRATION_SUCCESS = "UPDATE_VENDOR_REGISTRATION_SUCCESS";
export const UPDATE_VENDOR_REGISTRATION_FAIL = "UPDATE_VENDOR_REGISTRATION_FAIL";

export const FIND_VENDOR_INFORMATION_REQUEST = "FIND_VENDOR_INFORMATION_REQUEST";
export const FIND_VENDOR_INFORMATION_SUCCESS = "FIND_VENDOR_INFORMATION_SUCCESS";
export const FIND_VENDOR_INFORMATION_FAIL = "FIND_VENDOR_INFORMATION_FAIL";

export const CLEAR_VENDOR_REGISTRATION_ERROR = "CLEAR_VENDOR_REGISTRATION_ERROR";