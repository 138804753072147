// create
export const CREATE_GEWOGS_REQUEST = "CREATE_GEWOGS_REQUEST";
export const CREATE_GEWOGS_SUCCESS = "CREATE_GEWOGS_SUCCESS";
export const CREATE_GEWOGS_FAIL = "CREATE_GEWOGS_FAIL";
// get
export const GEWOGS_REQUEST = "GEWOGS_REQUEST";
export const GEWOGS_SUCCESS = "GEWOGS_SUCCESS";
export const GEWOGS_FAIL = "GEWOGS_FAIL";
// find
export const FIND_GEWOGS_REQUEST = "FIND_GEWOGS_REQUEST";
export const FIND_GEWOGS_SUCCESS = "FIND_GEWOGS_SUCCESS";
export const FIND_GEWOGS_FAIL = "FIND_GEWOGS_FAIL";

// update
export const UPDATE_GEWOGS_REQUEST = "UPDATE_GEWOGS_REQUEST";
export const UPDATE_GEWOGS_SUCCESS = "UPDATE_GEWOGS_SUCCESS";
export const UPDATE_GEWOGS_FAIL = "UPDATE_GEWOGS_FAIL";

// delete
export const DELETE_GEWOGS_REQUEST = "DELETE_GEWOGS_REQUEST";
export const DELETE_GEWOGS_SUCCESS = "DELETE_GEWOGS_SUCCESS";
export const DELETE_GEWOGS_FAIL = "DELETE_GEWOGS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";