// create
export const CREATE_USER_ROLE_REQUEST = "CREATE_USER_ROLE_REQUEST";
export const CREATE_USER_ROLE_SUCCESS = "CREATE_USER_ROLE_SUCCESS";
export const CREATE_USER_ROLE_FAIL = "CREATE_USER_ROLE_FAIL";

// get
export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_FAIL = "USER_ROLE_FAIL";

// find
export const FIND_USER_ROLE_REQUEST = "FIND_USER_ROLE_REQUEST";
export const FIND_USER_ROLE_SUCCESS = "FIND_USER_ROLE_SUCCESS";
export const FIND_USER_ROLE_FAIL = "FIND_USER_ROLE_FAIL";

// update/put
export const UPDATE_USER_ROLE_REQUEST = "UPDATE_USER_ROLE_REQUEST";
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
export const UPDATE_USER_ROLE_FAIL = "UPDATE_USER_ROLE_FAIL";

// delete
// export const DELETE_USER_ROLE_REQUEST = "DELETE_USER_ROLE_REQUEST";
// export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS";
// export const DELETE_USER_ROLE_FAIL = "DELETE_USER_ROLE_FAIL";

// find role main menu list
export const FIND_USER_ROLE_ASSIGN_MENU_REQUEST =
  "FIND_USER_ROLE_ASSIGN_MENU_REQUEST";
export const FIND_USER_ROLE_ASSIGN_MENU_SUCCESS =
  "FIND_USER_ROLE_ASSIGN_MENU_SUCCESS";
export const FIND_USER_ROLE_ASSIGN_MENU_FAIL =
  "FIND_USER_ROLE_ASSIGN_MENU_FAIL";


export const USER_ROLE_CLEAR_ERROR = "USER_ROLE_CLEAR_ERROR";
