import {
    AGENCY_RECEIPT_STOCK_REQUEST,
    AGENCY_RECEIPT_STOCK_SUCCESS,
    AGENCY_RECEIPT_STOCK_FAIL,
    CREATE_AGENCY_RECEIPT_STOCK_REQUEST,
    CREATE_AGENCY_RECEIPT_STOCK_SUCCESS,
    CREATE_AGENCY_RECEIPT_STOCK_FAIL,
    FIND_AGENCY_RECEIPT_STOCK_REQUEST,
    FIND_AGENCY_RECEIPT_STOCK_SUCCESS,
    FIND_AGENCY_RECEIPT_STOCK_FAIL,
    UPDATE_AGENCY_RECEIPT_STOCK_REQUEST,
    UPDATE_AGENCY_RECEIPT_STOCK_SUCCESS,
    UPDATE_AGENCY_RECEIPT_STOCK_FAIL,
    CLEAR_AGENCY_RECEIPT_STOCK_ERROR,
  } from "../../constants_Phase_2/ReceiptStock/AgencyReceiptStockConstant";
  
  // create
  export const getAgencyReceiptStockReducer = (state = { agencyReceiptStocks: [] }, action) => {
    switch (action.type) {
      case AGENCY_RECEIPT_STOCK_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          agencyReceiptStocks: [],
          status: null,
          error: null,
        };

      case AGENCY_RECEIPT_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          agencyReceiptStocks: action.payload,
          totalData : action.totalData,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };
  
      case AGENCY_RECEIPT_STOCK_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_AGENCY_RECEIPT_STOCK_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

   // create
   export const getAgencyReceiptStockResponseReducer = (state = { agencyReceiptStockResponse: [] }, action) => {
    switch (action.type) {
      case CREATE_AGENCY_RECEIPT_STOCK_REQUEST:
      case UPDATE_AGENCY_RECEIPT_STOCK_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case CREATE_AGENCY_RECEIPT_STOCK_SUCCESS:
      case UPDATE_AGENCY_RECEIPT_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case CREATE_AGENCY_RECEIPT_STOCK_FAIL:
      case UPDATE_AGENCY_RECEIPT_STOCK_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_AGENCY_RECEIPT_STOCK_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findAgencyReceiptStockReducer = (state = { agencyReceiptStock: {} }, action) => {
    switch (action.type) {
      case FIND_AGENCY_RECEIPT_STOCK_REQUEST:
        return {
          loading: true,
          agencyReceiptStock: {},
        };
      case FIND_AGENCY_RECEIPT_STOCK_SUCCESS:
        return {
          ...state,
          loading: false,
          agencyReceiptStock: action.payload,
        };
  
      case FIND_AGENCY_RECEIPT_STOCK_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case "EMPTY_AGENCY_RECEIPT_STOCK":
        return {
          ...state,
          agencyReceiptStock: {},
        };
  
      case CLEAR_AGENCY_RECEIPT_STOCK_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };

   // find one
   export const findAgencyUserReducer = (state = { agencyUser: [] }, action) => {
    switch (action.type) {
      case "AGENCY_USER_REQUEST":
        return {
          loading: true,
          agencyUser: [],
        };
      case "AGENCY_USER_SUCCESS":
        return {
          ...state,
          loading: false,
          agencyUser: action.payload,
        };
  
      case "AGENCY_USER_FAIL":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case "EMPTY_AGENCY_USER":
        return {
          ...state,
          agencyUser: [],
        };
  
      default:
        return state;
    }
  };
  
     // find one
     export const findRrcoAgencyUserReducer = (state = { rrcoAgencyUser: [] }, action) => {
      switch (action.type) {
        case "RRCO_AGENCY_USER_REQUEST":
          return {
            loading: true,
            rrcoAgencyUser: [],
          };
        case "RRCO_AGENCY_USER_SUCCESS":
          return {
            ...state,
            loading: false,
            rrcoAgencyUser: action.payload,
          };
    
        case "RRCO_AGENCY_USER_FAIL":
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case "EMPTY_RRCO_AGENCY_USER":
          return {
            ...state,
            rrcoAgencyUser: [],
          };
    
        default:
          return state;
      }
    };
    