import {
  RISK_PARAMETER_VALUE_REQUEST,
  RISK_PARAMETER_VALUE_SUCCESS,
  RISK_PARAMETER_VALUE_FAIL,

  CREATE_RISK_PARAMETER_VALUE_REQUEST,
  CREATE_RISK_PARAMETER_VALUE_SUCCESS,
  CREATE_RISK_PARAMETER_VALUE_FAIL,

  FIND_RISK_PARAMETER_VALUE_REQUEST,
  FIND_RISK_PARAMETER_VALUE_SUCCESS,
  FIND_RISK_PARAMETER_VALUE_FAIL,

  UPDATE_RISK_PARAMETER_VALUE_REQUEST,
  UPDATE_RISK_PARAMETER_VALUE_SUCCESS,
  UPDATE_RISK_PARAMETER_VALUE_FAIL,

  DELETE_RISK_PARAMETER_VALUE_REQUEST,
  DELETE_RISK_PARAMETER_VALUE_SUCCESS,
  DELETE_RISK_PARAMETER_VALUE_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskParameterValueConstant";

// create, update, delete and get all
export const getRiskParameterValuesReducer = (state = { riskParameterValues: [] }, action) => {
  switch (action.type) {
    case RISK_PARAMETER_VALUE_REQUEST:
    case CREATE_RISK_PARAMETER_VALUE_REQUEST:
    case UPDATE_RISK_PARAMETER_VALUE_REQUEST:
    case DELETE_RISK_PARAMETER_VALUE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_PARAMETER_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterValues: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_PARAMETER_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterValues: [...state.riskParameterValues, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_PARAMETER_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterValues: state.riskParameterValues.map((item) =>
          (item.riskParameterValueId === action.payload.riskParameterValueId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_PARAMETER_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterValues: action?.payload?.isDelete ?
          state.riskParameterValues.filter((item) =>
            item.riskParameterValueId !== action.payload.riskParameterValueId
          )
          :
          state.riskParameterValues.map((item) =>
            (item.riskParameterValueId === action.payload.riskParameterValueId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_PARAMETER_VALUE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_PARAMETER_VALUE_FAIL:
    case UPDATE_RISK_PARAMETER_VALUE_FAIL:
    case DELETE_RISK_PARAMETER_VALUE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskParameterValueReducer = (state = { riskParameterValue: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_PARAMETER_VALUE_REQUEST:
      return {
        loading: true,
        riskParameterValue: {},
      };

    case FIND_RISK_PARAMETER_VALUE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterValue: action.payload,
      };

    case FIND_RISK_PARAMETER_VALUE_FAIL:
      return {
        ...state,
        loading: false,
        riskParameterValue: {},
        error: action.payload,
      };

    case "EMPTY_RISK_PARAMETER_VALUE":
      return {
        riskParameterValue: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};