import {
  CREATE_REV_GL_HEAD_REQUEST,
  CREATE_REV_GL_HEAD_SUCCESS,
  CREATE_REV_GL_HEAD_FAIL,
  REV_GL_HEAD_REQUEST,
  REV_GL_HEAD_SUCCESS,
  REV_GL_HEAD_FAIL,
  FIND_REV_GL_HEAD_REQUEST,
  FIND_REV_GL_HEAD_SUCCESS,
  FIND_REV_GL_HEAD_FAIL,
  UPDATE_REV_GL_HEAD_REQUEST,
  UPDATE_REV_GL_HEAD_SUCCESS,
  UPDATE_REV_GL_HEAD_FAIL,
  DELETE_REV_GL_HEAD_REQUEST,
  DELETE_REV_GL_HEAD_SUCCESS,
  DELETE_REV_GL_HEAD_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/RevGLHeadConstant";

export const revGlHeadReducer = (state = { revGlHeads: [] }, action) => {
  switch (action.type) {
    case REV_GL_HEAD_REQUEST:
    case CREATE_REV_GL_HEAD_REQUEST:
    case UPDATE_REV_GL_HEAD_REQUEST:
    case DELETE_REV_GL_HEAD_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case REV_GL_HEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        revGlHeads: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REV_GL_HEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        revGlHeads: [...state.revGlHeads, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REV_GL_HEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        revGlHeads: [
          ...state.revGlHeads.filter(
            (item) => item.glHeadId !== action.payload.glHeadId
          ),
          action.payload,
        ].sort((pre, post) => pre.glHeadId - post.glHeadId),
        message: action.message,
        status: action.status,
      };

    case DELETE_REV_GL_HEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        revGlHeads: [
          ...state.revGlHeads.filter(
            (item) => item.glHeadId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_REV_GL_HEAD_SUCCESS":
      return {
        loading: false,
        revGlHeads: [
          ...state.revGlHeads.filter(
            (item) => item.glHeadId !== action.payload.glHeadId
          ),
          action.payload,
        ].sort((pre, post) => pre.glHeadId - post.glHeadId),
        message: action.message,
        status: action.status,
      };

    case REV_GL_HEAD_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_REV_GL_HEAD_FAIL:
    case UPDATE_REV_GL_HEAD_FAIL:
    case DELETE_REV_GL_HEAD_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRevGlHeadReducer = (state = { revGlHead: {} }, action) => {
  switch (action.type) {
    case FIND_REV_GL_HEAD_REQUEST:
      return {
        revGlHead: {},
        loading: true,
      };

    case FIND_REV_GL_HEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        revGlHead: action.payload,
      };

    case FIND_REV_GL_HEAD_FAIL:
      return {
        ...state,
        loading: false,
        revGlHead: {},
        error: action.payload,
      };

    case "EMPTY_REV_GL_HEAD":
      return {
        ...state,
        revGlHead: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
