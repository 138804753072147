// Business Sector create
export const CREATE_SERVICE_REQUEST = "CREATE_SERVICE_REQUEST";
export const CREATE_SERVICE_SUCCESS = "CREATE_SERVICE_SUCCESS";
export const CREATE_SERVICE_FAIL = "CREATE_SERVICE_FAIL"

// Business Sector get
export const SERVICE_REQUEST = "SERVICE_REQUEST";
export const SERVICE_SUCCESS = "SERVICE_SUCCESS";
export const SERVICE_FAIL = "SERVICE_FAIL"


// Business Sector find
export const FIND_SERVICE_REQUEST = "FIND_SERVICE_REQUEST";
export const FIND_SERVICE_SUCCESS = "FIND_SERVICE_SUCCESS";
export const FIND_SERVICE_FAIL = "FIND_SERVICE_FAIL";


//Business Sector update/put
export const UPDATE_SERVICE_REQUEST = "UPDATE_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "UPDATE_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "UPDATE_SERVICE_FAIL"


// Business Sector office update/put
export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL"


export const SERVICE_CLEAR_ERROR = "SERVICE_CLEAR_ERROR"