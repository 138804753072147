import {
    SELECTED_IDS_PAYMENT_ADVICE_REQUEST,
    SELECTED_IDS_PAYMENT_ADVICE_SUCCESS,
    SELECTED_IDS_PAYMENT_ADVICE_FAIL,

    FIND_GLHEADS_REWARD_PAYMENT_REQUEST,
    FIND_GLHEADS_REWARD_PAYMENT_SUCCESS,
    FIND_GLHEADS_REWARD_PAYMENT_FAIL,

    FIND_PREPAYMENT_BALANCE_REQUEST,
    FIND_PREPAYMENT_BALANCE_SUCCESS,
    FIND_PREPAYMENT_BALANCE_FAIL,

    FIND_USER_RECEIPT_STOCK_REQUEST,
    FIND_USER_RECEIPT_STOCK_SUCCESS,
    FIND_USER_RECEIPT_STOCK_FAIL,

    CASH_DENOMINATIONS_REQUEST,
    CASH_DENOMINATIONS_SUCCESS,
    CASH_DENOMINATIONS_FAIL,

    FIND_IDENTITY_TYPE_PAYMENT_ADVICE_REQUEST,
    FIND_IDENTITY_TYPE_PAYMENT_ADVICE_SUCCESS,
    FIND_IDENTITY_TYPE_PAYMENT_ADVICE_FAIL,

    CREATE_COUNTER_PAYMENT_REQUEST,
    CREATE_COUNTER_PAYMENT_SUCCESS,
    CREATE_COUNTER_PAYMENT_FAIL,

    COUNTER_PAYMENT_CLEAR_ERROR
} from "../../constants/CounterPayment/CounterPaymentConstant";


export const getSelectedIdsPaymentAdvice = (state = { selectedIdsPaymentAdvice: [] }, action) => {
    switch (action.type) {
        case SELECTED_IDS_PAYMENT_ADVICE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SELECTED_IDS_PAYMENT_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                selectedIdsPaymentAdvice:
                    action?.payload?.map(item => ({
                        ...item,
                        paymentReceiptGlHeadList: [
                            ...(item?.paymentAdviceGlHeadList || []),
                            ...(item?.paymentReceiptGlHeadList || [])
                        ],
                    })) || [],
                status: action?.status,
            };

        case SELECTED_IDS_PAYMENT_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: action?.status,
            };

        case "EMPTY_SELECTED_IDS_PAYMENT_ADVICE":
            return {
                selectedIdsPaymentAdvice: [],
                status: 200,
            }

        default:
            return state;
    }
};

export const findGlHeadsRewardPaymentReducer = (state = { glHeadsRewardPayment: {} }, action) => {
    switch (action.type) {
        case FIND_GLHEADS_REWARD_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FIND_GLHEADS_REWARD_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                glHeadsRewardPayment: action.payload,
            };

        case FIND_GLHEADS_REWARD_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "EMPTY_FIND_GLHEADS_REWARD_PAYMENT":
            return {
                glHeadsRewardPayment: {}
            }

        default:
            return state;
    }
};


export const findPrepaymentBalanceReducer = (state = { prepaymentBalance: 0 }, action) => {
    switch (action.type) {
        case FIND_PREPAYMENT_BALANCE_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case FIND_PREPAYMENT_BALANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                prepaymentBalance: action?.payload,
            };

        case FIND_PREPAYMENT_BALANCE_FAIL:
            return {
                prepaymentBalance: 0,
                loading: false,
                error: action?.payload,
                message: action?.message,
                status: action?.status
            };

        case "EMPTY_FIND_PREPAYMENT_BALANCE":
            return {
                prepaymentBalance: 0
            }

        default:
            return state;
    }
};


export const findUserReceiptStockReducer = (state = { userReceiptStock: {} }, action) => {
    switch (action.type) {
        case FIND_USER_RECEIPT_STOCK_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case FIND_USER_RECEIPT_STOCK_SUCCESS:
            return {
                ...state,
                loading: false,
                userReceiptStock: action?.payload,
            };

        case FIND_USER_RECEIPT_STOCK_FAIL:
            return {
                userReceiptStock: {},
                loading: false,
                error: action?.payload,
                message: action?.message,
                status: action?.status
            };

        case "EMPTY_FIND_USER_RECEIPT_STOCK":
            return {
                userReceiptStock: {}
            }

        default:
            return state;
    }
};

export const getCashDenominationsReducer = (state = { cashDenominations: [] }, action) => {
    switch (action.type) {
        case CASH_DENOMINATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case CASH_DENOMINATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                cashDenominations: action?.payload,
            };

        case CASH_DENOMINATIONS_FAIL:
            return {
                cashDenominations: {},
                loading: false,
                error: action?.payload,
                message: action?.message,
                status: action?.status
            };

        default:
            return state;
    }
};

export const findIdentityTypePaymentAdviceReducer = (state = { identityTypePaymentAdvice: [] }, action) => {
    switch (action.type) {
        case FIND_IDENTITY_TYPE_PAYMENT_ADVICE_REQUEST:
            return {
                ...state,
                identityTypePaymentAdvice: [],
                loading: true,
                message: null,
                status: null,
                error: null
            };

        case FIND_IDENTITY_TYPE_PAYMENT_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                identityTypePaymentAdvice: action.payload,
                totalData: action.totalData,
                message: action.message
            };

        case FIND_IDENTITY_TYPE_PAYMENT_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                identityTypePaymentAdvice: [],
                error: action.payload,
            };
        case "EMPTY_IDENTITY_TYPE_PAYMENT_ADVICE":
            return {
                ...state,
                identityTypePaymentAdvice: [],
                status: null,
                message: null,
                totalData: 0,
            };

        default:
            return state;
    }
};

// create, get, update,delete
export const getCounterPaymentResponseReducer = (state = { counterPaymentResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_COUNTER_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
                status: null,
                message: null,
                error: null
            };

        case CREATE_COUNTER_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                counterPaymentResponse: action.payload,
                message: action.message,
                status: action.status
            };

        case CREATE_COUNTER_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                counterPaymentResponse: {},
                message: action.message,
                status: action.status,
            };

        case COUNTER_PAYMENT_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_COUNTER_PAYMENT":
            return {
                ...state,
                counterPaymentResponse: {}
            }

        default:
            return state;
    }
};