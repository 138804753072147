import {
    CREATE_ACTION_TO_BE_TAKEN_REQUEST,
    CREATE_ACTION_TO_BE_TAKEN_SUCCESS,
    CREATE_ACTION_TO_BE_TAKEN_FAIL,
    ACTION_TO_BE_TAKEN_REQUEST,
    ACTION_TO_BE_TAKEN_SUCCESS,
    ACTION_TO_BE_TAKEN_FAIL,
    FIND_ACTION_TO_BE_TAKEN_REQUEST,
    FIND_ACTION_TO_BE_TAKEN_SUCCESS,
    FIND_ACTION_TO_BE_TAKEN_FAIL,
    UPDATE_ACTION_TO_BE_TAKEN_REQUEST,
    UPDATE_ACTION_TO_BE_TAKEN_SUCCESS,
    UPDATE_ACTION_TO_BE_TAKEN_FAIL,
    FIND_ACTION_TO_BE_TAKEN_DETAILS_REQUEST,
    FIND_ACTION_TO_BE_TAKEN_DETAILS_SUCCESS,
    FIND_ACTION_TO_BE_TAKEN_DETAILS_FAIL,
    CLEAR_ACTION_TO_BE_TAKEN_ERROR,
  } from "../../constants_Phase_2/AuditManagement/ActionToBeTakenConstant.js";
  

  export const actionToBeTakenListReducer = ( state = { actionToBeTakenList: [] }, action ) => {
    switch (action.type) {
      case ACTION_TO_BE_TAKEN_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          actionToBeTakenList: [],
          status: null,
          error: null,
        };
  
      case CREATE_ACTION_TO_BE_TAKEN_REQUEST:
      case UPDATE_ACTION_TO_BE_TAKEN_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case ACTION_TO_BE_TAKEN_SUCCESS:
        return {
          ...state,
          loading: false,
          actionToBeTakenList: action.payload,
          totalData: action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };
  
      case CREATE_ACTION_TO_BE_TAKEN_SUCCESS:
      case UPDATE_ACTION_TO_BE_TAKEN_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case ACTION_TO_BE_TAKEN_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_ACTION_TO_BE_TAKEN_FAIL:
      case UPDATE_ACTION_TO_BE_TAKEN_FAIL:
        return {
          ...state,
          loading: false,
          actionToBeTakenList: [...state.actionToBeTakenList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_ACTION_TO_BE_TAKEN_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  export const findActionToBeTakenDetailsReducer = ( state = { actionToBeTakenDetails: [] }, action ) => {
    switch (action.type) {
      case FIND_ACTION_TO_BE_TAKEN_DETAILS_REQUEST:
        return {
          actionToBeTakenDetails: [],
          loading: true,
        };
  
      case FIND_ACTION_TO_BE_TAKEN_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          actionToBeTakenDetails: action.payload,
        };
  
      case FIND_ACTION_TO_BE_TAKEN_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          actionToBeTakenDetails: [],
          error: action.payload,
        };
  
      case "CTION_TO_BE_TAKEN_DETAILS":
        return {
          actionToBeTakenDetails: [],
        };
  
      case  CLEAR_ACTION_TO_BE_TAKEN_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };

  export const findActionToBeTakenReducer = ( state = { actionToBeTaken: {} }, action ) => {
  
    switch (action.type) {
      case FIND_ACTION_TO_BE_TAKEN_REQUEST:
        return {
          actionToBeTaken: {},
          loading: true,
        };
  
      case FIND_ACTION_TO_BE_TAKEN_SUCCESS:
        return {
          ...state,
          loading: false,
          actionToBeTaken: action.payload,
        };
  
      case FIND_ACTION_TO_BE_TAKEN_FAIL:
        return {
          ...state,
          loading: false,
          actionToBeTaken: {},
          error: action.payload,
        };
  
      case "EMPTY_ACTION_TO_BE_TAKEN":
        return {
          ...state,
          actionToBeTaken: {},
        };
  
      case CLEAR_ACTION_TO_BE_TAKEN_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  