import {
  RISK_ACTION_REQUEST,
  RISK_ACTION_SUCCESS,
  RISK_ACTION_FAIL,

  CREATE_RISK_ACTION_REQUEST,
  CREATE_RISK_ACTION_SUCCESS,
  CREATE_RISK_ACTION_FAIL,

  FIND_RISK_ACTION_REQUEST,
  FIND_RISK_ACTION_SUCCESS,
  FIND_RISK_ACTION_FAIL,

  UPDATE_RISK_ACTION_REQUEST,
  UPDATE_RISK_ACTION_SUCCESS,
  UPDATE_RISK_ACTION_FAIL,

  DELETE_RISK_ACTION_REQUEST,
  DELETE_RISK_ACTION_SUCCESS,
  DELETE_RISK_ACTION_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskActionConstant";

// create, update, delete and get all
export const getRiskActionsReducer = (state = { riskActions: [] }, action) => {
  switch (action.type) {
    case RISK_ACTION_REQUEST:
    case CREATE_RISK_ACTION_REQUEST:
    case UPDATE_RISK_ACTION_REQUEST:
    case DELETE_RISK_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        riskActions: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        riskActions: [...state.riskActions, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        riskActions: state.riskActions.map((item) =>
          (item.riskActionId === action.payload.riskActionId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        riskActions: action?.payload?.isDelete ?
          state.riskActions.filter((item) =>
            item.riskActionId !== action.payload.riskActionId
          )
          :
          state.riskActions.map((item) =>
            (item.riskActionId === action.payload.riskActionId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_ACTION_FAIL:
    case UPDATE_RISK_ACTION_FAIL:
    case DELETE_RISK_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskActionReducer = (state = { riskAction: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_ACTION_REQUEST:
      return {
        loading: true,
        riskAction: {},
      };

    case FIND_RISK_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAction: action.payload,
      };

    case FIND_RISK_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        riskAction: {},
        error: action.payload,
      };

    case "EMPTY_RISK_ACTION":
      return {
        riskAction: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
