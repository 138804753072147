// create/post
export const CREATE_AUDIT_TYPE_REQUEST = "CREATE_AUDIT_TYPE_REQUEST";
export const CREATE_AUDIT_TYPE_SUCCESS = "CREATE_AUDIT_TYPE_SUCCESS";
export const CREATE_AUDIT_TYPE_FAIL = "CREATE_AUDIT_TYPE_FAIL";

// get
export const AUDIT_TYPE_REQUEST = "AUDIT_TYPE_REQUEST";
export const AUDIT_TYPE_SUCCESS = "AUDIT_TYPE_SUCCESS";
export const AUDIT_TYPE_FAIL = "AUDIT_TYPE_FAIL";

// find
export const FIND_AUDIT_TYPE_REQUEST = "FIND_AUDIT_TYPE_REQUEST";
export const FIND_AUDIT_TYPE_SUCCESS = "FIND_AUDIT_TYPE_SUCCESS";
export const FIND_AUDIT_TYPE_FAIL = "FIND_AUDIT_TYPE_FAIL";

// update/put
export const UPDATE_AUDIT_TYPE_REQUEST = "UPDATE_AUDIT_TYPE_REQUEST";
export const UPDATE_AUDIT_TYPE_SUCCESS = "UPDATE_AUDIT_TYPE_SUCCESS";
export const UPDATE_AUDIT_TYPE_FAIL = "UPDATE_AUDIT_TYPE_FAIL";

// delete/put
export const DELETE_AUDIT_TYPE_REQUEST = "DELETE_AUDIT_TYPE_REQUEST";
export const DELETE_AUDIT_TYPE_SUCCESS = "DELETE_AUDIT_TYPE_SUCCESS";
export const DELETE_AUDIT_TYPE_FAIL = "DELETE_AUDIT_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";