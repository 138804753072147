// create
export const CREATE_USER_PROFILE_REQUEST = "CREATE_USER_PROFILE_REQUEST";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_FAIL = "CREATE_USER_PROFILE_FAIL";

// get
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

// find
export const FIND_USER_PROFILE_REQUEST = "FIND_USER_PROFILE_REQUEST";
export const FIND_USER_PROFILE_SUCCESS = "FIND_USER_PROFILE_SUCCESS";
export const FIND_USER_PROFILE_FAIL = "FIND_USER_PROFILE_FAIL";

// update/put
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

// delete
// export const DELETE_USER_PROFILE_REQUEST = "DELETE_USER_PROFILE_REQUEST";
// export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS";
// export const DELETE_USER_PROFILE_FAIL = "DELETE_USER_PROFILE_FAIL";

export const USER_PROFILE_CLEAR_ERROR = "USER_PROFILE_CLEAR_ERROR"