import {
    CREATE_REWARD_BEFORE_DEPOSIT_REQUEST,
    CREATE_REWARD_BEFORE_DEPOSIT_SUCCESS,
    CREATE_REWARD_BEFORE_DEPOSIT_FAIL,

    REWARD_BEFORE_DEPOSIT_REQUEST,
    REWARD_BEFORE_DEPOSIT_SUCCESS,
    REWARD_BEFORE_DEPOSIT_FAIL,

    CLEAR_ERROR,
} from "../../constants_Phase_2/RefundAndDisbursement/RewardBeforeDepositConstant";

export const getRewardsBeforeDepositReducer = (state = { rewardsBeforeDeposit: [], rewardBeforeDepositResponse: {} }, action) => {
    switch (action.type) {
        case REWARD_BEFORE_DEPOSIT_REQUEST:
            return {
                ...state,
                rewardsBeforeDeposit: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case REWARD_BEFORE_DEPOSIT_SUCCESS:
            return {
                ...state,
                loading: false,
                rewardsBeforeDeposit: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case REWARD_BEFORE_DEPOSIT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CREATE_REWARD_BEFORE_DEPOSIT_REQUEST:
            return {
                ...state,
                rewardBeforeDepositResponse: {},
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_REWARD_BEFORE_DEPOSIT_SUCCESS:
            return {
                ...state,
                loading: false,
                rewardBeforeDepositResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_REWARD_BEFORE_DEPOSIT_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};