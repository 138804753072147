// create
export const CREATE_REWARD_PAYMENT_REQUEST = "CREATE_REWARD_PAYMENT_REQUEST";
export const CREATE_REWARD_PAYMENT_SUCCESS = "CREATE_REWARD_PAYMENT_SUCCESS";
export const CREATE_REWARD_PAYMENT_FAIL = "CREATE_REWARD_PAYMENT_FAIL";

// get
export const REWARD_PAYMENT_REQUEST = "REWARD_PAYMENT_REQUEST";
export const REWARD_PAYMENT_SUCCESS = "REWARD_PAYMENT_SUCCESS";
export const REWARD_PAYMENT_FAIL = "REWARD_PAYMENT_FAIL";

// find
export const FIND_REWARD_PAYMENT_REQUEST = "FIND_REWARD_PAYMENT_REQUEST";
export const FIND_REWARD_PAYMENT_SUCCESS = "FIND_REWARD_PAYMENT_SUCCESS";
export const FIND_REWARD_PAYMENT_FAIL = "FIND_REWARD_PAYMENT_FAIL";

// update
export const UPDATE_REWARD_PAYMENT_REQUEST = "UPDATE_REWARD_PAYMENT_REQUEST";
export const UPDATE_REWARD_PAYMENT_SUCCESS = "UPDATE_REWARD_PAYMENT_SUCCESS";
export const UPDATE_REWARD_PAYMENT_FAIL = "UPDATE_REWARD_PAYMENT_FAIL";

// delete
export const DELETE_REWARD_PAYMENT_REQUEST = "DELETE_REWARD_PAYMENT_REQUEST";
export const DELETE_REWARD_PAYMENT_SUCCESS = "DELETE_REWARD_PAYMENT_SUCCESS";
export const DELETE_REWARD_PAYMENT_FAIL = "DELETE_REWARD_PAYMENT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
