//CREATE
export const CREATE_MAIN_MENU_REQUEST = "CREATE_MAIN_MENU_REQUEST";
export const CREATE_MAIN_MENU_SUCCESS = "CREATE_MAIN_MENU_SUCCESS";
export const CREATE_MAIN_MENU_FAIL = "CREATE_MAIN_MENU_FAIL"
//GET ALL
export const MAIN_MENU_REQUEST = "MAIN_MENU_REQUEST";
export const MAIN_MENU_SUCCESS = "MAIN_MENU_SUCCESS";
export const MAIN_MENU_FAIL = "MAIN_MENU_FAIL"
//FIND ONE
export const FIND_MAIN_MENU_REQUEST = "FIND_MAIN_MENU_REQUEST";
export const FIND_MAIN_MENU_SUCCESS = "FIND_MAIN_MENU_SUCCESS";
export const FIND_MAIN_MENU_FAIL = "FIND_MAIN_MENU_FAIL"
//UPDATE ONE
export const UPDATE_MAIN_MENU_REQUEST = "UPDATE_MAIN_MENU_REQUEST";
export const UPDATE_MAIN_MENU_SUCCESS = "UPDATE_MAIN_MENU_SUCCESS";
export const UPDATE_MAIN_MENU_FAIL = "UPDATE_MAIN_MENU_FAIL"
//DELETE ONE
export const DELETE_MAIN_MENU_REQUEST = "DELETE_MAIN_MENU_REQUEST";
export const DELETE_MAIN_MENU_SUCCESS = "DELETE_MAIN_MENU_SUCCESS";
export const DELETE_MAIN_MENU_FAIL = "DELETE_MAIN_MENU_FAIL"

export const MAIN_MENU_CLEAR_ERROR = "CLEAR_ERROR"