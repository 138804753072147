import {
  CREATE_REWARD_PAYMENT_REQUEST,
  CREATE_REWARD_PAYMENT_SUCCESS,
  CREATE_REWARD_PAYMENT_FAIL,
  REWARD_PAYMENT_REQUEST,
  REWARD_PAYMENT_SUCCESS,
  REWARD_PAYMENT_FAIL,
  FIND_REWARD_PAYMENT_REQUEST,
  FIND_REWARD_PAYMENT_SUCCESS,
  FIND_REWARD_PAYMENT_FAIL,
  UPDATE_REWARD_PAYMENT_REQUEST,
  UPDATE_REWARD_PAYMENT_SUCCESS,
  UPDATE_REWARD_PAYMENT_FAIL,
  DELETE_REWARD_PAYMENT_REQUEST,
  DELETE_REWARD_PAYMENT_SUCCESS,
  DELETE_REWARD_PAYMENT_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/RewardPaymentConstant";

export const rewardPaymentReducer = (
  state = { rewardPayments: [] },
  action
) => {
  switch (action.type) {
    case REWARD_PAYMENT_REQUEST:
    case CREATE_REWARD_PAYMENT_REQUEST:
    case UPDATE_REWARD_PAYMENT_REQUEST:
    case DELETE_REWARD_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: [...state.rewardPayments, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: [
          ...state.rewardPayments.filter(
            (item) => item.rewardPaymentId !== action.payload.rewardPaymentId
          ),
          action.payload,
        ].sort((pre, post) => pre.rewardPaymentId - post.rewardPaymentId),
        message: action.message,
        status: action.status,
      };

    case DELETE_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayments: [
          ...state.rewardPayments.filter(
            (item) => item.rewardPaymentId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_REWARD_PAYMENT_SUCCESS":
      return {
        loading: false,
        rewardPayments: [
          ...state.rewardPayments.filter(
            (item) => item.rewardPaymentId !== action.payload.rewardPaymentId
          ),
          action.payload,
        ].sort((pre, post) => pre.rewardPaymentId - post.rewardPaymentId),
        message: action.message,
        status: action.status,
      };

    case REWARD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_REWARD_PAYMENT_FAIL:
    case UPDATE_REWARD_PAYMENT_FAIL:
    case DELETE_REWARD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRewardPaymentReducer = (
  state = { rewardPayment: {} },
  action
) => {
  switch (action.type) {
    case FIND_REWARD_PAYMENT_REQUEST:
      return {
        rewardPayment: {},
        loading: true,
      };

    case FIND_REWARD_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        rewardPayment: action.payload,
      };

    case FIND_REWARD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        rewardPayment: {},
        error: action.payload,
      };

    case "EMPTY_REWARD_PAYMENT":
      return {
        ...state,
        rewardPayment: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
