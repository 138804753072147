import {
  CREATE_BTC_COMMODITY_REQUEST,
  CREATE_BTC_COMMODITY_SUCCESS,
  CREATE_BTC_COMMODITY_FAIL,
  BTC_COMMODITY_REQUEST,
  BTC_COMMODITY_SUCCESS,
  BTC_COMMODITY_FAIL,
  FIND_BTC_COMMODITY_REQUEST,
  FIND_BTC_COMMODITY_SUCCESS,
  FIND_BTC_COMMODITY_FAIL,
  UPDATE_BTC_COMMODITY_REQUEST,
  UPDATE_BTC_COMMODITY_SUCCESS,
  UPDATE_BTC_COMMODITY_FAIL,
  DELETE_BTC_COMMODITY_REQUEST,
  DELETE_BTC_COMMODITY_SUCCESS,
  DELETE_BTC_COMMODITY_FAIL,
  FIND_BTC_SECTION_COMMODITY_REQUEST,
  FIND_BTC_SECTION_COMMODITY_SUCCESS,
  FIND_BTC_SECTION_COMMODITY_FAIL,
  FIND_BTC_CHAPTER_COMMODITY_REQUEST,
  FIND_BTC_CHAPTER_COMMODITY_SUCCESS,
  FIND_BTC_CHAPTER_COMMODITY_FAIL,
  BTC_COMMODITY_CLEAR_ERROR,
} from "../../constants/BtcMaster/CommodityConstant";

// get all data of list
export const getBtcCommoditiesReducer = (state = { btcCommodities: [] }, action ) => {
  switch (action.type) {
    case BTC_COMMODITY_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        btcCommodities: [],
        status: null,
        error: null,
      };

    case CREATE_BTC_COMMODITY_REQUEST:
    case UPDATE_BTC_COMMODITY_REQUEST:
    case DELETE_BTC_COMMODITY_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case BTC_COMMODITY_SUCCESS:
      return {
        ...state,
        loading: false,
        btcCommodities: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case CREATE_BTC_COMMODITY_SUCCESS:
    case UPDATE_BTC_COMMODITY_SUCCESS:
    case DELETE_BTC_COMMODITY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BTC_COMMODITY_SUCCESS":
      return {
        loading: false,
        btcCommodities: [
          ...state.btcCommodities.filter(
            (item) => item.commodityId !== action.payload.commodityId
          ),
          action.payload,
        ].sort((pre, post) => pre.commodityId - post.commodityId),
        message: action.message,
        status: action.status,
      };

    case BTC_COMMODITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_BTC_COMMODITY_FAIL:
    case UPDATE_BTC_COMMODITY_FAIL:
    case DELETE_BTC_COMMODITY_FAIL:
      return {
        ...state,
        loading: false,
        btcCommodities: [...state.btcCommodities],
        message: action.message,
        status: action.status,
      };

    case BTC_COMMODITY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBtcCommodityReducer = ( state = { btcCommodity: {} }, action ) => {
  switch (action.type) {
    case FIND_BTC_COMMODITY_REQUEST:
      return {
        btcCommodity: {},
        loading: true,
      };

    case FIND_BTC_COMMODITY_SUCCESS:
      return {
        ...state,
        loading: false,
        btcCommodity: action.payload,
      };

    case FIND_BTC_COMMODITY_FAIL:
      return {
        ...state,
        loading: false,
        btcCommodity: {},
        error: action.payload,
      };

    case "EMPTY_BTC_COMMODITY":
      return {
        ...state,
        btcCommodity: {},
      };

    case BTC_COMMODITY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find btc section commodity child
export const findBtcSectionCommodityReducer = (
  state = { btcSectionCommodity: [] },
  action
) => {
  switch (action.type) {
    case FIND_BTC_SECTION_COMMODITY_REQUEST:
      return {
        btcSectionCommodity: [],
        loading: true,
      };

    case FIND_BTC_SECTION_COMMODITY_SUCCESS:
      return {
        ...state,
        loading: false,
        btcSectionCommodity: action.payload,
      };

    case FIND_BTC_SECTION_COMMODITY_FAIL:
      return {
        ...state,
        loading: false,
        btcSectionCommodity: [],
        error: action.payload,
      };

    case "EMPTY_BTC_SECTION_COMMODITY":
      return {
        ...state,
        btcSectionCommodity: [],
      };

    case BTC_COMMODITY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

// find btc chapter commodity child
export const findBtcChapterCommodityReducer = (
  state = { btcChapterCommodity: [] },
  action
) => {
  switch (action.type) {
    case FIND_BTC_CHAPTER_COMMODITY_REQUEST:
      return {
        btcChapterCommodity: [],
        loading: true,
      };

    case FIND_BTC_CHAPTER_COMMODITY_SUCCESS:
      return {
        ...state,
        loading: false,
        btcChapterCommodity: action.payload,
      };

    case FIND_BTC_CHAPTER_COMMODITY_FAIL:
      return {
        ...state,
        loading: false,
        btcChapterCommodity: [],
        error: action.payload,
      };

    case "EMPTY_BTC_CHAPTER_COMMODITY":
      return {
        ...state,
        btcChapterCommodity: [],
      };

    case BTC_COMMODITY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
