// create
export const CREATE_REWARD_BEFORE_DEPOSIT_REQUEST = "CREATE_REWARD_BEFORE_DEPOSIT_REQUEST";
export const CREATE_REWARD_BEFORE_DEPOSIT_SUCCESS = "CREATE_REWARD_BEFORE_DEPOSIT_SUCCESS";
export const CREATE_REWARD_BEFORE_DEPOSIT_FAIL = "CREATE_REWARD_BEFORE_DEPOSIT_FAIL";

// get
export const REWARD_BEFORE_DEPOSIT_REQUEST = "REWARD_BEFORE_DEPOSIT_REQUEST";
export const REWARD_BEFORE_DEPOSIT_SUCCESS = "REWARD_BEFORE_DEPOSIT_SUCCESS";
export const REWARD_BEFORE_DEPOSIT_FAIL = "REWARD_BEFORE_DEPOSIT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
