// create
export const CREATE_MAJOR_GROUP_REQUEST = "CREATE_MAJOR_GROUP_REQUEST";
export const CREATE_MAJOR_GROUP_SUCCESS = "CREATE_MAJOR_GROUP_SUCCESS";
export const CREATE_MAJOR_GROUP_FAIL = "CREATE_MAJOR_GROUP_FAIL";

// get
export const MAJOR_GROUP_REQUEST = "MAJOR_GROUP_REQUEST";
export const MAJOR_GROUP_SUCCESS = "MAJOR_GROUP_SUCCESS";
export const MAJOR_GROUP_FAIL = "MAJOR_GROUP_FAIL";

// find
export const FIND_MAJOR_GROUP_REQUEST = "FIND_MAJOR_GROUP_REQUEST";
export const FIND_MAJOR_GROUP_SUCCESS = "FIND_MAJOR_GROUP_SUCCESS";
export const FIND_MAJOR_GROUP_FAIL = "FIND_MAJOR_GROUP_FAIL";

// update
export const UPDATE_MAJOR_GROUP_REQUEST = "UPDATE_MAJOR_GROUP_REQUEST";
export const UPDATE_MAJOR_GROUP_SUCCESS = "UPDATE_MAJOR_GROUP_SUCCESS";
export const UPDATE_MAJOR_GROUP_FAIL = "UPDATE_MAJOR_GROUP_FAIL";

// delete
export const DELETE_MAJOR_GROUP_REQUEST = "DELETE_MAJOR_GROUP_REQUEST";
export const DELETE_MAJOR_GROUP_SUCCESS = "DELETE_MAJOR_GROUP_SUCCESS";
export const DELETE_MAJOR_GROUP_FAIL = "DELETE_MAJOR_GROUP_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
