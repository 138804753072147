import {
    CREATE_RECONCILIATION_APPROVAL_REQUEST,
    CREATE_RECONCILIATION_APPROVAL_SUCCESS,
    CREATE_RECONCILIATION_APPROVAL_FAIL,

    RECONCILIATION_APPROVAL_REQUEST,
    RECONCILIATION_APPROVAL_SUCCESS,
    RECONCILIATION_APPROVAL_FAIL,

    FIND_RECONCILIATION_APPROVAL_REQUEST,
    FIND_RECONCILIATION_APPROVAL_SUCCESS,
    FIND_RECONCILIATION_APPROVAL_FAIL,

    UPDATE_RECONCILIATION_APPROVAL_REQUEST,
    UPDATE_RECONCILIATION_APPROVAL_SUCCESS,
    UPDATE_RECONCILIATION_APPROVAL_FAIL,

    DELETE_RECONCILIATION_APPROVAL_REQUEST,
    DELETE_RECONCILIATION_APPROVAL_SUCCESS,
    DELETE_RECONCILIATION_APPROVAL_FAIL,

    RECONCILIATION_APPROVAL_CLEAR_ERROR,

} from "../../constants/Reconciliation/ReconciliationApprovalConstant";







export const getReconciliationApprovalReducer = (state = { reconciliationApprovals: [] },action) => {
    switch (action.type) {
      case RECONCILIATION_APPROVAL_REQUEST:
      case CREATE_RECONCILIATION_APPROVAL_REQUEST:
      case UPDATE_RECONCILIATION_APPROVAL_REQUEST:
      case DELETE_RECONCILIATION_APPROVAL_REQUEST:
        return {
          ...state,
          reconciliationApprovals: [],
          loading: true,
          status: null,
          message: null,
          error: null,
        };
  
      case RECONCILIATION_APPROVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          reconciliationApprovals: action.payload,
          message: null,
          status: null,
          totalData: action.totalData,
        };
  
      case CREATE_RECONCILIATION_APPROVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          reconciliationApprovals: [...state.reconciliationApprovals, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_RECONCILIATION_APPROVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          reconciliationApprovals: [
            ...state.reconciliationApprovals.filter(
              (item) => item.reconcilationSummaryId !== action.payload.reconcilationSummaryId
            ),
            action.payload,
          ].sort((pre, post) => pre.reconcilationSummaryId - post.reconcilationSummaryId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_RECONCILIATION_APPROVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          reconciliationApprovals: [
            ...state.reconciliationApprovals.filter(
              (item) => item.reconcilationSummaryId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  

  
      case RECONCILIATION_APPROVAL_FAIL:
        return {
          ...state,
          loading: false,
          reconciliationApprovals: [...state.reconciliationApprovals],
          error: action.payload,
        };
  
      case CREATE_RECONCILIATION_APPROVAL_FAIL:
      case UPDATE_RECONCILIATION_APPROVAL_FAIL:
      case DELETE_RECONCILIATION_APPROVAL_FAIL:
        return {
          ...state,
          loading: false,
          reconciliationApprovals: [...state.reconciliationApprovals],
          message: action.message,
          status: action.status,
        };
  
      case RECONCILIATION_APPROVAL_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  




  
  // find one
  export const findReconciliationApprovalReducer = (
    state = { reconcilationSummary: {}, reconcilationList: [] },
    action
  ) => {
    switch (action.type) {
      case FIND_RECONCILIATION_APPROVAL_REQUEST:
        return {
          reconcilationSummary: {},
          reconcilationList:[],
          loading: true,
        };
      case FIND_RECONCILIATION_APPROVAL_SUCCESS:
        return {
          ...state,
          loading: false,
          reconcilationSummary: action.payload?.reconcilationSummary,
          reconcilationList:action.payload?.reconcilationList,
        };
  
      case FIND_RECONCILIATION_APPROVAL_FAIL:
        return {
          ...state,
          loading: false,
          reconcilationSummary: {},
          reconcilationList:[],
          error: action.payload,
        };
  
      case "EMPTY_RECONCILIATION_APPROVAL":
        return {
          ...state,
          reconcilationSummary: {},
          reconcilationList:[],
        };
  
      case RECONCILIATION_APPROVAL_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  