// create
export const CREATE_FINANCIAL_YEAR_REQUEST = "CREATE_FINANCIAL_YEAR_REQUEST";
export const CREATE_FINANCIAL_YEAR_SUCCESS = "CREATE_FINANCIAL_YEAR_SUCCESS";
export const CREATE_FINANCIAL_YEAR_FAIL = "CREATE_FINANCIAL_YEAR_FAIL"
export const CREATE_FINANCIAL_YEAR_CONFLICT = "CREATE_FINANCIAL_YEAR_CONFLICT"

// get
export const FINANCIAL_YEAR_REQUEST = "FINANCIAL_YEAR_REQUEST";
export const FINANCIAL_YEAR_SUCCESS = "FINANCIAL_YEAR_SUCCESS";
export const FINANCIAL_YEAR_FAIL = "FINANCIAL_YEAR_FAIL"


// find
export const FIND_FINANCIAL_YEAR_REQUEST = "FIND_FINANCIAL_YEAR_REQUEST";
export const FIND_FINANCIAL_YEAR_SUCCESS = "FIND_FINANCIAL_YEAR_SUCCESS";
export const FIND_FINANCIAL_YEAR_FAIL = "FIND_FINANCIAL_YEAR_FAIL"

// update
export const UPDATE_FINANCIAL_YEAR_REQUEST = "UPDATE_FINANCIAL_YEAR_REQUEST";
export const UPDATE_FINANCIAL_YEAR_SUCCESS = "UPDATE_FINANCIAL_YEAR_SUCCESS";
export const UPDATE_FINANCIAL_YEAR_FAIL = "UPDATE_FINANCIAL_YEAR_FAIL"

// delete
export const DELETE_FINANCIAL_YEAR_REQUEST = "DELETE_FINANCIAL_YEAR_REQUEST";
export const DELETE_FINANCIAL_YEAR_SUCCESS = "DELETE_FINANCIAL_YEAR_SUCCESS";
export const DELETE_FINANCIAL_YEAR_FAIL = "DELETE_FINANCIAL_YEAR_FAIL"


export const FINANCIAL_YEAR_CLEAR_ERROR = "FINANCIAL_YEAR_CLEAR_ERROR"
