import {
  CREATE_DEPOSIT_SLIP_REQUEST,
  CREATE_DEPOSIT_SLIP_SUCCESS,
  CREATE_DEPOSIT_SLIP_FAIL,

  DEPOSIT_SLIP_REQUEST,
  DEPOSIT_SLIP_SUCCESS,
  DEPOSIT_SLIP_FAIL,

  DEPOSIT_RECEIPTS_REQUEST,
  DEPOSIT_RECEIPTS_SUCCESS,
  DEPOSIT_RECEIPTS_FAIL,

  DEPOSIT_SLIP_ALL_USERS_REQUEST,
  DEPOSIT_SLIP_ALL_USERS_SUCCESS,
  DEPOSIT_SLIP_ALL_USERS_FAIL,

  DEPOSIT_SLIP_CLEAR_ERROR
} from "../../constants/DepositSlip/DepositSlipConstant";


//  get
export const getDepositSlipsReducer = (state = { depositSlips: [] }, action) => {
  switch (action.type) {
    case DEPOSIT_SLIP_REQUEST:
      return {
        ...state,
        depositSlips: [],
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case DEPOSIT_SLIP_SUCCESS:
      return {
        ...state,
        loading: false,
        depositSlips: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: null,
        status: null,
      };

    case DEPOSIT_SLIP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DEPOSIT_SLIP_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// create, update,delete
export const getDepositSlipResponseReducer = (state = { depositSlipResponse: {} }, action) => {
  switch (action.type) {
      case CREATE_DEPOSIT_SLIP_REQUEST:
          return {
              ...state,
              loading: true,
              status: null,
              message: null,
              error: null
          };

      case CREATE_DEPOSIT_SLIP_SUCCESS:
          return {
              ...state,
              loading: false,
              depositSlipResponse: action.payload,
              message: action.message,
              status: action.status
          };

      case CREATE_DEPOSIT_SLIP_FAIL:
          return {
              ...state,
              loading: false,
              depositSlipResponse: {},
              message: action.message,
              status: action.status,
          };

      case DEPOSIT_SLIP_CLEAR_ERROR:
          return {
              ...state,
              error: null,
              status: null,
              message: null,
          };

      case "EMPTY_DEPOSIT_SLIP":
          return {
              ...state,
              depositSlipResponse: {}
          }

      default:
          return state;
  }
};

// get all receipts
export const getDepositReceiptsReducer = (state = { depositReceipts: [] }, action) => {
  switch (action.type) {
    case DEPOSIT_RECEIPTS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null
      };

    case DEPOSIT_RECEIPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        depositReceipts: action.payload,
        message: null,
        status: null,
      };

    case DEPOSIT_RECEIPTS_FAIL:
      return {
        ...state,
        loading: false,
        depositReceipts: [...state.depositReceipts],
        error: action.payload,
      };

    case "EMPTY_DEPOSIT_RECEIPTS":
      return {
        ...state,
        depositReceipts: []
      }

    default:
      return state;
  }
};

// get all users
export const getDepositSlipAllUsersReducer = (state = { depositSlipAllUsers: [] }, action) => {
  switch (action.type) {
    case DEPOSIT_SLIP_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null
      };

    case DEPOSIT_SLIP_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        depositSlipAllUsers: action.payload,
        message: null,
        status: null,
      };

    case DEPOSIT_SLIP_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        depositSlipAllUsers: [...state.depositSlipAllUsers],
        error: action.payload,
      };

    case "EMPTY_DEPOSIT_SLIP_ALL_USERS_FAIL":
      return {
        ...state,
        depositSlipAllUsers: []
      }

    default:
      return state;
  }
};