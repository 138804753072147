import {
  CREATE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAIL,
  PAYMENT_METHOD_REQUEST,
  PAYMENT_METHOD_SUCCESS,
  PAYMENT_METHOD_FAIL,
  FIND_PAYMENT_METHOD_REQUEST,
  FIND_PAYMENT_METHOD_SUCCESS,
  FIND_PAYMENT_METHOD_FAIL,
  UPDATE_PAYMENT_METHOD_REQUEST,
  UPDATE_PAYMENT_METHOD_SUCCESS,
  UPDATE_PAYMENT_METHOD_FAIL,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAIL,
  PAYMENT_METHOD_CLEAR_ERROR,
} from "../../constants/OthersMaster/PaymentMethodConstant";

export const getPaymentMethodReducer = (
  state = { paymentMethods: [] },
  action
) => {
  switch (action.type) {
    case PAYMENT_METHOD_REQUEST:
    case CREATE_PAYMENT_METHOD_REQUEST:
    case UPDATE_PAYMENT_METHOD_REQUEST:
    case DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload,
        message: null,
        status: null,
      };

    case CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: [...state.paymentMethods, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: [
          ...state.paymentMethods.filter(
            (item) =>
              item.paymentLimitMethodId !== action.payload.paymentLimitMethodId
          ),
          action.payload,
        ].sort(
          (pre, post) => pre.paymentLimitMethodId - post.paymentLimitMethodId
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: [
          ...state.paymentMethods.filter(
            (item) => item.paymentLimitMethodId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_PAYMENT_METHOD_SUCCESS":
      return {
        loading: false,
        paymentMethods: [
          ...state.paymentMethods.filter(
            (item) =>
              item.paymentLimitMethodId !== action.payload.paymentLimitMethodId
          ),
          action.payload,
        ].sort(
          (pre, post) => pre.paymentLimitMethodId - post.paymentLimitMethodId
        ),
        message: action.message,
        status: action.status,
      };

    case PAYMENT_METHOD_FAIL:
      return {
        ...state,
        loading: false,
        paymentMethods: [...state.paymentMethods],
        error: action.payload,
      };

    case CREATE_PAYMENT_METHOD_FAIL:
    case UPDATE_PAYMENT_METHOD_FAIL:
    case DELETE_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        loading: false,
        paymentMethods: [...state.paymentMethods],
        message: action.message,
        status: action.status,
      };

    case PAYMENT_METHOD_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findPaymentMethodReducer = (
  state = { paymentMethod: {} },
  action
) => {
  switch (action.type) {
    case FIND_PAYMENT_METHOD_REQUEST:
      return {
        paymentMethod: {},
        loading: true,
      };
    case FIND_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethod: action.payload,
      };

    case FIND_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        loading: false,
        paymentMethod: {},
        error: action.payload,
      };

    case "EMPTY_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethod: {},
      };

    case PAYMENT_METHOD_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
