
export const CREATE_WRITE_OFF_REQUEST = "CREATE_WRITE_OFF_REQUEST";
export const CREATE_WRITE_OFF_SUCCESS = "CREATE_WRITE_OFF_SUCCESS";
export const CREATE_WRITE_OFF_FAIL = "CREATE_WRITE_OFF_FAIL";

export const WRITE_OFF_REQUEST = "WRITE_OFF_REQUEST";
export const WRITE_OFF_SUCCESS = "WRITE_OFF_SUCCESS";
export const WRITE_OFF_FAIL = "WRITE_OFF_FAIL";


export const FIND_WRITE_OFF_REQUEST = "FIND_WRITE_OFF_REQUEST";
export const FIND_WRITE_OFF_SUCCESS = "FIND_WRITE_OFF_SUCCESS";
export const FIND_WRITE_OFF_FAIL = "FIND_WRITE_OFF_FAIL";


export const UPDATE_WRITE_OFF_REQUEST = "UPDATE_WRITE_OFF_REQUEST";
export const UPDATE_WRITE_OFF_SUCCESS = "UPDATE_WRITE_OFF_SUCCESS";
export const UPDATE_WRITE_OFF_FAIL = "UPDATE_WRITE_OFF_FAIL";



export const CLEAR_WRITE_OFF_ERROR = "CLEAR_WRITE_OFF_ERROR";