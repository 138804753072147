import {
  RECEIPT_MAINTENANCE_REQUEST,
  RECEIPT_MAINTENANCE_SUCCESS,
  RECEIPT_MAINTENANCE_FAIL,

  RECEIPT_MAINTENANCE_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/ReceiptMaintenanceConstant";

export const getReceiptMaintenanceReducer = (state = { receiptMaintenances: [] }, action) => {
  switch (action.type) {
    case RECEIPT_MAINTENANCE_REQUEST:
      return {
        ...state,
        receiptMaintenances: [],
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case RECEIPT_MAINTENANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptMaintenances: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case RECEIPT_MAINTENANCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RECEIPT_MAINTENANCE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};