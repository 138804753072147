import {
  CREATE_RISK_SUB_AREA_REQUEST,
  CREATE_RISK_SUB_AREA_SUCCESS,
  CREATE_RISK_SUB_AREA_FAIL,

  RISK_SUB_AREA_REQUEST,
  RISK_SUB_AREA_SUCCESS,
  RISK_SUB_AREA_FAIL,

  FIND_RISK_SUB_AREA_REQUEST,
  FIND_RISK_SUB_AREA_SUCCESS,
  FIND_RISK_SUB_AREA_FAIL,

  UPDATE_RISK_SUB_AREA_REQUEST,
  UPDATE_RISK_SUB_AREA_SUCCESS,
  UPDATE_RISK_SUB_AREA_FAIL,

  DELETE_RISK_SUB_AREA_REQUEST,
  DELETE_RISK_SUB_AREA_SUCCESS,
  DELETE_RISK_SUB_AREA_FAIL,

  RISK_SUB_AREA_CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskSubAreaConstant";

export const getRiskSubAreasReducer = (state = { riskSubAreas: [] }, action) => {
  switch (action.type) {
    case RISK_SUB_AREA_REQUEST:
    case CREATE_RISK_SUB_AREA_REQUEST:
    case UPDATE_RISK_SUB_AREA_REQUEST:
    case DELETE_RISK_SUB_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RISK_SUB_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskSubAreas: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_SUB_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskSubAreas: [...state.riskSubAreas, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_SUB_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskSubAreas: state.riskSubAreas.map((item) =>
          (item.riskSubAreaId === action.payload.riskSubAreaId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_SUB_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskSubAreas: action?.payload?.isDelete ?
          state.riskSubAreas.filter((item) =>
            item.riskSubAreaId !== action.payload.riskSubAreaId
          )
          :
          state.riskSubAreas.map((item) =>
            (item.riskSubAreaId === action.payload.riskSubAreaId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_SUB_AREA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_SUB_AREA_FAIL:
    case UPDATE_RISK_SUB_AREA_FAIL:
    case DELETE_RISK_SUB_AREA_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case RISK_SUB_AREA_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskSubAreaReducer = (state = { riskSubArea: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_SUB_AREA_REQUEST:
      return {
        riskSubArea: {},
        loading: true,
      };

    case FIND_RISK_SUB_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        riskSubArea: action.payload,
      };

    case FIND_RISK_SUB_AREA_FAIL:
      return {
        ...state,
        loading: false,
        riskSubArea: {},
        error: action.payload,
      };

    case "EMPTY_RISK_SUB_AREA":
      return {
        riskSubArea: {},
      };

    case RISK_SUB_AREA_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};  