import {
  RISK_TRANSACTION_PROCESS_REQUEST,
  RISK_TRANSACTION_PROCESS_SUCCESS,
  RISK_TRANSACTION_PROCESS_FAIL,

  CREATE_RISK_TRANSACTION_PROCESS_REQUEST,
  CREATE_RISK_TRANSACTION_PROCESS_SUCCESS,
  CREATE_RISK_TRANSACTION_PROCESS_FAIL,

  FIND_RISK_TRANSACTION_PROCESS_REQUEST,
  FIND_RISK_TRANSACTION_PROCESS_SUCCESS,
  FIND_RISK_TRANSACTION_PROCESS_FAIL,

  UPDATE_RISK_TRANSACTION_PROCESS_REQUEST,
  UPDATE_RISK_TRANSACTION_PROCESS_SUCCESS,
  UPDATE_RISK_TRANSACTION_PROCESS_FAIL,

  DELETE_RISK_TRANSACTION_PROCESS_REQUEST,
  DELETE_RISK_TRANSACTION_PROCESS_SUCCESS,
  DELETE_RISK_TRANSACTION_PROCESS_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskTransactionProcessConstant";

// create, update, delete and get all
export const getRiskTransactionsProcessesReducer = (state = { riskTransactionsProcesses: [] }, action) => {
  switch (action.type) {
    case RISK_TRANSACTION_PROCESS_REQUEST:
    case CREATE_RISK_TRANSACTION_PROCESS_REQUEST:
    case UPDATE_RISK_TRANSACTION_PROCESS_REQUEST:
    case DELETE_RISK_TRANSACTION_PROCESS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_TRANSACTION_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        riskTransactionsProcesses: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_TRANSACTION_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        riskTransactionsProcesses: [...state.riskTransactionsProcesses, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_TRANSACTION_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        riskTransactionsProcesses: state.riskTransactionsProcesses.map((item) =>
          (item.transactionProcessId === action.payload.transactionProcessId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_TRANSACTION_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        riskTransactionsProcesses: action?.payload?.isDelete ?
          state.riskTransactionsProcesses.filter((item) =>
            item.transactionProcessId !== action.payload.transactionProcessId
          )
          :
          state.riskTransactionsProcesses.map((item) =>
            (item.transactionProcessId === action.payload.transactionProcessId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_TRANSACTION_PROCESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_TRANSACTION_PROCESS_FAIL:
    case UPDATE_RISK_TRANSACTION_PROCESS_FAIL:
    case DELETE_RISK_TRANSACTION_PROCESS_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskTransactionProcessReducer = (state = { riskTransactionProcess: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_TRANSACTION_PROCESS_REQUEST:
      return {
        loading: true,
        riskTransactionProcess: {},
      };

    case FIND_RISK_TRANSACTION_PROCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        riskTransactionProcess: action.payload,
      };

    case FIND_RISK_TRANSACTION_PROCESS_FAIL:
      return {
        ...state,
        loading: false,
        riskTransactionProcess: {},
        error: action.payload,
      };

    case "EMPTY_RISK_TRANSACTION_PROCESS":
      return {
        riskTransactionProcess: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
