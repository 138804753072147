import {
  CREATE_NOTIFICATION_CONFIGURATION_FAIL,
  CREATE_NOTIFICATION_CONFIGURATION_REQUEST,
  CREATE_NOTIFICATION_CONFIGURATION_SUCCESS,
  NOTIFICATION_CONFIGURATION_CLEAR_ERROR,
  NOTIFICATION_CONFIGURATION_FAIL,
  NOTIFICATION_CONFIGURATION_REQUEST,
  NOTIFICATION_CONFIGURATION_SUCCESS,
  FIND_NOTIFICATION_CONFIGURATION_FAIL,
  FIND_NOTIFICATION_CONFIGURATION_REQUEST,
  FIND_NOTIFICATION_CONFIGURATION_SUCCESS,
  UPDATE_NOTIFICATION_CONFIGURATION_FAIL,
  UPDATE_NOTIFICATION_CONFIGURATION_REQUEST,
  UPDATE_NOTIFICATION_CONFIGURATION_SUCCESS,
} from "../../constants_Phase_2/ArrearManagement/NotificationConfigurationConstant";

// get all data of list
export const getNotificationLevelConfigurationReducer = (
  state = { notificationConfigs: [] },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_CONFIGURATION_REQUEST:
    case CREATE_NOTIFICATION_CONFIGURATION_REQUEST:
    case UPDATE_NOTIFICATION_CONFIGURATION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case NOTIFICATION_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationConfigs: action.payload,
        message: null,
        status: null,
      };

    case CREATE_NOTIFICATION_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationConfigs: [...state.notificationConfigs, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_NOTIFICATION_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationConfigs: [
          ...state.notificationConfigs.filter(
            (item) =>
              item.notificationConfigurationId !== action.payload.notificationConfigurationId
          ),
          action.payload,
        ].sort(
          (pre, post) => pre.notificationConfigurationId - post.notificationConfigurationId
        ),
        message: action.message,
        status: action.status,
      };

    case NOTIFICATION_CONFIGURATION_FAIL:
      return {
        ...state,
        loading: false,
        notificationConfigs: [...state.notificationConfigs],
        error: action.payload,
      };

    case CREATE_NOTIFICATION_CONFIGURATION_FAIL:
    case UPDATE_NOTIFICATION_CONFIGURATION_FAIL:
      return {
        ...state,
        loading: false,
        notificationConfigs: [...state.notificationConfigs],
        message: action.message,
        status: action.status,
      };

    case NOTIFICATION_CONFIGURATION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findNotificationConfigurationReducer = ( state = { notificationLevelConfig: {} }, action ) => {
  switch (action.type) {
    case FIND_NOTIFICATION_CONFIGURATION_REQUEST:
      return {
        notificationLevelConfig: {},
        loading: true,
      };

    case FIND_NOTIFICATION_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationLevelConfig: action.payload,
      };

    case FIND_NOTIFICATION_CONFIGURATION_FAIL:
      return {
        ...state,
        loading: false,
        notificationLevelConfig: {},
        error: action.payload,
      };

    case "EMPTY_NOTIFICATION_CONFIGURATION":
      return {
        ...state,
        notificationLevelConfig: {},
      };

    case NOTIFICATION_CONFIGURATION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
