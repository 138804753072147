import {
  CREATE_VILLAGE_REQUEST,
  CREATE_VILLAGE_SUCCESS,
  CREATE_VILLAGE_FAIL,
  VILLAGE_REQUEST,
  VILLAGE_SUCCESS,
  VILLAGE_FAIL,
  FIND_VILLAGE_REQUEST,
  FIND_VILLAGE_SUCCESS,
  FIND_VILLAGE_FAIL,
  UPDATE_VILLAGE_REQUEST,
  UPDATE_VILLAGE_SUCCESS,
  UPDATE_VILLAGE_FAIL,
  DELETE_VILLAGE_REQUEST,
  DELETE_VILLAGE_SUCCESS,
  DELETE_VILLAGE_FAIL,
  CLEAR_ERROR,
} from "../../constants/LocationMaster/villageConstant";

export const villageReducer = (state = { villages: [] }, action) => {
  switch (action.type) {
    case VILLAGE_REQUEST:
    case CREATE_VILLAGE_REQUEST:
    case UPDATE_VILLAGE_REQUEST:
    case DELETE_VILLAGE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: action.payload,
        status: null,
        message: null,
      };

    case CREATE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: [...state.villages, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: [
          ...state.villages.filter(
            (item) => item.villageId !== action.payload.villageId
          ),
          action.payload,
        ].sort((pre, post) => pre.villageId - post.villageId),
        message: action.message,
        status: action.status,
      };

    case DELETE_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        villages: [
          ...state.villages.filter((item) => item.villageId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_VILLAGE_SUCCESS":
      return {
        loading: false,
        villages: [
          ...state.villages.filter(
            (item) => item.villageId !== action.payload.villageId
          ),
          action.payload,
        ].sort((pre, post) => pre.villageId - post.villageId),
        message: action.message,
        status: action.status,
      };

    case VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        villages: [...state.villages],
        error: action.payload,
      };

    case CREATE_VILLAGE_FAIL:
    case UPDATE_VILLAGE_FAIL:
    case DELETE_VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        villages: [...state.villages],
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const findVillageReducer = (state = { village: {} }, action) => {
  switch (action.type) {
    case FIND_VILLAGE_REQUEST:
      return {
        loading: true,
        village: {},
      };
    case FIND_VILLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        village: action.payload,
      };

    case FIND_VILLAGE_FAIL:
      return {
        ...state,
        loading: false,
        village: {},
        error: action.payload,
      };
    case "EMPTY_VILLAGE":
      return {
        ...state,
        village: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
