import {
  SUB_MENU_REQUEST,
  SUB_MENU_SUCCESS,
  SUB_MENU_FAIL,
  CREATE_SUB_MENU_REQUEST,
  CREATE_SUB_MENU_SUCCESS,
  CREATE_SUB_MENU_FAIL,
  FIND_SUB_MENU_REQUEST,
  FIND_SUB_MENU_SUCCESS,
  FIND_SUB_MENU_FAIL,
  UPDATE_SUB_MENU_REQUEST,
  UPDATE_SUB_MENU_SUCCESS,
  UPDATE_SUB_MENU_FAIL,
  DELETE_SUB_MENU_REQUEST,
  DELETE_SUB_MENU_SUCCESS,
  DELETE_SUB_MENU_FAIL,
  SUB_MENU_CLEAR_ERROR,
} from "../../constants/Menu/SubMenuConstant";

// create, update, delete and get all
export const subMenuReducer = (state = { subMenus: [] }, action) => {
  switch (action.type) {
    case SUB_MENU_REQUEST:
    case CREATE_SUB_MENU_REQUEST:
    case UPDATE_SUB_MENU_REQUEST:
    case DELETE_SUB_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case SUB_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenus: action.payload,
        message: null,
        status: null,
      };

    case CREATE_SUB_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenus: [...state.subMenus, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_SUB_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenus: [
          ...state.subMenus.filter(
            (item) => item.subMenuId !== action.payload.subMenuId
          ),
          action.payload,
        ].sort((pre, post) => pre.subMenuId - post.subMenuId),
        message: action.message,
        status: action.status,
      };

    case DELETE_SUB_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenus: [
          ...state.subMenus.filter((item) => item.subMenuId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case SUB_MENU_FAIL:
      return {
        ...state,
        loading: false,
        subMenus: [...state.subMenus],
        error: action.payload,
      };

    case CREATE_SUB_MENU_FAIL:
    case UPDATE_SUB_MENU_FAIL:
      return {
        ...state,
        loading: false,
        subMenus: [...state.subMenus],
        message: action.message,
        status: action.status,
      };

    case DELETE_SUB_MENU_FAIL:
      return {
        ...state,
        loading: false,
        subMenus: [...state.subMenus],
        error: action.payload,
      };

    case SUB_MENU_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findSubMenuReducer = (state = { subMenu: {} }, action) => {
  switch (action.type) {
    case FIND_SUB_MENU_REQUEST:
      return {
        loading: true,
        subMenu: {},
      };
    case FIND_SUB_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        subMenu: action.payload,
      };

    case FIND_SUB_MENU_FAIL:
      return {
        ...state,
        loading: false,
        subMenu: {},
        error: action.payload,
      };

    case "EMPTY_SUB_MENU":
      return {
        ...state,
        subMenu: {},
      };

    case SUB_MENU_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};


