import {
    CREATE_RECONCILIATION_MAINTENANCE_REQUEST,
    CREATE_RECONCILIATION_MAINTENANCE_SUCCESS,
    CREATE_RECONCILIATION_MAINTENANCE_FAIL,
    RECONCILIATION_MAINTENANCE_REQUEST,
    RECONCILIATION_MAINTENANCE_SUCCESS,
    RECONCILIATION_MAINTENANCE_FAIL,
    FIND_RECONCILIATION_MAINTENANCE_REQUEST,
    FIND_RECONCILIATION_MAINTENANCE_SUCCESS,
    FIND_RECONCILIATION_MAINTENANCE_FAIL,
    UPDATE_RECONCILIATION_MAINTENANCE_REQUEST,
    UPDATE_RECONCILIATION_MAINTENANCE_SUCCESS,
    UPDATE_RECONCILIATION_MAINTENANCE_FAIL,
    CLEAR_RECONCILIATION_MAINTENANCE_ERROR,
  } from "../../constants_Phase_2/ReconciliationMaintenance/ReconciliationMaintenanceConstant";
  
  // create
  export const reconciliationMaintenanceReducer = (state = { reconciliationMaintenanceList: [] }, action) => {
    switch (action.type) {
      case  RECONCILIATION_MAINTENANCE_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          reconciliationMaintenanceList: [],
          status: null,
          error: null,
        };

        case CREATE_RECONCILIATION_MAINTENANCE_REQUEST:
          case UPDATE_RECONCILIATION_MAINTENANCE_REQUEST:
          return {
            ...state,
            loading: true,
            message: null,
            status: null,
            error: null,
          };


      case RECONCILIATION_MAINTENANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          reconciliationMaintenanceList: action.payload,
          totalData : action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };


      case  CREATE_RECONCILIATION_MAINTENANCE_SUCCESS:
        case  UPDATE_RECONCILIATION_MAINTENANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case RECONCILIATION_MAINTENANCE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_RECONCILIATION_MAINTENANCE_FAIL:
        case UPDATE_RECONCILIATION_MAINTENANCE_FAIL:
        return {
          ...state,
          loading: false,
          reconciliationMaintenanceList: [...state.reconciliationMaintenanceList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_RECONCILIATION_MAINTENANCE_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
  export const findReconciliationMaintenanceReducer = (state = { reconciliationMaintenance: {} }, action) => {
    switch (action.type) {
        case FIND_RECONCILIATION_MAINTENANCE_REQUEST:
            return {
                reconciliationMaintenance: {},
                loading: true,
            };

        case FIND_RECONCILIATION_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                reconciliationMaintenance: action.payload,
            };

        case FIND_RECONCILIATION_MAINTENANCE_FAIL:
            return {
                ...state,
                loading: false,
                reconciliationMaintenance: {},
                error: action.payload
            };

        case "EMPTY_RECONCILIATION_MAINTENANCE":
            return {
                ...state,
                reconciliationMaintenance: {}
            }

        case CLEAR_RECONCILIATION_MAINTENANCE_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

    