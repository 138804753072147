
export const CREATE_RECONCILIATION_MAINTENANCE_REQUEST = "CREATE_RECONCILIATION_MAINTENANCE_REQUEST";
export const CREATE_RECONCILIATION_MAINTENANCE_SUCCESS = "CREATE_RECONCILIATION_MAINTENANCE_SUCCESS";
export const CREATE_RECONCILIATION_MAINTENANCE_FAIL = "CREATE_RECONCILIATION_MAINTENANCE_FAIL";

export const RECONCILIATION_MAINTENANCE_REQUEST = "RECONCILIATION_MAINTENANCE_REQUEST";
export const RECONCILIATION_MAINTENANCE_SUCCESS = "RECONCILIATION_MAINTENANCE_SUCCESS";
export const RECONCILIATION_MAINTENANCE_FAIL = "RECONCILIATION_MAINTENANCE_FAIL";


export const FIND_RECONCILIATION_MAINTENANCE_REQUEST = "FIND_RECONCILIATION_MAINTENANCE_REQUEST";
export const FIND_RECONCILIATION_MAINTENANCE_SUCCESS = "FIND_RECONCILIATION_MAINTENANCE_SUCCESS";
export const FIND_RECONCILIATION_MAINTENANCE_FAIL = "FIND_RECONCILIATION_MAINTENANCE_FAIL";


export const UPDATE_RECONCILIATION_MAINTENANCE_REQUEST = "UPDATE_RECONCILIATION_MAINTENANCE_REQUEST";
export const UPDATE_RECONCILIATION_MAINTENANCE_SUCCESS = "UPDATE_RECONCILIATION_MAINTENANCE_SUCCESS";
export const UPDATE_RECONCILIATION_MAINTENANCE_FAIL = "UPDATE_RECONCILIATION_MAINTENANCE_FAIL";



export const CLEAR_RECONCILIATION_MAINTENANCE_ERROR = "CLEAR_RECONCILIATION_MAINTENANCE_ERROR";