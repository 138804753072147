import {
  CREATE_REV_TYPE_REQUEST,
  CREATE_REV_TYPE_SUCCESS,
  CREATE_REV_TYPE_FAIL,
  REV_TYPE_REQUEST,
  REV_TYPE_SUCCESS,
  REV_TYPE_FAIL,
  FIND_REV_TYPE_REQUEST,
  FIND_REV_TYPE_SUCCESS,
  FIND_REV_TYPE_FAIL,
  UPDATE_REV_TYPE_REQUEST,
  UPDATE_REV_TYPE_SUCCESS,
  UPDATE_REV_TYPE_FAIL,
  DELETE_REV_TYPE_REQUEST,
  DELETE_REV_TYPE_SUCCESS,
  DELETE_REV_TYPE_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/RevTypeConstant";

export const revTypeReducer = (state = { revTypes: [] }, action) => {
  switch (action.type) {
    case REV_TYPE_REQUEST:
    case CREATE_REV_TYPE_REQUEST:
    case UPDATE_REV_TYPE_REQUEST:
    case DELETE_REV_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case REV_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REV_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revTypes: [...state.revTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REV_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revTypes: [
          ...state.revTypes.filter(
            (item) => item.revenueTypeId !== action.payload.revenueTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueTypeId - post.revenueTypeId),
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_REV_TYPE_SUCCESS":
      return {
        loading: false,
        revTypes: [
          ...state.revTypes.filter(
            (item) => item.revenueTypeId !== action.payload.revenueTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueTypeId - post.revenueTypeId),
        message: action.message,
        status: action.status,
      };

    case DELETE_REV_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revTypes: [
          ...state.revTypes.filter(
            (item) => item.revenueTypeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case REV_TYPE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_REV_TYPE_FAIL:
    case UPDATE_REV_TYPE_FAIL:
    case DELETE_REV_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRevTypeReducer = (state = { revType: {} }, action) => {
  switch (action.type) {
    case FIND_REV_TYPE_REQUEST:
      return {
        revType: {},
        loading: true,
      };

    case FIND_REV_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revType: action.payload,
      };

    case FIND_REV_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        revType: {},
        error: action.payload,
      };

    case "EMPTY_REV_TYPE":
      return {
        ...state,
        revType: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
