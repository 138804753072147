// create
export const CREATE_RAMIS_FUNCTIONAL_AREA_REQUEST = "CREATE_RAMIS_FUNCTIONAL_AREA_REQUEST";
export const CREATE_RAMIS_FUNCTIONAL_AREA_SUCCESS = "CREATE_RAMIS_FUNCTIONAL_AREA_SUCCESS";
export const CREATE_RAMIS_FUNCTIONAL_AREA_FAIL = "CREATE_RAMIS_FUNCTIONAL_AREA_FAIL";

// get
export const RAMIS_FUNCTIONAL_AREA_REQUEST = "RAMIS_FUNCTIONAL_AREA_REQUEST";
export const RAMIS_FUNCTIONAL_AREA_SUCCESS = "RAMIS_FUNCTIONAL_AREA_SUCCESS";
export const RAMIS_FUNCTIONAL_AREA_FAIL = "RAMIS_FUNCTIONAL_AREA_FAIL";

// find
export const FIND_RAMIS_FUNCTIONAL_AREA_REQUEST = "FIND_RAMIS_FUNCTIONAL_AREA_REQUEST";
export const FIND_RAMIS_FUNCTIONAL_AREA_SUCCESS = "FIND_RAMIS_FUNCTIONAL_AREA_SUCCESS";
export const FIND_RAMIS_FUNCTIONAL_AREA_FAIL = "FIND_RAMIS_FUNCTIONAL_AREA_FAIL";

// update
export const UPDATE_RAMIS_FUNCTIONAL_AREA_REQUEST = "UPDATE_RAMIS_FUNCTIONAL_AREA_REQUEST";
export const UPDATE_RAMIS_FUNCTIONAL_AREA_SUCCESS = "UPDATE_RAMIS_FUNCTIONAL_AREA_SUCCESS";
export const UPDATE_RAMIS_FUNCTIONAL_AREA_FAIL = "UPDATE_RAMIS_FUNCTIONAL_AREA_FAIL";

// delete
export const DELETE_RAMIS_FUNCTIONAL_AREA_REQUEST = "DELETE_RAMIS_FUNCTIONAL_AREA_REQUEST";
export const DELETE_RAMIS_FUNCTIONAL_AREA_SUCCESS = "DELETE_RAMIS_FUNCTIONAL_AREA_SUCCESS";
export const DELETE_RAMIS_FUNCTIONAL_AREA_FAIL = "DELETE_RAMIS_FUNCTIONAL_AREA_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
