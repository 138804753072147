import {
    CREATE_ADVANCE_PAYMENT_REQUEST,
    CREATE_ADVANCE_PAYMENT_SUCCESS,
    CREATE_ADVANCE_PAYMENT_FAIL,

    ADVANCE_PAYMENT_REQUEST,
    ADVANCE_PAYMENT_SUCCESS,
    ADVANCE_PAYMENT_FAIL,

    FIND_ADVANCE_PAYMENT_REQUEST,
    FIND_ADVANCE_PAYMENT_SUCCESS,
    FIND_ADVANCE_PAYMENT_FAIL,

    UPDATE_ADVANCE_PAYMENT_REQUEST,
    UPDATE_ADVANCE_PAYMENT_SUCCESS,
    UPDATE_ADVANCE_PAYMENT_FAIL,

    DELETE_ADVANCE_PAYMENT_REQUEST,
    DELETE_ADVANCE_PAYMENT_SUCCESS,
    DELETE_ADVANCE_PAYMENT_FAIL,
    CLEAR_ERROR,
} from "../../constants_Phase_2/RefundAndDisbursement/AdvancePaymentConstant";

export const getAdvancePaymentsReducer = (state = { advancePayments: [] }, action) => {
    switch (action.type) {
        case ADVANCE_PAYMENT_REQUEST:
            return {
                ...state,
                advancePayments: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case ADVANCE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                advancePayments: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case ADVANCE_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// create, get, update,delete
export const getAdvancePaymentResponseReducer = (state = { advancePaymentResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_ADVANCE_PAYMENT_REQUEST:
        case UPDATE_ADVANCE_PAYMENT_REQUEST:
        case DELETE_ADVANCE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_ADVANCE_PAYMENT_SUCCESS:
        case UPDATE_ADVANCE_PAYMENT_SUCCESS:
        case DELETE_ADVANCE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                advancePaymentResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_ADVANCE_PAYMENT_FAIL:
        case UPDATE_ADVANCE_PAYMENT_FAIL:
        case DELETE_ADVANCE_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_ADVANCE_PAYMENT_RESPONSE":
            return {
                ...state,
                advancePaymentResponse: {}
            }

        default:
            return state;
    }
};

// find one
export const findAdvancePaymentReducer = (state = { advancePayment: {} }, action) => {
    switch (action.type) {
        case FIND_ADVANCE_PAYMENT_REQUEST:
            return {
                advancePayment: {},
                loading: true,
            };

        case FIND_ADVANCE_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                advancePayment: action.payload,
            };

        case FIND_ADVANCE_PAYMENT_FAIL:
            return {
                ...state,
                loading: false,
                advancePayment: {},
                error: action.payload
            };

        case "EMPTY_ADVANCE_PAYMENT":
            return {
                ...state,
                advancePayment: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find
export const findAdvancePaymentVendorReducer = (state = { advancePaymentVendor: {} }, action) => {
    switch (action.type) {
        case "FIND_ADVANCE_PAYMENT_VENDOR_REQUEST":
            return {
                ...state,
                loading: true,
            };

        case "FIND_ADVANCE_PAYMENT_VENDOR_SUCCESS":
            return {
                ...state,
                loading: false,
                advancePaymentVendor: action.payload,
                message: action.message,
            };

        case "FIND_ADVANCE_PAYMENT_VENDOR_FAIL":
            return {
                ...state,
                loading: false,
                advancePaymentVendor: { ...state.advancePaymentVendor },
                error: action.payload,
            };

        case "EMPTY_ADVANCE_PAYMENT_VENDOR":
            return {
                advancePaymentVendor: {}
            };

        case "CLEAR_ADVANCE_PAYMENT_VENDOR_ERROR":
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};