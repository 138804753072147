import {
  RECEIPT_MAINTENANCE_REVIEW_REQUEST,
  RECEIPT_MAINTENANCE_REVIEW_SUCCESS,
  RECEIPT_MAINTENANCE_REVIEW_FAIL,

  RECEIPT_MAINTENANCE_REVIEW_CLEAR_ERROR
} from "../../../constants/ReceiptMaintenance/ReceiptMaintenanceReview/ReceiptMaintenanceReviewConstant";

export const getReceiptMaintenanceReviewReducer = (state = { receiptMaintenanceReview: []}, action) => {
  switch (action.type) {
    case RECEIPT_MAINTENANCE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case RECEIPT_MAINTENANCE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptMaintenanceReview: action.payload,
        message: null,
        status: null,
      };

    case RECEIPT_MAINTENANCE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        receiptMaintenanceReview: [...state.receiptMaintenanceReview],
        error: action.payload,
      };

      case "EMPTY_RECEIPT_MAINTENANCE_REVIEW":
      return {
        ...state,
        receiptMaintenanceReview: [],
      };

    case RECEIPT_MAINTENANCE_REVIEW_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};