import {
    CREATE_AGENCY_MAINTENANCE_REQUEST,
    CREATE_AGENCY_MAINTENANCE_SUCCESS,
    CREATE_AGENCY_MAINTENANCE_FAIL,

    AGENCY_MAINTENANCE_REQUEST,
    AGENCY_MAINTENANCE_SUCCESS,
    AGENCY_MAINTENANCE_FAIL,

    FIND_AGENCY_MAINTENANCE_REQUEST,
    FIND_AGENCY_MAINTENANCE_SUCCESS,
    FIND_AGENCY_MAINTENANCE_FAIL,

    UPDATE_AGENCY_MAINTENANCE_REQUEST,
    UPDATE_AGENCY_MAINTENANCE_SUCCESS,
    UPDATE_AGENCY_MAINTENANCE_FAIL,

    DELETE_AGENCY_MAINTENANCE_REQUEST,
    DELETE_AGENCY_MAINTENANCE_SUCCESS,
    DELETE_AGENCY_MAINTENANCE_FAIL,

    AGENCY_MAINTENANCE_CLEAR_ERROR,

} from "../../constants/AgencyMaintenance/AgencyMaintenanceConstant";


// create, update, delete and get all
export const getAgencyMaintenanceReducer = (state = { agencyMaintenances: [] }, action) => {
    switch (action.type) {
        case AGENCY_MAINTENANCE_REQUEST:
        case CREATE_AGENCY_MAINTENANCE_REQUEST:
        case UPDATE_AGENCY_MAINTENANCE_REQUEST:
        case DELETE_AGENCY_MAINTENANCE_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error:null
            };
        case AGENCY_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyMaintenances: action.payload,
                message: null,
                status: null,
            };

        case CREATE_AGENCY_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyMaintenances: [...state.agencyMaintenances, action.payload],
                createdObject: action.payload,
                message: action.message,
                status: action.status
            };

        case UPDATE_AGENCY_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyMaintenances: [
                    ...state.agencyMaintenances.filter(
                        (item) => item.agencyId !== action.payload.agencyId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.agencyId - post.agencyId),
                message: action.message,
                status: action.status,

            };

        case DELETE_AGENCY_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyMaintenances: [
                    ...state.agencyMaintenances.filter(
                        (item) => item.agencyId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case AGENCY_MAINTENANCE_FAIL:
            return {
                ...state,
                loading: false,
                agencyMaintenances: [...state.agencyMaintenances],
                error: action.payload,
            };

        case CREATE_AGENCY_MAINTENANCE_FAIL:
        case UPDATE_AGENCY_MAINTENANCE_FAIL:
            return {
                ...state,
                loading: false,
                agencyMaintenances: [...state.agencyMaintenances],
                message: action.message,
                status: action.status,
            };

        case DELETE_AGENCY_MAINTENANCE_FAIL:
            return {
                ...state,
                loading: false,
                agencyMaintenances: [...state.agencyMaintenances],
                error: action.payload,
            };

        case AGENCY_MAINTENANCE_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};


// find one
export const findAgencyMaintenanceReducer = (state = { agencyMaintenance: {} }, action) => {
    switch (action.type) {
        case FIND_AGENCY_MAINTENANCE_REQUEST:
            return {
                agencyMaintenance: {},
                loading: true,
            };

        case FIND_AGENCY_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyMaintenance: action.payload,
            };

        case FIND_AGENCY_MAINTENANCE_FAIL:
            return {
                ...state,
                loading: false,
                agencyMaintenance: {},
                error: action.payload,
            };

        case "EMPTY_AGENCY_MAINTENANCE":
            return {
                ...state,
                agencyMaintenance: {}
            }

        case AGENCY_MAINTENANCE_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};