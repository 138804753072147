import {
    PAYMENT_ADVICE_SYSTEM_REQUEST,
    PAYMENT_ADVICE_SYSTEM_SUCCESS,
    PAYMENT_ADVICE_SYSTEM_FAIL,

    PAYMENT_ADVICE_SYSTEM_CLEAR_ERROR,
} from "../../constants/PaymentAdvice/PaymentAdviceSystemConstant";

export const getPaymentAdviceSystemsReducer = (state = { paymentAdviceSystems: [] }, action) => {
    switch (action.type) {
        case PAYMENT_ADVICE_SYSTEM_REQUEST:
            return {
                ...state,
                paymentAdviceSystems: [],
                loading: true,
                status: null,
                message: null,
                error: null
            };

        case PAYMENT_ADVICE_SYSTEM_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentAdviceSystems: action.payload,
                totalData: action.totalData,
                message: null,
                status: null,
            };

        case PAYMENT_ADVICE_SYSTEM_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case PAYMENT_ADVICE_SYSTEM_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};