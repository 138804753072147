import {
  DRC_RECEIPT_STOCK_REQUEST,
  DRC_RECEIPT_STOCK_SUCCESS,
  DRC_RECEIPT_STOCK_FAIL,
  CREATE_DRC_RECEIPT_STOCK_REQUEST,
  CREATE_DRC_RECEIPT_STOCK_SUCCESS,
  CREATE_DRC_RECEIPT_STOCK_FAIL,
  FIND_DRC_RECEIPT_STOCK_REQUEST,
  FIND_DRC_RECEIPT_STOCK_SUCCESS,
  FIND_DRC_RECEIPT_STOCK_FAIL,
  UPDATE_DRC_RECEIPT_STOCK_REQUEST,
  UPDATE_DRC_RECEIPT_STOCK_SUCCESS,
  UPDATE_DRC_RECEIPT_STOCK_FAIL,
  CANCEL_RECEIPT_STOCK_REQUEST,
  CANCEL_RECEIPT_STOCK_SUCCESS,
  CANCEL_RECEIPT_STOCK_FAIL,
  CLEAR_DRC_RECEIPT_STOCK_ERROR,
} from "../../constants_Phase_2/ReceiptStock/DRCReceiptStockConstant";

// create, update, get all
export const getDRCReceiptStockReducer = (state = { drcReceiptStocks: [] }, action) => {
  switch (action.type) {
    case DRC_RECEIPT_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        drcReceiptStocks: [],
        message: null,
        status: null,
        error: null,
      };

    case DRC_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        drcReceiptStocks: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case DRC_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_DRC_RECEIPT_STOCK_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const cancelReceiptStockReducer = (state = { cancelReceiptStocks: [] }, action) => {
  switch (action.type) {
    case CANCEL_RECEIPT_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        cancelReceiptStocks: [],
        message: null,
        status: null,
        error: null,
      };

    case CANCEL_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        cancelReceiptStocks: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case CANCEL_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_DRC_RECEIPT_STOCK_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// create, update, get all
export const getDRCReceiptStockResponseReducer = (state = { drcReceiptStockResponse: [] }, action) => {
  switch (action.type) {
    case CREATE_DRC_RECEIPT_STOCK_REQUEST:
    case UPDATE_DRC_RECEIPT_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_DRC_RECEIPT_STOCK_SUCCESS:
    case UPDATE_DRC_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CREATE_DRC_RECEIPT_STOCK_FAIL:
    case UPDATE_DRC_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_DRC_RECEIPT_STOCK_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findDRCReceiptStockReducer = (
  state = { RCReceiptStock: [] },
  action
) => {
  switch (action.type) {
    case FIND_DRC_RECEIPT_STOCK_REQUEST:
      return {
        loading: true,
        DRCReceiptStock: [],
      };
    case FIND_DRC_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        DRCReceiptStock: action.payload,
      };

    case FIND_DRC_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "EMPTY_DRC_RECEIPT_STOCK":
      return {
        ...state,
        DRCReceiptStock: [],
      };

    case CLEAR_DRC_RECEIPT_STOCK_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
