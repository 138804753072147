import {
    CREATE_REFUND_APPLICATION_REQUEST,
    CREATE_REFUND_APPLICATION_SUCCESS,
    CREATE_REFUND_APPLICATION_FAIL,
    REFUND_APPLICATION_REQUEST,
    REFUND_APPLICATION_SUCCESS,
    REFUND_APPLICATION_FAIL,
    FIND_REFUND_APPLICATION_REQUEST,
    FIND_REFUND_APPLICATION_SUCCESS,
    FIND_REFUND_APPLICATION_FAIL,
    UPDATE_REFUND_APPLICATION_REQUEST,
    UPDATE_REFUND_APPLICATION_SUCCESS,
    UPDATE_REFUND_APPLICATION_FAIL,
    CLEAR_REFUND_APPLICATION_ERROR,
  } from "../../constants_Phase_2/RefundAndDisbursement/RefundApplicationConstant";
  
  // create
  export const refundApplicationListReducer = (state = { refundApplicationList: [] }, action) => {
    switch (action.type) {
      case  REFUND_APPLICATION_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          refundApplicationList: [],
          status: null,
          error: null,
        };

        case CREATE_REFUND_APPLICATION_REQUEST:
          case UPDATE_REFUND_APPLICATION_REQUEST:
          return {
            ...state,
            loading: true,
            message: null,
            status: null,
            error: null,
          };


      case REFUND_APPLICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          refundApplicationList: action.payload,
          totalData : action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };


      case  CREATE_REFUND_APPLICATION_SUCCESS:
        case  UPDATE_REFUND_APPLICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case REFUND_APPLICATION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_REFUND_APPLICATION_FAIL:
        case UPDATE_REFUND_APPLICATION_FAIL:
        return {
          ...state,
          loading: false,
          refundApplicationList: [...state.refundApplicationList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_REFUND_APPLICATION_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
export const findRefundApplicationReducer = (state = { refundApplication: {} }, action) => {
    switch (action.type) {
        case FIND_REFUND_APPLICATION_REQUEST:
            return {
                refundApplication: {},
                loading: true,
            };

        case FIND_REFUND_APPLICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                refundApplication: action.payload,
            };

        case FIND_REFUND_APPLICATION_FAIL:
            return {
                ...state,
                loading: false,
                refundApplication: {},
                error: action.payload
            };

        case "EMPTY_REFUND_APPLICATION":
            return {
                ...state,
                refundApplication: {}
            }

        case CLEAR_REFUND_APPLICATION_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

    