//CREATE
export const CREATE_SUB_MENU_REQUEST = "CREATE_SUB_MENU_REQUEST";
export const CREATE_SUB_MENU_SUCCESS = "CREATE_SUB_MENU_SUCCESS";
export const CREATE_SUB_MENU_FAIL = "CREATE_SUB_MENU_FAIL"
//GET ALL
export const SUB_MENU_REQUEST = "SUB_MENU_REQUEST";
export const SUB_MENU_SUCCESS = "SUB_MENU_SUCCESS";
export const SUB_MENU_FAIL = "SUB_MENU_FAIL"
//FIND ONE
export const FIND_SUB_MENU_REQUEST = "FIND_SUB_MENU_REQUEST";
export const FIND_SUB_MENU_SUCCESS = "FIND_SUB_MENU_SUCCESS";
export const FIND_SUB_MENU_FAIL = "FIND_SUB_MENU_FAIL"
//UPDATE ONE
export const UPDATE_SUB_MENU_REQUEST = "UPDATE_SUB_MENU_REQUEST";
export const UPDATE_SUB_MENU_SUCCESS = "UPDATE_SUB_MENU_SUCCESS";
export const UPDATE_SUB_MENU_FAIL = "UPDATE_SUB_MENU_FAIL"
//DELETE ONE
export const DELETE_SUB_MENU_REQUEST = "DELETE_SUB_MENU_REQUEST";
export const DELETE_SUB_MENU_SUCCESS = "DELETE_SUB_MENU_SUCCESS";
export const DELETE_SUB_MENU_FAIL = "DELETE_SUB_MENU_FAIL"

export const SUB_MENU_CLEAR_ERROR = "CLEAR_ERROR"