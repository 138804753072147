import {
  //create agency
  CREATE_AGENCY_TYPE_REQUEST,
  CREATE_AGENCY_TYPE_SUCCESS,
  CREATE_AGENCY_TYPE_FAIL,
  //update agency
  UPDATE_AGENCY_TYPE_REQUEST,
  UPDATE_AGENCY_TYPE_SUCCESS,
  UPDATE_AGENCY_TYPE_FAIL,
  //delete agency
  DELETE_AGENCY_TYPE_REQUEST,
  DELETE_AGENCY_TYPE_SUCCESS,
  DELETE_AGENCY_TYPE_FAIL,

  //get all agency types
  AGENCY_TYPE_REQUEST,
  AGENCY_TYPE_SUCCESS,
  AGENCY_TYPE_FAIL,

  FIND_AGENCY_TYPE_REQUEST,
  FIND_AGENCY_TYPE_SUCCESS,
  FIND_AGENCY_TYPE_FAIL,

  AGENCY_MASTER_CLEAR_ERROR,

} from "../../constants/AgencyMaster/AgencyMasterConstant";

// create agency 
export const getAgencyTypesReducer = (state = { agencyTypes: [] }, action) => {

  switch (action.type) {
    case AGENCY_TYPE_REQUEST:
    case CREATE_AGENCY_TYPE_REQUEST:
    case UPDATE_AGENCY_TYPE_REQUEST:
    case DELETE_AGENCY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };
    case AGENCY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_AGENCY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyTypes: [...state.agencyTypes, action.payload],
        message: action.message,
        status: action.status
      };

    case UPDATE_AGENCY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyTypes: [...state.agencyTypes.filter(
          (item) => item.agencyTypeId !== action.payload.agencyTypeId
        ),
        action.payload,
        ].sort((pre, post) => pre.agencyTypeId - post.agencyTypeId),
        message: action.message,
        status: action.status
      };


    case DELETE_AGENCY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyTypes: [
          ...state.agencyTypes.filter(
            (item) => item.agencyTypeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status
      };



    case "FUTURE_DELETE_AGENCY_TYPE_SUCCESS":
      return {
        loading: false,
        agencyTypes: [
          ...state.agencyTypes.filter(
            (item) => item.agencyTypeId !== action.payload.agencyTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.agencyTypeId - post.agencyTypeId),
        message: action.message,
        status: action.status,
      };



    case AGENCY_TYPE_FAIL:
    case CREATE_AGENCY_TYPE_FAIL:
    case UPDATE_AGENCY_TYPE_FAIL:
    case DELETE_AGENCY_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        agencyTypes: [...state.agencyTypes],
        message: action.message,
        status: action.status,
      };
    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};







// find one
export const findAgencyTypeReducer = (state = { agencyType: {} }, action) => {
  switch (action.type) {
    case FIND_AGENCY_TYPE_REQUEST:
      return {
        agencyType: {},
        loading: true,
      };

    case FIND_AGENCY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyType: action.payload,
      };

    case FIND_AGENCY_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        agencyType: {},
        error: action.payload,
      };

    case "EMPTY_AGENCY_TYPE":
      return {
        ...state,
        agencyType: {},
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};



