import {
    CREATE_GROUP_DEPOSIT_SLIP_REQUEST,
    CREATE_GROUP_DEPOSIT_SLIP_SUCCESS,
    CREATE_GROUP_DEPOSIT_SLIP_FAIL,
    GROUP_DEPOSIT_SLIP_REQUEST,
    GROUP_DEPOSIT_SLIP_SUCCESS,
    GROUP_DEPOSIT_SLIP_FAIL,
    FIND_GROUP_DEPOSIT_SLIP_REQUEST,
    FIND_GROUP_DEPOSIT_SLIP_SUCCESS,
    FIND_GROUP_DEPOSIT_SLIP_FAIL,
    UPDATE_GROUP_DEPOSIT_SLIP_REQUEST,
    UPDATE_GROUP_DEPOSIT_SLIP_SUCCESS,
    UPDATE_GROUP_DEPOSIT_SLIP_FAIL,
    CLEAR_GROUP_DEPOSIT_SLIP_ERROR,
  } from "../../constants_Phase_2/GroupDepositSlip/GroupDepositSlipConstant";
  
  // create
  export const groupDepositSlipListReducer = ( state = { groupDepositSlipList: [] }, action ) => {
    switch (action.type) {
      case GROUP_DEPOSIT_SLIP_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          groupDepositSlipList: [],
          status: null,
          error: null,
        };
  
      case CREATE_GROUP_DEPOSIT_SLIP_REQUEST:
      case UPDATE_GROUP_DEPOSIT_SLIP_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case GROUP_DEPOSIT_SLIP_SUCCESS:
        return {
          ...state,
          loading: false,
          groupDepositSlipList: action.payload,
          totalData: action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };
  
      case CREATE_GROUP_DEPOSIT_SLIP_SUCCESS:
      case UPDATE_GROUP_DEPOSIT_SLIP_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
          groupDepositId: action?.groupDepositId
        };
  
      case GROUP_DEPOSIT_SLIP_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_GROUP_DEPOSIT_SLIP_FAIL:
      case UPDATE_GROUP_DEPOSIT_SLIP_FAIL:
        return {
          ...state,
          loading: false,
          groupDepositSlipList: [...state.groupDepositSlipList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_GROUP_DEPOSIT_SLIP_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
  export const findGroupDepositSlipReducer = ( state = { groupDepositSlip: {} }, action ) => {
  
    switch (action.type) {
      case FIND_GROUP_DEPOSIT_SLIP_REQUEST:
        return {
          groupDepositSlip: {},
          loading: true,
        };
  
      case FIND_GROUP_DEPOSIT_SLIP_SUCCESS:
        return {
          ...state,
          loading: false,
          groupDepositSlip: action.payload,
        };
  
      case FIND_GROUP_DEPOSIT_SLIP_FAIL:
        return {
          ...state,
          loading: false,
          groupDepositSlip: {},
          error: action.payload,
        };
  
      case "EMPTY_GROUP_DEPOSIT_SLIP":
        return {
          ...state,
          groupDepositSlip: {},
        };
  
      case CLEAR_GROUP_DEPOSIT_SLIP_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  