
export const CREATE_WITHDRAWAL_DEPOSIT_REQUEST = "CREATE_WITHDRAWAL_DEPOSIT_REQUEST";
export const CREATE_WITHDRAWAL_DEPOSIT_SUCCESS = "CREATE_WITHDRAWAL_DEPOSIT_SUCCESS";
export const CREATE_WITHDRAWAL_DEPOSIT_FAIL = "CREATE_WITHDRAWAL_DEPOSIT_FAIL";

export const WITHDRAWAL_DEPOSIT_REQUEST = "WITHDRAWAL_DEPOSIT_REQUEST";
export const WITHDRAWAL_DEPOSIT_SUCCESS = "WITHDRAWAL_DEPOSIT_SUCCESS";
export const WITHDRAWAL_DEPOSIT_FAIL = "WITHDRAWAL_DEPOSIT_FAIL";


export const FIND_WITHDRAWAL_DEPOSIT_REQUEST = "FIND_WITHDRAWAL_DEPOSIT_REQUEST";
export const FIND_WITHDRAWAL_DEPOSIT_SUCCESS = "FIND_WITHDRAWAL_DEPOSIT_SUCCESS";
export const FIND_WITHDRAWAL_DEPOSIT_FAIL = "FIND_WITHDRAWAL_DEPOSIT_FAIL";


export const UPDATE_WITHDRAWAL_DEPOSIT_REQUEST = "UPDATE_WITHDRAWAL_DEPOSIT_REQUEST";
export const UPDATE_WITHDRAWAL_DEPOSIT_SUCCESS = "UPDATE_WITHDRAWAL_DEPOSIT_SUCCESS";
export const UPDATE_WITHDRAWAL_DEPOSIT_FAIL = "UPDATE_WITHDRAWAL_DEPOSIT_FAIL";



export const CLEAR_WITHDRAWAL_DEPOSIT_ERROR = "CLEAR_WITHDRAWAL_DEPOSIT_ERROR";