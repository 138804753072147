// create/post
export const CREATE_RISK_RULE_REQUEST = "CREATE_RISK_RULE_REQUEST";
export const CREATE_RISK_RULE_SUCCESS = "CREATE_RISK_RULE_SUCCESS";
export const CREATE_RISK_RULE_FAIL = "CREATE_RISK_RULE_FAIL";

// get
export const RISK_RULE_REQUEST = "RISK_RULE_REQUEST";
export const RISK_RULE_SUCCESS = "RISK_RULE_SUCCESS";
export const RISK_RULE_FAIL = "RISK_RULE_FAIL";

// get filtered
export const FILTERED_RISK_RULE_REQUEST = "FILTERED_RISK_RULE_REQUEST";
export const FILTERED_RISK_RULE_SUCCESS = "FILTERED_RISK_RULE_SUCCESS";
export const FILTERED_RISK_RULE_FAIL = "FILTERED_RISK_RULE_FAIL";

// find
export const FIND_RISK_RULE_REQUEST = "FIND_RISK_RULE_REQUEST";
export const FIND_RISK_RULE_SUCCESS = "FIND_RISK_RULE_SUCCESS";
export const FIND_RISK_RULE_FAIL = "FIND_RISK_RULE_FAIL";

// update/put
export const UPDATE_RISK_RULE_REQUEST = "UPDATE_RISK_RULE_REQUEST";
export const UPDATE_RISK_RULE_SUCCESS = "UPDATE_RISK_RULE_SUCCESS";
export const UPDATE_RISK_RULE_FAIL = "UPDATE_RISK_RULE_FAIL";

// delete
export const DELETE_RISK_RULE_REQUEST = "DELETE_RISK_RULE_REQUEST";
export const DELETE_RISK_RULE_SUCCESS = "DELETE_RISK_RULE_SUCCESS";
export const DELETE_RISK_RULE_FAIL = "DELETE_RISK_RULE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";