import {
  CREATE_REV_GROUP_REQUEST,
  CREATE_REV_GROUP_SUCCESS,
  CREATE_REV_GROUP_FAIL,
  REV_GROUP_REQUEST,
  REV_GROUP_SUCCESS,
  REV_GROUP_FAIL,
  FIND_REV_GROUP_REQUEST,
  FIND_REV_GROUP_SUCCESS,
  FIND_REV_GROUP_FAIL,
  UPDATE_REV_GROUP_REQUEST,
  UPDATE_REV_GROUP_SUCCESS,
  UPDATE_REV_GROUP_FAIL,
  DELETE_REV_GROUP_REQUEST,
  DELETE_REV_GROUP_SUCCESS,
  DELETE_REV_GROUP_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/RevGroupConstant";

export const revGroupReducer = (state = { revGroups: [] }, action) => {
  switch (action.type) {
    case REV_GROUP_REQUEST:
    case CREATE_REV_GROUP_REQUEST:
    case UPDATE_REV_GROUP_REQUEST:
    case DELETE_REV_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revGroups: [...state.revGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revGroups: [
          ...state.revGroups.filter(
            (item) => item.revenueGroupId !== action.payload.revenueGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueGroupId - post.revenueGroupId),
        message: action.message,
        status: action.status,
      };

    case DELETE_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revGroups: [
          ...state.revGroups.filter(
            (item) => item.revenueGroupId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_REV_GROUP_SUCCESS":
      return {
        loading: false,
        revGroups: [
          ...state.revGroups.filter(
            (item) => item.revenueGroupId !== action.payload.revenueGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueGroupId - post.revenueGroupId),
        message: action.message,
        status: action.status,
      };

    case REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_REV_GROUP_FAIL:
    case UPDATE_REV_GROUP_FAIL:
    case DELETE_REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRevGroupReducer = (state = { revGroup: {} }, action) => {
  switch (action.type) {
    case FIND_REV_GROUP_REQUEST:
      return {
        revGroup: {},
        loading: true,
      };

    case FIND_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        revGroup: action.payload,
      };

    case FIND_REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        revGroup: {},
        error: action.payload,
      };

    case "EMPTY_REVENUE_GROUP":
      return {
        ...state,
        revGroup: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
