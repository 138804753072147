import {
  MENU_TITLE_REQUEST,
  MENU_TITLE_SUCCESS,
  MENU_TITLE_FAIL,

  CREATE_MENU_TITLE_REQUEST,
  CREATE_MENU_TITLE_SUCCESS,
  CREATE_MENU_TITLE_FAIL,

  FIND_MENU_TITLE_REQUEST,
  FIND_MENU_TITLE_SUCCESS,
  FIND_MENU_TITLE_FAIL,

  UPDATE_MENU_TITLE_REQUEST,
  UPDATE_MENU_TITLE_SUCCESS,
  UPDATE_MENU_TITLE_FAIL,

  DELETE_MENU_TITLE_REQUEST,
  DELETE_MENU_TITLE_SUCCESS,
  DELETE_MENU_TITLE_FAIL,

  MENU_TITLE_CLEAR_ERROR,
} from "../../constants/Menu/MenuTitleConstant";

// create, update, delete and get all
export const getMenuTitlesReducer = (state = { menuTitles: [] }, action) => {
  switch (action.type) {
    case MENU_TITLE_REQUEST:
    case CREATE_MENU_TITLE_REQUEST:
    case UPDATE_MENU_TITLE_REQUEST:
    case DELETE_MENU_TITLE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null
      };
    case MENU_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuTitles: action.payload,
        message: null,
        status: null,
      };

    case CREATE_MENU_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuTitles: [...state.menuTitles, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_MENU_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuTitles: [
          ...state.menuTitles.filter(
            (item) => item.menuTitleId !== action.payload.menuTitleId
          ),
          action.payload,
        ].sort((pre, post) => pre.menuTitleId - post.menuTitleId),
        message: action.message,
        status: action.status,
      };

    case DELETE_MENU_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuTitles: [
          ...state.menuTitles.filter((item) => item.menuTitleId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case MENU_TITLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case DELETE_MENU_TITLE_FAIL:
    case CREATE_MENU_TITLE_FAIL:
    case UPDATE_MENU_TITLE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status
      };


    case MENU_TITLE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findMenuTitleReducer = (state = { menuTitle: {} }, action) => {
  switch (action.type) {
    case FIND_MENU_TITLE_REQUEST:
      return {
        menuTitle: {},
        loading: true,
      };

    case FIND_MENU_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        menuTitle: action.payload,
      };

    case FIND_MENU_TITLE_FAIL:
      return {
        ...state,
        loading: false,
        menuTitle: {},
        error: action.payload,
      };

    case "EMPTY_MENU_TITLE":
      return {
        ...state,
        menuTitle: {}
      };

    case MENU_TITLE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
