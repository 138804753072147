export const CREATE_ACTION_TO_BE_TAKEN_REQUEST = "CREATE_ACTION_TO_BE_TAKEN_REQUEST";
export const CREATE_ACTION_TO_BE_TAKEN_SUCCESS = "CREATE_ACTION_TO_BE_TAKEN_SUCCESS";
export const CREATE_ACTION_TO_BE_TAKEN_FAIL = "CREATE_ACTION_TO_BE_TAKEN_FAIL";

export const ACTION_TO_BE_TAKEN_REQUEST = "ACTION_TO_BE_TAKEN_REQUEST";
export const ACTION_TO_BE_TAKEN_SUCCESS = "ACTION_TO_BE_TAKEN_SUCCESS";
export const ACTION_TO_BE_TAKEN_FAIL = "ACTION_TO_BE_TAKEN_FAIL";


export const FIND_ACTION_TO_BE_TAKEN_REQUEST = "FIND_ACTION_TO_BE_TAKEN_REQUEST";
export const FIND_ACTION_TO_BE_TAKEN_SUCCESS = "FIND_ACTION_TO_BE_TAKEN_SUCCESS";
export const FIND_ACTION_TO_BE_TAKEN_FAIL = "FIND_ACTION_TO_BE_TAKEN_FAIL";


export const UPDATE_ACTION_TO_BE_TAKEN_REQUEST = "UPDATE_ACTION_TO_BE_TAKEN_REQUEST";
export const UPDATE_ACTION_TO_BE_TAKEN_SUCCESS = "UPDATE_ACTION_TO_BE_TAKEN_SUCCESS";
export const UPDATE_ACTION_TO_BE_TAKEN_FAIL = "UPDATE_ACTION_TO_BE_TAKEN_FAIL";

export const FIND_ACTION_TO_BE_TAKEN_DETAILS_REQUEST = "FIND_ACTION_TO_BE_TAKEN_DETAILS_REQUEST";
export const FIND_ACTION_TO_BE_TAKEN_DETAILS_SUCCESS = "FIND_ACTION_TO_BE_TAKEN_DETAILS_SUCCESS";
export const FIND_ACTION_TO_BE_TAKEN_DETAILS_FAIL = "FIND_ACTION_TO_BE_TAKEN_DETAILS_FAIL";



export const CLEAR_ACTION_TO_BE_TAKEN_ERROR = "CLEAR_ACTION_TO_BE_TAKEN_ERROR";