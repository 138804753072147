// create
export const CREATE_RMA_FAILED_TRANSACTION_REQUEST = "CREATE_RMA_FAILED_TRANSACTION_REQUEST";
export const CREATE_RMA_FAILED_TRANSACTION_SUCCESS = "CREATE_RMA_FAILED_TRANSACTION_SUCCESS";
export const CREATE_RMA_FAILED_TRANSACTION_FAIL = "CREATE_RMA_FAILED_TRANSACTION_FAIL";

// get
export const RMA_FAILED_TRANSACTION_REQUEST = "RMA_FAILED_TRANSACTION_REQUEST";
export const RMA_FAILED_TRANSACTION_SUCCESS = "RMA_FAILED_TRANSACTION_SUCCESS";
export const RMA_FAILED_TRANSACTION_FAIL = "RMA_FAILED_TRANSACTION_FAIL";

// find
export const FIND_RMA_FAILED_TRANSACTION_REQUEST = "FIND_RMA_FAILED_TRANSACTION_REQUEST";
export const FIND_RMA_FAILED_TRANSACTION_SUCCESS = "FIND_RMA_FAILED_TRANSACTION_SUCCESS";
export const FIND_RMA_FAILED_TRANSACTION_FAIL = "FIND_RMA_FAILED_TRANSACTION_FAIL";

// update
export const UPDATE_RMA_FAILED_TRANSACTION_REQUEST = "UPDATE_RMA_FAILED_TRANSACTION_REQUEST";
export const UPDATE_RMA_FAILED_TRANSACTION_SUCCESS = "UPDATE_RMA_FAILED_TRANSACTION_SUCCESS";
export const UPDATE_RMA_FAILED_TRANSACTION_FAIL = "UPDATE_RMA_FAILED_TRANSACTION_FAIL";

// delete
export const DELETE_RMA_FAILED_TRANSACTION_REQUEST = "DELETE_RMA_FAILED_TRANSACTION_REQUEST";
export const DELETE_RMA_FAILED_TRANSACTION_SUCCESS = "DELETE_RMA_FAILED_TRANSACTION_SUCCESS";
export const DELETE_RMA_FAILED_TRANSACTION_FAIL = "DELETE_RMA_FAILED_TRANSACTION_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
