import {
    CREATE_AUDIT_PLAN_REQUEST,
    CREATE_AUDIT_PLAN_SUCCESS,
    CREATE_AUDIT_PLAN_FAIL,

    AUDIT_PLAN_REQUEST,
    AUDIT_PLAN_SUCCESS,
    AUDIT_PLAN_FAIL,

    FIND_AUDIT_PLAN_REQUEST,
    FIND_AUDIT_PLAN_SUCCESS,
    FIND_AUDIT_PLAN_FAIL,

    UPDATE_AUDIT_PLAN_REQUEST,
    UPDATE_AUDIT_PLAN_SUCCESS,
    UPDATE_AUDIT_PLAN_FAIL,

    DELETE_AUDIT_PLAN_REQUEST,
    DELETE_AUDIT_PLAN_SUCCESS,
    DELETE_AUDIT_PLAN_FAIL,

    AUDIT_PLAN_TEAM_ROLE_REQUEST,
    AUDIT_PLAN_TEAM_ROLE_SUCCESS,
    AUDIT_PLAN_TEAM_ROLE_FAIL,

    AUDIT_PLAN_CLEAR_ERROR,
} from "../../constants_Phase_2/AuditManagement/AuditPlanConstant";

export const getAuditPlansReducer = (state = { auditPlans: [] }, action) => {
    switch (action.type) {
        case AUDIT_PLAN_REQUEST:
            return {
                ...state,
                auditPlans: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case AUDIT_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                auditPlans: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case AUDIT_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case AUDIT_PLAN_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// create, get, update,delete
export const auditPlanResponseReducer = (state = { auditPlanResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_AUDIT_PLAN_REQUEST:
        case UPDATE_AUDIT_PLAN_REQUEST:
        case DELETE_AUDIT_PLAN_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_AUDIT_PLAN_SUCCESS:
        case UPDATE_AUDIT_PLAN_SUCCESS:
        case DELETE_AUDIT_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                auditPlanResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_AUDIT_PLAN_FAIL:
        case UPDATE_AUDIT_PLAN_FAIL:
        case DELETE_AUDIT_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case AUDIT_PLAN_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_AUDIT_PLAN_RESPONSE":
            return {
                ...state,
                auditPlanResponse: {}
            }

        default:
            return state;
    }
};

// find one
export const findAuditPlanReducer = (state = { auditPlan: {} }, action) => {
    switch (action.type) {
        case FIND_AUDIT_PLAN_REQUEST:
            return {
                auditPlan: {},
                loading: true,
            };

        case FIND_AUDIT_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                auditPlan: action.payload,
            };

        case FIND_AUDIT_PLAN_FAIL:
            return {
                ...state,
                loading: false,
                auditPlan: {},
                error: action.payload
            };

        case "EMPTY_AUDIT_PLAN":
            return {
                auditPlan: {}
            }

        case AUDIT_PLAN_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

export const getAuditPlanTeamRoles = (state = { auditPlanTeamRoles: [] }, action) => {
    switch (action.type) {
      case AUDIT_PLAN_TEAM_ROLE_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case AUDIT_PLAN_TEAM_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          auditPlanTeamRoles: action.payload,
        };
  
      case AUDIT_PLAN_TEAM_ROLE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  