// create
export const CREATE_USER_ROLE_DETAIL_REQUEST = "CREATE_USER_ROLE_DETAIL_REQUEST";
export const CREATE_USER_ROLE_DETAIL_SUCCESS = "CREATE_USER_ROLE_DETAIL_SUCCESS";
export const CREATE_USER_ROLE_DETAIL_FAIL = "CREATE_USER_ROLE_DETAIL_FAIL";

// get
export const USER_ROLE_DETAIL_REQUEST = "USER_ROLE_DETAIL_REQUEST";
export const USER_ROLE_DETAIL_SUCCESS = "USER_ROLE_DETAIL_SUCCESS";
export const USER_ROLE_DETAIL_FAIL = "USER_ROLE_DETAIL_FAIL";

// find
export const FIND_USER_ROLE_DETAIL_REQUEST = "FIND_USER_ROLE_DETAIL_REQUEST";
export const FIND_USER_ROLE_DETAIL_SUCCESS = "FIND_USER_ROLE_DETAIL_SUCCESS";
export const FIND_USER_ROLE_DETAIL_FAIL = "FIND_USER_ROLE_DETAIL_FAIL";

// update/put
export const UPDATE_USER_ROLE_DETAIL_REQUEST = "UPDATE_USER_ROLE_DETAIL_REQUEST";
export const UPDATE_USER_ROLE_DETAIL_SUCCESS = "UPDATE_USER_ROLE_DETAIL_SUCCESS";
export const UPDATE_USER_ROLE_DETAIL_FAIL = "UPDATE_USER_ROLE_DETAIL_FAIL";

export const USER_ROLE_DETAIL_CLEAR_ERROR = "USER_ROLE_DETAIL_CLEAR_ERROR"