// find one
export const prelimAuditReportDataReducer = (state = { prelimAuditReportData: {} }, action) => {
  switch (action.type) {
    case "SET_PRELIM_AUDIT_REPORT_DATA":
      return {
        prelimAuditReportData: action.payload,
      };

    case "EMPTY_PRELIM_AUDIT_REPORT_DATA":
      return {
        prelimAuditReportData: {},
      };

    default:
      return state;
  }
};