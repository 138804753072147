import {
    VENDOR_REGISTRATION_REQUEST,
    VENDOR_REGISTRATION_SUCCESS,
    VENDOR_REGISTRATION_FAIL,

    CREATE_VENDOR_REGISTRATION_REQUEST,
    CREATE_VENDOR_REGISTRATION_SUCCESS,
    CREATE_VENDOR_REGISTRATION_FAIL,

    FIND_VENDOR_INFORMATION_REQUEST,
    FIND_VENDOR_INFORMATION_SUCCESS,
    FIND_VENDOR_INFORMATION_FAIL,

    UPDATE_VENDOR_REGISTRATION_REQUEST,
    UPDATE_VENDOR_REGISTRATION_SUCCESS,
    UPDATE_VENDOR_REGISTRATION_FAIL,

    CLEAR_VENDOR_REGISTRATION_ERROR,
  } from "../../constants_Phase_2/RefundAndDisbursement/VendorRegistrationConstant";
  
  // create
  export const vendorRegistrationListReducer = (state = { vendorRegistrationList: [] }, action) => {
    switch (action.type) {
      case VENDOR_REGISTRATION_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          vendorRegistrationList: [],
          status: null,
          error: null,
        };

      case CREATE_VENDOR_REGISTRATION_REQUEST:
        case UPDATE_VENDOR_REGISTRATION_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };

      case VENDOR_REGISTRATION_SUCCESS:
        return {
          ...state,
          loading: false,
          vendorRegistrationList: action.payload,
          totalData : action.totalData,
          currentPage: action.currentPage,
          message: null,
          status: null,
        };
  
      case CREATE_VENDOR_REGISTRATION_SUCCESS:
        case UPDATE_VENDOR_REGISTRATION_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case VENDOR_REGISTRATION_FAIL:
        return {
          ...state,
          loading: false,
          vendorRegistrationList: [...state.vendorRegistrationList],
          error: action.payload,
        };
  
      case CREATE_VENDOR_REGISTRATION_FAIL:
        case UPDATE_VENDOR_REGISTRATION_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_VENDOR_REGISTRATION_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };


// create
export const findVendorInformationReducer = (state = { vendorInformation: {} }, action) => {
  switch (action.type) {
    case FIND_VENDOR_INFORMATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIND_VENDOR_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        vendorInformation: action.payload,
        message: action.message,
      };

    case FIND_VENDOR_INFORMATION_FAIL:
      return {
        ...state,
        loading: false,
        vendorInformation: { ...state.vendorInformation },
        error: action.payload,
        message: null,
      };

    case "EMPTY_VENDOR_INFORMATION":
      return {
        vendorInformation: {}
      };

    case CLEAR_VENDOR_REGISTRATION_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};