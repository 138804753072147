// create
export const CREATE_NOTIFICATION_LEVEL_REQUEST = "CREATE_NOTIFICATION_LEVEL_REQUEST";
export const CREATE_NOTIFICATION_LEVEL_SUCCESS = "CREATE_NOTIFICATION_LEVEL_SUCCESS";
export const CREATE_NOTIFICATION_LEVEL_FAIL = "CREATE_NOTIFICATION_LEVEL_FAIL"
export const CREATE_NOTIFICATION_LEVEL_CONFLICT = "CREATE_NOTIFICATION_LEVEL_CONFLICT"

// get
export const NOTIFICATION_LEVEL_REQUEST = "NOTIFICATION_LEVEL_REQUEST";
export const NOTIFICATION_LEVEL_SUCCESS = "NOTIFICATION_LEVEL_SUCCESS";
export const NOTIFICATION_LEVEL_FAIL = "NOTIFICATION_LEVEL_FAIL"

export const NOTIFY_TO_REQUEST = "NOTIFY_TO_REQUEST";
export const NOTIFY_TO_SUCCESS = "NOTIFY_TO_SUCCESS";
export const NOTIFY_TO_FAIL = "NOTIFY_TO_FAIL"


// find
export const FIND_NOTIFICATION_LEVEL_REQUEST = "FIND_NOTIFICATION_LEVEL_REQUEST";
export const FIND_NOTIFICATION_LEVEL_SUCCESS = "FIND_NOTIFICATION_LEVEL_SUCCESS";
export const FIND_NOTIFICATION_LEVEL_FAIL = "FIND_NOTIFICATION_LEVEL_FAIL"

// update
export const UPDATE_NOTIFICATION_LEVEL_REQUEST = "UPDATE_NOTIFICATION_LEVEL_REQUEST";
export const UPDATE_NOTIFICATION_LEVEL_SUCCESS = "UPDATE_NOTIFICATION_LEVEL_SUCCESS";
export const UPDATE_NOTIFICATION_LEVEL_FAIL = "UPDATE_NOTIFICATION_LEVEL_FAIL"



export const NOTIFICATION_LEVEL_CLEAR_ERROR = "NOTIFICATION_LEVEL_CLEAR_ERROR"
