import {
    CREATE_COA_BTC_MAP_REQUEST,
    CREATE_COA_BTC_MAP_SUCCESS,
    CREATE_COA_BTC_MAP_FAIL,
    COA_BTC_MAP_REQUEST,
    COA_BTC_MAP_SUCCESS,
    COA_BTC_MAP_FAIL,
    FIND_COA_BTC_MAP_REQUEST,
    FIND_COA_BTC_MAP_SUCCESS,
    FIND_COA_BTC_MAP_FAIL,
    UPDATE_COA_BTC_MAP_REQUEST,
    UPDATE_COA_BTC_MAP_SUCCESS,
    UPDATE_COA_BTC_MAP_FAIL,
    DELETE_COA_BTC_MAP_REQUEST,
    DELETE_COA_BTC_MAP_SUCCESS,
    DELETE_COA_BTC_MAP_FAIL,
    COA_BTC_MAP_CLEAR_ERROR,
  } from "../../constants/CoaMapping/CoaBtcMapConstant";
  
  export const getCoaBtcMapReducer = (state = { coaBtcMaps: [] }, action) => {
    switch (action.type) {
      case COA_BTC_MAP_REQUEST:
      case CREATE_COA_BTC_MAP_REQUEST:
      case UPDATE_COA_BTC_MAP_REQUEST:
      case DELETE_COA_BTC_MAP_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
        };
  
      case COA_BTC_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaBtcMaps: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_COA_BTC_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaBtcMaps: [...state.coaBtcMaps, ...action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_COA_BTC_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaBtcMaps: [
            ...state.coaBtcMaps.filter(
              (item) => item.coaBtcMappingId !== action.payload.coaBtcMappingId
            ),
            action.payload,
          ].sort((pre, post) => pre.coaBtcMappingId - post.coaBtcMappingId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_BTC_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaBtcMaps: [
            ...state.coaBtcMaps.filter(
              (item) => item.coaBtcMappingId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  
      case COA_BTC_MAP_FAIL:
        return {
          ...state,
          loading: false,
        };
  
      case CREATE_COA_BTC_MAP_FAIL:
      case UPDATE_COA_BTC_MAP_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_BTC_MAP_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case COA_BTC_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findCoaBtcMapReducer = (state = { coaBtcMap: {} }, action) => {
    switch (action.type) {
      case FIND_COA_BTC_MAP_REQUEST:
        return {
          coaBtcMap: {},
          loading: true,
        };
  
      case FIND_COA_BTC_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaBtcMap: action.payload,
        };
  
      case FIND_COA_BTC_MAP_FAIL:
        return {
          ...state,
          loading: false,
          coaBtcMap: {},
          error: action.payload
        };
  
      case "EMPTY_COA_BTC_MAP":
        return {
          ...state,
          coaBtcMap: {}
        }
  
      case COA_BTC_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  