// Exchange Rate create/post
export const CREATE_EXCHANGE_RATE_REQUEST = "CREATE_EXCHANGE_RATE_REQUEST";
export const CREATE_EXCHANGE_RATE_SUCCESS = "CREATE_EXCHANGE_RATE_SUCCESS";
export const CREATE_EXCHANGE_RATE_FAIL = "CREATE_EXCHANGE_RATE_FAIL";

// Exchange Rate get
export const EXCHANGE_RATE_REQUEST = "EXCHANGE_RATE_REQUEST";
export const EXCHANGE_RATE_SUCCESS = "EXCHANGE_RATE_SUCCESS";
export const EXCHANGE_RATE_FAIL = "EXCHANGE_RATE_FAIL";


// Exchange Rate find
export const FIND_EXCHANGE_RATE_REQUEST = "FIND_EXCHANGE_RATE_REQUEST";
export const FIND_EXCHANGE_RATE_SUCCESS = "FIND_EXCHANGE_RATE_SUCCESS";
export const FIND_EXCHANGE_RATE_FAIL = "FIND_EXCHANGE_RATE_FAIL";

// Exchange Rate update/put
export const UPDATE_EXCHANGE_RATE_REQUEST = "UPDATE_EXCHANGE_RATE_REQUEST";
export const UPDATE_EXCHANGE_RATE_SUCCESS = "UPDATE_EXCHANGE_RATE_SUCCESS";
export const UPDATE_EXCHANGE_RATE_FAIL = "UPDATE_EXCHANGE_RATE_FAIL";


// Exchange Rate update/put
// export const DELETE_EXCHANGE_RATE_REQUEST = "DELETE_EXCHANGE_RATE_REQUEST";
// export const DELETE_EXCHANGE_RATE_SUCCESS = "DELETE_EXCHANGE_RATE_SUCCESS";
// export const DELETE_EXCHANGE_RATE_FAIL = "DELETE_EXCHANGE_RATE_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR";
