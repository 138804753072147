import {
  CREATE_DUNGKHAG_REQUEST,
  CREATE_DUNGKHAG_SUCCESS,
  CREATE_DUNGKHAG_FAIL,
  DUNGKHAG_REQUEST,
  DUNGKHAG_SUCCESS,
  DUNGKHAG_FAIL,
  FIND_DUNGKHAG_REQUEST,
  FIND_DUNGKHAG_SUCCESS,
  FIND_DUNGKHAG_FAIL,
  UPDATE_DUNGKHAG_REQUEST,
  UPDATE_DUNGKHAG_SUCCESS,
  UPDATE_DUNGKHAG_FAIL,
  DELETE_DUNGKHAG_REQUEST,
  DELETE_DUNGKHAG_SUCCESS,
  DELETE_DUNGKHAG_FAIL,
  CLEAR_ERROR,
} from "../../constants/LocationMaster/dungkhagConstant";

export const dungkhagReducer = (state = { dungkhags: [] }, action) => {
  switch (action.type) {
    case DUNGKHAG_REQUEST:
    case CREATE_DUNGKHAG_REQUEST:
    case UPDATE_DUNGKHAG_REQUEST:
    case DELETE_DUNGKHAG_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: action.payload,
        status: null,
        message: null,
      };

    case CREATE_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: [...state.dungkhags, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: [
          ...state.dungkhags.filter(
            (item) => item.dungkhagId !== action.payload.dungkhagId
          ),
          action.payload,
        ].sort((pre, post) => pre.dungkhagId - post.dungkhagId),
        message: action.message,
        status: action.status,
      };

    case DELETE_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhags: [
          ...state.dungkhags.filter(
            (item) => item.dungkhagId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_DUNGKHAG_SUCCESS":
      return {
        loading: false,
        dungkhags: [
          ...state.dungkhags.filter(
            (item) => item.dungkhagId !== action.payload.dungkhagId
          ),
          action.payload,
        ].sort((pre, post) => pre.dungkhagId - post.dungkhagId),
        message: action.message,
        status: action.status,
      };

    case DUNGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        dungkhags: [...state.dungkhags],
        error: action.payload,
      };

    case CREATE_DUNGKHAG_FAIL:
    case UPDATE_DUNGKHAG_FAIL:
    case DELETE_DUNGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        dungkhags: [...state.dungkhags],
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const findDungkhagReducer = (state = { dungkhag: {} }, action) => {
  switch (action.type) {
    case FIND_DUNGKHAG_REQUEST:
      return {
        loading: true,
        dungkhag: {},
      };
    case FIND_DUNGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        dungkhag: action.payload,
      };

    case FIND_DUNGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        dungkhag: {},
        error: action.payload,
      };
    case "EMPTY_DUNGKHAG":
      return {
        ...state,
        dungkhag: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
