// Prepayment type create/post
export const CREATE_PREPAYMENT_TYPE_REQUEST = "CREATE_PREPAYMENT_TYPE_REQUEST";
export const CREATE_PREPAYMENT_TYPE_SUCCESS = "CREATE_PREPAYMENT_TYPE_SUCCESS";
export const CREATE_PREPAYMENT_TYPE_FAIL = "CREATE_PREPAYMENT_TYPE_FAIL"

// Prepayment type get
export const PREPAYMENT_TYPE_REQUEST = "PREPAYMENT_TYPE_REQUEST";
export const PREPAYMENT_TYPE_SUCCESS = "PREPAYMENT_TYPE_SUCCESS";
export const PREPAYMENT_TYPE_FAIL = "PREPAYMENT_TYPE_FAIL"


// Prepayment type find
export const FIND_PREPAYMENT_TYPE_REQUEST = "FIND_PREPAYMENT_TYPE_REQUEST";
export const FIND_PREPAYMENT_TYPE_SUCCESS = "FIND_PREPAYMENT_TYPE_SUCCESS";
export const FIND_PREPAYMENT_TYPE_FAIL = "FIND_PREPAYMENT_TYPE_FAIL"

// Prepayment type update/put
export const UPDATE_PREPAYMENT_TYPE_REQUEST = "UPDATE_PREPAYMENT_TYPE_REQUEST";
export const UPDATE_PREPAYMENT_TYPE_SUCCESS = "UPDATE_PREPAYMENT_TYPE_SUCCESS";
export const UPDATE_PREPAYMENT_TYPE_FAIL = "UPDATE_PREPAYMENT_TYPE_FAIL"

// Prepayment type update/put
export const DELETE_PREPAYMENT_TYPE_REQUEST = "DELETE_PREPAYMENT_TYPE_REQUEST";
export const DELETE_PREPAYMENT_TYPE_SUCCESS = "DELETE_PREPAYMENT_TYPE_SUCCESS";
export const DELETE_PREPAYMENT_TYPE_FAIL = "DELETE_PREPAYMENT_TYPE_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"
