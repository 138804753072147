// search
export const AUDIT_SUPPORT_DATA_REQUEST = "AUDIT_SUPPORT_DATA_REQUEST";
export const AUDIT_SUPPORT_DATA_SUCCESS = "AUDIT_SUPPORT_DATA_SUCCESS";
export const AUDIT_SUPPORT_DATA_FAIL = "AUDIT_SUPPORT_DATA_FAIL";

// get
export const ASSIGNED_AUDITS_REQUEST = "ASSIGNED_AUDITS_REQUEST";
export const ASSIGNED_AUDITS_SUCCESS = "ASSIGNED_AUDITS_SUCCESS";
export const ASSIGNED_AUDITS_FAIL = "ASSIGNED_AUDITS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";