// AGENCY_MAINTENANCE create
export const CREATE_AGENCY_MAINTENANCE_REQUEST = "CREATE_AGENCY_MAINTENANCE_REQUEST";
export const CREATE_AGENCY_MAINTENANCE_SUCCESS = "CREATE_AGENCY_MAINTENANCE_SUCCESS";
export const CREATE_AGENCY_MAINTENANCE_FAIL = "CREATE_AGENCY_MAINTENANCE_FAIL"
export const CREATE_AGENCY_MAINTENANCE_CONFLICT = "CREATE_AGENCY_MAINTENANCE_CONFLICT"

// AGENCY_MAINTENANCE get
export const AGENCY_MAINTENANCE_REQUEST = "AGENCY_MAINTENANCE_REQUEST";
export const AGENCY_MAINTENANCE_SUCCESS = "AGENCY_MAINTENANCE_SUCCESS";
export const AGENCY_MAINTENANCE_FAIL = "AGENCY_MAINTENANCE_FAIL"


// AGENCY_MAINTENANCE find
export const FIND_AGENCY_MAINTENANCE_REQUEST = "FIND_AGENCY_MAINTENANCE_REQUEST";
export const FIND_AGENCY_MAINTENANCE_SUCCESS = "FIND_AGENCY_MAINTENANCE_SUCCESS";
export const FIND_AGENCY_MAINTENANCE_FAIL = "FIND_AGENCY_MAINTENANCE_FAIL"

//AGENCY_MAINTENANCE update/put
export const UPDATE_AGENCY_MAINTENANCE_REQUEST = "UPDATE_AGENCY_MAINTENANCE_REQUEST";
export const UPDATE_AGENCY_MAINTENANCE_SUCCESS = "UPDATE_AGENCY_MAINTENANCE_SUCCESS";
export const UPDATE_AGENCY_MAINTENANCE_FAIL = "UPDATE_AGENCY_MAINTENANCE_FAIL"

// AGENCY_MAINTENANCE delete
export const DELETE_AGENCY_MAINTENANCE_REQUEST = "DELETE_AGENCY_MAINTENANCE_REQUEST";
export const DELETE_AGENCY_MAINTENANCE_SUCCESS = "DELETE_AGENCY_MAINTENANCE_SUCCESS";
export const DELETE_AGENCY_MAINTENANCE_FAIL = "DELETE_AGENCY_MAINTENANCE_FAIL"


export const AGENCY_MAINTENANCE_CLEAR_ERROR = "AGENCY_MAINTENANCE_CLEAR_ERROR"



