import { 
    CREATE_COA_GST_MAP_REQUEST,
    CREATE_COA_GST_MAP_SUCCESS,
    CREATE_COA_GST_MAP_FAIL,

    COA_GST_MAP_REQUEST,
    COA_GST_MAP_SUCCESS,
    COA_GST_MAP_FAIL,

    FIND_COA_GST_MAP_REQUEST,
    FIND_COA_GST_MAP_SUCCESS,
    FIND_COA_GST_MAP_FAIL,

    UPDATE_COA_GST_MAP_REQUEST,
    UPDATE_COA_GST_MAP_SUCCESS,
    UPDATE_COA_GST_MAP_FAIL,

    DELETE_COA_GST_MAP_REQUEST,
    DELETE_COA_GST_MAP_SUCCESS,
    DELETE_COA_GST_MAP_FAIL,

    COA_GST_MAP_CLEAR_ERROR,
 } from "../../constants/CoaMapping/CoaGstMapConstant";





// get all data of list
export const getCoaNonTaxGstMapReducer = (state = { coaNonTaxGstMaps: [] }, action) => {
    switch (action.type) {
        case COA_GST_MAP_REQUEST:
        case CREATE_COA_GST_MAP_REQUEST:
        case UPDATE_COA_GST_MAP_REQUEST:
        case DELETE_COA_GST_MAP_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null
            };
        case COA_GST_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: action.payload,
                message: null,
                status: null,
            };

        case CREATE_COA_GST_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: [...state.coaNonTaxGstMaps, action.payload],
                message: action.message,
                status: action.status
            };

        case UPDATE_COA_GST_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: [
                    ...state.coaNonTaxGstMaps.filter(
                        (item) => item.nonTaxGstId !== action.payload.nonTaxGstId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.nonTaxGstId - post.nonTaxGstId),
                message: action.message,
                status: action.status,

            };

        case DELETE_COA_GST_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: [
                    ...state.coaNonTaxGstMaps.filter(
                        (item) => item.nonTaxGstId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case COA_GST_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: [...state.coaNonTaxGstMaps],
                error: action.payload,
            };

        case CREATE_COA_GST_MAP_FAIL:
        case UPDATE_COA_GST_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: [...state.coaNonTaxGstMaps],
                message: action.message,
                status: action.status,
            };

        case DELETE_COA_GST_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMaps: [...state.coaNonTaxGstMaps],
                error: action.payload,
            };

        case COA_GST_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findCoaNonTaxGstMapReducer = (state = { coaNonTaxGstMap: {} }, action) => {
    switch (action.type) {
        case FIND_COA_GST_MAP_REQUEST:
            return {
                coaNonTaxGstMap: {},
                loading: true,
            };

        case FIND_COA_GST_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMap: action.payload,
            };

        case FIND_COA_GST_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaNonTaxGstMap: {},
                error: action.payload,
            };

        case "EMPTY_COA_GST_MAP":
            return {
                ...state,
                coaNonTaxGstMap: {}
            }

        case COA_GST_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};