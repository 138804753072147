// create/post
export const CREATE_RISK_ACTION_REQUEST = "CREATE_RISK_ACTION_REQUEST";
export const CREATE_RISK_ACTION_SUCCESS = "CREATE_RISK_ACTION_SUCCESS";
export const CREATE_RISK_ACTION_FAIL = "CREATE_RISK_ACTION_FAIL";

// get
export const RISK_ACTION_REQUEST = "RISK_ACTION_REQUEST";
export const RISK_ACTION_SUCCESS = "RISK_ACTION_SUCCESS";
export const RISK_ACTION_FAIL = "RISK_ACTION_FAIL";

// find
export const FIND_RISK_ACTION_REQUEST = "FIND_RISK_ACTION_REQUEST";
export const FIND_RISK_ACTION_SUCCESS = "FIND_RISK_ACTION_SUCCESS";
export const FIND_RISK_ACTION_FAIL = "FIND_RISK_ACTION_FAIL";

// update/put
export const UPDATE_RISK_ACTION_REQUEST = "UPDATE_RISK_ACTION_REQUEST";
export const UPDATE_RISK_ACTION_SUCCESS = "UPDATE_RISK_ACTION_SUCCESS";
export const UPDATE_RISK_ACTION_FAIL = "UPDATE_RISK_ACTION_FAIL";

// update/put
export const DELETE_RISK_ACTION_REQUEST = "DELETE_RISK_ACTION_REQUEST";
export const DELETE_RISK_ACTION_SUCCESS = "DELETE_RISK_ACTION_SUCCESS";
export const DELETE_RISK_ACTION_FAIL = "DELETE_RISK_ACTION_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
