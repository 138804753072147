import {
  CREATE_REV_SUB_TYPE_REQUEST,
  CREATE_REV_SUB_TYPE_SUCCESS,
  CREATE_REV_SUB_TYPE_FAIL,
  REV_SUB_TYPE_REQUEST,
  REV_SUB_TYPE_SUCCESS,
  REV_SUB_TYPE_FAIL,
  FIND_REV_SUB_TYPE_REQUEST,
  FIND_REV_SUB_TYPE_SUCCESS,
  FIND_REV_SUB_TYPE_FAIL,
  UPDATE_REV_SUB_TYPE_REQUEST,
  UPDATE_REV_SUB_TYPE_SUCCESS,
  UPDATE_REV_SUB_TYPE_FAIL,
  DELETE_REV_SUB_TYPE_REQUEST,
  DELETE_REV_SUB_TYPE_SUCCESS,
  DELETE_REV_SUB_TYPE_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/RevSubTypeConstant";

export const revSubTypeReducer = (state = { revSubTypes: [] }, action) => {
  switch (action.type) {
    case REV_SUB_TYPE_REQUEST:
    case CREATE_REV_SUB_TYPE_REQUEST:
    case UPDATE_REV_SUB_TYPE_REQUEST:
    case DELETE_REV_SUB_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revSubTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revSubTypes: [...state.revSubTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revSubTypes: [
          ...state.revSubTypes.filter(
            (item) => item.revenueSubTypeId !== action.payload.revenueSubTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueSubTypeId - post.revenueSubTypeId),
        message: action.message,
        status: action.status,
      };

    case DELETE_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revSubTypes: [
          ...state.revSubTypes.filter(
            (item) => item.revenueSubTypeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_REV_SUB_TYPE_SUCCESS":
      return {
        loading: false,
        revSubTypes: [
          ...state.revSubTypes.filter(
            (item) => item.revenueSubTypeId !== action.payload.revenueSubTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueSubTypeId - post.revenueSubTypeId),
        message: action.message,
        status: action.status,
      };

    case REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_REV_SUB_TYPE_FAIL:
    case UPDATE_REV_SUB_TYPE_FAIL:
    case DELETE_REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRevSubTypeReducer = (state = { revSubType: {} }, action) => {
  switch (action.type) {
    case FIND_REV_SUB_TYPE_REQUEST:
      return {
        revSubType: {},
        loading: true,
      };

    case FIND_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        revSubType: action.payload,
      };

    case FIND_REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        revSubType: {},
        error: action.payload,
      };

    case "EMPTY_REV_SUB_TYPE":
      return {
        ...state,
        revSubType: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
