// Agency receipt stock create
export const CREATE_AGENCY_RECEIPT_STOCK_REQUEST = "CREATE_AGENCY_RECEIPT_STOCK_REQUEST";
export const CREATE_AGENCY_RECEIPT_STOCK_SUCCESS = "CREATE_AGENCY_RECEIPT_STOCK_SUCCESS";
export const CREATE_AGENCY_RECEIPT_STOCK_FAIL = "CREATE_AGENCY_RECEIPT_STOCK_FAIL";

// Agency receipt stock get
export const AGENCY_RECEIPT_STOCK_REQUEST = "AGENCY_RECEIPT_STOCK_REQUEST";
export const AGENCY_RECEIPT_STOCK_SUCCESS = "AGENCY_RECEIPT_STOCK_SUCCESS";
export const AGENCY_RECEIPT_STOCK_FAIL = "AGENCY_RECEIPT_STOCK_FAIL";

// Agency receipt stock
export const FIND_AGENCY_RECEIPT_STOCK_REQUEST = "FIND_AGENCY_RECEIPT_STOCK_REQUEST";
export const FIND_AGENCY_RECEIPT_STOCK_SUCCESS = "FIND_AGENCY_RECEIPT_STOCK_SUCCESS";
export const FIND_AGENCY_RECEIPT_STOCK_FAIL = "FIND_AGENCY_RECEIPT_STOCK_FAIL";

// Agency receipt stock update
export const UPDATE_AGENCY_RECEIPT_STOCK_REQUEST = "UPDATE_AGENCY_RECEIPT_STOCK_REQUEST";
export const UPDATE_AGENCY_RECEIPT_STOCK_SUCCESS = "UPDATE_AGENCY_RECEIPT_STOCK_SUCCESS";
export const UPDATE_AGENCY_RECEIPT_STOCK_FAIL = "UPDATE_AGENCY_RECEIPT_STOCK_FAIL";



export const CLEAR_AGENCY_RECEIPT_STOCK_ERROR = "CLEAR_AGENCY_RECEIPT_STOCK_ERROR";