import {
    RISK_LEVEL_THRESHOLD_REQUEST,
    RISK_LEVEL_THRESHOLD_SUCCESS,
    RISK_LEVEL_THRESHOLD_FAIL,
  
    CREATE_RISK_LEVEL_THRESHOLD_REQUEST,
    CREATE_RISK_LEVEL_THRESHOLD_SUCCESS,
    CREATE_RISK_LEVEL_THRESHOLD_FAIL,
  
    FIND_RISK_LEVEL_THRESHOLD_REQUEST,
    FIND_RISK_LEVEL_THRESHOLD_SUCCESS,
    FIND_RISK_LEVEL_THRESHOLD_FAIL,
  
    UPDATE_RISK_LEVEL_THRESHOLD_REQUEST,
    UPDATE_RISK_LEVEL_THRESHOLD_SUCCESS,
    UPDATE_RISK_LEVEL_THRESHOLD_FAIL,
  
    DELETE_RISK_LEVEL_THRESHOLD_REQUEST,
    DELETE_RISK_LEVEL_THRESHOLD_SUCCESS,
    DELETE_RISK_LEVEL_THRESHOLD_FAIL,
  
    CLEAR_ERROR,
  } from "../../constants_Phase_2/RiskManagement/RiskLevelThresholdConstant";
  
  // create, update, delete and get all
  export const getRiskLevelThresholdsReducer = (state = { riskLevelThresholds: [] }, action) => {
    switch (action.type) {
      case RISK_LEVEL_THRESHOLD_REQUEST:
      case CREATE_RISK_LEVEL_THRESHOLD_REQUEST:
      case UPDATE_RISK_LEVEL_THRESHOLD_REQUEST:
      case DELETE_RISK_LEVEL_THRESHOLD_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case RISK_LEVEL_THRESHOLD_SUCCESS:
        return {
          ...state,
          loading: false,
          riskLevelThresholds: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_RISK_LEVEL_THRESHOLD_SUCCESS:
        return {
          ...state,
          loading: false,
          riskLevelThresholds: [...state.riskLevelThresholds, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_RISK_LEVEL_THRESHOLD_SUCCESS:
        return {
          ...state,
          loading: false,
          riskLevelThresholds: state.riskLevelThresholds.map((item) =>
            (item.riskLevelThresholdId === action.payload.riskLevelThresholdId) ? action.payload : item
          ),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_RISK_LEVEL_THRESHOLD_SUCCESS:
        return {
          ...state,
          loading: false,
          riskLevelThresholds: action?.payload?.isDelete ?
            state.riskLevelThresholds.filter((item) =>
              item.riskLevelThresholdId !== action.payload.riskLevelThresholdId
            )
            :
            state.riskLevelThresholds.map((item) =>
              (item.riskLevelThresholdId === action.payload.riskLevelThresholdId) ? action.payload : item
            ),
          message: action.message,
          status: action.status,
        };
  
      case RISK_LEVEL_THRESHOLD_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_RISK_LEVEL_THRESHOLD_FAIL:
      case UPDATE_RISK_LEVEL_THRESHOLD_FAIL:
      case DELETE_RISK_LEVEL_THRESHOLD_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findRiskLevelThresholdReducer = (state = { riskLevelThreshold: {} }, action) => {
    switch (action.type) {
      case FIND_RISK_LEVEL_THRESHOLD_REQUEST:
        return {
          loading: true,
          riskLevelThreshold: {},
        };
  
      case FIND_RISK_LEVEL_THRESHOLD_SUCCESS:
        return {
          ...state,
          loading: false,
          riskLevelThreshold: action.payload,
        };
  
      case FIND_RISK_LEVEL_THRESHOLD_FAIL:
        return {
          ...state,
          loading: false,
          riskLevelThreshold: {},
          error: action.payload,
        };
  
      case "EMPTY_RISK_LEVEL_THRESHOLD":
        return {
          riskLevelThreshold: {},
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };  