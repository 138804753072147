// upload excel
export const RECONCILIATION_UPLOAD_EXCEL_REQUEST = "RECONCILIATION_UPLOAD_EXCEL_REQUEST";
export const RECONCILIATION_UPLOAD_EXCEL_SUCCESS = "RECONCILIATION_UPLOAD_EXCEL_SUCCESS";
export const RECONCILIATION_UPLOAD_EXCEL_FAIL = "RECONCILIATION_UPLOAD_EXCEL_FAIL";

//create summary
export const CREATE_RECONCILIATION_SUMMARY_REQUEST = "CREATE_RECONCILIATION_SUMMARY_REQUEST";
export const CREATE_RECONCILIATION_SUMMARY_SUCCESS = "CREATE_RECONCILIATION_SUMMARY_SUCCESS";
export const CREATE_RECONCILIATION_SUMMARY_FAIL = "CREATE_RECONCILIATION_SUMMARY_FAIL";

//get reconciliation staging
export const GET_RECONCILIATION_STAGING_REQUEST = "GET_RECONCILIATION_STAGING_REQUEST";
export const  GET_RECONCILIATION_STAGING_SUCCESS = "GET_RECONCILIATION_STAGING_SUCCESS";
export const  GET_RECONCILIATION_STAGING_FAIL = " GET_RECONCILIATION_STAGING_FAIL";

//clear error
export const RECONCILIATION_CLEAR_ERROR = "RECONCILIATION_CLEAR_ERROR";