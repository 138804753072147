
export const CREATE_PREVIOUS_YEAR_REVENUE_REQUEST = "CREATE_PREVIOUS_YEAR_REVENUE_REQUEST";
export const CREATE_PREVIOUS_YEAR_REVENUE_SUCCESS = "CREATE_PREVIOUS_YEAR_REVENUE_SUCCESS";
export const CREATE_PREVIOUS_YEAR_REVENUE_FAIL = "CREATE_PREVIOUS_YEAR_REVENUE_FAIL";

export const PREVIOUS_YEAR_REVENUE_REQUEST = "PREVIOUS_YEAR_REVENUE_REQUEST";
export const PREVIOUS_YEAR_REVENUE_SUCCESS = "PREVIOUS_YEAR_REVENUE_SUCCESS";
export const PREVIOUS_YEAR_REVENUE_FAIL = "PREVIOUS_YEAR_REVENUE_FAIL";


export const FIND_PREVIOUS_YEAR_REVENUE_REQUEST = "FIND_PREVIOUS_YEAR_REVENUE_REQUEST";
export const FIND_PREVIOUS_YEAR_REVENUE_SUCCESS = "FIND_PREVIOUS_YEAR_REVENUE_SUCCESS";
export const FIND_PREVIOUS_YEAR_REVENUE_FAIL = "FIND_PREVIOUS_YEAR_REVENUE_FAIL";


export const UPDATE_PREVIOUS_YEAR_REVENUE_REQUEST = "UPDATE_PREVIOUS_YEAR_REVENUE_REQUEST";
export const UPDATE_PREVIOUS_YEAR_REVENUE_SUCCESS = "UPDATE_PREVIOUS_YEAR_REVENUE_SUCCESS";
export const UPDATE_PREVIOUS_YEAR_REVENUE_FAIL = "UPDATE_PREVIOUS_YEAR_REVENUE_FAIL";



export const CLEAR_PREVIOUS_YEAR_REVENUE_ERROR = "CLEAR_PREVIOUS_YEAR_REVENUE_ERROR";