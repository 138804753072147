import {
  AUDIT_TYPE_REQUEST,
  AUDIT_TYPE_SUCCESS,
  AUDIT_TYPE_FAIL,

  CREATE_AUDIT_TYPE_REQUEST,
  CREATE_AUDIT_TYPE_SUCCESS,
  CREATE_AUDIT_TYPE_FAIL,

  FIND_AUDIT_TYPE_REQUEST,
  FIND_AUDIT_TYPE_SUCCESS,
  FIND_AUDIT_TYPE_FAIL,

  UPDATE_AUDIT_TYPE_REQUEST,
  UPDATE_AUDIT_TYPE_SUCCESS,
  UPDATE_AUDIT_TYPE_FAIL,

  DELETE_AUDIT_TYPE_REQUEST,
  DELETE_AUDIT_TYPE_SUCCESS,
  DELETE_AUDIT_TYPE_FAIL,

  CLEAR_ERROR,
} from "../../constants/OthersMaster/AuditTypeConstant";

// create, update, delete and get all
export const getAuditTypesReducer = (state = { auditTypes: [] }, action) => {
  switch (action.type) {
    case AUDIT_TYPE_REQUEST:
    case CREATE_AUDIT_TYPE_REQUEST:
    case UPDATE_AUDIT_TYPE_REQUEST:
    case DELETE_AUDIT_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case AUDIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_AUDIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditTypes: [...state.auditTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_AUDIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditTypes: state.auditTypes.map((item) =>
          (item.auditTypeId === action.payload.auditTypeId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_AUDIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditTypes: action?.payload?.isDelete ?
          state.auditTypes.filter((item) =>
            item.auditTypeId !== action.payload.auditTypeId
          )
          :
          state.auditTypes.map((item) =>
            (item.auditTypeId === action.payload.auditTypeId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case AUDIT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_AUDIT_TYPE_FAIL:
    case UPDATE_AUDIT_TYPE_FAIL:
    case DELETE_AUDIT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findAuditTypeReducer = (state = { auditType: {} }, action) => {
  switch (action.type) {
    case FIND_AUDIT_TYPE_REQUEST:
      return {
        loading: true,
        auditType: {},
      };

    case FIND_AUDIT_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditType: action.payload,
      };

    case FIND_AUDIT_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        auditType: {},
        error: action.payload,
      };

    case "EMPTY_AUDIT_TYPE":
      return {
        auditType: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};