import {
    CREATE_RMA_FAILED_TRANSACTION_REQUEST,
    CREATE_RMA_FAILED_TRANSACTION_SUCCESS,
    CREATE_RMA_FAILED_TRANSACTION_FAIL,

    RMA_FAILED_TRANSACTION_REQUEST,
    RMA_FAILED_TRANSACTION_SUCCESS,
    RMA_FAILED_TRANSACTION_FAIL,

    FIND_RMA_FAILED_TRANSACTION_REQUEST,
    FIND_RMA_FAILED_TRANSACTION_SUCCESS,
    FIND_RMA_FAILED_TRANSACTION_FAIL,

    UPDATE_RMA_FAILED_TRANSACTION_REQUEST,
    UPDATE_RMA_FAILED_TRANSACTION_SUCCESS,
    UPDATE_RMA_FAILED_TRANSACTION_FAIL,

    DELETE_RMA_FAILED_TRANSACTION_REQUEST,
    DELETE_RMA_FAILED_TRANSACTION_SUCCESS,
    DELETE_RMA_FAILED_TRANSACTION_FAIL,
    CLEAR_ERROR,
} from "../../constants/RMAFailedTransaction/RMAFailedTransactionConstant";

export const getRmaFailedTransactionsReducer = (state = { rmaFailedTransactions: [] }, action) => {
    switch (action.type) {
        case RMA_FAILED_TRANSACTION_REQUEST:
            return {
                ...state,
                rmaFailedTransactions: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case RMA_FAILED_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                rmaFailedTransactions: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case RMA_FAILED_TRANSACTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// create, get, update,delete
export const getRmaFailedTransactionResponseReducer = (state = { rmaFailedTransactionResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_RMA_FAILED_TRANSACTION_REQUEST:
        case UPDATE_RMA_FAILED_TRANSACTION_REQUEST:
        case DELETE_RMA_FAILED_TRANSACTION_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case CREATE_RMA_FAILED_TRANSACTION_SUCCESS:
        case UPDATE_RMA_FAILED_TRANSACTION_SUCCESS:
        case DELETE_RMA_FAILED_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                rmaFailedTransactionResponse: action.payload,
                message: action.message,
                status: action.status,
            };

        case CREATE_RMA_FAILED_TRANSACTION_FAIL:
        case UPDATE_RMA_FAILED_TRANSACTION_FAIL:
        case DELETE_RMA_FAILED_TRANSACTION_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_RMA_FAILED_TRANSACTION_RESPONSE":
            return {
                ...state,
                rmaFailedTransactionResponse: {}
            }

        default:
            return state;
    }
};

// find one
export const findRmaFailedTransactionReducer = (state = { rmaFailedTransaction: {} }, action) => {
    switch (action.type) {
        case FIND_RMA_FAILED_TRANSACTION_REQUEST:
            return {
                rmaFailedTransaction: {},
                loading: true,
            };

        case FIND_RMA_FAILED_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                rmaFailedTransaction: action.payload,
            };

        case FIND_RMA_FAILED_TRANSACTION_FAIL:
            return {
                ...state,
                loading: false,
                rmaFailedTransaction: {},
                error: action.payload
            };

        case "EMPTY_RMA_FAILED_TRANSACTION":
            return {
                ...state,
                rmaFailedTransaction: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
