import {
    CREATE_REIMBURSEMENT_REQUEST,
    CREATE_REIMBURSEMENT_SUCCESS,
    CREATE_REIMBURSEMENT_FAIL,
    REIMBURSEMENT_REQUEST,
    REIMBURSEMENT_SUCCESS,
    REIMBURSEMENT_FAIL,
    FIND_REIMBURSEMENT_REQUEST,
    FIND_REIMBURSEMENT_SUCCESS,
    FIND_REIMBURSEMENT_FAIL,
    UPDATE_REIMBURSEMENT_REQUEST,
    UPDATE_REIMBURSEMENT_SUCCESS,
    UPDATE_REIMBURSEMENT_FAIL,
    CLEAR_REIMBURSEMENT_ERROR,
  } from "../../constants_Phase_2/Reimbursement/ReimbursementConstant";
  
  // create
  export const reimbursementReducer = (state = { reimbursementList: [] }, action) => {
    switch (action.type) {
      case  REIMBURSEMENT_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          reimbursementList: [],
          status: null,
          error: null,
        };

        case CREATE_REIMBURSEMENT_REQUEST:
          case UPDATE_REIMBURSEMENT_REQUEST:
          return {
            ...state,
            loading: true,
            message: null,
            status: null,
            error: null,
          };


      case REIMBURSEMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          reimbursementList: action.payload,
          totalData : action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };


      case  CREATE_REIMBURSEMENT_SUCCESS:
        case  UPDATE_REIMBURSEMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          reimbursementId: action.reimbursementId,
          message: action.message,
          status: action.status,
        };
  
      case REIMBURSEMENT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_REIMBURSEMENT_FAIL:
        case UPDATE_REIMBURSEMENT_FAIL:
        return {
          ...state,
          loading: false,
          reimbursementList: [...state.reimbursementList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_REIMBURSEMENT_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
export const findReimbursementReducer = (state = { reimbursement: {} }, action) => {
    switch (action.type) {
        case FIND_REIMBURSEMENT_REQUEST:
            return {
                reimbursement: {},
                loading: true,
            };

        case FIND_REIMBURSEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                reimbursement: action.payload,
            };

        case FIND_REIMBURSEMENT_FAIL:
            return {
                ...state,
                loading: false,
                reimbursement: {},
                error: action.payload
            };

        case "EMPTY_REIMBURSEMENT":
            return {
                ...state,
                reimbursement: {}
            }

        case CLEAR_REIMBURSEMENT_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

    