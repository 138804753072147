//PA GROUPING create
export const CREATE_PAGROUPING_REQUEST = "CREATE_PAGROUPING_REQUEST";
export const CREATE_PAGROUPING_SUCCESS = "CREATE_PAGROUPING_SUCCESS";
export const CREATE_PAGROUPING_FAIL = "CREATE_PAGROUPING_FAIL"

//PA GROUPING get
export const PAGROUPING_REQUEST = "PAGROUPING_REQUEST";
export const PAGROUPING_SUCCESS = "PAGROUPING_SUCCESS";
export const PAGROUPING_FAIL = "PAGROUPING_FAIL"

//PA for PA Grouping
export const PA_FOR_GROUPING_REQUEST = "PA_FOR_GROUPING_REQUEST";
export const PA_FOR_GROUPING_SUCCESS = "PA_FOR_GROUPING_SUCCESS";
export const PA_FOR_GROUPING_FAIL = "PA_FOR_GROUPING_FAIL";

export const PAGROUPING_CLEAR_ERROR = "PAGROUPING_CLEAR_ERROR";