import {
  CREATE_SECTORAL_LIST_REQUEST,
  CREATE_SECTORAL_LIST_SUCCESS,
  CREATE_SECTORAL_LIST_FAIL,
  SECTORAL_LIST_REQUEST,
  SECTORAL_LIST_SUCCESS,
  SECTORAL_LIST_FAIL,
  FIND_SECTORAL_LIST_REQUEST,
  FIND_SECTORAL_LIST_SUCCESS,
  FIND_SECTORAL_LIST_FAIL,
  UPDATE_SECTORAL_LIST_REQUEST,
  UPDATE_SECTORAL_LIST_SUCCESS,
  UPDATE_SECTORAL_LIST_FAIL,
  DELETE_SECTORAL_LIST_REQUEST,
  DELETE_SECTORAL_LIST_SUCCESS,
  DELETE_SECTORAL_LIST_FAIL,
  SECTORAL_LIST_CLEAR_ERROR,
} from "../../constants/OthersMaster/SectoralListConstant";

// get all data of list
export const getSectoralListReducer = (
  state = { sectoralList: [] },
  action
) => {
  switch (action.type) {
    case SECTORAL_LIST_REQUEST:
    case CREATE_SECTORAL_LIST_REQUEST:
    case UPDATE_SECTORAL_LIST_REQUEST:
    case DELETE_SECTORAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };
    case SECTORAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sectoralList: action.payload,
        message: null,
        status: null,
      };

    case CREATE_SECTORAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sectoralList: [...state.sectoralList, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_SECTORAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sectoralList: [
          ...state.sectoralList.filter(
            (item) => item.sectorId !== action.payload.sectorId
          ),
          action.payload,
        ].sort((pre, post) => pre.sectorId - post.sectorId),
        message: action.message,
        status: action.status,
      };

    case DELETE_SECTORAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sectoralList: [
          ...state.sectoralList.filter(
            (item) => item.sectorId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_SECTORAL_LIST_SUCCESS":
      return {
        loading: false,
        sectoralList: [
          ...state.sectoralList.filter(
            (item) => item.sectorId !== action.payload.sectorId
          ),
          action.payload,
        ].sort((pre, post) => pre.sectorId - post.sectorId),
        message: action.message,
        status: action.status,
      };

    case SECTORAL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        sectoralList: [...state.sectoralList],
        error: action.payload,
      };

    case CREATE_SECTORAL_LIST_FAIL:
    case UPDATE_SECTORAL_LIST_FAIL:
    case DELETE_SECTORAL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        sectoralList: [...state.sectoralList],
        message: action.message,
        status: action.status,
      };

    case SECTORAL_LIST_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findSectoralListReducer = (
  state = { singleSector: {} },
  action
) => {
  switch (action.type) {
    case FIND_SECTORAL_LIST_REQUEST:
      return {
        singleSector: {},
        loading: true,
      };
    case FIND_SECTORAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        singleSector: action.payload,
      };

    case FIND_SECTORAL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        singleSector: {},
        error: action.payload,
      };

    case "EMPTY_SECTORAL_LIST":
      return {
        ...state,
        singleSector: {},
      };

    case SECTORAL_LIST_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
