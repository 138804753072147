// create
export const CREATE_REV_SUB_TYPE_REQUEST = "CREATE_REV_SUB_TYPE_REQUEST";
export const CREATE_REV_SUB_TYPE_SUCCESS = "CREATE_REV_SUB_TYPE_SUCCESS";
export const CREATE_REV_SUB_TYPE_FAIL = "CREATE_REV_SUB_TYPE_FAIL";

// get
export const REV_SUB_TYPE_REQUEST = "REV_SUB_TYPE_REQUEST";
export const REV_SUB_TYPE_SUCCESS = "REV_SUB_TYPE_SUCCESS";
export const REV_SUB_TYPE_FAIL = "REV_SUB_TYPE_FAIL";

// find
export const FIND_REV_SUB_TYPE_REQUEST = "FIND_REV_SUB_TYPE_REQUEST";
export const FIND_REV_SUB_TYPE_SUCCESS = "FIND_REV_SUB_TYPE_SUCCESS";
export const FIND_REV_SUB_TYPE_FAIL = "FIND_REV_SUB_TYPE_FAIL";

// update
export const UPDATE_REV_SUB_TYPE_REQUEST = "UPDATE_REV_SUB_TYPE_REQUEST";
export const UPDATE_REV_SUB_TYPE_SUCCESS = "UPDATE_REV_SUB_TYPE_SUCCESS";
export const UPDATE_REV_SUB_TYPE_FAIL = "UPDATE_REV_SUB_TYPE_FAIL";

// delete
export const DELETE_REV_SUB_TYPE_REQUEST = "DELETE_REV_SUB_TYPE_REQUEST";
export const DELETE_REV_SUB_TYPE_SUCCESS = "DELETE_REV_SUB_TYPE_SUCCESS";
export const DELETE_REV_SUB_TYPE_FAIL = "DELETE_REV_SUB_TYPE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
