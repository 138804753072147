import {
    CREATE_WRITE_OFF_REQUEST,
    CREATE_WRITE_OFF_SUCCESS,
    CREATE_WRITE_OFF_FAIL,
    WRITE_OFF_REQUEST,
    WRITE_OFF_SUCCESS,
    WRITE_OFF_FAIL,
    FIND_WRITE_OFF_REQUEST,
    FIND_WRITE_OFF_SUCCESS,
    FIND_WRITE_OFF_FAIL,
    UPDATE_WRITE_OFF_REQUEST,
    UPDATE_WRITE_OFF_SUCCESS,
    UPDATE_WRITE_OFF_FAIL,
    CLEAR_WRITE_OFF_ERROR,
  } from "../../constants_Phase_2/WriteOff/WriteOffConstant";
  
  // create
  export const writeOffListReducer = ( state = { writeOffList: [] }, action ) => {
    switch (action.type) {
      case WRITE_OFF_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          writeOffList: [],
          status: null,
          error: null,
        };
  
      case CREATE_WRITE_OFF_REQUEST:
      case UPDATE_WRITE_OFF_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case WRITE_OFF_SUCCESS:
        return {
          ...state,
          loading: false,
          writeOffList: action.payload,
          totalData: action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };
  
      case CREATE_WRITE_OFF_SUCCESS:
      case UPDATE_WRITE_OFF_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case WRITE_OFF_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_WRITE_OFF_FAIL:
      case UPDATE_WRITE_OFF_FAIL:
        return {
          ...state,
          loading: false,
          writeOffList: [...state.writeOffList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_WRITE_OFF_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  export const writeOffReasonReducer = (state = { writeOffReasons: [] }, action) => {
    switch (action.type) {
      case "WRITE_OFF_REASON_REQUEST":
        return {
          ...state,
          loading: true,
          message: null,
          writeOffReasons: [],
          status: null,
          error: null,
        };
  
      case "WRITE_OFF_REASON_SUCCESS":
        return {
          ...state,
          loading: false,
          writeOffReasons: action.targetType,
          message: action.message,
          status: action.status,
        };
  
      case "WRITE_OFF_REASON_FAIL":
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findWriteOffReducer = ( state = { writeOff: {} }, action ) => {
  
    switch (action.type) {
      case FIND_WRITE_OFF_REQUEST:
        return {
          writeOff: {},
          loading: true,
        };
  
      case FIND_WRITE_OFF_SUCCESS:
        return {
          ...state,
          loading: false,
          writeOff: action.payload,
        };
  
      case FIND_WRITE_OFF_FAIL:
        return {
          ...state,
          loading: false,
          writeOff: {},
          error: action.payload,
        };
  
      case "EMPTY_WRITE_OFF":
        return {
          ...state,
          writeOff: {},
        };
  
      case CLEAR_WRITE_OFF_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  