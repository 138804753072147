// create
export const CREATE_UNMAP_PAYMENT_ADVICE_REQUEST = "CREATE_UNMAP_PAYMENT_ADVICE_REQUEST";
export const CREATE_UNMAP_PAYMENT_ADVICE_SUCCESS = "CREATE_UNMAP_PAYMENT_ADVICE_SUCCESS";
export const CREATE_UNMAP_PAYMENT_ADVICE_FAIL = "CREATE_UNMAP_PAYMENT_ADVICE_FAIL";

// find
export const FIND_UNMAP_PAYMENT_ADVICE_REQUEST = "FIND_UNMAP_PAYMENT_ADVICE_REQUEST";
export const FIND_UNMAP_PAYMENT_ADVICE_SUCCESS = "FIND_UNMAP_PAYMENT_ADVICE_SUCCESS";
export const FIND_UNMAP_PAYMENT_ADVICE_FAIL = "FIND_UNMAP_PAYMENT_ADVICE_FAIL";

// update
export const UPDATE_UNMAP_PAYMENT_ADVICE_REQUEST = "UPDATE_UNMAP_PAYMENT_ADVICE_REQUEST";
export const UPDATE_UNMAP_PAYMENT_ADVICE_SUCCESS = "UPDATE_UNMAP_PAYMENT_ADVICE_SUCCESS";
export const UPDATE_UNMAP_PAYMENT_ADVICE_FAIL = "UPDATE_UNMAP_PAYMENT_ADVICE_FAIL";

export const UNMAP_PAYMENT_ADVICE_CLEAR_ERROR = "UNMAP_PAYMENT_ADVICE_CLEAR_ERROR";