// get
export const RISK_ASSESSMENT_REQUEST = "RISK_ASSESSMENT_REQUEST";
export const RISK_ASSESSMENT_SUCCESS = "RISK_ASSESSMENT_SUCCESS";
export const RISK_ASSESSMENT_FAIL = "RISK_ASSESSMENT_FAIL";

// update/put
export const UPDATE_RISK_ASSESSMENT_REQUEST = "UPDATE_RISK_ASSESSMENT_REQUEST";
export const UPDATE_RISK_ASSESSMENT_SUCCESS = "UPDATE_RISK_ASSESSMENT_SUCCESS";
export const UPDATE_RISK_ASSESSMENT_FAIL = "UPDATE_RISK_ASSESSMENT_FAIL";

export const RISK_ASSESSMENT_CLEAR_ERROR = "RISK_ASSESSMENT_CLEAR_ERROR";