// create
export const CREATE_VILLAGE_REQUEST = "CREATE_VILLAGE_REQUEST";
export const CREATE_VILLAGE_SUCCESS = "CREATE_VILLAGE_SUCCESS";
export const CREATE_VILLAGE_FAIL = "CREATE_VILLAGE_FAIL";
// get
export const VILLAGE_REQUEST = "VILLAGE_REQUEST";
export const VILLAGE_SUCCESS = "VILLAGE_SUCCESS";
export const VILLAGE_FAIL = "VILLAGE_FAIL";
// find
export const FIND_VILLAGE_REQUEST = "FIND_VILLAGE_REQUEST";
export const FIND_VILLAGE_SUCCESS = "FIND_VILLAGE_SUCCESS";
export const FIND_VILLAGE_FAIL = "FIND_VILLAGE_FAIL";

// update
export const UPDATE_VILLAGE_REQUEST = "UPDATE_VILLAGE_REQUEST";
export const UPDATE_VILLAGE_SUCCESS = "UPDATE_VILLAGE_SUCCESS";
export const UPDATE_VILLAGE_FAIL = "UPDATE_VILLAGE_FAIL";

// delete
export const DELETE_VILLAGE_REQUEST = "DELETE_VILLAGE_REQUEST";
export const DELETE_VILLAGE_SUCCESS = "DELETE_VILLAGE_SUCCESS";
export const DELETE_VILLAGE_FAIL = "DELETE_VILLAGE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";