import {
  //create divisional or regional office
  CREATE_DIVISION_REGIONAL_OFFICE_REQUEST,
  CREATE_DIVISION_REGIONAL_OFFICE_SUCCESS,
  CREATE_DIVISION_REGIONAL_OFFICE_FAIL,
  //update divisional or regional office
  UPDATE_DIVISION_REGIONAL_OFFICE_REQUEST,
  UPDATE_DIVISION_REGIONAL_OFFICE_SUCCESS,
  UPDATE_DIVISION_REGIONAL_OFFICE_FAIL,
  //delete divisional or regional office
  DELETE_DIVISION_REGIONAL_OFFICE_REQUEST,
  DELETE_DIVISION_REGIONAL_OFFICE_SUCCESS,
  DELETE_DIVISION_REGIONAL_OFFICE_FAIL,
  //Division get by department Id
  GET_DIVISION_REGIONAL_OFFICE_REQUEST,
  GET_DIVISION_REGIONAL_OFFICE_SUCCESS,
  GET_DIVISION_REGIONAL_OFFICE_FAIL,
  //get all divisional or regional office
  DIVISION_REGIONAL_OFFICE_REQUEST,
  DIVISION_REGIONAL_OFFICE_SUCCESS,
  DIVISION_REGIONAL_OFFICE_FAIL,

  // find 
  FIND_DIVISION_REGIONAL_OFFICE_REQUEST,
  FIND_DIVISION_REGIONAL_OFFICE_SUCCESS,
  FIND_DIVISION_REGIONAL_OFFICE_FAIL,


  FIND_AGENCY_BY_DIVISION_ID_REQUEST,
  FIND_AGENCY_BY_DIVISION_ID_SUCCESS,
  FIND_AGENCY_BY_DIVISION_ID_FAIL,

  AGENCY_MASTER_CLEAR_ERROR

} from "../../constants/AgencyMaster/AgencyMasterConstant";



//create division or regional office
export const divisionalRegionalOfficeReducer = (state = { divisionalMaster: [] }, action) => {
  switch (action.type) {
    case DIVISION_REGIONAL_OFFICE_REQUEST:
    case CREATE_DIVISION_REGIONAL_OFFICE_REQUEST:
    case UPDATE_DIVISION_REGIONAL_OFFICE_REQUEST:
    case DELETE_DIVISION_REGIONAL_OFFICE_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case CREATE_DIVISION_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionalMaster: [...state.divisionalMaster, action.payload],
        message: action.message,
        status: action.status
      };
    case UPDATE_DIVISION_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionalMaster: [
          ...state.divisionalMaster.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload,
        ].sort((pre, post) => pre.id - post.id),
        message: action.message,
        status: action.status
      };
    case DELETE_DIVISION_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionalMaster: [
          ...state.divisionalMaster.filter(
            (item) => item.id !== action.payload
          ),
        ],
        message: action.message,
        status: action.status
      };
    case "FUTURE_DELETE_DIVISION_REGIONAL_OFFICE_SUCCESS":
      return {
        loading: false,
        divisionalMaster: [
          ...state.divisionalMaster.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload,
        ].sort((pre, post) => pre.id - post.id),
        message: action.message,
        status: action.status,
      };


    case DIVISION_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionalMaster: action.payload,
        message: null,
        status: null,
      };

    case DIVISION_REGIONAL_OFFICE_FAIL:
    case CREATE_DIVISION_REGIONAL_OFFICE_FAIL:
    case UPDATE_DIVISION_REGIONAL_OFFICE_FAIL:
    case DELETE_DIVISION_REGIONAL_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        divisionalMaster: [...state.divisionalMaster],
        message: action.message,
        status: action.status,
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};



export const getDivisionalRegionalOfficeReducer = (state = { divisionalMasterById: [] }, action) => {
  switch (action.type) {
    case GET_DIVISION_REGIONAL_OFFICE_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case GET_DIVISION_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionalMasterById: action.payload,
        message: action.message,
        status: action.status
      };

    case GET_DIVISION_REGIONAL_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        divisionalMasterById: [...state.divisionalMasterById],
        error: action.payload,
      };

      case "GET_DIVISION_REGIONAL_OFFICE":
        return {
          ...state,
          divisionalMasterById: [],
        };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
}


// find one
export const findDivisionalRegionalOfficeReducer = (state = { singleDivisionMaster: {} }, action) => {
  switch (action.type) {
    case FIND_DIVISION_REGIONAL_OFFICE_REQUEST:
      return {
        singleDivisionMaster: {},
        loading: true,
      };

    case FIND_DIVISION_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleDivisionMaster: action.payload,
      };

    case FIND_DIVISION_REGIONAL_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        singleDivisionMaster: {},
        error: action.payload,
      };

    case "EMPTY_AGENCY_DIVISION_REGIONAL_OFFICE":
      return {
        ...state,
        singleDivisionMaster: {},
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};






export const AgencyListByDivisionIdReducer = (state = { agencyListByDivisionId: [] }, action) => {
  switch (action.type) {
    case FIND_AGENCY_BY_DIVISION_ID_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case FIND_AGENCY_BY_DIVISION_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyListByDivisionId: action.payload,
        message: action.message,
        status: action.status
      };

      case "EMPTY_FIND_AGENCY_BY_DIVISION":
        return {
          ...state,
          agencyListByDivisionId: [],
        };

    case FIND_AGENCY_BY_DIVISION_ID_FAIL:
      return {
        ...state,
        loading: false,
        agencyListByDivisionId: [...state.agencyListByDivisionId],
        error: action.payload,
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
}