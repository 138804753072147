import {
    CREATE_PREPAYMENT_ADVICE_REQUEST,
    CREATE_PREPAYMENT_ADVICE_SUCCESS,
    CREATE_PREPAYMENT_ADVICE_FAIL,

    PREPAYMENT_ADVICE_REQUEST,
    PREPAYMENT_ADVICE_SUCCESS,
    PREPAYMENT_ADVICE_FAIL,

    FIND_PREPAYMENT_ADVICE_REQUEST,
    FIND_PREPAYMENT_ADVICE_SUCCESS,
    FIND_PREPAYMENT_ADVICE_FAIL,

    UPDATE_PREPAYMENT_ADVICE_REQUEST,
    UPDATE_PREPAYMENT_ADVICE_SUCCESS,
    UPDATE_PREPAYMENT_ADVICE_FAIL,

    DELETE_PREPAYMENT_ADVICE_REQUEST,
    DELETE_PREPAYMENT_ADVICE_SUCCESS,
    DELETE_PREPAYMENT_ADVICE_FAIL,

    CLEAR_ERROR,
} from "../../constants/PrepaymentAdvice/PrepaymentAdviceConstant";

export const getPrepaymentAdvicesReducer = (state = { prepaymentAdvices: [] }, action) => {
    switch (action.type) {
        case PREPAYMENT_ADVICE_REQUEST:
            return {
                ...state,
                prepaymentAdvices: [],
                loading: true,
                status: null,
                message: null,
                error: null
            };

        case PREPAYMENT_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                prepaymentAdvices: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: null,
                status: null,
            };

        case PREPAYMENT_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// create, update
export const prepaymentAdviceResponseReducer = (state = { prepaymentAdviceResponse: {} }, action) => {
    switch (action.type) {
        case CREATE_PREPAYMENT_ADVICE_REQUEST:
        case UPDATE_PREPAYMENT_ADVICE_REQUEST:
        case DELETE_PREPAYMENT_ADVICE_REQUEST:
            return {
                ...state,
                loading: true,
                status: null,
                message: null,
                error: null
            };

        case CREATE_PREPAYMENT_ADVICE_SUCCESS:
        case UPDATE_PREPAYMENT_ADVICE_SUCCESS:
        case DELETE_PREPAYMENT_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                prepaymentAdviceResponse: action.payload,
                message: action.message,
                status: action.status
            };

        case CREATE_PREPAYMENT_ADVICE_FAIL:
        case UPDATE_PREPAYMENT_ADVICE_FAIL:
        case DELETE_PREPAYMENT_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        case "EMPTY_PREPAYMENT_ADVICE_RESPONSE":
            return {
                ...state,
                prepaymentAdviceResponse: {}
            }

        default:
            return state;
    }
};

export const findPrepaymentAdviceReducer = (state = { prepaymentAdvice: {} }, action) => {
    switch (action.type) {
        case FIND_PREPAYMENT_ADVICE_REQUEST:
            return {
                loading: true,
                prepaymentAdvice: {},
                message: null,
            };

        case FIND_PREPAYMENT_ADVICE_SUCCESS:
            return {
                ...state,
                loading: false,
                prepaymentAdvice: action.payload,
                message: action.message,
            };

        case FIND_PREPAYMENT_ADVICE_FAIL:
            return {
                ...state,
                loading: false,
                prepaymentAdvice: {},
                error: action.payload,
            };

        case "EMPTY_PREPAYMENT_ADVICE":
            return {
                ...state,
                prepaymentAdvice: {},
                message: null,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};