// BTC_COMMODITY create
export const CREATE_BTC_COMMODITY_REQUEST = "CREATE_BTC_COMMODITY_REQUEST";
export const CREATE_BTC_COMMODITY_SUCCESS = "CREATE_BTC_COMMODITY_SUCCESS";
export const CREATE_BTC_COMMODITY_FAIL = "CREATE_BTC_COMMODITY_FAIL"
export const CREATE_BTC_COMMODITY_CONFLICT = "CREATE_BTC_COMMODITY_CONFLICT"

// BTC_COMMODITY get
export const BTC_COMMODITY_REQUEST = "BTC_COMMODITY_REQUEST";
export const BTC_COMMODITY_SUCCESS = "BTC_COMMODITY_SUCCESS";
export const BTC_COMMODITY_FAIL = "BTC_COMMODITY_FAIL"


// BTC_COMMODITY find
export const FIND_BTC_COMMODITY_REQUEST = "FIND_BTC_COMMODITY_REQUEST";
export const FIND_BTC_COMMODITY_SUCCESS = "FIND_BTC_COMMODITY_SUCCESS";
export const FIND_BTC_COMMODITY_FAIL = "FIND_BTC_COMMODITY_FAIL";

//BTC_COMMODITY update/put
export const UPDATE_BTC_COMMODITY_REQUEST = "UPDATE_BTC_COMMODITY_REQUEST";
export const UPDATE_BTC_COMMODITY_SUCCESS = "UPDATE_BTC_COMMODITY_SUCCESS";
export const UPDATE_BTC_COMMODITY_FAIL = "UPDATE_BTC_COMMODITY_FAIL";

// BTC_COMMODITY delete
export const DELETE_BTC_COMMODITY_REQUEST = "DELETE_BTC_COMMODITY_REQUEST";
export const DELETE_BTC_COMMODITY_SUCCESS = "DELETE_BTC_COMMODITY_SUCCESS";
export const DELETE_BTC_COMMODITY_FAIL = "DELETE_BTC_COMMODITY_FAIL";

// BTC_SECTION_COMMODITY find
export const FIND_BTC_SECTION_COMMODITY_REQUEST = "FIND_BTC_SECTION_COMMODITY_REQUEST";
export const FIND_BTC_SECTION_COMMODITY_SUCCESS = "FIND_BTC_SECTION_COMMODITY_SUCCESS";
export const FIND_BTC_SECTION_COMMODITY_FAIL = "FIND_BTC_SECTION_COMMODITY_FAIL";

// BTC_CHAPTER_COMMODITY find
export const FIND_BTC_CHAPTER_COMMODITY_REQUEST = "FIND_BTC_CHAPTER_COMMODITY_REQUEST";
export const FIND_BTC_CHAPTER_COMMODITY_SUCCESS = "FIND_BTC_CHAPTER_COMMODITY_SUCCESS";
export const FIND_BTC_CHAPTER_COMMODITY_FAIL = "FIND_BTC_CHAPTER_COMMODITY_FAIL";

export const BTC_COMMODITY_CLEAR_ERROR = "BTC_COMMODITY_CLEAR_ERROR";
