// create
export const CREATE_TARGET_TYPE_REQUEST = "CREATE_TARGET_TYPE_REQUEST";
export const CREATE_TARGET_TYPE_SUCCESS = "CREATE_TARGET_TYPE_SUCCESS";
export const CREATE_TARGET_TYPE_FAIL = "CREATE_TARGET_TYPE_FAIL"
export const CREATE_TARGET_TYPE_CONFLICT = "CREATE_TARGET_TYPE_CONFLICT"

// get
export const TARGET_TYPE_REQUEST = "TARGET_TYPE_REQUEST";
export const TARGET_TYPE_SUCCESS = "TARGET_TYPE_SUCCESS";
export const TARGET_TYPE_FAIL = "TARGET_TYPE_FAIL"


// find
export const FIND_TARGET_TYPE_REQUEST = "FIND_TARGET_TYPE_REQUEST";
export const FIND_TARGET_TYPE_SUCCESS = "FIND_TARGET_TYPE_SUCCESS";
export const FIND_TARGET_TYPE_FAIL = "FIND_TARGET_TYPE_FAIL"

// update
export const UPDATE_TARGET_TYPE_REQUEST = "UPDATE_TARGET_TYPE_REQUEST";
export const UPDATE_TARGET_TYPE_SUCCESS = "UPDATE_TARGET_TYPE_SUCCESS";
export const UPDATE_TARGET_TYPE_FAIL = "UPDATE_TARGET_TYPE_FAIL"

// delete
export const DELETE_TARGET_TYPE_REQUEST = "DELETE_TARGET_TYPE_REQUEST";
export const DELETE_TARGET_TYPE_SUCCESS = "DELETE_TARGET_TYPE_SUCCESS";
export const DELETE_TARGET_TYPE_FAIL = "DELETE_TARGET_TYPE_FAIL"


export const TARGET_TYPE_CLEAR_ERROR = "TARGET_TYPE_CLEAR_ERROR"
