import {
    CREATE_COA_APP_REQUEST_REQUEST,
    CREATE_COA_APP_REQUEST_SUCCESS,
    CREATE_COA_APP_REQUEST_FAIL,
    COA_APP_REQUEST_REQUEST,
    COA_APP_REQUEST_SUCCESS,
    COA_APP_REQUEST_FAIL,
    FIND_COA_APP_REQUEST_REQUEST,
    FIND_COA_APP_REQUEST_SUCCESS,
    FIND_COA_APP_REQUEST_FAIL,
    CREATE_COA_APP_REVIEW_REQUEST,
    CREATE_COA_APP_REVIEW_SUCCESS,
    CREATE_COA_APP_REVIEW_FAIL,
    COA_APP_REVIEW_REQUEST,
    COA_APP_REVIEW_SUCCESS,
    COA_APP_REVIEW_FAIL,
    COA_APP_CLEAR_ERROR,
} from "../../constants/CoaApplication/CoaApplicationConstant";

export const getCoaAppRequestReducer = (state = { coaAppRequests: [] }, action) => {
    switch (action.type) {
        case COA_APP_REQUEST_REQUEST:
        case CREATE_COA_APP_REQUEST_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
            };

        case COA_APP_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAppRequests: action.payload,
                message: null,
                status: null,
            };

        case CREATE_COA_APP_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAppRequests: [...state.coaAppRequests, action.payload],
                message: action.message,
                status: action.status,
            };

        case COA_APP_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
            };

        case CREATE_COA_APP_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case COA_APP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

export const getCoaAppReviewReducer = (state = { coaAppReviews: [] }, action) => {
    switch (action.type) {
        case COA_APP_REVIEW_REQUEST:
        case CREATE_COA_APP_REVIEW_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
            };

        case COA_APP_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAppReviews: action.payload,
                message: null,
                status: null,
            };

        case CREATE_COA_APP_REVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAppReviews: [
                    ...state.coaAppReviews.filter(
                        (item) => item.coaApplicationId !== action.payload.coaApplicationId
                    )
                ].sort((pre, post) => pre.coaApplicationId - post.coaApplicationId),
                message: action.message,
                status: action.status,
            };

        case COA_APP_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
            };

        case CREATE_COA_APP_REVIEW_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case COA_APP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findCoaAppRequestReducer = (state = { coaAppRequest: {} }, action) => {
    switch (action.type) {
        case FIND_COA_APP_REQUEST_REQUEST:
            return {
                coaAppRequest: {},
                loading: true,
            };

        case FIND_COA_APP_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAppRequest: action.payload,
            };

        case FIND_COA_APP_REQUEST_FAIL:
            return {
                ...state,
                loading: false,
                coaAppRequest: {},
                error: action.payload
            };

        case "EMPTY_COA_APP_REQUEST":
            return {
                ...state,
                coaAppRequest: {}
            }

        case COA_APP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
