import {
  CREATE_PAGROUPING_REQUEST,
  CREATE_PAGROUPING_SUCCESS,
  CREATE_PAGROUPING_FAIL,

  PA_FOR_GROUPING_REQUEST,
  PA_FOR_GROUPING_SUCCESS,
  PA_FOR_GROUPING_FAIL,

  PAGROUPING_CLEAR_ERROR,

} from "../../constants/PaGrouping/PaGroupingConstant";


export const getPaForGroupingReducer = (state = { paForGrouping: [] }, action) => {
  switch (action.type) {
    case PA_FOR_GROUPING_REQUEST:
    case CREATE_PAGROUPING_REQUEST:
      return {
        ...state,
        paForGrouping: [],
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case PA_FOR_GROUPING_SUCCESS:
      return {
        ...state,
        loading: false,
        paForGrouping: action.payload,
        message: null,
        status: null,
        totalData: action.totalData,
      };

    case CREATE_PAGROUPING_SUCCESS:
      return {
        ...state,
        loading: false,
        // paForGrouping: [...state.paForGrouping, action.payload],
        paGroupObject: action.payload,
        message: action.message,
        status: action.status,
      };

    case PA_FOR_GROUPING_FAIL:
      return {
        ...state,
        loading: false,
        paForGrouping: [...state.paForGrouping],
        error: action.payload,
      };

    case CREATE_PAGROUPING_FAIL:
      return {
        ...state,
        loading: false,
        paForGrouping: [...state.paForGrouping],
        message: action.message,
        status: action.status,
      };

    case PAGROUPING_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_PA_FOR_GROUPING":
      return {
        ...state,
        paForGrouping: [],
      };

    default:
      return state;
  }
};