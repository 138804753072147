// create
export const CREATE_NOTIFICATION_CONFIGURATION_REQUEST =
  "CREATE_NOTIFICATION_CONFIGURATION_REQUEST";
export const CREATE_NOTIFICATION_CONFIGURATION_SUCCESS =
  "CREATE_NOTIFICATION_CONFIGURATION_SUCCESS";
export const CREATE_NOTIFICATION_CONFIGURATION_FAIL =
  "CREATE_NOTIFICATION_CONFIGURATION_FAIL";
export const CREATE_NOTIFICATION_CONFIGURATION_CONFLICT =
  "CREATE_NOTIFICATION_CONFIGURATION_CONFLICT";

// get
export const NOTIFICATION_CONFIGURATION_REQUEST =
  "NOTIFICATION_CONFIGURATION_REQUEST";
export const NOTIFICATION_CONFIGURATION_SUCCESS =
  "NOTIFICATION_CONFIGURATION_SUCCESS";
export const NOTIFICATION_CONFIGURATION_FAIL =
  "NOTIFICATION_CONFIGURATION_FAIL";

// find
export const FIND_NOTIFICATION_CONFIGURATION_REQUEST =
  "FIND_NOTIFICATION_CONFIGURATION_REQUEST";
export const FIND_NOTIFICATION_CONFIGURATION_SUCCESS =
  "FIND_NOTIFICATION_CONFIGURATION_SUCCESS";
export const FIND_NOTIFICATION_CONFIGURATION_FAIL =
  "FIND_NOTIFICATION_CONFIGURATION_FAIL";

// update
export const UPDATE_NOTIFICATION_CONFIGURATION_REQUEST =
  "UPDATE_NOTIFICATION_CONFIGURATION_REQUEST";
export const UPDATE_NOTIFICATION_CONFIGURATION_SUCCESS =
  "UPDATE_NOTIFICATION_CONFIGURATION_SUCCESS";
export const UPDATE_NOTIFICATION_CONFIGURATION_FAIL =
  "UPDATE_NOTIFICATION_CONFIGURATION_FAIL";

export const NOTIFICATION_CONFIGURATION_CLEAR_ERROR =
  "NOTIFICATION_CONFIGURATION_CLEAR_ERROR";
