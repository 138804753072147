// create
export const CREATE_DUNGKHAG_REQUEST = "CREATE_DUNGKHAG_REQUEST";
export const CREATE_DUNGKHAG_SUCCESS = "CREATE_DUNGKHAG_SUCCESS";
export const CREATE_DUNGKHAG_FAIL = "CREATE_DUNGKHAG_FAIL";
// get
export const DUNGKHAG_REQUEST = "DUNGKHAG_REQUEST";
export const DUNGKHAG_SUCCESS = "DUNGKHAG_SUCCESS";
export const DUNGKHAG_FAIL = "DUNGKHAG_FAIL";
// find
export const FIND_DUNGKHAG_REQUEST = "FIND_DUNGKHAG_REQUEST";
export const FIND_DUNGKHAG_SUCCESS = "FIND_DUNGKHAG_SUCCESS";
export const FIND_DUNGKHAG_FAIL = "FIND_DUNGKHAG_FAIL";

// update
export const UPDATE_DUNGKHAG_REQUEST = "UPDATE_DUNGKHAG_REQUEST";
export const UPDATE_DUNGKHAG_SUCCESS = "UPDATE_DUNGKHAG_SUCCESS";
export const UPDATE_DUNGKHAG_FAIL = "UPDATE_DUNGKHAG_FAIL";

// delete
export const DELETE_DUNGKHAG_REQUEST = "DELETE_DUNGKHAG_REQUEST";
export const DELETE_DUNGKHAG_SUCCESS = "DELETE_DUNGKHAG_SUCCESS";
export const DELETE_DUNGKHAG_FAIL = "DELETE_DUNGKHAG_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";