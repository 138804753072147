import {
  CREATE_BTC_SECTION_REQUEST,
  CREATE_BTC_SECTION_SUCCESS,
  CREATE_BTC_SECTION_FAIL,
  BTC_SECTION_REQUEST,
  BTC_SECTION_SUCCESS,
  BTC_SECTION_FAIL,
  FIND_BTC_SECTION_REQUEST,
  FIND_BTC_SECTION_SUCCESS,
  FIND_BTC_SECTION_FAIL,
  UPDATE_BTC_SECTION_REQUEST,
  UPDATE_BTC_SECTION_SUCCESS,
  UPDATE_BTC_SECTION_FAIL,
  DELETE_BTC_SECTION_REQUEST,
  DELETE_BTC_SECTION_SUCCESS,
  DELETE_BTC_SECTION_FAIL,
  BTC_SECTION_CLEAR_ERROR,
} from "../../constants/BtcMaster/SectionConstant";

// get all data of list
export const getBtcSectionsReducer = (state = { btcSections: [] }, action) => {
  switch (action.type) {
    case BTC_SECTION_REQUEST:
    case CREATE_BTC_SECTION_REQUEST:
    case UPDATE_BTC_SECTION_REQUEST:
    case DELETE_BTC_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case BTC_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcSections: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BTC_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcSections: [...state.btcSections, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BTC_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcSections: [
          ...state.btcSections.filter(
            (item) => item.btcSectionId !== action.payload.btcSectionId
          ),
          action.payload,
        ].sort((pre, post) => pre.btcSectionId - post.btcSectionId),
        message: action.message,
        status: action.status,
      };

    case DELETE_BTC_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcSections: [
          ...state.btcSections.filter(
            (item) => item.btcSectionId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BTC_SECTION_SUCCESS":
      return {
        loading: false,
        btcSections: [
          ...state.btcSections.filter(
            (item) => item.btcSectionId !== action.payload.btcSectionId
          ),
          action.payload,
        ].sort((pre, post) => pre.btcSectionId - post.btcSectionId),
        message: action.message,
        status: action.status,
      };

    case BTC_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        btcSections: [...state.btcSections],
        error: action.payload,
      };

    case CREATE_BTC_SECTION_FAIL:
    case UPDATE_BTC_SECTION_FAIL:
    case DELETE_BTC_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        btcSections: [...state.btcSections],
        message: action.message,
        status: action.status,
      };

    case BTC_SECTION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBtcSectionReducer = (state = { btcSection: {} }, action) => {
  switch (action.type) {
    case FIND_BTC_SECTION_REQUEST:
      return {
        btcSection: {},
        loading: true,
      };

    case FIND_BTC_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcSection: action.payload,
      };

    case FIND_BTC_SECTION_FAIL:
      return {
        ...state,
        loading: false,
        btcSection: {},
        error: action.payload,
      };

    case "EMPTY_BTC_SECTION":
      return {
        ...state,
        btcSection: {},
      };

    case BTC_SECTION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
