// create
export const CREATE_ACTION_REQUEST = "CREATE_ACTION_REQUEST";
export const CREATE_ACTION_SUCCESS = "CREATE_ACTION_SUCCESS";
export const CREATE_ACTION_FAIL = "CREATE_ACTION_FAIL";

export const FIND_ACTION_REQUEST = "FIND_ACTION_REQUEST";
export const FIND_ACTION_SUCCESS = "FIND_ACTION_SUCCESS";
export const FIND_ACTION_FAIL = "FIND_ACTION_FAIL";

export const UPDATE_ACTION_REQUEST = "UPDATE_ACTION_REQUEST";
export const UPDATE_ACTION_SUCCESS = "UPDATE_ACTION_SUCCESS";
export const UPDATE_ACTION_FAIL = "UPDATE_ACTION_FAIL";

export const CANCEL_ACTION_REQUEST = "CANCEL_ACTION_REQUEST";
export const CANCEL_ACTION_SUCCESS = "CANCEL_ACTION_SUCCESS";
export const CANCEL_ACTION_FAIL = "CANCEL_ACTION_FAIL";

export const CREATE_AUDIT_RESPONSE_REQUEST = "CREATE_AUDIT_RESPONSE_REQUEST";
export const CREATE_AUDIT_RESPONSE_SUCCESS = "CREATE_AUDIT_RESPONSE_SUCCESS";
export const CREATE_AUDIT_RESPONSE_FAIL = "CREATE_AUDIT_RESPONSE_FAIL";


// find observation response
export const FIND_OBSERVATION_RESPONSE_REQUEST = "FIND_OBSERVATION_RESPONSE_REQUEST";
export const FIND_OBSERVATION_RESPONSE_SUCCESS = "FIND_OBSERVATION_RESPONSE_SUCCESS";
export const FIND_OBSERVATION_RESPONSE_FAIL = "FIND_OBSERVATION_RESPONSE_FAIL";

export const UPDATE_OBSERVATION_RESPONSE_REQUEST = "UPDATE_OBSERVATION_RESPONSE_REQUEST";
export const UPDATE_OBSERVATION_RESPONSE_SUCCESS = "UPDATE_OBSERVATION_RESPONSE_SUCCESS";
export const UPDATE_OBSERVATION_RESPONSE_FAIL = "UPDATE_OBSERVATION_RESPONSE_FAIL";


// get
export const AUDIT_RESPONSE_REQUEST = "AUDIT_RESPONSE_REQUEST";
export const AUDIT_RESPONSE_SUCCESS = "AUDIT_RESPONSE_SUCCESS";
export const AUDIT_RESPONSE_FAIL = "AUDIT_RESPONSE_FAIL";

export const AUDIT_ACTION_REQUEST = "AUDIT_ACTION_REQUEST";
export const AUDIT_ACTION_SUCCESS = "AUDIT_ACTION_SUCCESS";
export const AUDIT_ACTION_FAIL = "AUDIT_ACTION_FAIL";

// find

export const FIND_AUDIT_RESPONSE_REQUEST = "FIND_AUDIT_RESPONSE_REQUEST";
export const FIND_AUDIT_RESPONSE_SUCCESS = "FIND_AUDIT_RESPONSE_SUCCESS";
export const FIND_AUDIT_RESPONSE_FAIL = "FIND_AUDIT_RESPONSE_FAIL";

export const FIND_AUDIT_RESPONSE_LIST_REQUEST = "FIND_AUDIT_RESPONSE_LIST_REQUEST";
export const FIND_AUDIT_RESPONSE_LIST_SUCCESS = "FIND_AUDIT_RESPONSE_SLIST_UCCESS";
export const FIND_AUDIT_RESPONSE_LIST_FAIL = "FIND_AUDIT_RESPONSE_LIST_FAIL";

export const AUDIT_RESPONSE_CLEAR_ERROR = "AUDIT_RESPONSE_CLEAR_ERROR";

