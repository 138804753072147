import { 
  RECONCILIATION_UPLOAD_EXCEL_REQUEST,
  RECONCILIATION_UPLOAD_EXCEL_SUCCESS,
  RECONCILIATION_UPLOAD_EXCEL_FAIL,
  GET_RECONCILIATION_STAGING_REQUEST,
  GET_RECONCILIATION_STAGING_SUCCESS,
  GET_RECONCILIATION_STAGING_FAIL,
  CREATE_RECONCILIATION_SUMMARY_REQUEST,
  CREATE_RECONCILIATION_SUMMARY_SUCCESS,
  CREATE_RECONCILIATION_SUMMARY_FAIL,
  RECONCILIATION_CLEAR_ERROR
} from "../../constants/Reconciliation/ReconciliationConstants";
  
  
  // create, get, update,delete
  export const getReconciliationExcelData = (state = { reconciliationExcelData: [] }, action) => {
    
    switch (action.type) {
      
      case RECONCILIATION_UPLOAD_EXCEL_REQUEST:
        return {
          ...state,
          loading: true,
          reconciliationExcelData:[]
          
        };
      case RECONCILIATION_UPLOAD_EXCEL_SUCCESS:
     
        return {
          ...state,
          loading: false,
          reconciliationExcelData: action.payload,
          message: action.message,
          status: action.status
        };
  
    
      case RECONCILIATION_UPLOAD_EXCEL_FAIL:
        return {
          ...state,
          loading: false,
          reconciliationExcelData: action.payload,
          error: action.payload,
        };
        case RECONCILIATION_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

     
  
      default:
        return state;
    }
  };

  //get reconcilIation staging data
  export const getReconciliationStagingData = (state = { reconciliationStagingData: [] }, action) => {
     
    switch (action.type) {
      
      case GET_RECONCILIATION_STAGING_REQUEST:
        return {
          ...state,
          loading: true,
          reconciliationStagingData:[]
          
        };
      case GET_RECONCILIATION_STAGING_SUCCESS:
        return {
          ...state,
          loading: false,
          reconciliationStagingData: action.payload,
          message: action.message,
          status: action.status
        };
  
    
      case GET_RECONCILIATION_STAGING_FAIL:
        return {
          ...state,
          loading: false,
          reconciliationStagingData: action.payload,
          error: action.payload,
        };
        case RECONCILIATION_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

     
  
      default:
        return state;
    }
  };

  //create Summary
  export const createReconciliationSummary = (state = { reconciliationSummary: [] }, action) => {
    
    switch (action.type) {
      
      case CREATE_RECONCILIATION_SUMMARY_REQUEST:
        return {
          ...state,
          loading: true,
          reconciliationSummary:[]
          
        };
      case CREATE_RECONCILIATION_SUMMARY_SUCCESS:

        return {
          ...state,
          loading: false,
          reconciliationSummary: action.payload,
          message: action.message,
          status: action.status
        };
  
    
      case CREATE_RECONCILIATION_SUMMARY_FAIL:
        return {
          ...state,
          loading: false,
          reconciliationSummary: action.payload,
          error: action.payload,
        };
        case RECONCILIATION_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

     
  
      default:
        return state;
    }
  };

  
  
  