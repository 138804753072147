import { 
  CREATE_RAMIS_REV_GROUP_REQUEST,
  CREATE_RAMIS_REV_GROUP_SUCCESS,
  CREATE_RAMIS_REV_GROUP_FAIL,
  RAMIS_REV_GROUP_REQUEST,
  RAMIS_REV_GROUP_SUCCESS,
  RAMIS_REV_GROUP_FAIL,
  FIND_RAMIS_REV_GROUP_REQUEST,
  FIND_RAMIS_REV_GROUP_SUCCESS,
  FIND_RAMIS_REV_GROUP_FAIL,
  UPDATE_RAMIS_REV_GROUP_REQUEST,
  UPDATE_RAMIS_REV_GROUP_SUCCESS,
  UPDATE_RAMIS_REV_GROUP_FAIL,
  DELETE_RAMIS_REV_GROUP_REQUEST,
  DELETE_RAMIS_REV_GROUP_SUCCESS,
  DELETE_RAMIS_REV_GROUP_FAIL,
  CLEAR_ERROR,
} from "../../constants/COARamis/RamisRevGroupConstant";

export const ramisRevGroupReducer = (state = { ramisRevGroups: [] }, action) => {
  switch (action.type) {
    case RAMIS_REV_GROUP_REQUEST:
    case CREATE_RAMIS_REV_GROUP_REQUEST:
    case UPDATE_RAMIS_REV_GROUP_REQUEST:
    case DELETE_RAMIS_REV_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RAMIS_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RAMIS_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevGroups: [...state.ramisRevGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RAMIS_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevGroups: [
          ...state.ramisRevGroups.filter(
            (item) => item.revenueGroupId !== action.payload.revenueGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueGroupId - post.revenueGroupId),
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevGroups: [
          ...state.ramisRevGroups.filter(
            (item) => item.revenueGroupId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case RAMIS_REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_RAMIS_REV_GROUP_FAIL:
    case UPDATE_RAMIS_REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRamisRevGroupReducer = (state = { ramisRevGroup: {} }, action) => {
  switch (action.type) {
    case FIND_RAMIS_REV_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIND_RAMIS_REV_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevGroup: action.payload,
      };

    case FIND_RAMIS_REV_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        ramisRevGroup: {},
        error: action.payload
      };

    case "EMPTY_RAMIS_REVENUE_GROUP":
      return {
        ...state,
        ramisRevGroup: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};