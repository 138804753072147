// create
export const CREATE_AUDIT_PLAN_REQUEST = "CREATE_AUDIT_PLAN_REQUEST";
export const CREATE_AUDIT_PLAN_SUCCESS = "CREATE_AUDIT_PLAN_SUCCESS";
export const CREATE_AUDIT_PLAN_FAIL = "CREATE_AUDIT_PLAN_FAIL";

// get
export const AUDIT_PLAN_REQUEST = "AUDIT_PLAN_REQUEST";
export const AUDIT_PLAN_SUCCESS = "AUDIT_PLAN_SUCCESS";
export const AUDIT_PLAN_FAIL = "AUDIT_PLAN_FAIL";

// find
export const FIND_AUDIT_PLAN_REQUEST = "FIND_AUDIT_PLAN_REQUEST";
export const FIND_AUDIT_PLAN_SUCCESS = "FIND_AUDIT_PLAN_SUCCESS";
export const FIND_AUDIT_PLAN_FAIL = "FIND_AUDIT_PLAN_FAIL";

// update
export const UPDATE_AUDIT_PLAN_REQUEST = "UPDATE_AUDIT_PLAN_REQUEST";
export const UPDATE_AUDIT_PLAN_SUCCESS = "UPDATE_AUDIT_PLAN_SUCCESS";
export const UPDATE_AUDIT_PLAN_FAIL = "UPDATE_AUDIT_PLAN_FAIL";

// delete
export const DELETE_AUDIT_PLAN_REQUEST = "DELETE_AUDIT_PLAN_REQUEST";
export const DELETE_AUDIT_PLAN_SUCCESS = "DELETE_AUDIT_PLAN_SUCCESS";
export const DELETE_AUDIT_PLAN_FAIL = "DELETE_AUDIT_PLAN_FAIL";

export const AUDIT_PLAN_TEAM_ROLE_REQUEST = "AUDIT_PLAN_TEAM_ROLE_REQUEST";
export const AUDIT_PLAN_TEAM_ROLE_SUCCESS = "AUDIT_PLAN_TEAM_ROLE_SUCCESS";
export const AUDIT_PLAN_TEAM_ROLE_FAIL = "AUDIT_PLAN_TEAM_ROLE_FAIL";

export const AUDIT_PLAN_CLEAR_ERROR = "AUDIT_PLAN_CLEAR_ERROR";