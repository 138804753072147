// Receipt Allocation To Agencies create
export const CREATE_RECEIPT_ALLOCATION_AGENCIES_REQUEST = "CREATE_RECEIPT_ALLOCATION_AGENCIES_REQUEST";
export const CREATE_RECEIPT_ALLOCATION_AGENCIES_SUCCESS = "CREATE_RECEIPT_ALLOCATION_AGENCIES_SUCCESS";
export const CREATE_RECEIPT_ALLOCATION_AGENCIES_FAIL = "CREATE_RECEIPT_ALLOCATION_AGENCIES_FAIL";

// Receipt Allocation To Agencies get
export const RECEIPT_ALLOCATION_AGENCIES_REQUEST = "RECEIPT_ALLOCATION_AGENCIES_REQUEST";
export const RECEIPT_ALLOCATION_AGENCIES_SUCCESS = "RECEIPT_ALLOCATION_AGENCIES_SUCCESS";
export const RECEIPT_ALLOCATION_AGENCIES_FAIL = "RECEIPT_ALLOCATION_AGENCIES_FAIL";

// Receipt Allocation To Agencies find
export const FIND_RECEIPT_ALLOCATION_AGENCIES_REQUEST = "FIND_RECEIPT_ALLOCATION_AGENCIES_REQUEST";
export const FIND_RECEIPT_ALLOCATION_AGENCIES_SUCCESS = "FIND_RECEIPT_ALLOCATION_AGENCIES_SUCCESS";
export const FIND_RECEIPT_ALLOCATION_AGENCIES_FAIL = "FIND_RECEIPT_ALLOCATION_AGENCIES_FAIL";

export const RECEIPT_ALLOCATION_AGENCIES_CLEAR_ERROR = "RECEIPT_ALLOCATION_AGENCIES_CLEAR_ERROR";