// AGENCY_TYPE_CHILDREN find
export const FIND_AGENCY_TYPE_CHILDREN_REQUEST = "FIND_AGENCY_TYPE_CHILDREN_REQUEST";
export const FIND_AGENCY_TYPE_CHILDREN_SUCCESS = "FIND_AGENCY_TYPE_CHILDREN_SUCCESS";
export const FIND_AGENCY_TYPE_CHILDREN_FAIL = "FIND_AGENCY_TYPE_CHILDREN_FAIL";

// AGENCY_PARENT_CHILDREN find
export const FIND_PARENT_AGENCY_CHILDREN_REQUEST = "FIND_PARENT_AGENCY_CHILDREN_REQUEST";
export const FIND_PARENT_AGENCY_CHILDREN_SUCCESS = "FIND_PARENT_AGENCY_CHILDREN_SUCCESS";
export const FIND_PARENT_AGENCY_CHILDREN_FAIL = "FIND_PARENT_AGENCY_CHILDREN_FAIL";

// AGENCY_CHILDREN find
export const FIND_AGENCY_CHILDREN_REQUEST = "FIND_AGENCY_CHILDREN_REQUEST";
export const FIND_AGENCY_CHILDREN_SUCCESS = "FIND_AGENCY_CHILDREN_SUCCESS";
export const FIND_AGENCY_CHILDREN_FAIL = "FIND_AGENCY_CHILDREN_FAIL";

export const  FIND_SUB_AGENCY_OF_PARENT_AGENCY_REQUEST="FIND_SUB_AGENCY_OF_PARENT_AGENCY_REQUEST";
export const FIND_SUB_AGENCY_OF_PARENT_AGENCY_SUCCESS="FIND_SUB_AGENCY_OF_PARENT_AGENCY_SUCCESS";
export const FIND_SUB_AGENCY_OF_PARENT_AGENCY_FAIL="FIND_SUB_AGENCY_OF_PARENT_AGENCY_FAIL";

// AGENCY_GLHEAD_SERVICES find
export const FIND_AGENCY_COA_SERVICES_REQUEST = "FIND_AGENCY_COA_SERVICES_REQUEST";
export const FIND_AGENCY_COA_SERVICES_SUCCESS = "FIND_AGENCY_COA_SERVICES_SUCCESS";
export const FIND_AGENCY_COA_SERVICES_FAIL = "FIND_AGENCY_COA_SERVICES_FAIL";

// Clear_Error
export const CLEAR_ERROR = "CLEAR_ERROR";