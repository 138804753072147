// Tax Type Master create
export const CREATE_TAX_TYPE_MASTER_REQUEST = "CREATE_TAX_TYPE_MASTER_REQUEST";
export const CREATE_TAX_TYPE_MASTER_SUCCESS = "CREATE_TAX_TYPE_MASTER_SUCCESS";
export const CREATE_TAX_TYPE_MASTER_FAIL = "CREATE_TAX_TYPE_MASTER_FAIL"


// Tax Type Master get
export const TAX_TYPE_MASTER_REQUEST = "TAX_TYPE_MASTER_REQUEST";
export const TAX_TYPE_MASTER_SUCCESS = "TAX_TYPE_MASTER_SUCCESS";
export const TAX_TYPE_MASTER_FAIL = "TAX_TYPE_MASTER_FAIL"


// Tax Type Master find
export const FIND_TAX_TYPE_MASTER_REQUEST = "FIND_TAX_TYPE_MASTER_REQUEST";
export const FIND_TAX_TYPE_MASTER_SUCCESS = "FIND_TAX_TYPE_MASTER_SUCCESS";
export const FIND_TAX_TYPE_MASTER_FAIL = "FIND_TAX_TYPE_MASTER_FAIL"


//Tax Type Master update/put
export const UPDATE_TAX_TYPE_MASTER_REQUEST = "UPDATE_TAX_TYPE_MASTER_REQUEST";
export const UPDATE_TAX_TYPE_MASTER_SUCCESS = "UPDATE_TAX_TYPE_MASTER_SUCCESS";
export const UPDATE_TAX_TYPE_MASTER_FAIL = "UPDATE_TAX_TYPE_MASTER_FAIL"


// Tax Type Master office update/put
export const DELETE_TAX_TYPE_MASTER_REQUEST = "DELETE_TAX_TYPE_MASTER_REQUEST";
export const DELETE_TAX_TYPE_MASTER_SUCCESS = "DELETE_TAX_TYPE_MASTER_SUCCESS";
export const DELETE_TAX_TYPE_MASTER_FAIL = "DELETE_TAX_TYPE_MASTER_FAIL"


export const TAX_TYPE_MASTER_CLEAR_ERROR = "TAX_TYPE_MASTER_CLEAR_ERROR"
