// COA_SERVICE_MAP create
export const CREATE_COA_SERVICE_MAP_REQUEST = "CREATE_COA_SERVICE_MAP_REQUEST";
export const CREATE_COA_SERVICE_MAP_SUCCESS = "CREATE_COA_SERVICE_MAP_SUCCESS";
export const CREATE_COA_SERVICE_MAP_FAIL = "CREATE_COA_SERVICE_MAP_FAIL"
export const CREATE_COA_SERVICE_MAP_CONFLICT = "CREATE_COA_SERVICE_MAP_CONFLICT"

// COA_SERVICE_MAP get
export const COA_SERVICE_MAP_REQUEST = "COA_SERVICE_MAP_REQUEST";
export const COA_SERVICE_MAP_SUCCESS = "COA_SERVICE_MAP_SUCCESS";
export const COA_SERVICE_MAP_FAIL = "COA_SERVICE_MAP_FAIL"


// COA_SERVICE_MAP find
export const FIND_COA_SERVICE_MAP_REQUEST = "FIND_COA_SERVICE_MAP_REQUEST";
export const FIND_COA_SERVICE_MAP_SUCCESS = "FIND_COA_SERVICE_MAP_SUCCESS";
export const FIND_COA_SERVICE_MAP_FAIL = "FIND_COA_SERVICE_MAP_FAIL"

//COA_SERVICE_MAP update/put
export const UPDATE_COA_SERVICE_MAP_REQUEST = "UPDATE_COA_SERVICE_MAP_REQUEST";
export const UPDATE_COA_SERVICE_MAP_SUCCESS = "UPDATE_COA_SERVICE_MAP_SUCCESS";
export const UPDATE_COA_SERVICE_MAP_FAIL = "UPDATE_COA_SERVICE_MAP_FAIL"

// COA_SERVICE_MAP delete
export const DELETE_COA_SERVICE_MAP_REQUEST = "DELETE_COA_SERVICE_MAP_REQUEST";
export const DELETE_COA_SERVICE_MAP_SUCCESS = "DELETE_COA_SERVICE_MAP_SUCCESS";
export const DELETE_COA_SERVICE_MAP_FAIL = "DELETE_COA_SERVICE_MAP_FAIL"


export const COA_SERVICE_MAP_CLEAR_ERROR = "COA_SERVICE_MAP_CLEAR_ERROR"
