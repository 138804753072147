// create
export const CREATE_REV_GL_HEAD_REQUEST = "CREATE_REV_GL_HEAD_REQUEST";
export const CREATE_REV_GL_HEAD_SUCCESS = "CREATE_REV_GL_HEAD_SUCCESS";
export const CREATE_REV_GL_HEAD_FAIL = "CREATE_REV_GL_HEAD_FAIL";

// get
export const REV_GL_HEAD_REQUEST = "REV_GL_HEAD_REQUEST";
export const REV_GL_HEAD_SUCCESS = "REV_GL_HEAD_SUCCESS";
export const REV_GL_HEAD_FAIL = "REV_GL_HEAD_FAIL";

// find
export const FIND_REV_GL_HEAD_REQUEST = "FIND_REV_GL_HEAD_REQUEST";
export const FIND_REV_GL_HEAD_SUCCESS = "FIND_REV_GL_HEAD_SUCCESS";
export const FIND_REV_GL_HEAD_FAIL = "FIND_REV_GL_HEAD_FAIL";

// update
export const UPDATE_REV_GL_HEAD_REQUEST = "UPDATE_REV_GL_HEAD_REQUEST";
export const UPDATE_REV_GL_HEAD_SUCCESS = "UPDATE_REV_GL_HEAD_SUCCESS";
export const UPDATE_REV_GL_HEAD_FAIL = "UPDATE_REV_GL_HEAD_FAIL";

// delete
export const DELETE_REV_GL_HEAD_REQUEST = "DELETE_REV_GL_HEAD_REQUEST";
export const DELETE_REV_GL_HEAD_SUCCESS = "DELETE_REV_GL_HEAD_SUCCESS";
export const DELETE_REV_GL_HEAD_FAIL = "DELETE_REV_GL_HEAD_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
