import {
  CREATE_RECEIPT_ALLOCATION_AGENCIES_REQUEST,
  CREATE_RECEIPT_ALLOCATION_AGENCIES_SUCCESS,
  CREATE_RECEIPT_ALLOCATION_AGENCIES_FAIL,

  RECEIPT_ALLOCATION_AGENCIES_REQUEST,
  RECEIPT_ALLOCATION_AGENCIES_SUCCESS,
  RECEIPT_ALLOCATION_AGENCIES_FAIL,


  RECEIPT_ALLOCATION_AGENCIES_CLEAR_ERROR,
} from "../../constants_Phase_2/ReceiptStock/ReceiptAllocationToAgenciesConstant";

// get all data of list
export const getReceiptAllocationAgenciesReducer = (state = { receiptAllocationAgencies: [] }, action) => {
  switch (action.type) {
    case RECEIPT_ALLOCATION_AGENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        receiptAllocationAgencies: [],
        status: null,
        error: null,
      };

    case RECEIPT_ALLOCATION_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAllocationAgencies: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case RECEIPT_ALLOCATION_AGENCIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RECEIPT_ALLOCATION_AGENCIES_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// get all data of list
export const getReceiptAllocationAgenciesResponseReducer = (state = { receiptAllocationAgenciesResponse: [] }, action) => {
  switch (action.type) {
    case CREATE_RECEIPT_ALLOCATION_AGENCIES_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_RECEIPT_ALLOCATION_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CREATE_RECEIPT_ALLOCATION_AGENCIES_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case RECEIPT_ALLOCATION_AGENCIES_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

