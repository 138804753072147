import {
    CREATE_COA_SERVICE_MAP_REQUEST,
    CREATE_COA_SERVICE_MAP_SUCCESS,
    CREATE_COA_SERVICE_MAP_FAIL,

    COA_SERVICE_MAP_REQUEST,
    COA_SERVICE_MAP_SUCCESS,
    COA_SERVICE_MAP_FAIL,

    FIND_COA_SERVICE_MAP_REQUEST,
    FIND_COA_SERVICE_MAP_SUCCESS,
    FIND_COA_SERVICE_MAP_FAIL,

    UPDATE_COA_SERVICE_MAP_REQUEST,
    UPDATE_COA_SERVICE_MAP_SUCCESS,
    UPDATE_COA_SERVICE_MAP_FAIL,

    DELETE_COA_SERVICE_MAP_REQUEST,
    DELETE_COA_SERVICE_MAP_SUCCESS,
    DELETE_COA_SERVICE_MAP_FAIL,

    COA_SERVICE_MAP_CLEAR_ERROR
} from "../../constants/CoaMapping/CoaServiceMapConstant";


// get all data of list
export const getCoaServiceMapReducer = (state = { coaServiceMaps: [] }, action) => {
    switch (action.type) {
        case COA_SERVICE_MAP_REQUEST:
        case CREATE_COA_SERVICE_MAP_REQUEST:
        case UPDATE_COA_SERVICE_MAP_REQUEST:
        case DELETE_COA_SERVICE_MAP_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };
        case COA_SERVICE_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaServiceMaps: action.payload,
                message: null,
                status: null,
            };

        case CREATE_COA_SERVICE_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaServiceMaps: [...state.coaServiceMaps, action.payload],
                message: action.message,
                status: action.status
            };

        case UPDATE_COA_SERVICE_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaServiceMaps: [
                    ...state.coaServiceMaps.filter(
                        (item) => item.coaServiceMappingId !== action.payload.coaServiceMappingId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.coaServiceMappingId - post.coaServiceMappingId),
                message: action.message,
                status: action.status,

            };

        case DELETE_COA_SERVICE_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaServiceMaps: [
                    ...state.coaServiceMaps.filter(
                        (item) => item.coaServiceMappingId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case COA_SERVICE_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaServiceMaps: [...state.coaServiceMaps],
                error: action.payload,
            };

        case CREATE_COA_SERVICE_MAP_FAIL:
        case UPDATE_COA_SERVICE_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaServiceMaps: [...state.coaServiceMaps],
                message: action.message,
                status: action.status,
            };

        case DELETE_COA_SERVICE_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaServiceMaps: [...state.coaServiceMaps],
                error: action.payload,
            };

        case COA_SERVICE_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findCoaServiceMapReducer = (state = { coaServiceMap: {} }, action) => {
    switch (action.type) {
        case FIND_COA_SERVICE_MAP_REQUEST:
            return {
                coaServiceMap: {},
                loading: true,
            };

        case FIND_COA_SERVICE_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaServiceMap: action.payload,
            };

        case FIND_COA_SERVICE_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaServiceMap: {},
                error: action.payload,
            };

        case "EMPTY_COA_SERVICE_MAP":
            return {
                coaServiceMap: {}
            }

        case COA_SERVICE_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};