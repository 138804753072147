import {
  CREATE_AMEND_REQUEST,
  CREATE_AMEND_SUCCESS,
  CREATE_AMEND_FAIL,
  FIND_AMEND_REQUEST,
  FIND_AMEND_SUCCESS,
  FIND_AMEND_FAIL,
  UPDATE_AMEND_REQUEST,
  UPDATE_AMEND_SUCCESS,
  UPDATE_AMEND_FAIL,

  AMEND_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/AmendConstant";

export const getAmendReducer = (state = { receiptAmends: [] }, action) => {
  switch (action.type) {
    case CREATE_AMEND_REQUEST:
    case UPDATE_AMEND_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_AMEND_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAmends: [...state.receiptAmends, action.payload],
        amendObject: action.payload,
        message: action.message,
        status: action.status,
      };

    case UPDATE_AMEND_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAmends: [
          ...state.receiptAmends.filter(
            (item) => item.receiptMaintenanceAmendmentId !== action.payload.receiptMaintenanceAmendmentId
          ),
          action.payload,
        ].sort((pre, post) => pre.receiptMaintenanceAmendmentId - post.receiptMaintenanceAmendmentId),
        message: action.message,
        status: action.status,
      };

    case CREATE_AMEND_FAIL:
    case UPDATE_AMEND_FAIL:
      return {
        ...state,
        loading: false,
        receiptAmends: [...state.receiptAmends],
        message: action.message,
        status: action.status,
      };

    case AMEND_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_AMEND":
      return {
        ...state,
        receiptAmends: []
      }

    default:
      return state;
  }
};

export const findAmendReducer = (state = { receiptAmend: {} }, action) => {
  switch (action.type) {
    case FIND_AMEND_REQUEST:
      return {
        loading: true,
        receiptAmend: {},
      };
    case FIND_AMEND_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAmend: action.payload,
      };

    case FIND_AMEND_FAIL:
      return {
        ...state,
        loading: false,
        receiptAmend: {},
        error: action.payload,
      };
    case "EMPTY_AMEND":
      return {
        ...state,
        receiptAmend: {},
      };

    case AMEND_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};