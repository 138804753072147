import {
    CREATE_REVISE_FORECAST_REQUEST,
    CREATE_REVISE_FORECAST_SUCCESS,
    CREATE_REVISE_FORECAST_FAIL,
    REVISE_FORECAST_REQUEST,
    REVISE_FORECAST_SUCCESS,
    REVISE_FORECAST_FAIL,
    FIND_REVISE_FORECAST_REQUEST,
    FIND_REVISE_FORECAST_SUCCESS,
    FIND_REVISE_FORECAST_FAIL,
    UPDATE_REVISE_FORECAST_REQUEST,
    UPDATE_REVISE_FORECAST_SUCCESS,
    UPDATE_REVISE_FORECAST_FAIL,
    CLEAR_REVISE_FORECAST_ERROR,
  } from "../../constants_Phase_2/RevenueForecast/ReviseForecastConstant";
  
  // create
  export const reviseForecastListReducer = ( state = { reviseForecastList: [] }, action ) => {
    switch (action.type) {
      case REVISE_FORECAST_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          reviseForecastList: [],
          status: null,
          error: null,
        };
  
      case CREATE_REVISE_FORECAST_REQUEST:
      case UPDATE_REVISE_FORECAST_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
          error: null,
        };
  
      case REVISE_FORECAST_SUCCESS:
        return {
          ...state,
          loading: false,
          reviseForecastList: action.payload,
          totalData: action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };
  
      case CREATE_REVISE_FORECAST_SUCCESS:
      case UPDATE_REVISE_FORECAST_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case REVISE_FORECAST_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_REVISE_FORECAST_FAIL:
      case UPDATE_REVISE_FORECAST_FAIL:
        return {
          ...state,
          loading: false,
          reviseForecastList: [...state.reviseForecastList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_REVISE_FORECAST_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findReviseForecastReducer = ( state = { reviseForecast: {} }, action ) => {
  
    switch (action.type) {
      case FIND_REVISE_FORECAST_REQUEST:
        return {
          reviseForecast: {},
          loading: true,
        };
  
      case FIND_REVISE_FORECAST_SUCCESS:
        return {
          ...state,
          loading: false,
          reviseForecast: action.payload,
        };
  
      case FIND_REVISE_FORECAST_FAIL:
        return {
          ...state,
          loading: false,
          reviseForecast: {},
          error: action.payload,
        };
  
      case "EMPTY_REVISE_FORECAST":
        return {
          ...state,
          reviseForecast: {},
        };
  
      case CLEAR_REVISE_FORECAST_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  