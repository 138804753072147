import {
  CREATE_REVERSAL_REQUEST,
  CREATE_REVERSAL_SUCCESS,
  CREATE_REVERSAL_FAIL,
  FIND_REVERSAL_REQUEST,
  FIND_REVERSAL_SUCCESS,
  FIND_REVERSAL_FAIL,
  UPDATE_REVERSAL_REQUEST,
  UPDATE_REVERSAL_SUCCESS,
  UPDATE_REVERSAL_FAIL,

  REVERSAL_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/ReversalConstant";

export const getReversalReducer = (state = { receiptReversals: [] }, action) => {
  switch (action.type) {
    case CREATE_REVERSAL_REQUEST:
    case UPDATE_REVERSAL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_REVERSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptReversals: [...state.receiptReversals, action.payload],
        message: action.message,
        status: action.status,
      };

      case UPDATE_REVERSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptReversals: [
          ...state.receiptReversals.filter(
            (item) => item.receiptReversalId !== action.payload.receiptReversalId
          ),
          action.payload,
        ].sort((pre, post) => pre.receiptReversalId - post.receiptReversalId),
        message: action.message,
        status: action.status,
      };

    case CREATE_REVERSAL_FAIL:
    case UPDATE_REVERSAL_FAIL:
      return {
        ...state,
        loading: false,
        receiptReversals: [...state.receiptReversals],
        message: action.message,
        status: action.status,
      };

    case REVERSAL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_REVERSAL":
      return {
        ...state,
        receiptReversals: []
      }

    default:
      return state;
  }
};

export const findReversalReducer = (state = { receiptReversal: {} }, action) => {
  switch (action.type) {
    case FIND_REVERSAL_REQUEST:
      return {
        loading: true,
        receiptReversal: {},
      };
    case FIND_REVERSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptReversal: action.payload,
      };

    case FIND_REVERSAL_FAIL:
      return {
        ...state,
        loading: false,
        receiptReversal: {},
        error: action.payload,
      };
    case "EMPTY_REVERSAL":
      return {
        ...state,
        receiptReversal: {},
      };

    case REVERSAL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};