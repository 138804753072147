import {
  CREATE_GEWOGS_REQUEST,
  CREATE_GEWOGS_SUCCESS,
  CREATE_GEWOGS_FAIL,
  GEWOGS_REQUEST,
  GEWOGS_SUCCESS,
  GEWOGS_FAIL,
  FIND_GEWOGS_REQUEST,
  FIND_GEWOGS_SUCCESS,
  FIND_GEWOGS_FAIL,
  UPDATE_GEWOGS_REQUEST,
  UPDATE_GEWOGS_SUCCESS,
  UPDATE_GEWOGS_FAIL,
  DELETE_GEWOGS_REQUEST,
  DELETE_GEWOGS_SUCCESS,
  DELETE_GEWOGS_FAIL,
  CLEAR_ERROR,
} from "../../constants/LocationMaster/gewogsConstant";

export const gewogReducer = (state = { gewogs: [] }, action) => {
  switch (action.type) {
    case GEWOGS_REQUEST:
    case CREATE_GEWOGS_REQUEST:
    case UPDATE_GEWOGS_REQUEST:
    case DELETE_GEWOGS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case GEWOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: action.payload,
        status: null,
        message: null,
      };

    case CREATE_GEWOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: [...state.gewogs, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_GEWOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: [
          ...state.gewogs.filter(
            (item) => item.gewogId !== action.payload.gewogId
          ),
          action.payload,
        ].sort((pre, post) => pre.gewogId - post.gewogId),
        message: action.message,
        status: action.status,
      };

    case DELETE_GEWOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        gewogs: [
          ...state.gewogs.filter((item) => item.gewogId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_GEWOGS_SUCCESS":
      return {
        loading: false,
        gewogs: [
          ...state.gewogs.filter(
            (item) => item.gewogId !== action.payload.gewogId
          ),
          action.payload,
        ].sort((pre, post) => pre.gewogId - post.gewogId),
        message: action.message,
        status: action.status,
      };

    case GEWOGS_FAIL:
      return {
        ...state,
        loading: false,
        gewogs: [...state.gewogs],
        error: action.payload,
      };

    case CREATE_GEWOGS_FAIL:
    case UPDATE_GEWOGS_FAIL:
    case DELETE_GEWOGS_FAIL:
      return {
        ...state,
        loading: false,
        gewogs: [...state.gewogs],
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const findGewogReducer = (state = { gewog: {} }, action) => {
  switch (action.type) {
    case FIND_GEWOGS_REQUEST:
      return {
        loading: true,
        gewog: {},
      };
    case FIND_GEWOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        gewog: action.payload,
      };

    case FIND_GEWOGS_FAIL:
      return {
        ...state,
        loading: false,
        gewog: {},
        error: action.payload,
      };

    case "EMPTY_GEWOGS":
      return {
        ...state,
        gewog: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
