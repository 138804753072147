import {
  CREATE_BTC_EDITION_REQUEST,
  CREATE_BTC_EDITION_SUCCESS,
  CREATE_BTC_EDITION_FAIL,
  BTC_EDITION_REQUEST,
  BTC_EDITION_SUCCESS,
  BTC_EDITION_FAIL,
  FIND_BTC_EDITION_REQUEST,
  FIND_BTC_EDITION_SUCCESS,
  FIND_BTC_EDITION_FAIL,
  UPDATE_BTC_EDITION_REQUEST,
  UPDATE_BTC_EDITION_SUCCESS,
  UPDATE_BTC_EDITION_FAIL,
  DELETE_BTC_EDITION_REQUEST,
  DELETE_BTC_EDITION_SUCCESS,
  DELETE_BTC_EDITION_FAIL,
  BTC_EDITION_CLEAR_ERROR,
} from "../../constants/BtcMaster/EditionConstant";

// get all data of list
export const getBtcEditionsReducer = (state = { btcEditions: [] }, action) => {
  switch (action.type) {
    case BTC_EDITION_REQUEST:
    case CREATE_BTC_EDITION_REQUEST:
    case UPDATE_BTC_EDITION_REQUEST:
    case DELETE_BTC_EDITION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case BTC_EDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcEditions: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BTC_EDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcEditions: [...state.btcEditions, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BTC_EDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcEditions: [
          ...state.btcEditions.filter(
            (item) => item.btcEditionId !== action.payload.btcEditionId
          ),
          action.payload,
        ].sort((pre, post) => pre.btcEditionId - post.btcEditionId),
        message: action.message,
        status: action.status,
      };

    case DELETE_BTC_EDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcEditions: [
          ...state.btcEditions.filter(
            (item) => item.btcEditionId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BTC_EDITION_SUCCESS":
      return {
        loading: false,
        btcEditions: [
          ...state.btcEditions.filter(
            (item) => item.btcEditionId !== action.payload.btcEditionId
          ),
          action.payload,
        ].sort((pre, post) => pre.btcEditionId - post.btcEditionId),
        message: action.message,
        status: action.status,
      };

    case BTC_EDITION_FAIL:
      return {
        ...state,
        loading: false,
        btcEditions: [...state.btcEditions],
        error: action.payload,
      };

    case CREATE_BTC_EDITION_FAIL:
    case UPDATE_BTC_EDITION_FAIL:
    case DELETE_BTC_EDITION_FAIL:
      return {
        ...state,
        loading: false,
        btcEditions: [...state.btcEditions],
        message: action.message,
        status: action.status,
      };

    case BTC_EDITION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBtcEditionReducer = (state = { btcEdition: {} }, action) => {
  switch (action.type) {
    case FIND_BTC_EDITION_REQUEST:
      return {
        btcEdition: {},
        loading: true,
      };

    case FIND_BTC_EDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        btcEdition: action.payload,
      };

    case FIND_BTC_EDITION_FAIL:
      return {
        ...state,
        loading: false,
        btcEdition: {},
        error: action.payload,
      };

    case "EMPTY_BTC_EDITION":
      return {
        ...state,
        btcEdition: {},
      };

    case BTC_EDITION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
