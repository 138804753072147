import {
    CREATE_COA_DISBURSE_MAP_REQUEST,
    CREATE_COA_DISBURSE_MAP_SUCCESS,
    CREATE_COA_DISBURSE_MAP_FAIL,
    COA_DISBURSE_MAP_REQUEST,
    COA_DISBURSE_MAP_SUCCESS,
    COA_DISBURSE_MAP_FAIL,
    FIND_COA_DISBURSE_MAP_REQUEST,
    FIND_COA_DISBURSE_MAP_SUCCESS,
    FIND_COA_DISBURSE_MAP_FAIL,
    UPDATE_COA_DISBURSE_MAP_REQUEST,
    UPDATE_COA_DISBURSE_MAP_SUCCESS,
    UPDATE_COA_DISBURSE_MAP_FAIL,
    DELETE_COA_DISBURSE_MAP_REQUEST,
    DELETE_COA_DISBURSE_MAP_SUCCESS,
    DELETE_COA_DISBURSE_MAP_FAIL,
    COA_DISBURSE_MAP_CLEAR_ERROR,
  } from "../../constants/CoaMapping/CoaDisburseMapConstant";
  
  export const getCoaDisburseMapReducer = (state = { coaDisburseMaps: [] }, action) => {
    switch (action.type) {
      case COA_DISBURSE_MAP_REQUEST:
      case CREATE_COA_DISBURSE_MAP_REQUEST:
      case UPDATE_COA_DISBURSE_MAP_REQUEST:
      case DELETE_COA_DISBURSE_MAP_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
        };
  
      case COA_DISBURSE_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDisburseMaps: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_COA_DISBURSE_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDisburseMaps: [...state.coaDisburseMaps, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_COA_DISBURSE_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDisburseMaps: [
            ...state.coaDisburseMaps.filter(
              (item) => item.disbursementTypeMappingId !== action.payload.disbursementTypeMappingId
            ),
            action.payload,
          ].sort((pre, post) => pre.disbursementTypeMappingId - post.disbursementTypeMappingId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_DISBURSE_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDisburseMaps: [
            ...state.coaDisburseMaps.filter(
              (item) => item.disbursementTypeMappingId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  
      case COA_DISBURSE_MAP_FAIL:
        return {
          ...state,
          loading: false,
        };
  
      case CREATE_COA_DISBURSE_MAP_FAIL:
      case UPDATE_COA_DISBURSE_MAP_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_DISBURSE_MAP_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case COA_DISBURSE_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findCoaDisburseMapReducer = (state = { coaDisburseMap: {} }, action) => {
    switch (action.type) {
      case FIND_COA_DISBURSE_MAP_REQUEST:
        return {
          coaDisburseMap: {},
          loading: true,
        };
  
      case FIND_COA_DISBURSE_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaDisburseMap: action.payload,
        };
  
      case FIND_COA_DISBURSE_MAP_FAIL:
        return {
          ...state,
          loading: false,
          coaDisburseMap: {},
          error: action.payload
        };
  
      case "EMPTY_COA_DISBURSE_MAP":
        return {
          ...state,
          coaDisburseMap: {}
        }
  
      case COA_DISBURSE_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  