// CURRENCY create
export const CREATE_CURRENCY_REQUEST = "CREATE_CURRENCY_REQUEST";
export const CREATE_CURRENCY_SUCCESS = "CREATE_CURRENCY_SUCCESS";
export const CREATE_CURRENCY_FAIL = "CREATE_CURRENCY_FAIL"
export const CREATE_ERROR_CURRENCY_SUCCESS="CREATE_ERROR_CURRENCY_SUCCESS"

// CURRENCY get
export const CURRENCY_REQUEST = "CURRENCY_REQUEST";
export const CURRENCY_SUCCESS = "CURRENCY_SUCCESS";
export const CURRENCY_FAIL = "CURRENCY_FAIL"


// CURRENCY find
export const FIND_CURRENCY_REQUEST = "FIND_CURRENCY_REQUEST";
export const FIND_CURRENCY_SUCCESS = "FIND_CURRENCY_SUCCESS";
export const FIND_CURRENCY_FAIL = "FIND_CURRENCY_FAIL"

//CURRENCY update/put
export const UPDATE_CURRENCY_REQUEST = "UPDATE_CURRENCY_REQUEST";
export const UPDATE_CURRENCY_SUCCESS = "UPDATE_CURRENCY_SUCCESS";
export const UPDATE_CURRENCY_FAIL = "UPDATE_CURRENCY_FAIL"

// CURRENCY delete
export const DELETE_CURRENCY_REQUEST = "DELETE_CURRENCY_REQUEST";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAIL = "DELETE_CURRENCY_FAIL"

export const CURRENCY_CLEAR_ERROR = "CURRENCY_CLEAR_ERROR"
