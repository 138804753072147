import {
  CREATE_STATUS_REQUEST,
  CREATE_STATUS_SUCCESS,
  CREATE_STATUS_FAIL,
  STATUS_REQUEST,
  STATUS_SUCCESS,
  STATUS_FAIL,
  FIND_STATUS_REQUEST,
  FIND_STATUS_SUCCESS,
  FIND_STATUS_FAIL,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  DELETE_STATUS_REQUEST,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAIL,
  STATUS_CLEAR_ERROR,
} from "../../constants/OthersMaster/StatusMasterConstant";

// get all data of list
export const getStatusReducer = (state = { statusMasters: [] }, action) => {
  switch (action.type) {
    case STATUS_REQUEST:
    case CREATE_STATUS_REQUEST:
    case UPDATE_STATUS_REQUEST:
    case DELETE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };
    case STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: action.payload,
        message: null,
        status: null,
      };

    case CREATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: [...state.statusMasters, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: [
          ...state.statusMasters.filter(
            (item) => item.statusId !== action.payload.statusId
          ),
          action.payload,
        ].sort((pre, post) => pre.statusId - post.statusId),
        message: action.message,
        status: action.status,
      };

    case DELETE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMasters: [
          ...state.statusMasters.filter(
            (item) => item.statusId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_STATUS_SUCCESS":
      return {
        loading: false,
        statusMasters: [
          ...state.statusMasters.filter(
            (item) => item.statusId !== action.payload.statusId
          ),
          action.payload,
        ].sort((pre, post) => pre.statusId - post.statusId),
        message: action.message,
        status: action.status,
      };

    case STATUS_FAIL:
      return {
        ...state,
        loading: false,
        statusMasters: [...state.statusMasters],
        error: action.payload,
      };

    case CREATE_STATUS_FAIL:
    case UPDATE_STATUS_FAIL:
    case DELETE_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        statusMasters: [...state.statusMasters],
        message: action.message,
        status: action.status,
      };

    case STATUS_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findStatusReducer = (state = { statusMaster: {} }, action) => {
  switch (action.type) {
    case FIND_STATUS_REQUEST:
      return {
        statusMaster: {},
        loading: true,
      };
    case FIND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusMaster: action.payload,
      };

    case FIND_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        statusMaster: {},
        error: action.payload,
      };

    case "EMPTY_STATUS_MASTER":
      return {
        ...state,
        statusMaster: {},
      };

    case STATUS_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
