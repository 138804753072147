// create
export const CREATE_PAYMENT_ADVICE_COUNTER_REQUEST = "CREATE_PAYMENT_ADVICE_COUNTER_REQUEST";
export const CREATE_PAYMENT_ADVICE_COUNTER_SUCCESS = "CREATE_PAYMENT_ADVICE_COUNTER_SUCCESS";
export const CREATE_PAYMENT_ADVICE_COUNTER_FAIL = "CREATE_PAYMENT_ADVICE_COUNTER_FAIL";

// PAYMENT_ADVICE_COUNTER get
export const PAYMENT_ADVICE_COUNTER_REQUEST = "PAYMENT_ADVICE_COUNTER_REQUEST";
export const PAYMENT_ADVICE_COUNTER_SUCCESS = "PAYMENT_ADVICE_COUNTER_SUCCESS";
export const PAYMENT_ADVICE_COUNTER_FAIL = "PAYMENT_ADVICE_COUNTER_FAIL";

// find
export const FIND_PAYMENT_ADVICE_COUNTER_REQUEST = "FIND_PAYMENT_ADVICE_COUNTER_REQUEST";
export const FIND_PAYMENT_ADVICE_COUNTER_SUCCESS = "FIND_PAYMENT_ADVICE_COUNTER_SUCCESS";
export const FIND_PAYMENT_ADVICE_COUNTER_FAIL = "FIND_PAYMENT_ADVICE_COUNTER_FAIL";

// update
export const UPDATE_PAYMENT_ADVICE_COUNTER_REQUEST = "UPDATE_PAYMENT_ADVICE_COUNTER_REQUEST";
export const UPDATE_PAYMENT_ADVICE_COUNTER_SUCCESS = "UPDATE_PAYMENT_ADVICE_COUNTER_SUCCESS";
export const UPDATE_PAYMENT_ADVICE_COUNTER_FAIL = "UPDATE_PAYMENT_ADVICE_COUNTER_FAIL";

// DELETE_PAYMENT_ADVICE_COUNTER get
export const DELETE_PAYMENT_ADVICE_COUNTER_REQUEST = "DELETE_PAYMENT_ADVICE_COUNTER_REQUEST";
export const DELETE_PAYMENT_ADVICE_COUNTER_SUCCESS = "DELETE_PAYMENT_ADVICE_COUNTER_SUCCESS";
export const DELETE_PAYMENT_ADVICE_COUNTER_FAIL = "DELETE_PAYMENT_ADVICE_COUNTER_FAIL";

export const PAYMENT_ADVICE_COUNTER_CLEAR_ERROR = "PAYMENT_ADVICE_COUNTER_CLEAR_ERROR";