import {
  CREATE_DISBURSE_TYPE_REQUEST,
  CREATE_DISBURSE_TYPE_SUCCESS,
  CREATE_DISBURSE_TYPE_FAIL,
  DISBURSE_TYPE_REQUEST,
  DISBURSE_TYPE_SUCCESS,
  DISBURSE_TYPE_FAIL,
  FIND_DISBURSE_TYPE_REQUEST,
  FIND_DISBURSE_TYPE_SUCCESS,
  FIND_DISBURSE_TYPE_FAIL,
  UPDATE_DISBURSE_TYPE_REQUEST,
  UPDATE_DISBURSE_TYPE_SUCCESS,
  UPDATE_DISBURSE_TYPE_FAIL,
  DELETE_DISBURSE_TYPE_REQUEST,
  DELETE_DISBURSE_TYPE_SUCCESS,
  DELETE_DISBURSE_TYPE_FAIL,
  CLEAR_ERROR,
} from "../../constants/OthersMaster/DisburseTypeConstant";

export const disburseTypeReducer = (state = { disburseTypes: [] }, action) => {
  switch (action.type) {
    case DISBURSE_TYPE_REQUEST:
    case CREATE_DISBURSE_TYPE_REQUEST:
    case UPDATE_DISBURSE_TYPE_REQUEST:
    case DELETE_DISBURSE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DISBURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DISBURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseTypes: [...state.disburseTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DISBURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseTypes: [
          ...state.disburseTypes.filter(
            (item) =>
              item.disbursementTypeId !== action.payload.disbursementTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.disbursementTypeId - post.disbursementTypeId),
        message: action.message,
        status: action.status,
      };

    case DELETE_DISBURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseTypes: [
          ...state.disburseTypes.filter(
            (item) => item.disbursementTypeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DISBURSE_TYPE_SUCCESS":
      return {
        loading: false,
        disburseTypes: [
          ...state.disburseTypes.filter(
            (item) =>
              item.disbursementTypeId !== action.payload.disbursementTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.disbursementTypeId - post.disbursementTypeId),
        message: action.message,
        status: action.status,
      };

    case DISBURSE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_DISBURSE_TYPE_FAIL:
    case UPDATE_DISBURSE_TYPE_FAIL:
    case DELETE_DISBURSE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findDisburseTypeReducer = (
  state = { disburseType: {} },
  action
) => {
  switch (action.type) {
    case FIND_DISBURSE_TYPE_REQUEST:
      return {
        disburseType: {},
        loading: true,
      };

    case FIND_DISBURSE_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseType: action.payload,
      };

    case FIND_DISBURSE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        disburseType: {},
        error: action.payload,
      };

    case "EMPTY_DISBURSE_TYPE":
      return {
        ...state,
        disburseType: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
