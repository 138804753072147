//agency create
export const CREATE_AGENCY_TYPE_REQUEST = "CREATE_AGENCY_TYPE_REQUEST"
export const CREATE_AGENCY_TYPE_SUCCESS = "CREATE_AGENCY_TYPE_SUCCESS"
export const CREATE_AGENCY_TYPE_FAIL = "CREATE_AGENCY_TYPE_FAIL"
//update agency
export const UPDATE_AGENCY_TYPE_REQUEST="UPDATE_AGENCY_TYPE_REQUEST"
export const UPDATE_AGENCY_TYPE_SUCCESS="UPDATE_AGENCY_TYPE_SUCCESS"
export const UPDATE_AGENCY_TYPE_FAIL="UPDATE_AGENCY_TYPE_FAIL"
//delete agency
export const DELETE_AGENCY_TYPE_REQUEST="DELETE_AGENCY_TYPE_REQUEST"
export const DELETE_AGENCY_TYPE_SUCCESS="DELETE_AGENCY_TYPE_SUCCESS"
export const DELETE_AGENCY_TYPE_FAIL="DELETE_AGENCY_TYPE_FAIL"
//get all agency type
export const AGENCY_TYPE_REQUEST="AGENCY_TYPE_REQUEST"
export const AGENCY_TYPE_SUCCESS="AGENCY_TYPE_SUCCESS"
export const AGENCY_TYPE_FAIL="AGENCY_TYPE_FAIL"
//find agency type master
export const FIND_AGENCY_TYPE_REQUEST="FIND_AGENCY_TYPE_REQUEST"
export const FIND_AGENCY_TYPE_SUCCESS="FIND_AGENCY_TYPE_SUCCESS"
export const FIND_AGENCY_TYPE_FAIL="FIND_AGENCY_TYPE_FAIL"







//agency ministry or org create
export const CREATE_MINISTRY_ORGANIZATION_REQUEST = "CREATE_MINISTRY_ORGANIZATION_REQUEST"
export const CREATE_MINISTRY_ORGANIZATION_SUCCESS = "CREATE_MINISTRY_ORGANIZATION_SUCCESS"
export const CREATE_MINISTRY_ORGANIZATION_FAIL = "CREATE_MINISTRY_ORGANIZATION_FAIL"
//update ministry or org 
export const UPDATE_MINISTRY_ORGANIZATION_REQUEST = "UPDATE_MINISTRY_ORGANIZATION_REQUEST"
export const UPDATE_MINISTRY_ORGANIZATION_SUCCESS = "UPDATE_MINISTRY_ORGANIZATION_SUCCESS"
export const UPDATE_MINISTRY_ORGANIZATION_FAIL = "UPDATE_MINISTRY_ORGANIZATION_FAIL"
//delete ministry or org 
export const DELETE_MINISTRY_ORGANIZATION_REQUEST = "DELETE_MINISTRY_ORGANIZATION_REQUEST"
export const DELETE_MINISTRY_ORGANIZATION_SUCCESS = "DELETE_MINISTRY_ORGANIZATION_SUCCESS"
export const DELETE_MINISTRY_ORGANIZATION_FAIL = "DELETE_MINISTRY_ORGANIZATION_FAIL"
//agency ministry get by agency id
export const GET_MINISTRY_ORGANIZATION_REQUEST="GET_MINISTRY_ORGANIZATION_REQUEST"
export const GET_MINISTRY_ORGANIZATION_SUCCESS="GET_MINISTRY_ORGANIZATION_SUCCESS"
export const GET_MINISTRY_ORGANIZATION_FAIL="GET_MINISTRY_ORGANIZATION_FAIL"
//get all ministry list
export const MINISTRY_ORGANIZATION_REQUEST="MINISTRY_ORGANIZATION_REQUEST"
export const MINISTRY_ORGANIZATION_SUCCESS="MINISTRY_ORGANIZATION_SUCCESS"
export const MINISTRY_ORGANIZATION_FAIL="MINISTRY_ORGANIZATION_FAIL"
//find ministry
export const FIND_MINISTRY_ORGANIZATION_REQUEST="FIND_MINISTRY_ORGANIZATION_REQUEST"
export const FIND_MINISTRY_ORGANIZATION_SUCCESS="FIND_MINISTRY_ORGANIZATION_SUCCESS"
export const FIND_MINISTRY_ORGANIZATION_FAIL="FIND_MINISTRY_ORGANIZATION_FAIL"



//create department 
export const CREATE_DEPARTMENT_REQUEST = "CREATE_DEPARTMENT_REQUEST"
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS"
export const CREATE_DEPARTMENT_FAIL = "CREATE_DEPARTMENT_FAIL"
//update department 
export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST"
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS"
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL"
//delete department
export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST"
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS"
export const DELETE_DEPARTMENT_FAIL = "DELETE_DEPARTMENT_FAIL"
//get department list by ministry id
export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST"
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS"
export const GET_DEPARTMENT_FAIL = "GET_DEPARTMENT_FAIL"
//get all department list by ministry
export const DEPARTMENT_REQUEST = "DEPARTMENT_REQUEST"
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS"
export const DEPARTMENT_FAIL = "DEPARTMENT_FAIL"
//find department
export const FIND_DEPARTMENT_REQUEST = "FIND_DEPARTMENT_REQUEST"
export const FIND_DEPARTMENT_SUCCESS = "FIND_DEPARTMENT_SUCCESS"
export const FIND_DEPARTMENT_FAIL = "FIND_DEPARTMENT_FAIL"



//divisional or regional office create
export const CREATE_DIVISION_REGIONAL_OFFICE_REQUEST = "CREATE_DIVISION_REGIONAL_OFFICE_REQUEST"
export const CREATE_DIVISION_REGIONAL_OFFICE_SUCCESS = "CREATE_DIVISION_REGIONAL_OFFICE_SUCCESS"
export const CREATE_DIVISION_REGIONAL_OFFICE_FAIL = "CREATE_DIVISION_REGIONAL_OFFICE_FAIL"
//update divisional or regional office
export const UPDATE_DIVISION_REGIONAL_OFFICE_REQUEST = "UPDATE_DIVISION_REGIONAL_OFFICE_REQUEST"
export const UPDATE_DIVISION_REGIONAL_OFFICE_SUCCESS = "UPDATE_DIVISION_REGIONAL_OFFICE_SUCCESS"
export const UPDATE_DIVISION_REGIONAL_OFFICE_FAIL = "UPDATE_DIVISION_REGIONAL_OFFICE_FAIL"
//delete divisional or regional office
export const DELETE_DIVISION_REGIONAL_OFFICE_REQUEST = "DELETE_DIVISION_REGIONAL_OFFICE_REQUEST"
export const DELETE_DIVISION_REGIONAL_OFFICE_SUCCESS = "DELETE_DIVISION_REGIONAL_OFFICE_SUCCESS"
export const DELETE_DIVISION_REGIONAL_OFFICE_FAIL = "DELETE_DIVISION_REGIONAL_OFFICE_FAIL"
//divisional get by id 
export const GET_DIVISION_REGIONAL_OFFICE_REQUEST="GET_DIVISION_REGIONAL_OFFICE_REQUEST"
export const GET_DIVISION_REGIONAL_OFFICE_SUCCESS="GET_DIVISION_REGIONAL_OFFICE_SUCCESS"
export const GET_DIVISION_REGIONAL_OFFICE_FAIL="GET_DIVISION_REGIONAL_OFFICE_FAIL"
//get all divisional or regional office
export const DIVISION_REGIONAL_OFFICE_REQUEST="DIVISION_REGIONAL_OFFICE_REQUEST"
export const DIVISION_REGIONAL_OFFICE_SUCCESS="DIVISION_REGIONAL_OFFICE_SUCCESS"
export const DIVISION_REGIONAL_OFFICE_FAIL="DIVISION_REGIONAL_OFFICE_FAIL"
// find
export const FIND_DIVISION_REGIONAL_OFFICE_REQUEST = "FIND_DIVISION_REGIONAL_OFFICE_REQUEST"
export const FIND_DIVISION_REGIONAL_OFFICE_SUCCESS = "FIND_DIVISION_REGIONAL_OFFICE_SUCCESS"
export const FIND_DIVISION_REGIONAL_OFFICE_FAIL = "FIND_DIVISION_REGIONAL_OFFICE_FAIL"

// find agency by division id
export const FIND_AGENCY_BY_DIVISION_ID_REQUEST = "FIND_AGENCY_BY_DIVISION_ID_REQUEST"
export const FIND_AGENCY_BY_DIVISION_ID_SUCCESS = "FIND_AGENCY_BY_DIVISION_ID_SUCCESS"
export const FIND_AGENCY_BY_DIVISION_ID_FAIL = "FIND_AGENCY_BY_DIVISION_ID_FAIL"

export const RESET="RESET"
export const AGENCY_MASTER_CLEAR_ERROR = "AGENCY_MASTER_CLEAR_ERROR"
