// create
export const CREATE_AMEND_REQUEST = "CREATE_AMEND_REQUEST";
export const CREATE_AMEND_SUCCESS = "CREATE_AMEND_SUCCESS";
export const CREATE_AMEND_FAIL = "CREATE_AMEND_FAIL";

// find
export const FIND_AMEND_REQUEST = "FIND_AMEND_REQUEST";
export const FIND_AMEND_SUCCESS = "FIND_AMEND_SUCCESS";
export const FIND_AMEND_FAIL = "FIND_AMEND_FAIL";

// update
export const UPDATE_AMEND_REQUEST = "UPDATE_AMEND_REQUEST";
export const UPDATE_AMEND_SUCCESS = "UPDATE_AMEND_SUCCESS";
export const UPDATE_AMEND_FAIL = "UPDATE_AMEND_FAIL";

export const AMEND_CLEAR_ERROR = "AMEND_CLEAR_ERROR";