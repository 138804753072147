import { 
  FIND_RAMIS_MAJOR_GROUP_GLHEAD_REQUEST,
  FIND_RAMIS_MAJOR_GROUP_GLHEAD_SUCCESS,
  FIND_RAMIS_MAJOR_GROUP_GLHEAD_FAIL,

  FIND_RAMIS_REVENUE_GROUP_GLHEAD_REQUEST,
  FIND_RAMIS_REVENUE_GROUP_GLHEAD_SUCCESS,
  FIND_RAMIS_REVENUE_GROUP_GLHEAD_FAIL,

  FIND_RAMIS_REVENUE_TYPE_GLHEAD_REQUEST,
  FIND_RAMIS_REVENUE_TYPE_GLHEAD_SUCCESS,
  FIND_RAMIS_REVENUE_TYPE_GLHEAD_FAIL,

  FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_REQUEST,
  FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_SUCCESS,
  FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_FAIL,

  FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_REQUEST,
  FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_SUCCESS,
  FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_FAIL,

  CLEAR_ERROR
} from "../../constants/COARamis/RamisGlHeadFilterConstant";

// find agency type child
export const findRamisMajorGroupGlHeadReducer = (state = { ramisMajorGroupGlHeads: [] }, action) => {
  switch (action.type) {
      case FIND_RAMIS_MAJOR_GROUP_GLHEAD_REQUEST:
          return {
              ramisMajorGroupGlHeads: [],
              loading: true,
          };

      case FIND_RAMIS_MAJOR_GROUP_GLHEAD_SUCCESS:
          return {
              ...state,
              loading: false,
              ramisMajorGroupGlHeads: action.payload,
          };

      case FIND_RAMIS_MAJOR_GROUP_GLHEAD_FAIL:
          return {
              ...state,
              loading: false,
              ramisMajorGroupGlHeads: [],
              error: action.payload
          };

      case "EMPTY_RAMIS_MAJOR_GROUP_GLHEAD":
          return {
              ...state,
              ramisMajorGroupGlHeads: []
          }

      case CLEAR_ERROR:
          return {
              ...state,
              error: null,
              status: null,
          };

      default:
          return state;
  }
};

// find parent agency child
export const findRamisRevenueGroupGlHeadReducer = (state = { ramisRevenueGroupGlHeads: [] }, action) => {
  switch (action.type) {
      case FIND_RAMIS_REVENUE_GROUP_GLHEAD_REQUEST:
          return {
              ramisRevenueGroupGlHeads: [],
              loading: true,
          };

      case FIND_RAMIS_REVENUE_GROUP_GLHEAD_SUCCESS:
          return {
              ...state,
              loading: false,
              ramisRevenueGroupGlHeads: action.payload,
          };

      case FIND_RAMIS_REVENUE_GROUP_GLHEAD_FAIL:
          return {
              ...state,
              loading: false,
              ramisRevenueGroupGlHeads: [],
              error: action.payload
          };

      case "EMPTY_RAMIS_REVENUE_GROUP_GLHEAD":
          return {
              ...state,
              ramisRevenueGroupGlHeads: []
          }

      case CLEAR_ERROR:
          return {
              ...state,
              error: null,
              status: null,
          };

      default:
          return state;
  }
};

// find agency child
export const findRamisRevenueTypeGlHeadReducer = (state = { ramisRevenueTypeGlHeads: [] }, action) => {
  switch (action.type) {
      case FIND_RAMIS_REVENUE_TYPE_GLHEAD_REQUEST:
          return {
              ramisRevenueTypeGlHeads: [],
              loading: true,
          };

      case FIND_RAMIS_REVENUE_TYPE_GLHEAD_SUCCESS:
          return {
              ...state,
              loading: false,
              ramisRevenueTypeGlHeads: action.payload,
          };

      case FIND_RAMIS_REVENUE_TYPE_GLHEAD_FAIL:
          return {
              ...state,
              loading: false,
              ramisRevenueTypeGlHeads: [],
              error: action.payload
          };

      case "EMPTY_RAMIS_REVENUE_TYPE_GLHEAD":
          return {
              ...state,
              ramisRevenueTypeGlHeads: []
          }

      case CLEAR_ERROR:
          return {
              ...state,
              error: null,
              status: null,
          };

      default:
          return state;
  }
};


// find agency child
export const findRamisRevenueSubTypeGlHeadReducer = (state = { ramisRevenueSubTypeGlHeads: [] }, action) => {
  switch (action.type) {
      case FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_REQUEST:
          return {
              ramisRevenueSubTypeGlHeads: [],
              loading: true,
          };

      case FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_SUCCESS:
          return {
              ...state,
              loading: false,
              ramisRevenueSubTypeGlHeads: action.payload,
          };

      case FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_FAIL:
          return {
              ...state,
              loading: false,
              ramisRevenueSubTypeGlHeads: [],
              error: action.payload
          };

      case "EMPTY_RAMIS_REVENUE_SUB_TYPE_GLHEAD":
          return {
              ...state,
              ramisRevenueSubTypeGlHeads: []
          }

      case CLEAR_ERROR:
          return {
              ...state,
              error: null,
              status: null,
          };

      default:
          return state;
  }
};


// find agency child
export const findRamisFunctionalAreaGlHeadReducer = (state = { ramisFunctionalAreaGlHeads: [] }, action) => {
  switch (action.type) {
      case FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_REQUEST:
          return {
              ramisFunctionalAreaGlHeads: [],
              loading: true,
          };

      case FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_SUCCESS:
          return {
              ...state,
              loading: false,
              ramisFunctionalAreaGlHeads: action.payload,
          };

      case FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_FAIL:
          return {
              ...state,
              loading: false,
              ramisFunctionalAreaGlHeads: [],
              error: action.payload
          };

      case "EMPTY_RAMIS_FUNCTIONAL_AREA_GLHEAD":
          return {
              ...state,
              ramisFunctionalAreaGlHeads: []
          }

      case CLEAR_ERROR:
          return {
              ...state,
              error: null,
              status: null,
          };

      default:
          return state;
  }
};