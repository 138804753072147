import {
  CREATE_COA_SECTOR_MAP_REQUEST,
  CREATE_COA_SECTOR_MAP_SUCCESS,
  CREATE_COA_SECTOR_MAP_FAIL,

  COA_SECTOR_MAP_REQUEST,
  COA_SECTOR_MAP_SUCCESS,
  COA_SECTOR_MAP_FAIL,

  FIND_COA_SECTOR_MAP_REQUEST,
  FIND_COA_SECTOR_MAP_SUCCESS,
  FIND_COA_SECTOR_MAP_FAIL,

  UPDATE_COA_SECTOR_MAP_REQUEST,
  UPDATE_COA_SECTOR_MAP_SUCCESS,
  UPDATE_COA_SECTOR_MAP_FAIL,

  DELETE_COA_SECTOR_MAP_REQUEST,
  DELETE_COA_SECTOR_MAP_SUCCESS,
  DELETE_COA_SECTOR_MAP_FAIL,

  COA_SECTOR_MAP_CLEAR_ERROR,
} from "../../constants/CoaMapping/CoaSectorMapConstant";

// create, update, delete and get all
export const getCoaSectorMapReducer = (state = { coaSectorMaps: [] }, action) => {
  switch (action.type) {
    case COA_SECTOR_MAP_REQUEST:
    case CREATE_COA_SECTOR_MAP_REQUEST:
    case UPDATE_COA_SECTOR_MAP_REQUEST:
    case DELETE_COA_SECTOR_MAP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null
      };
    case COA_SECTOR_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaSectorMaps: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COA_SECTOR_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaSectorMaps: [...state.coaSectorMaps, action.payload],
        message: action.message,
        status: action.status
      };

    case UPDATE_COA_SECTOR_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaSectorMaps: [
          ...state.coaSectorMaps.filter(
            (item) => item.coaSectorMappingId !== action.payload.coaSectorMappingId
          ),
          action.payload,
        ].sort((pre, post) => pre.coaSectorMappingId - post.coaSectorMappingId),
        message: action.message,
        status: action.status,

      };

    case DELETE_COA_SECTOR_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaSectorMaps: [
          ...state.coaSectorMaps.filter(
            (item) => item.coaSectorMappingId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };


    case COA_SECTOR_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaSectorMaps: [...state.coaSectorMaps],
        error: action.payload,
      };

    case CREATE_COA_SECTOR_MAP_FAIL:
    case UPDATE_COA_SECTOR_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaSectorMaps: [...state.coaSectorMaps],
        message: action.message,
        status: action.status,
      };

    case DELETE_COA_SECTOR_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaSectorMaps: [...state.coaSectorMaps],
        error: action.payload,
      };

    case COA_SECTOR_MAP_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


// find one
export const findCoaSectorMapReducer = (state = { coaSectorMap: {} }, action) => {
  switch (action.type) {
    case FIND_COA_SECTOR_MAP_REQUEST:
      return {
        coaSectorMap: {},
        loading: true,
      };

    case FIND_COA_SECTOR_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaSectorMap: action.payload,
      };

    case FIND_COA_SECTOR_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaSectorMap: {},
        error: action.payload,
      };

    case "EMPTY_COA_SECTOR_MAP":
      return {
        ...state,
        coaSectorMap: {}
      }

    case COA_SECTOR_MAP_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};