import {
  CREATE_COA_GROUPS_REQUEST,
  CREATE_COA_GROUPS_SUCCESS,
  CREATE_COA_GROUPS_FAIL,
  COA_GROUPS_REQUEST,
  COA_GROUPS_SUCCESS,
  COA_GROUPS_FAIL,
  FIND_COA_GROUPS_REQUEST,
  FIND_COA_GROUPS_SUCCESS,
  FIND_COA_GROUPS_FAIL,
  UPDATE_COA_GROUPS_REQUEST,
  UPDATE_COA_GROUPS_SUCCESS,
  UPDATE_COA_GROUPS_FAIL,
  DELETE_COA_GROUPS_REQUEST,
  DELETE_COA_GROUPS_SUCCESS,
  DELETE_COA_GROUPS_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/COAGroupsConstant";

export const getCOAGroupsReducer = (state = { coaGroups: [] }, action) => {
  switch (action.type) {
    case COA_GROUPS_REQUEST:
    case CREATE_COA_GROUPS_REQUEST:
    case UPDATE_COA_GROUPS_REQUEST:
    case DELETE_COA_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case COA_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COA_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: [...state.coaGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_COA_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: [
          ...state.coaGroups.filter(
            (item) => item.coaGroupId !== action.payload.coaGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.coaGroupId - post.coaGroupId),
        message: action.message,
        status: action.status,
      };

    case DELETE_COA_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroups: [
          ...state.coaGroups.filter(
            (item) => item.coaGroupId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case COA_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_COA_GROUPS_FAIL:
    case UPDATE_COA_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case DELETE_COA_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findCOAGroupReducer = (state = { coaGroup: {} }, action) => {
  switch (action.type) {
    case FIND_COA_GROUPS_REQUEST:
      return {
        coaGroup: {},
        loading: true,
      };

    case FIND_COA_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        coaGroup: action.payload,
      };

    case FIND_COA_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        coaGroup: {},
        error: action.payload
      };

    case "EMPTY_COA_GROUPS":
      return {
        ...state,
        coaGroup: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
