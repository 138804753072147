//CID IN FORM
export const CID_REQUEST = "CID_REQUEST";
export const CID_SUCCESS = "CID_SUCCESS";
export const CID_FAIL = "CID_FAIL";

//PAYMENT CID IN FORM
export const PAYMENT_CID_REQUEST = "PAYMENT_CID_REQUEST";
export const PAYMENT_CID_SUCCESS = "PAYMENT_CID_SUCCESS";
export const PAYMENT_CID_FAIL = "PAYMENT_CID_FAIL";

//WORK_PERMIT IN FORM
export const WORK_PERMIT_REQUEST = "WORK_PERMIT_REQUEST";
export const WORK_PERMIT_SUCCESS = "WORK_PERMIT_SUCCESS";
export const WORK_PERMIT_FAIL = "WORK_PERMIT_FAIL";

//LC_PLC IN FORM
export const LC_PLC_REQUEST = "LC_PLC_REQUEST";
export const LC_PLC_SUCCESS = "LC_PLC_SUCCESS";
export const LC_PLC_FAIL = "LC_PLC_FAIL";

//TPN IN FORM
export const PAYMENT_TPN_REQUEST = "PAYMENT_TPN_REQUEST";
export const PAYMENT_TPN_SUCCESS = "PAYMENT_TPN_SUCCESS";
export const PAYMENT_TPN_FAIL = "PAYMENT_TPN_FAIL";

//Clear all
export const THIRD_PARTY_CLEAR_ERROR = "THIRD_PARTY_CLEAR_ERROR"