// BTC_HEADING create
export const CREATE_BTC_HEADING_REQUEST = "CREATE_BTC_HEADING_REQUEST";
export const CREATE_BTC_HEADING_SUCCESS = "CREATE_BTC_HEADING_SUCCESS";
export const CREATE_BTC_HEADING_FAIL = "CREATE_BTC_HEADING_FAIL";
export const CREATE_BTC_HEADING_CONFLICT = "CREATE_BTC_HEADING_CONFLICT";

// BTC_HEADING get
export const BTC_HEADING_REQUEST = "BTC_HEADING_REQUEST";
export const BTC_HEADING_SUCCESS = "BTC_HEADING_SUCCESS";
export const BTC_HEADING_FAIL = "BTC_HEADING_FAIL";


// BTC_HEADING find
export const FIND_BTC_HEADING_REQUEST = "FIND_BTC_HEADING_REQUEST";
export const FIND_BTC_HEADING_SUCCESS = "FIND_BTC_HEADING_SUCCESS";
export const FIND_BTC_HEADING_FAIL = "FIND_BTC_HEADING_FAIL";

//BTC_HEADING update/put
export const UPDATE_BTC_HEADING_REQUEST = "UPDATE_BTC_HEADING_REQUEST";
export const UPDATE_BTC_HEADING_SUCCESS = "UPDATE_BTC_HEADING_SUCCESS";
export const UPDATE_BTC_HEADING_FAIL = "UPDATE_BTC_HEADING_FAIL";

// BTC_HEADING delete
export const DELETE_BTC_HEADING_REQUEST = "DELETE_BTC_HEADING_REQUEST";
export const DELETE_BTC_HEADING_SUCCESS = "DELETE_BTC_HEADING_SUCCESS";
export const DELETE_BTC_HEADING_FAIL = "DELETE_BTC_HEADING_FAIL";


export const BTC_HEADING_CLEAR_ERROR = "BTC_HEADING_CLEAR_ERROR";
