import {
  CREATE_REVENUE_FORECAST_REQUEST,
  CREATE_REVENUE_FORECAST_SUCCESS,
  CREATE_REVENUE_FORECAST_FAIL,
  REVENUE_FORECAST_REQUEST,
  REVENUE_FORECAST_SUCCESS,
  REVENUE_FORECAST_FAIL,
  FIND_REVENUE_FORECAST_REQUEST,
  FIND_REVENUE_FORECAST_SUCCESS,
  FIND_REVENUE_FORECAST_FAIL,
  UPDATE_REVENUE_FORECAST_REQUEST,
  UPDATE_REVENUE_FORECAST_SUCCESS,
  UPDATE_REVENUE_FORECAST_FAIL,
  CLEAR_REVENUE_FORECAST_ERROR,
} from "../../constants_Phase_2/RevenueForecast/RevenueForecastConstant";

// create
export const revenueForecastListReducer = ( state = { revenueForecastList: [] }, action ) => {
  switch (action.type) {
    case REVENUE_FORECAST_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        revenueForecastList: [],
        status: null,
        error: null,
      };

    case CREATE_REVENUE_FORECAST_REQUEST:
    case UPDATE_REVENUE_FORECAST_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case REVENUE_FORECAST_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueForecastList: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case CREATE_REVENUE_FORECAST_SUCCESS:
    case UPDATE_REVENUE_FORECAST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case REVENUE_FORECAST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_REVENUE_FORECAST_FAIL:
    case UPDATE_REVENUE_FORECAST_FAIL:
      return {
        ...state,
        loading: false,
        revenueForecastList: [...state.revenueForecastList],
        message: action.message,
        status: action.status,
      };

    case CLEAR_REVENUE_FORECAST_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const targetTypeListReducer = (state = { targetTypes: [] }, action) => {
  switch (action.type) {
    case "TARGET_TYPE_REQUEST":
      return {
        ...state,
        loading: true,
        message: null,
        targetTypes: [],
        status: null,
        error: null,
      };

    case "TARGET_TYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        targetTypes: action.targetType,
        message: action.message,
        status: action.status,
      };

    case "TARGET_TYPE_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const revisionQuarterListReducer = (state = { quarterList: [] }, action) => {
  switch (action.type) {
    case "REVISION_QUARTER_REQUEST":
      return {
        ...state,
        loading: true,
        message: null,
        quarterList: [],
        status: null,
        error: null,
      };

    case "REVISION_QUARTER_SUCCESS":
      return {
        ...state,
        loading: false,
        quarterList: action.quarterList,
        message: action.message,
        status: action.status,
      };

    case "REVISION_QUARTER_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// find one
export const findRevenueForecastReducer = ( state = { revenueForecast: {} }, action ) => {

  switch (action.type) {
    case FIND_REVENUE_FORECAST_REQUEST:
      return {
        revenueForecast: {},
        loading: true,
      };

    case FIND_REVENUE_FORECAST_SUCCESS:
      return {
        ...state,
        loading: false,
        revenueForecast: action.payload,
      };

    case FIND_REVENUE_FORECAST_FAIL:
      return {
        ...state,
        loading: false,
        revenueForecast: {},
        error: action.payload,
      };

    case "EMPTY_REVENUE_FORECAST":
      return {
        ...state,
        revenueForecast: {},
      };

    case CLEAR_REVENUE_FORECAST_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
