
export const CREATE_REVENUE_FORECAST_REQUEST = "CREATE_REVENUE_FORECAST_REQUEST";
export const CREATE_REVENUE_FORECAST_SUCCESS = "CREATE_REVENUE_FORECAST_SUCCESS";
export const CREATE_REVENUE_FORECAST_FAIL = "CREATE_REVENUE_FORECAST_FAIL";

export const REVENUE_FORECAST_REQUEST = "REVENUE_FORECAST_REQUEST";
export const REVENUE_FORECAST_SUCCESS = "REVENUE_FORECAST_SUCCESS";
export const REVENUE_FORECAST_FAIL = "REVENUE_FORECAST_FAIL";


export const FIND_REVENUE_FORECAST_REQUEST = "FIND_REVENUE_FORECAST_REQUEST";
export const FIND_REVENUE_FORECAST_SUCCESS = "FIND_REVENUE_FORECAST_SUCCESS";
export const FIND_REVENUE_FORECAST_FAIL = "FIND_REVENUE_FORECAST_FAIL";


export const UPDATE_REVENUE_FORECAST_REQUEST = "UPDATE_REVENUE_FORECAST_REQUEST";
export const UPDATE_REVENUE_FORECAST_SUCCESS = "UPDATE_REVENUE_FORECAST_SUCCESS";
export const UPDATE_REVENUE_FORECAST_FAIL = "UPDATE_REVENUE_FORECAST_FAIL";



export const CLEAR_REVENUE_FORECAST_ERROR = "CLEAR_REVENUE_FORECAST_ERROR";