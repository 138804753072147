// create
export const CREATE_PREPAYMENT_ADVICE_REQUEST = "CREATE_PREPAYMENT_ADVICE_REQUEST";
export const CREATE_PREPAYMENT_ADVICE_SUCCESS = "CREATE_PREPAYMENT_ADVICE_SUCCESS";
export const CREATE_PREPAYMENT_ADVICE_FAIL = "CREATE_PREPAYMENT_ADVICE_FAIL";

// get
export const PREPAYMENT_ADVICE_REQUEST = "PREPAYMENT_ADVICE_REQUEST";
export const PREPAYMENT_ADVICE_SUCCESS = "PREPAYMENT_ADVICE_SUCCESS";
export const PREPAYMENT_ADVICE_FAIL = "PREPAYMENT_ADVICE_FAIL";

// find
export const FIND_PREPAYMENT_ADVICE_REQUEST = "FIND_PREPAYMENT_ADVICE_REQUEST";
export const FIND_PREPAYMENT_ADVICE_SUCCESS = "FIND_PREPAYMENT_ADVICE_SUCCESS";
export const FIND_PREPAYMENT_ADVICE_FAIL = "FIND_PREPAYMENT_ADVICE_FAIL";

// update
export const UPDATE_PREPAYMENT_ADVICE_REQUEST = "UPDATE_PREPAYMENT_ADVICE_REQUEST";
export const UPDATE_PREPAYMENT_ADVICE_SUCCESS = "UPDATE_PREPAYMENT_ADVICE_SUCCESS";
export const UPDATE_PREPAYMENT_ADVICE_FAIL = "UPDATE_PREPAYMENT_ADVICE_FAIL";

// delete
export const DELETE_PREPAYMENT_ADVICE_REQUEST = "DELETE_PREPAYMENT_ADVICE_REQUEST";
export const DELETE_PREPAYMENT_ADVICE_SUCCESS = "DELETE_PREPAYMENT_ADVICE_SUCCESS";
export const DELETE_PREPAYMENT_ADVICE_FAIL = "DELETE_PREPAYMENT_ADVICE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";