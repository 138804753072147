// create
export const CREATE_DRC_BANK_ACCOUNT_REQUEST = "CREATE_DRC_BANK_ACCOUNT_REQUEST";
export const CREATE_DRC_BANK_ACCOUNT_SUCCESS = "CREATE_DRC_BANK_ACCOUNT_SUCCESS";
export const CREATE_DRC_BANK_ACCOUNT_FAIL = "CREATE_DRC_BANK_ACCOUNT_FAIL";

// get
export const DRC_BANK_ACCOUNT_REQUEST = "DRC_BANK_ACCOUNT_REQUEST";
export const DRC_BANK_ACCOUNT_SUCCESS = "DRC_BANK_ACCOUNT_SUCCESS";
export const DRC_BANK_ACCOUNT_FAIL = "DRC_BANK_ACCOUNT_FAIL";

// find
export const FIND_DRC_BANK_ACCOUNT_REQUEST = "FIND_DRC_BANK_ACCOUNT_REQUEST";
export const FIND_DRC_BANK_ACCOUNT_SUCCESS = "FIND_DRC_BANK_ACCOUNT_SUCCESS";
export const FIND_DRC_BANK_ACCOUNT_FAIL = "FIND_DRC_BANK_ACCOUNT_FAIL";

// update
export const UPDATE_DRC_BANK_ACCOUNT_REQUEST = "UPDATE_DRC_BANK_ACCOUNT_REQUEST";
export const UPDATE_DRC_BANK_ACCOUNT_SUCCESS = "UPDATE_DRC_BANK_ACCOUNT_SUCCESS";
export const UPDATE_DRC_BANK_ACCOUNT_FAIL = "UPDATE_DRC_BANK_ACCOUNT_FAIL";

// delete
export const DELETE_DRC_BANK_ACCOUNT_REQUEST = "DELETE_DRC_BANK_ACCOUNT_REQUEST";
export const DELETE_DRC_BANK_ACCOUNT_SUCCESS = "DELETE_DRC_BANK_ACCOUNT_SUCCESS";
export const DELETE_DRC_BANK_ACCOUNT_FAIL = "DELETE_DRC_BANK_ACCOUNT_FAIL";

// find bank accounts
export const FIND_BANK_BRANCH_ACCOUNTS_REQUEST = "FIND_BANK_BRANCH_ACCOUNTS_REQUEST";
export const FIND_BANK_BRANCH_ACCOUNTS_SUCCESS = "FIND_BANK_BRANCH_ACCOUNTS_SUCCESS";
export const FIND_BANK_BRANCH_ACCOUNTS_FAIL = "FIND_BANK_BRANCH_ACCOUNTS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
