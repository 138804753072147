import {
  CREATE_DRC_BANK_ACCOUNT_REQUEST,
  CREATE_DRC_BANK_ACCOUNT_SUCCESS,
  CREATE_DRC_BANK_ACCOUNT_FAIL,
  DRC_BANK_ACCOUNT_REQUEST,
  DRC_BANK_ACCOUNT_SUCCESS,
  DRC_BANK_ACCOUNT_FAIL,
  FIND_DRC_BANK_ACCOUNT_REQUEST,
  FIND_DRC_BANK_ACCOUNT_SUCCESS,
  FIND_DRC_BANK_ACCOUNT_FAIL,
  UPDATE_DRC_BANK_ACCOUNT_REQUEST,
  UPDATE_DRC_BANK_ACCOUNT_SUCCESS,
  UPDATE_DRC_BANK_ACCOUNT_FAIL,
  DELETE_DRC_BANK_ACCOUNT_REQUEST,
  DELETE_DRC_BANK_ACCOUNT_SUCCESS,
  DELETE_DRC_BANK_ACCOUNT_FAIL,
  FIND_BANK_BRANCH_ACCOUNTS_REQUEST,
  FIND_BANK_BRANCH_ACCOUNTS_SUCCESS,
  FIND_BANK_BRANCH_ACCOUNTS_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/DRCBankAccountConstant";

export const drcBankAccountReducer = (
  state = { drcBankAccounts: [] },
  action
) => {
  switch (action.type) {
    case DRC_BANK_ACCOUNT_REQUEST:
    case CREATE_DRC_BANK_ACCOUNT_REQUEST:
    case UPDATE_DRC_BANK_ACCOUNT_REQUEST:
    case DELETE_DRC_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case DRC_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        drcBankAccounts: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DRC_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        drcBankAccounts: [...state.drcBankAccounts, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DRC_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        drcBankAccounts: [
          ...state.drcBankAccounts.filter(
            (item) => item.drcAccountId !== action.payload.drcAccountId
          ),
          action.payload,
        ].sort((pre, post) => pre.drcAccountId - post.drcAccountId),
        message: action.message,
        status: action.status,
      };

    case DELETE_DRC_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        drcBankAccounts: [
          ...state.drcBankAccounts.filter(
            (item) => item.drcAccountId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case DRC_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
      };

    case "FUTURE_DELETE_DRC_BANK_ACCOUNT_SUCCESS":
      return {
        loading: false,
        drcBankAccounts: [
          ...state.drcBankAccounts.filter(
            (item) => item.drcAccountId !== action.payload.drcAccountId
          ),
          action.payload,
        ].sort((pre, post) => pre.drcAccountId - post.drcAccountId),
        message: action.message,
        status: action.status,
      };

    case CREATE_DRC_BANK_ACCOUNT_FAIL:
    case UPDATE_DRC_BANK_ACCOUNT_FAIL:
    case DELETE_DRC_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findDrcBankAccountReducer = (
  state = { drcBankAccount: {} },
  action
) => {
  switch (action.type) {
    case FIND_DRC_BANK_ACCOUNT_REQUEST:
      return {
        drcBankAccount: {},
        loading: true,
      };

    case FIND_DRC_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        drcBankAccount: action.payload,
      };

    case FIND_DRC_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        loading: false,
        drcBankAccount: {},
        error: action.payload,
      };

    case "EMPTY_DRC_BANK_ACCOUNT":
      return {
        ...state,
        drcBankAccount: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

// find one
export const findBankBranchAccountsReducer = (
  state = { bankBranchAccounts: [] },
  action
) => {
  switch (action.type) {
    case FIND_BANK_BRANCH_ACCOUNTS_REQUEST:
      return {
        bankBranchAccounts: [],
        loading: true,
      };

    case FIND_BANK_BRANCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranchAccounts: action.payload,
      };

    case FIND_BANK_BRANCH_ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
        bankBranchAccounts: [],
        error: action.payload,
      };

    case "EMPTY_BANK_BRANCH_ACCOUNTS":
      return {
        ...state,
        bankBranchAccounts: [],
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
