// COA_APP_REQUEST create
export const CREATE_COA_APP_REQUEST_REQUEST = "CREATE_COA_APP_REQUEST_REQUEST";
export const CREATE_COA_APP_REQUEST_SUCCESS = "CREATE_COA_APP_REQUEST_SUCCESS";
export const CREATE_COA_APP_REQUEST_FAIL = "CREATE_COA_APP_REQUEST_FAIL";

// COA_APP_REQUEST get
export const COA_APP_REQUEST_REQUEST = "COA_APP_REQUEST_REQUEST";
export const COA_APP_REQUEST_SUCCESS = "COA_APP_REQUEST_SUCCESS";
export const COA_APP_REQUEST_FAIL = "COA_APP_REQUEST_FAIL";


// COA_APP_REQUEST find
export const FIND_COA_APP_REQUEST_REQUEST = "FIND_COA_APP_REQUEST_REQUEST";
export const FIND_COA_APP_REQUEST_SUCCESS = "FIND_COA_APP_REQUEST_SUCCESS";
export const FIND_COA_APP_REQUEST_FAIL = "FIND_COA_APP_REQUEST_FAIL";

// COA_APP_REQUEST create
export const CREATE_COA_APP_REVIEW_REQUEST = "CREATE_COA_APP_REVIEW_REQUEST";
export const CREATE_COA_APP_REVIEW_SUCCESS = "CREATE_COA_APP_REVIEW_SUCCESS";
export const CREATE_COA_APP_REVIEW_FAIL = "CREATE_COA_APP_REVIEW_FAIL";

// COA_APP_REVIEW get
export const COA_APP_REVIEW_REQUEST = "COA_APP_REVIEW_REQUEST";
export const COA_APP_REVIEW_SUCCESS = "COA_APP_REVIEW_SUCCESS";
export const COA_APP_REVIEW_FAIL = "COA_APP_REVIEW_FAIL";

export const COA_APP_CLEAR_ERROR = "COA_APP_CLEAR_ERROR";
