// Payment Method create
export const CREATE_PAYMENT_METHOD_REQUEST = "CREATE_PAYMENT_METHOD_REQUEST";
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS";
export const CREATE_PAYMENT_METHOD_FAIL = "CREATE_PAYMENT_METHOD_FAIL"


// Payment Method get
export const PAYMENT_METHOD_REQUEST = "PAYMENT_METHOD_REQUEST";
export const PAYMENT_METHOD_SUCCESS = "PAYMENT_METHOD_SUCCESS";
export const PAYMENT_METHOD_FAIL = "PAYMENT_METHOD_FAIL"


// Payment Method find
export const FIND_PAYMENT_METHOD_REQUEST = "FIND_PAYMENT_METHOD_REQUEST";
export const FIND_PAYMENT_METHOD_SUCCESS = "FIND_PAYMENT_METHOD_SUCCESS";
export const FIND_PAYMENT_METHOD_FAIL = "FIND_PAYMENT_METHOD_FAIL"


// Payment Method update/put
export const UPDATE_PAYMENT_METHOD_REQUEST = "UPDATE_PAYMENT_METHOD_REQUEST";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";
export const UPDATE_PAYMENT_METHOD_FAIL = "UPDATE_PAYMENT_METHOD_FAIL"


// Payment Method delete
export const DELETE_PAYMENT_METHOD_REQUEST = "DELETE_PAYMENT_METHOD_REQUEST";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_FAIL = "DELETE_PAYMENT_METHOD_FAIL"


export const PAYMENT_METHOD_CLEAR_ERROR = "PAYMENT_METHOD_CLEAR_ERROR";