import {
  CREATE_TAX_TYPE_MASTER_REQUEST,
  CREATE_TAX_TYPE_MASTER_SUCCESS,
  CREATE_TAX_TYPE_MASTER_FAIL,
  TAX_TYPE_MASTER_REQUEST,
  TAX_TYPE_MASTER_SUCCESS,
  TAX_TYPE_MASTER_FAIL,
  FIND_TAX_TYPE_MASTER_REQUEST,
  FIND_TAX_TYPE_MASTER_SUCCESS,
  FIND_TAX_TYPE_MASTER_FAIL,
  UPDATE_TAX_TYPE_MASTER_REQUEST,
  UPDATE_TAX_TYPE_MASTER_SUCCESS,
  UPDATE_TAX_TYPE_MASTER_FAIL,
  DELETE_TAX_TYPE_MASTER_REQUEST,
  DELETE_TAX_TYPE_MASTER_SUCCESS,
  DELETE_TAX_TYPE_MASTER_FAIL,
  TAX_TYPE_MASTER_CLEAR_ERROR,
} from "../../constants/OthersMaster/TaxTypeMasterConstant";

export const getTaxTypeReducer = (state = { taxTypes: [] }, action) => {
  switch (action.type) {
    case TAX_TYPE_MASTER_REQUEST:
    case CREATE_TAX_TYPE_MASTER_REQUEST:
    case UPDATE_TAX_TYPE_MASTER_REQUEST:
    case DELETE_TAX_TYPE_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case TAX_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        taxTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_TAX_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        taxTypes: [...state.taxTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_TAX_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        taxTypes: [
          ...state.taxTypes.filter(
            (item) => item.taxTypeId !== action.payload.taxTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.taxTypeId - post.taxTypeId),
        message: action.message,
        status: action.status,
      };

    case DELETE_TAX_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        taxTypes: [
          ...state.taxTypes.filter((item) => item.taxTypeId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_TAX_TYPE_MASTER_SUCCESS":
      return {
        loading: false,
        taxTypes: [
          ...state.taxTypes.filter(
            (item) => item.taxTypeId !== action.payload.taxTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.taxTypeId - post.taxTypeId),
        message: action.message,
        status: action.status,
      };

    case TAX_TYPE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        taxTypes: [...state.taxTypes],
        error: action.payload,
      };

    case CREATE_TAX_TYPE_MASTER_FAIL:
    case UPDATE_TAX_TYPE_MASTER_FAIL:
    case DELETE_TAX_TYPE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        taxTypes: [...state.taxTypes],
        message: action.message,
        status: action.status,
      };

    case TAX_TYPE_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findTaxTypeReducer = (state = { taxType: {} }, action) => {
  switch (action.type) {
    case FIND_TAX_TYPE_MASTER_REQUEST:
      return {
        taxType: {},
        loading: true,
      };
    case FIND_TAX_TYPE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        taxType: action.payload,
      };

    case FIND_TAX_TYPE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        taxType: {},
        error: action.payload,
      };

    case "EMPTY_TAX_TYPE_MASTER":
      return {
        ...state,
        taxType: {},
      };

    case TAX_TYPE_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
