import {
  AUDIT_RESPONSE_CLEAR_ERROR,

  AUDIT_RESPONSE_FAIL,
  AUDIT_RESPONSE_REQUEST,
  AUDIT_RESPONSE_SUCCESS,

  AUDIT_ACTION_REQUEST,
  AUDIT_ACTION_SUCCESS,
  AUDIT_ACTION_FAIL,

  CREATE_AUDIT_RESPONSE_REQUEST,
  CREATE_AUDIT_RESPONSE_SUCCESS,
  CREATE_AUDIT_RESPONSE_FAIL,

  CREATE_ACTION_REQUEST,
  CREATE_ACTION_SUCCESS,
  CREATE_ACTION_FAIL,

  FIND_ACTION_REQUEST,
  FIND_ACTION_SUCCESS,
  FIND_ACTION_FAIL, 

  UPDATE_ACTION_REQUEST,
  UPDATE_ACTION_SUCCESS,
  UPDATE_ACTION_FAIL,

  CANCEL_ACTION_REQUEST,
  CANCEL_ACTION_SUCCESS,
  CANCEL_ACTION_FAIL,

  FIND_AUDIT_RESPONSE_REQUEST,
  FIND_AUDIT_RESPONSE_SUCCESS,
  FIND_AUDIT_RESPONSE_FAIL,

  FIND_AUDIT_RESPONSE_LIST_REQUEST,
  FIND_AUDIT_RESPONSE_LIST_SUCCESS,
  FIND_AUDIT_RESPONSE_LIST_FAIL,

  FIND_OBSERVATION_RESPONSE_REQUEST,
  FIND_OBSERVATION_RESPONSE_SUCCESS,
  FIND_OBSERVATION_RESPONSE_FAIL,
  
  UPDATE_OBSERVATION_RESPONSE_REQUEST,
  UPDATE_OBSERVATION_RESPONSE_SUCCESS,
  UPDATE_OBSERVATION_RESPONSE_FAIL, 

} from "../../constants_Phase_2/AuditManagement/AuditResponseConstant";

export const auditResponsesReducer = ( state = { auditResponses: [] }, action ) => {
  switch (action.type) {
    case AUDIT_RESPONSE_REQUEST:
    case FIND_AUDIT_RESPONSE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        auditResponses: [],
        status: null,
        error: null,
      };

    case AUDIT_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditResponses: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case FIND_AUDIT_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditResponse: action.payload,
        message: action.message,
        status: action.status,
      };

    case AUDIT_RESPONSE_FAIL:
    case FIND_AUDIT_RESPONSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AUDIT_RESPONSE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const findAuditResponsesReducer = ( state = { auditResponse: [] }, action ) => {
  switch (action.type) {
    case FIND_AUDIT_RESPONSE_REQUEST:
      return {
        auditResponse: [],
        loading: true,
      };

    case FIND_AUDIT_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        auditResponse: action.payload,
      };

    case FIND_AUDIT_RESPONSE_FAIL:
      return {
        ...state,
        loading: false,
        auditResponse: [],
        error: action.payload,
      };

    case "EMPTY_AUDIT_RESPONSE":
      return {
        auditResponse: [],
      };

    case AUDIT_RESPONSE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

export const findAuditResponsesListReducer = ( state = { auditResponseList: [] }, action ) => {
  switch (action.type) {
    case FIND_AUDIT_RESPONSE_LIST_REQUEST:
      case CREATE_AUDIT_RESPONSE_REQUEST:
        case UPDATE_OBSERVATION_RESPONSE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        auditResponseList: [],
        status: null,
        error: null,
      };

    case FIND_AUDIT_RESPONSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        auditResponseList: action.payload,
      };

      case CREATE_AUDIT_RESPONSE_SUCCESS:
        case UPDATE_OBSERVATION_RESPONSE_SUCCESS:
        return {
          ...state,
          loading: false,
          auditResponseList: [],
          error: action.payload,
          message: action.message,
          status: action.status,
        };

    case FIND_AUDIT_RESPONSE_LIST_FAIL:
      case CREATE_AUDIT_RESPONSE_FAIL:
        case UPDATE_OBSERVATION_RESPONSE_FAIL:
      return {
        ...state,
        loading: false,
        auditResponseList: [],
        error: action.payload,
        message: null,
      };

    case "EMPTY_AUDIT_LIST_RESPONSE":
      return {
        auditResponseList: [],
      };

    case AUDIT_RESPONSE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        message:null,
        status: null,
      };

    default:
      return state;
  }
};

// ACTION
export const findAuditActionReducer = (state = { auditAction: [] }, action) => {
  switch (action.type) {
    case AUDIT_ACTION_REQUEST:
    case CREATE_ACTION_REQUEST:
      case UPDATE_ACTION_REQUEST:
        case CANCEL_ACTION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        auditAction: [],
        status: null,
        error: null,
      };

    case AUDIT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        auditAction: action.payload,
      };

      case CREATE_ACTION_SUCCESS:
        case UPDATE_ACTION_SUCCESS:
        case CANCEL_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        auditAction: action.payload,
        message: action.message,
        status: action.status,
      };

    case AUDIT_ACTION_FAIL:
    case CREATE_ACTION_FAIL:
      case UPDATE_ACTION_FAIL:
        case CANCEL_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        auditAction: [],
        error: action.payload,
      };

    case "EMPTY_ACTIONS":
      return {
        auditAction: [],
        error: null,
        status: null,
        message: null,
      };

    case AUDIT_RESPONSE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findActionByIdReducer = ( state = { singleAction: {} }, action ) => {

  switch (action.type) {
    case FIND_ACTION_REQUEST:
      return {
        singleAction: {},
        loading: true,
      };

    case FIND_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleAction: action.payload,
      };

    case FIND_ACTION_FAIL:
      return {
        ...state,
        loading: false,
        singleAction: {},
        error: action.payload,
      };

    case "EMPTY_ACTION":
      return {
        ...state,
        singleAction: {},
      };

    case AUDIT_RESPONSE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};


// find one
export const findObservationResponseReview = ( state = { responseReview: {} }, action ) => {

  switch (action.type) {
    case FIND_OBSERVATION_RESPONSE_REQUEST:
      return {
        responseReview: {},
        loading: true,
      };

    case FIND_OBSERVATION_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        responseReview: action.payload,
      };

    case FIND_OBSERVATION_RESPONSE_FAIL:
      return {
        ...state,
        loading: false,
        responseReview: {},
        error: action.payload,
      };

    case "EMPTY_OBSERVATION_RESPONSE":
      return {
        ...state,
        responseReview: {},
      };

    case AUDIT_RESPONSE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

// Review Observation Response
