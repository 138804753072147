import {
    CREATE_USER_DATA_REQUEST,
    CREATE_USER_DATA_SUCCESS,
    CREATE_USER_DATA_FAIL,
    USER_DATA_REQUEST,
    USER_DATA_SUCCESS,
    USER_DATA_FAIL,
    FIND_USER_DATA_REQUEST,
    FIND_USER_DATA_SUCCESS,
    FIND_USER_DATA_FAIL,
    UPDATE_USER_DATA_REQUEST,
    UPDATE_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_FAIL,
    LOCK_USER_DATA_REQUEST,
    LOCK_USER_DATA_SUCCESS,
    LOCK_USER_DATA_FAIL,
    CHANGE_USER_STATUS_SUCCESS,
    // DELETE_USER_DATA_REQUEST,
    DELETE_USER_DATA_SUCCESS,
    // DELETE_USER_DATA_FAIL,
    USER_DATA_CLEAR_ERROR,
} from "../../constants/User/UserDataConstant";

export const getUsersDataReducer = (state = { usersData: [] }, action) => {
    switch (action.type) {
        case USER_DATA_REQUEST:
        case CREATE_USER_DATA_REQUEST:
        case UPDATE_USER_DATA_REQUEST:
            case  LOCK_USER_DATA_REQUEST:
            //   case DELETE_USER_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
            };

        case USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                usersData: action.payload,
                message: null,
                status: null,
            };

        case CREATE_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                usersData: [...state.usersData, action.payload],
                message: action.message,
                status: action.status,
            };

        case UPDATE_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                usersData: [
                    ...state.usersData.filter(
                        (item) => item.userId !== action.payload.userId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.userId - post.userId),
                message: action.message,
                status: action.status,
            };


            case  LOCK_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                usersData: [
                    ...state.usersData.filter(
                        (item) => item.userId !== action.payload.userId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.userId - post.userId),
                message: action.message,
                status: action.status,
            };

        case CHANGE_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                usersData: [
                    ...state.usersData.filter(
                        (item) => item.username !== action.payload.username
                    ),
                    action.payload,
                ].sort((pre, post) => pre.userId - post.userId),
                message: action.message,
                status: action.status,
            };

        case DELETE_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                usersData: [
                    ...state.usersData.filter(
                        (item) => item.username !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };

        case USER_DATA_FAIL:
            return {
                ...state,
                loading: false,
            };

        case CREATE_USER_DATA_FAIL:
        case UPDATE_USER_DATA_FAIL:
            case LOCK_USER_DATA_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        //   case DELETE_USER_DATA_FAIL:
        //     return {
        //       ...state,
        //       loading: false,
        //       error: action.payload,
        //     };

        case USER_DATA_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findUserDataReducer = (state = { userData: {} }, action) => {
    switch (action.type) {
        case FIND_USER_DATA_REQUEST:
            return {
                userData: {},
                loading: true,
            };

        case FIND_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload,
            };

        case FIND_USER_DATA_FAIL:
            return {
                ...state,
                loading: false,
                userData: {},
                error: action.payload
            };

        case "EMPTY_USER_DATA":
            return {
                ...state,
                userData: {}
            }

        case USER_DATA_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

//Current user find
export const findCurrentUserDataReducer = (state = { currentUserData: {} }, action) => {
    switch (action.type) {
        case "FIND_CURRENT_USER_DATA_REQUEST":
            return {
                currentUserData: {},
                loading: true,
            };

        case "FIND_CURRENT_USER_DATA_SUCCESS":
            return {
                ...state,
                loading: false,
                currentUserData: action.payload,
            };

        case "FIND_CURRENT_USER_DATA_FAIL":
            return {
                ...state,
                loading: false,
                currentUserData: {},
                error: action.payload
            };

        case "EMPTY_CURRENT_USER_DATA":
            return {
                ...state,
                currentUserData: {}
            }

        case USER_DATA_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};