// AGENCY_RRCO_DZONGKHAG create/post
export const AGENCY_RRCO_DZONGKHAG_CREATE_REQUEST = "AGENCY_RRCO_DZONGKHAG_CREATE_REQUEST";
export const AGENCY_RRCO_DZONGKHAG_CREATE_SUCCESS = "AGENCY_RRCO_DZONGKHAG_CREATE_SUCCESS";
export const AGENCY_RRCO_DZONGKHAG_CREATE_FAIL = "AGENCY_RRCO_DZONGKHAG_CREATE_FAIL"

// AGENCY_RRCO_DZONGKHAG get
export const AGENCY_RRCO_DZONGKHAG_REQUEST = "AGENCY_RRCO_DZONGKHAG_REQUEST";
export const AGENCY_RRCO_DZONGKHAG_SUCCESS = "AGENCY_RRCO_DZONGKHAG_SUCCESS";
export const AGENCY_RRCO_DZONGKHAG_FAIL = "AGENCY_RRCO_DZONGKHAG_FAIL"


// AGENCY_RRCO_DZONGKHAG find
export const FIND_AGENCY_RRCO_DZONGKHAG_REQUEST = "FIND_AGENCY_RRCO_DZONGKHAG_REQUEST";
export const FIND_AGENCY_RRCO_DZONGKHAG_SUCCESS = "FIND_AGENCY_RRCO_DZONGKHAG_SUCCESS";
export const FIND_AGENCY_RRCO_DZONGKHAG_FAIL = "FIND_AGENCY_RRCO_DZONGKHAG_FAIL"

// AGENCY_RRCO_DZONGKHAG update/put
export const UPDATE_AGENCY_RRCO_DZONGKHAG_REQUEST = "UPDATE_AGENCY_RRCO_DZONGKHAG_REQUEST";
export const UPDATE_AGENCY_RRCO_DZONGKHAG_SUCCESS = "UPDATE_AGENCY_RRCO_DZONGKHAG_SUCCESS";
export const UPDATE_AGENCY_RRCO_DZONGKHAG_FAIL = "UPDATE_AGENCY_RRCO_DZONGKHAG_FAIL"

// AGENCY_RRCO_DZONGKHAG update/put
export const DELETE_AGENCY_RRCO_DZONGKHAG_REQUEST = "DELETE_AGENCY_RRCO_DZONGKHAG_REQUEST";
export const DELETE_AGENCY_RRCO_DZONGKHAG_SUCCESS = "DELETE_AGENCY_RRCO_DZONGKHAG_SUCCESS";
export const DELETE_AGENCY_RRCO_DZONGKHAG_FAIL = "DELETE_AGENCY_RRCO_DZONGKHAG_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"