import {
  CREATE_CURRENCY_REQUEST,
  CREATE_CURRENCY_SUCCESS,
  CREATE_CURRENCY_FAIL,
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_FAIL,
  FIND_CURRENCY_REQUEST,
  FIND_CURRENCY_SUCCESS,
  FIND_CURRENCY_FAIL,
  UPDATE_CURRENCY_REQUEST,
  UPDATE_CURRENCY_SUCCESS,
  UPDATE_CURRENCY_FAIL,
  DELETE_CURRENCY_REQUEST,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAIL,
  CURRENCY_CLEAR_ERROR,
} from "../../constants/OthersMaster/CurrencyConstant";

// get all data of list
export const getCurrenciesReducer = (state = { currencies: [] }, action) => {
  switch (action.type) {
    case CURRENCY_REQUEST:
    case CREATE_CURRENCY_REQUEST:
    case UPDATE_CURRENCY_REQUEST:
    case DELETE_CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };
    case CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: action.payload,
        message: null,
        status: null,
      };

    case CREATE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: [...state.currencies, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: [
          ...state.currencies.filter(
            (item) => item.currencyId !== action.payload.currencyId
          ),
          action.payload,
        ].sort((pre, post) => pre.currencyId - post.currencyId),
        message: action.message,
        status: action.status,
      };

    case DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currencies: [
          ...state.currencies.filter(
            (item) => item.currencyId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_CURRENCY_SUCCESS":
      return {
        loading: false,
        currencies: [
          ...state.currencies.filter(
            (item) => item.currencyId !== action.payload.currencyId
          ),
          action.payload,
        ].sort((pre, post) => pre.currencyId - post.currencyId),
        message: action.message,
        status: action.status,
      };

    case CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        currencies: [...state.currencies],
        error: action.payload,
      };

    case CREATE_CURRENCY_FAIL:
    case UPDATE_CURRENCY_FAIL:
    case DELETE_CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        currencies: [...state.currencies],
        message: action.message,
        status: action.status,
      };

    case CURRENCY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findCurrencyReducer = (state = { currency: {} }, action) => {
  switch (action.type) {
    case FIND_CURRENCY_REQUEST:
      return {
        currency: {},
        loading: true,
      };

    case FIND_CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        currency: action.payload,
      };

    case FIND_CURRENCY_FAIL:
      return {
        ...state,
        loading: false,
        currency: {},
        error: action.payload,
      };

    case "EMPTY_CURRENCY":
      return {
        ...state,
        currency: {},
      };

    case CURRENCY_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
