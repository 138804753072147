import {
  AGENCY_RRCO_DZONGKHAG_REQUEST,
  AGENCY_RRCO_DZONGKHAG_SUCCESS,
  AGENCY_RRCO_DZONGKHAG_FAIL,
  AGENCY_RRCO_DZONGKHAG_CREATE_REQUEST,
  AGENCY_RRCO_DZONGKHAG_CREATE_SUCCESS,
  AGENCY_RRCO_DZONGKHAG_CREATE_FAIL,
  FIND_AGENCY_RRCO_DZONGKHAG_REQUEST,
  FIND_AGENCY_RRCO_DZONGKHAG_SUCCESS,
  FIND_AGENCY_RRCO_DZONGKHAG_FAIL,
  UPDATE_AGENCY_RRCO_DZONGKHAG_REQUEST,
  UPDATE_AGENCY_RRCO_DZONGKHAG_SUCCESS,
  UPDATE_AGENCY_RRCO_DZONGKHAG_FAIL,
  DELETE_AGENCY_RRCO_DZONGKHAG_REQUEST,
  DELETE_AGENCY_RRCO_DZONGKHAG_SUCCESS,
  DELETE_AGENCY_RRCO_DZONGKHAG_FAIL,
  CLEAR_ERROR,
} from "../../constants/AgencyMaintenance/AgencyRrcoDzongkhagConstant";

// create, update, delete and get all
export const getAgencyRrcoDzongkhagReducer = (state = { agencyRrcoDzongkhags: [] }, action) => {
  switch (action.type) {
    case AGENCY_RRCO_DZONGKHAG_REQUEST:
    case AGENCY_RRCO_DZONGKHAG_CREATE_REQUEST:
    case UPDATE_AGENCY_RRCO_DZONGKHAG_REQUEST:
    case DELETE_AGENCY_RRCO_DZONGKHAG_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error:null

      };
    case AGENCY_RRCO_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: action.payload,
        message: null,
        status: null,
      };

    case AGENCY_RRCO_DZONGKHAG_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: [...state.agencyRrcoDzongkhags, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_AGENCY_RRCO_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: [
          ...state.agencyRrcoDzongkhags.filter(
            (item) => item.agencyId !== action.payload.agencyId
          ),
          action.payload,
        ].sort((pre, post) => pre.agencyId - post.agencyId),
        message: action.message,
        status: action.status,
      };

    case DELETE_AGENCY_RRCO_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: [
          ...state.agencyRrcoDzongkhags.filter((item) => item.agencyId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case AGENCY_RRCO_DZONGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: [...state.agencyRrcoDzongkhags],
        error: action.payload,
      };

    case AGENCY_RRCO_DZONGKHAG_CREATE_FAIL:
    case UPDATE_AGENCY_RRCO_DZONGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: [...state.agencyRrcoDzongkhags],
        message: action.message,
        status: action.status,
      };
      
    case DELETE_AGENCY_RRCO_DZONGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhags: [...state.agencyRrcoDzongkhags],
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findAgencyRrcoDzongkhagReducer = (state = { agencyRrcoDzongkhag: {} }, action) => {
  switch (action.type) {
    case FIND_AGENCY_RRCO_DZONGKHAG_REQUEST:
      return {
        loading: true,
        agencyRrcoDzongkhag: {},
      };
    case FIND_AGENCY_RRCO_DZONGKHAG_SUCCESS:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhag: action.payload,
      };

    case FIND_AGENCY_RRCO_DZONGKHAG_FAIL:
      return {
        ...state,
        loading: false,
        agencyRrcoDzongkhag: {},
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};