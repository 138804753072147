import {
    CREATE_WITHDRAWAL_DEPOSIT_REQUEST,
    CREATE_WITHDRAWAL_DEPOSIT_SUCCESS,
    CREATE_WITHDRAWAL_DEPOSIT_FAIL,
    WITHDRAWAL_DEPOSIT_REQUEST,
    WITHDRAWAL_DEPOSIT_SUCCESS,
    WITHDRAWAL_DEPOSIT_FAIL,
    FIND_WITHDRAWAL_DEPOSIT_REQUEST,
    FIND_WITHDRAWAL_DEPOSIT_SUCCESS,
    FIND_WITHDRAWAL_DEPOSIT_FAIL,
    UPDATE_WITHDRAWAL_DEPOSIT_REQUEST,
    UPDATE_WITHDRAWAL_DEPOSIT_SUCCESS,
    UPDATE_WITHDRAWAL_DEPOSIT_FAIL,
    CLEAR_WITHDRAWAL_DEPOSIT_ERROR,
  } from "../../constants_Phase_2/WithdrawalDeposit/WithdrawalDepositConstant";
  
  // create
  export const withdrawalDepositListReducer = (state = { withdrawalDepositList: [] }, action) => {
    switch (action.type) {
      case  WITHDRAWAL_DEPOSIT_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          withdrawalDepositList: [],
          status: null,
          error: null,
        };

        case CREATE_WITHDRAWAL_DEPOSIT_REQUEST:
          case UPDATE_WITHDRAWAL_DEPOSIT_REQUEST:
          return {
            ...state,
            loading: true,
            message: null,
            status: null,
            error: null,
          };


      case WITHDRAWAL_DEPOSIT_SUCCESS:
        return {
          ...state,
          loading: false,
          withdrawalDepositList: action.payload,
          totalData : action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };


      case  CREATE_WITHDRAWAL_DEPOSIT_SUCCESS:
        case  UPDATE_WITHDRAWAL_DEPOSIT_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
          responseId: action.responseId
        };
  
      case WITHDRAWAL_DEPOSIT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_WITHDRAWAL_DEPOSIT_FAIL:
        case UPDATE_WITHDRAWAL_DEPOSIT_FAIL:
        return {
          ...state,
          loading: false,
          withdrawalDepositList: [...state.withdrawalDepositList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_WITHDRAWAL_DEPOSIT_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
export const findWithdrawalDepositReducer = (state = { withdrawalDeposit: {} }, action) => {
    switch (action.type) {
        case FIND_WITHDRAWAL_DEPOSIT_REQUEST:
            return {
                withdrawalDeposit: {},
                loading: true,
            };

        case FIND_WITHDRAWAL_DEPOSIT_SUCCESS:
            return {
                ...state,
                loading: false,
                withdrawalDeposit: action.payload,
            };

        case FIND_WITHDRAWAL_DEPOSIT_FAIL:
            return {
                ...state,
                loading: false,
                withdrawalDeposit: {},
                error: action.payload
            };

        case "EMPTY_WITHDRAWAL_DEPOSIT":
            return {
                ...state,
                withdrawalDeposit: {}
            }

        case CLEAR_WITHDRAWAL_DEPOSIT_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

    