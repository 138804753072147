// MODE_OF_PAYMENT create
export const CREATE_MODE_OF_PAYMENT_REQUEST = "CREATE_MODE_OF_PAYMENT_REQUEST";
export const CREATE_MODE_OF_PAYMENT_SUCCESS = "CREATE_MODE_OF_PAYMENT_SUCCESS";
export const CREATE_MODE_OF_PAYMENT_FAIL = "CREATE_MODE_OF_PAYMENT_FAIL"

// MODE_OF_PAYMENT get
export const MODE_OF_PAYMENT_REQUEST = "MODE_OF_PAYMENT_REQUEST";
export const MODE_OF_PAYMENT_SUCCESS = "MODE_OF_PAYMENT_SUCCESS";
export const MODE_OF_PAYMENT_FAIL = "MODE_OF_PAYMENT_FAIL"


// MODE_OF_PAYMENT find
export const FIND_MODE_OF_PAYMENT_REQUEST = "FIND_MODE_OF_PAYMENT_REQUEST";
export const FIND_MODE_OF_PAYMENT_SUCCESS = "FIND_MODE_OF_PAYMENT_SUCCESS";
export const FIND_MODE_OF_PAYMENT_FAIL = "FIND_MODE_OF_PAYMENT_FAIL"

//MODE_OF_PAYMENT update
// update/put
export const MODE_OF_PAYMENT_UPDATE_REQUEST = "MODE_OF_PAYMENT_UPDATE_REQUEST";
export const MODE_OF_PAYMENT_UPDATE_SUCCESS = "MODE_OF_PAYMENT_UPDATE_SUCCESS";
export const MODE_OF_PAYMENT_UPDATE_FAIL = "MODE_OF_PAYMENT_UPDATE_FAIL"

// MODE_OF_PAYMENT delete
export const MODE_OF_PAYMENT_DELETE_REQUEST = "MODE_OF_PAYMENT_DELETE_REQUEST";
export const MODE_OF_PAYMENT_DELETE_SUCCESS = "MODE_OF_PAYMENT_DELETE_SUCCESS";
export const MODE_OF_PAYMENT_DELETE_FAIL = "MODE_OF_PAYMENT_DELETE_FAIL"


export const MODE_OF_PAYMENT_CLEAR_ERROR = "MODE_OF_PAYMENT_CLEAR_ERROR"
