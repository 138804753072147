import {
    CREATE_COA_PREPAYMENT_MAP_REQUEST,
    CREATE_COA_PREPAYMENT_MAP_SUCCESS,
    CREATE_COA_PREPAYMENT_MAP_FAIL,

    COA_PREPAYMENT_MAP_REQUEST,
    COA_PREPAYMENT_MAP_SUCCESS,
    COA_PREPAYMENT_MAP_FAIL,

    FIND_COA_PREPAYMENT_MAP_REQUEST,
    FIND_COA_PREPAYMENT_MAP_SUCCESS,
    FIND_COA_PREPAYMENT_MAP_FAIL,

    UPDATE_COA_PREPAYMENT_MAP_REQUEST,
    UPDATE_COA_PREPAYMENT_MAP_SUCCESS,
    UPDATE_COA_PREPAYMENT_MAP_FAIL,

    DELETE_COA_PREPAYMENT_MAP_REQUEST,
    DELETE_COA_PREPAYMENT_MAP_SUCCESS,
    DELETE_COA_PREPAYMENT_MAP_FAIL,

    COA_PREPAYMENT_MAP_CLEAR_ERROR,
  } from "../../constants/CoaMapping/CoaPrepaymentMapConstant";
  
  export const getCoaPrepaymentMapReducer = (state = { coaPrepaymentMaps: [] }, action) => {
    switch (action.type) {
      case COA_PREPAYMENT_MAP_REQUEST:
      case CREATE_COA_PREPAYMENT_MAP_REQUEST:
      case UPDATE_COA_PREPAYMENT_MAP_REQUEST:
      case DELETE_COA_PREPAYMENT_MAP_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          status: null,
        };
  
      case COA_PREPAYMENT_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaPrepaymentMaps: action.payload,
          message: null,
          status: null,
        };
  
      case CREATE_COA_PREPAYMENT_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaPrepaymentMaps: [...state.coaPrepaymentMaps, action.payload],
          message: action.message,
          status: action.status,
        };
  
      case UPDATE_COA_PREPAYMENT_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaPrepaymentMaps: [
            ...state.coaPrepaymentMaps.filter(
              (item) => item.prepaymentTypeMappingId !== action.payload.prepaymentTypeMappingId
            ),
            action.payload,
          ].sort((pre, post) => pre.prepaymentTypeMappingId - post.prepaymentTypeMappingId),
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_PREPAYMENT_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaPrepaymentMaps: [
            ...state.coaPrepaymentMaps.filter(
              (item) => item.prepaymentTypeMappingId !== action.payload
            ),
          ],
          message: action.message,
          status: action.status,
        };
  
      case COA_PREPAYMENT_MAP_FAIL:
        return {
          ...state,
          loading: false,
        };
  
      case CREATE_COA_PREPAYMENT_MAP_FAIL:
      case UPDATE_COA_PREPAYMENT_MAP_FAIL:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case DELETE_COA_PREPAYMENT_MAP_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case COA_PREPAYMENT_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };
  
  // find one
  export const findCoaPrepaymentMapReducer = (state = { coaPrepaymentMap: {} }, action) => {
    switch (action.type) {
      case FIND_COA_PREPAYMENT_MAP_REQUEST:
        return {
          coaPrepaymentMap: {},
          loading: true,
        };
  
      case FIND_COA_PREPAYMENT_MAP_SUCCESS:
        return {
          ...state,
          loading: false,
          coaPrepaymentMap: action.payload,
        };
  
      case FIND_COA_PREPAYMENT_MAP_FAIL:
        return {
          ...state,
          loading: false,
          coaPrepaymentMap: {},
          error: action.payload
        };
  
      case "EMPTY_COA_PREPAYMENT_MAP":
        return {
          ...state,
          coaPrepaymentMap: {}
        }
  
      case COA_PREPAYMENT_MAP_CLEAR_ERROR:
        return {
          ...state,
          error: null,
          status: null,
        };
  
      default:
        return state;
    }
  };
  