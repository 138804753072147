
export const CREATE_REVISE_FORECAST_REQUEST = "CREATE_REVISE_FORECAST_REQUEST";
export const CREATE_REVISE_FORECAST_SUCCESS = "CREATE_REVISE_FORECAST_SUCCESS";
export const CREATE_REVISE_FORECAST_FAIL = "CREATE_REVISE_FORECAST_FAIL";

export const REVISE_FORECAST_REQUEST = "REVISE_FORECAST_REQUEST";
export const REVISE_FORECAST_SUCCESS = "REVISE_FORECAST_SUCCESS";
export const REVISE_FORECAST_FAIL = "REVISE_FORECAST_FAIL";


export const FIND_REVISE_FORECAST_REQUEST = "FIND_REVISE_FORECAST_REQUEST";
export const FIND_REVISE_FORECAST_SUCCESS = "FIND_REVISE_FORECAST_SUCCESS";
export const FIND_REVISE_FORECAST_FAIL = "FIND_REVISE_FORECAST_FAIL";


export const UPDATE_REVISE_FORECAST_REQUEST = "UPDATE_REVISE_FORECAST_REQUEST";
export const UPDATE_REVISE_FORECAST_SUCCESS = "UPDATE_REVISE_FORECAST_SUCCESS";
export const UPDATE_REVISE_FORECAST_FAIL = "UPDATE_REVISE_FORECAST_FAIL";



export const CLEAR_REVISE_FORECAST_ERROR = "CLEAR_REVISE_FORECAST_ERROR";