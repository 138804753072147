// create/post
export const CREATE_RISK_PARAMETER_VALUE_REQUEST = "CREATE_RISK_PARAMETER_VALUE_REQUEST";
export const CREATE_RISK_PARAMETER_VALUE_SUCCESS = "CREATE_RISK_PARAMETER_VALUE_SUCCESS";
export const CREATE_RISK_PARAMETER_VALUE_FAIL = "CREATE_RISK_PARAMETER_VALUE_FAIL";

// get
export const RISK_PARAMETER_VALUE_REQUEST = "RISK_PARAMETER_VALUE_REQUEST";
export const RISK_PARAMETER_VALUE_SUCCESS = "RISK_PARAMETER_VALUE_SUCCESS";
export const RISK_PARAMETER_VALUE_FAIL = "RISK_PARAMETER_VALUE_FAIL";

// find
export const FIND_RISK_PARAMETER_VALUE_REQUEST = "FIND_RISK_PARAMETER_VALUE_REQUEST";
export const FIND_RISK_PARAMETER_VALUE_SUCCESS = "FIND_RISK_PARAMETER_VALUE_SUCCESS";
export const FIND_RISK_PARAMETER_VALUE_FAIL = "FIND_RISK_PARAMETER_VALUE_FAIL";

// update/put
export const UPDATE_RISK_PARAMETER_VALUE_REQUEST = "UPDATE_RISK_PARAMETER_VALUE_REQUEST";
export const UPDATE_RISK_PARAMETER_VALUE_SUCCESS = "UPDATE_RISK_PARAMETER_VALUE_SUCCESS";
export const UPDATE_RISK_PARAMETER_VALUE_FAIL = "UPDATE_RISK_PARAMETER_VALUE_FAIL";

// delete
export const DELETE_RISK_PARAMETER_VALUE_REQUEST = "DELETE_RISK_PARAMETER_VALUE_REQUEST";
export const DELETE_RISK_PARAMETER_VALUE_SUCCESS = "DELETE_RISK_PARAMETER_VALUE_SUCCESS";
export const DELETE_RISK_PARAMETER_VALUE_FAIL = "DELETE_RISK_PARAMETER_VALUE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";