import {
  CREATE_USER_ROLE_REQUEST,
  CREATE_USER_ROLE_SUCCESS,
  CREATE_USER_ROLE_FAIL,
  USER_ROLE_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_FAIL,
  FIND_USER_ROLE_REQUEST,
  FIND_USER_ROLE_SUCCESS,
  FIND_USER_ROLE_FAIL,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAIL,
  // DELETE_USER_ROLE_REQUEST,
  // DELETE_USER_ROLE_SUCCESS,
  // DELETE_USER_ROLE_FAIL,
  FIND_USER_ROLE_ASSIGN_MENU_REQUEST,
  FIND_USER_ROLE_ASSIGN_MENU_SUCCESS,
  FIND_USER_ROLE_ASSIGN_MENU_FAIL,

  USER_ROLE_CLEAR_ERROR,
} from "../../constants/User/UserRoleConstant";

export const getUserRoleReducer = (state = { userRoles: [] }, action) => {
  switch (action.type) {
    case USER_ROLE_REQUEST:
    case CREATE_USER_ROLE_REQUEST:
    case UPDATE_USER_ROLE_REQUEST:
      //   case DELETE_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoles: action.payload,
        message: null,
        status: null,
      };

    case CREATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoles: [...state.userRoles, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoles: [
          ...state.userRoles.filter(
            (item) => item.roleId !== action.payload.roleId
          ),
          action.payload,
        ].sort((pre, post) => pre.roleId - post.roleId),
        message: action.message,
        status: action.status,
      };

    //   case DELETE_USER_ROLE_SUCCESS:
    //     return {
    //       ...state,
    //       loading: false,
    //       userRoles: [
    //         ...state.userRoles.filter(
    //           (item) => item.roleId !== action.payload
    //         ),
    //       ],
    //       message: action.message,
    //       status: action.status,
    //     };

    case USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_USER_ROLE_FAIL:
    case UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    //   case DELETE_USER_ROLE_FAIL:
    //     return {
    //       ...state,
    //       loading: false,
    //       error: action.payload,
    //     };

    case USER_ROLE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findUserRoleReducer = (state = { userRole: {} }, action) => {
  switch (action.type) {
    case FIND_USER_ROLE_REQUEST:
      return {
        userRole: {},
        loading: true,
      };

    case FIND_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userRole: action.payload,
      };

    case FIND_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        userRole: {},
        error: action.payload,
      };

    case "EMPTY_USER_ROLE":
      return {
        ...state,
        userRole: {},
      };

    case USER_ROLE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};

// Find all Role Main Menu List
export const finRoleAssignMenuReducer = (
  state = { assignMenuList: [] },
  action
) => {
  switch (action.type) {
    case FIND_USER_ROLE_ASSIGN_MENU_REQUEST:
      return {
        assignMenuList: [],
        loading: true,
      };

    case FIND_USER_ROLE_ASSIGN_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        assignMenuList: action.payload,
      };

    case FIND_USER_ROLE_ASSIGN_MENU_FAIL:
      return {
        ...state,
        loading: false,
        assignMenuList: [],
        error: action.payload,
      };

    case "EMPTY_ROLE_MAIN_MENU_LIST":
      return {
        ...state,
        assignMenuList: [],
      };

    case USER_ROLE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
