import {
    CHEQUE_BOUNCE_LIST_REQUEST,
    CHEQUE_BOUNCE_LIST_SUCCESS,
    CHEQUE_BOUNCE_LIST_FAIL,

    CHEQUE_BOUNCE_LIST_CLEAR_ERROR,
} from "../../constants/ChequeBounce/ChequeBounceConstant";

export const getChequeBounceListReducer = (state = { chequeBounceList: [] }, action) => {
    switch (action.type) {
        case CHEQUE_BOUNCE_LIST_REQUEST:
            return {
                ...state,
                chequeBounceList: [],
                loading: true,
                status: null,
                message: null,
                error: null
            };

        case CHEQUE_BOUNCE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                chequeBounceList: action.payload,
                totalData: action.totalData,
                message: null,
                status: null,
            };

        case CHEQUE_BOUNCE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CHEQUE_BOUNCE_LIST_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};