// create
export const CREATE_DEPOSIT_SLIP_REQUEST = "CREATE_DEPOSIT_SLIP_REQUEST";
export const CREATE_DEPOSIT_SLIP_SUCCESS = "CREATE_DEPOSIT_SLIP_SUCCESS";
export const CREATE_DEPOSIT_SLIP_FAIL = "CREATE_DEPOSIT_SLIP_FAIL";

// get
export const DEPOSIT_SLIP_REQUEST = "DEPOSIT_SLIP_REQUEST";
export const DEPOSIT_SLIP_SUCCESS = "DEPOSIT_SLIP_SUCCESS";
export const DEPOSIT_SLIP_FAIL = "DEPOSIT_SLIP_FAIL";

//get deposit receipts
export const DEPOSIT_RECEIPTS_REQUEST = "DEPOSIT_RECEIPTS_REQUEST";
export const DEPOSIT_RECEIPTS_SUCCESS = "DEPOSIT_RECEIPTS_SUCCESS";
export const DEPOSIT_RECEIPTS_FAIL = "DEPOSIT_RECEIPTS_FAIL";

//all user
export const DEPOSIT_SLIP_ALL_USERS_REQUEST = "DEPOSIT_SLIP_ALL_USERS_REQUEST";
export const DEPOSIT_SLIP_ALL_USERS_SUCCESS = "DEPOSIT_SLIP_ALL_USERS_SUCCESS";
export const DEPOSIT_SLIP_ALL_USERS_FAIL = "DEPOSIT_SLIP_ALL_USERS_FAIL";

export const DEPOSIT_RECEIPTS_CLEAR_ERROR = "DEPOSIT_RECEIPTS_CLEAR_ERROR";
export const DEPOSIT_SLIP_CLEAR_ERROR = "DEPOSIT_SLIP_CLEAR_ERROR";