import {
  RISK_PROFILE_REQUEST,
  RISK_PROFILE_SUCCESS,
  RISK_PROFILE_FAIL,

  CREATE_RISK_PROFILE_REQUEST,
  CREATE_RISK_PROFILE_SUCCESS,
  CREATE_RISK_PROFILE_FAIL,

  FIND_RISK_PROFILE_REQUEST,
  FIND_RISK_PROFILE_SUCCESS,
  FIND_RISK_PROFILE_FAIL,

  UPDATE_RISK_PROFILE_REQUEST,
  UPDATE_RISK_PROFILE_SUCCESS,
  UPDATE_RISK_PROFILE_FAIL,

  DELETE_RISK_PROFILE_REQUEST,
  DELETE_RISK_PROFILE_SUCCESS,
  DELETE_RISK_PROFILE_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskProfileConstant";

// create, update, delete and get all
export const getRiskProfilesReducer = (state = { riskProfiles: [] }, action) => {
  switch (action.type) {
    case RISK_PROFILE_REQUEST:
    case CREATE_RISK_PROFILE_REQUEST:
    case UPDATE_RISK_PROFILE_REQUEST:
    case DELETE_RISK_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfiles: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfiles: [...state.riskProfiles, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfiles: state.riskProfiles.map((item) =>
          (item.riskProfileId === action.payload.riskProfileId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfiles: action?.payload?.isDelete ?
          state.riskProfiles.filter((item) =>
            item.riskProfileId !== action.payload.riskProfileId
          )
          :
          state.riskProfiles.map((item) =>
            (item.riskProfileId === action.payload.riskProfileId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_PROFILE_FAIL:
    case UPDATE_RISK_PROFILE_FAIL:
    case DELETE_RISK_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskProfileReducer = (state = { riskProfile: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_PROFILE_REQUEST:
      return {
        loading: true,
        riskProfile: {},
      };

    case FIND_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskProfile: action.payload,
      };

    case FIND_RISK_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        riskProfile: {},
        error: action.payload,
      };

    case "EMPTY_RISK_PROFILE":
      return {
        riskProfile: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};