
export const CREATE_ADVANCE_ADJUSTMENT_REQUEST = "CREATE_ADVANCE_ADJUSTMENT_REQUEST";
export const CREATE_ADVANCE_ADJUSTMENT_SUCCESS = "CREATE_ADVANCE_ADJUSTMENT_SUCCESS";
export const CREATE_ADVANCE_ADJUSTMENT_FAIL = "CREATE_ADVANCE_ADJUSTMENT_FAIL";

export const ADVANCE_ADJUSTMENT_REQUEST = "ADVANCE_ADJUSTMENT_REQUEST";
export const ADVANCE_ADJUSTMENT_SUCCESS = "ADVANCE_ADJUSTMENT_SUCCESS";
export const ADVANCE_ADJUSTMENT_FAIL = "ADVANCE_ADJUSTMENT_FAIL";


export const FIND_ADVANCE_ADJUSTMENT_REQUEST = "FIND_ADVANCE_ADJUSTMENT_REQUEST";
export const FIND_ADVANCE_ADJUSTMENT_SUCCESS = "FIND_ADVANCE_ADJUSTMENT_SUCCESS";
export const FIND_ADVANCE_ADJUSTMENT_FAIL = "FIND_ADVANCE_ADJUSTMENT_FAIL";


export const UPDATE_ADVANCE_ADJUSTMENT_REQUEST = "UPDATE_ADVANCE_ADJUSTMENT_REQUEST";
export const UPDATE_ADVANCE_ADJUSTMENT_SUCCESS = "UPDATE_ADVANCE_ADJUSTMENT_SUCCESS";
export const UPDATE_ADVANCE_ADJUSTMENT_FAIL = "UPDATE_ADVANCE_ADJUSTMENT_FAIL";



export const CLEAR_ADVANCE_ADJUSTMENT_ERROR = "CLEAR_ADVANCE_ADJUSTMENT_ERROR";