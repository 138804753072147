
export const CREATE_REFUND_APPLICATION_REQUEST = "CREATE_REFUND_APPLICATION_REQUEST";
export const CREATE_REFUND_APPLICATION_SUCCESS = "CREATE_REFUND_APPLICATION_SUCCESS";
export const CREATE_REFUND_APPLICATION_FAIL = "CREATE_REFUND_APPLICATION_FAIL";

export const REFUND_APPLICATION_REQUEST = "REFUND_APPLICATION_REQUEST";
export const REFUND_APPLICATION_SUCCESS = "REFUND_APPLICATION_SUCCESS";
export const REFUND_APPLICATION_FAIL = "REFUND_APPLICATION_FAIL";


export const FIND_REFUND_APPLICATION_REQUEST = "FIND_REFUND_APPLICATION_REQUEST";
export const FIND_REFUND_APPLICATION_SUCCESS = "FIND_REFUND_APPLICATION_SUCCESS";
export const FIND_REFUND_APPLICATION_FAIL = "FIND_REFUND_APPLICATION_FAIL";


export const UPDATE_REFUND_APPLICATION_REQUEST = "UPDATE_REFUND_APPLICATION_REQUEST";
export const UPDATE_REFUND_APPLICATION_SUCCESS = "UPDATE_REFUND_APPLICATION_SUCCESS";
export const UPDATE_REFUND_APPLICATION_FAIL = "UPDATE_REFUND_APPLICATION_FAIL";



export const CLEAR_REFUND_APPLICATION_ERROR = "CLEAR_REFUND_APPLICATION_ERROR";