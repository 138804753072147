import {
    CID_REQUEST,
    CID_SUCCESS,
    CID_FAIL,

    PAYMENT_CID_REQUEST,
    PAYMENT_CID_SUCCESS,
    PAYMENT_CID_FAIL,

    WORK_PERMIT_REQUEST,
    WORK_PERMIT_SUCCESS,
    WORK_PERMIT_FAIL,

    LC_PLC_REQUEST,
    LC_PLC_SUCCESS,
    LC_PLC_FAIL,

    PAYMENT_TPN_REQUEST,
    PAYMENT_TPN_SUCCESS,
    PAYMENT_TPN_FAIL,

    THIRD_PARTY_CLEAR_ERROR
} from "../../constants/ThirdParty/ThirdPartyConstant";

// find CidDetail
export const findCidDetailReducer = (state = { cidDetail: {} }, action) => {
    switch (action.type) {
        case CID_REQUEST:
            return {
                cidDetail: {},
                loading: true,
            };

        case CID_SUCCESS:
            return {
                ...state,
                loading: false,
                cidDetail: action.payload,
            };

        case CID_FAIL:
            return {
                ...state,
                loading: false,
                cidDetail: {},
                error: action.payload,
            };

        case "EMPTY_THIRD_PARTY":
            return {
                cidDetail: {}
            }

        case THIRD_PARTY_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find CidDetail
export const findPaymentCidDetailReducer = (state = { paymentCidDetail: {} }, action) => {
    switch (action.type) {
        case PAYMENT_CID_REQUEST:
            return {
                paymentCidDetail: {},
                loading: true,
            };

        case PAYMENT_CID_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentCidDetail: action.payload,
            };

        case PAYMENT_CID_FAIL:
            return {
                ...state,
                loading: false,
                paymentCidDetail: {},
                error: action.payload,
            };

        case "EMPTY_THIRD_PARTY":
            return {
                paymentCidDetail: {}
            }

        case THIRD_PARTY_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find workPermitDetail
export const findWorkPermitDetailReducer = (state = { workPermitDetail: {} }, action) => {
    switch (action.type) {
        case WORK_PERMIT_REQUEST:
            return {
                workPermitDetail: {},
                loading: true,
            };

        case WORK_PERMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                workPermitDetail: action.payload,
            };

        case WORK_PERMIT_FAIL:
            return {
                ...state,
                loading: false,
                workPermitDetail: {},
                error: action.payload,
            };

        case "EMPTY_THIRD_PARTY":
            return {
                workPermitDetail: {}
            }

        case THIRD_PARTY_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find lcPlcDetail
export const findLcPlcDetailReducer = (state = { lcPlcDetail: {} }, action) => {
    switch (action.type) {
        case LC_PLC_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case LC_PLC_SUCCESS:
            return {
                ...state,
                loading: false,
                lcPlcDetail: action.payload,
            };

        case LC_PLC_FAIL:
            return {
                ...state,
                loading: false,
                lcPlcDetail: {},
                error: action.payload,
            };

        case "EMPTY_THIRD_PARTY":
            return {
                lcPlcDetail: {}
            }

        case THIRD_PARTY_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find tpnDetail
export const findPaymentTpnDetailReducer = (state = { paymentTpnDetail: {} }, action) => {
    switch (action.type) {
        case PAYMENT_TPN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PAYMENT_TPN_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentTpnDetail: action.payload,
            };

        case PAYMENT_TPN_FAIL:
            return {
                ...state,
                loading: false,
                paymentTpnDetail: {},
                error: action.payload,
            };

        case "EMPTY_THIRD_PARTY":
            return {
                paymentTpnDetail: {}
            }

        case THIRD_PARTY_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};