import {
  FIND_REPRINT_REQUEST,
  FIND_REPRINT_SUCCESS,
  FIND_REPRINT_FAIL,

  REPRINT_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/ReprintConstant";

export const findReprintReducer = (state = { receiptReprint: {} }, action) => {
  switch (action.type) {
    case FIND_REPRINT_REQUEST:
      return {
        loading: true,
        receiptReprint: {},
      };
    case FIND_REPRINT_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptReprint: action.payload,
      };

    case FIND_REPRINT_FAIL:
      return {
        ...state,
        loading: false,
        receiptReprint: {},
        error: action.payload,
      };
    case "EMPTY_REPRINT":
      return {
        ...state,
        receiptReprint: {},
      };

    case REPRINT_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};