import {
    CREATE_ADVANCE_ADJUSTMENT_REQUEST,
    CREATE_ADVANCE_ADJUSTMENT_SUCCESS,
    CREATE_ADVANCE_ADJUSTMENT_FAIL,
    ADVANCE_ADJUSTMENT_REQUEST,
    ADVANCE_ADJUSTMENT_SUCCESS,
    ADVANCE_ADJUSTMENT_FAIL,
    FIND_ADVANCE_ADJUSTMENT_REQUEST,
    FIND_ADVANCE_ADJUSTMENT_SUCCESS,
    FIND_ADVANCE_ADJUSTMENT_FAIL,
    UPDATE_ADVANCE_ADJUSTMENT_REQUEST,
    UPDATE_ADVANCE_ADJUSTMENT_SUCCESS,
    UPDATE_ADVANCE_ADJUSTMENT_FAIL,
    CLEAR_ADVANCE_ADJUSTMENT_ERROR,
  } from "../../constants_Phase_2/AdvanceAdjustment/AdvanceAdjustmentConstant";
  
  // create
  export const advanceAdjustmentReducer = (state = { advanceAdjustmentList: [] }, action) => {
    switch (action.type) {
      case  ADVANCE_ADJUSTMENT_REQUEST:
        return {
          ...state,
          loading: true,
          message: null,
          advanceAdjustmentList: [],
          status: null,
          error: null,
        };

        case CREATE_ADVANCE_ADJUSTMENT_REQUEST:
          case UPDATE_ADVANCE_ADJUSTMENT_REQUEST:
          return {
            ...state,
            loading: true,
            message: null,
            status: null,
            error: null,
          };


      case ADVANCE_ADJUSTMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          advanceAdjustmentList: action.payload,
          totalData : action.totalData || 0,
          currentPage: action.currentPage,
          message: action.message,
          status: action.status,
        };


      case  CREATE_ADVANCE_ADJUSTMENT_SUCCESS:
        case  UPDATE_ADVANCE_ADJUSTMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          message: action.message,
          status: action.status,
        };
  
      case ADVANCE_ADJUSTMENT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CREATE_ADVANCE_ADJUSTMENT_FAIL:
        case UPDATE_ADVANCE_ADJUSTMENT_FAIL:
        return {
          ...state,
          loading: false,
          advanceAdjustmentList: [...state.advanceAdjustmentList],
          message: action.message,
          status: action.status,
        };
  
      case CLEAR_ADVANCE_ADJUSTMENT_ERROR:
        return {
          ...state,
          error: null,
          status: null,
          message: null,
        };
  
      default:
        return state;
    }
  };

  // find one
export const findAdvanceAdjustmentReducer = (state = { advanceAdjustment: {} }, action) => {
    switch (action.type) {
        case FIND_ADVANCE_ADJUSTMENT_REQUEST:
            return {
                advanceAdjustment: {},
                loading: true,
            };

        case FIND_ADVANCE_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                advanceAdjustment: action.payload,
            };

        case FIND_ADVANCE_ADJUSTMENT_FAIL:
            return {
                ...state,
                loading: false,
                advanceAdjustment: {},
                error: action.payload
            };

        case "EMPTY_ADVANCE_ADJUSTMENT":
            return {
                ...state,
                advanceAdjustment: {}
            }

        case CLEAR_ADVANCE_ADJUSTMENT_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

    