import {
  CREATE_COA_RAMIS_MAP_REQUEST,
  CREATE_COA_RAMIS_MAP_SUCCESS,
  CREATE_COA_RAMIS_MAP_FAIL,

  COA_RAMIS_MAP_REQUEST,
  COA_RAMIS_MAP_SUCCESS,
  COA_RAMIS_MAP_FAIL,

  FIND_COA_RAMIS_MAP_REQUEST,
  FIND_COA_RAMIS_MAP_SUCCESS,
  FIND_COA_RAMIS_MAP_FAIL,

  UPDATE_COA_RAMIS_MAP_REQUEST,
  UPDATE_COA_RAMIS_MAP_SUCCESS,
  UPDATE_COA_RAMIS_MAP_FAIL,

  DELETE_COA_RAMIS_MAP_REQUEST,
  DELETE_COA_RAMIS_MAP_SUCCESS,
  DELETE_COA_RAMIS_MAP_FAIL,

  COA_RAMIS_MAP_CLEAR_ERROR,
} from "../../constants/CoaMapping/CoaRamisMapConstant";

// create, update, delete and get all
export const getCoaRamisMapReducer = (state = { coaRamisMaps: [] }, action) => {
  switch (action.type) {
    case COA_RAMIS_MAP_REQUEST:
    case CREATE_COA_RAMIS_MAP_REQUEST:
    case UPDATE_COA_RAMIS_MAP_REQUEST:
    case DELETE_COA_RAMIS_MAP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null
      };
    case COA_RAMIS_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaRamisMaps: action.payload,
        message: null,
        status: null,
      };

    case CREATE_COA_RAMIS_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaRamisMaps: [...state.coaRamisMaps, action.payload],
        createdObject: action.payload,
        message: action.message,
        status: action.status
      };

    case UPDATE_COA_RAMIS_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaRamisMaps: [
          ...state.coaRamisMaps.filter(
            (item) => item.ramiscoaBrimscoaMappingId !== action.payload.ramiscoaBrimscoaMappingId
          ),
          action.payload,
        ].sort((pre, post) => pre.ramiscoaBrimscoaMappingId - post.ramiscoaBrimscoaMappingId),
        message: action.message,
        status: action.status,

      };

    case DELETE_COA_RAMIS_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaRamisMaps: [
          ...state.coaRamisMaps.filter(
            (item) => item.ramiscoaBrimscoaMappingId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };


    case COA_RAMIS_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaRamisMaps: [...state.coaRamisMaps],
        error: action.payload,
      };

    case CREATE_COA_RAMIS_MAP_FAIL:
    case UPDATE_COA_RAMIS_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaRamisMaps: [...state.coaRamisMaps],
        message: action.message,
        status: action.status,
      };

    case DELETE_COA_RAMIS_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaRamisMaps: [...state.coaRamisMaps],
        error: action.payload,
      };

    case COA_RAMIS_MAP_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


// find one
export const findCoaRamisMapReducer = (state = { coaRamisMap: {} }, action) => {
  switch (action.type) {
    case FIND_COA_RAMIS_MAP_REQUEST:
      return {
        coaRamisMap: {},
        loading: true,
      };

    case FIND_COA_RAMIS_MAP_SUCCESS:
      return {
        ...state,
        loading: false,
        coaRamisMap: action.payload,
      };

    case FIND_COA_RAMIS_MAP_FAIL:
      return {
        ...state,
        loading: false,
        coaRamisMap: {},
        error: action.payload,
      };

    case "EMPTY_COA_RAMIS_MAP":
      return {
        ...state,
        coaRamisMap: {}
      }

    case COA_RAMIS_MAP_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};