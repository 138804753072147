import {
  REGIONAL_OFFICE_REQUEST,
  REGIONAL_OFFICE_SUCCESS,
  REGIONAL_OFFICE_FAIL,
  REGIONAL_OFFICE_CREATE_REQUEST,
  REGIONAL_OFFICE_CREATE_SUCCESS,
  REGIONAL_OFFICE_CREATE_FAIL,
  FIND_REGIONAL_OFFICE_REQUEST,
  FIND_REGIONAL_OFFICE_SUCCESS,
  FIND_REGIONAL_OFFICE_FAIL,
  UPDATE_REGIONAL_OFFICE_REQUEST,
  UPDATE_REGIONAL_OFFICE_SUCCESS,
  UPDATE_REGIONAL_OFFICE_FAIL,
  DELETE_REGIONAL_OFFICE_REQUEST,
  DELETE_REGIONAL_OFFICE_SUCCESS,
  DELETE_REGIONAL_OFFICE_FAIL,
  CLEAR_ERROR,
} from "../../constants/LocationMaster/RegionalOfficeConstant";

// create, update, delete and get all
export const getRegionalOffceReducer = (state = { offices: [] }, action) => {
  switch (action.type) {
    case REGIONAL_OFFICE_REQUEST:
    case REGIONAL_OFFICE_CREATE_REQUEST:
    case UPDATE_REGIONAL_OFFICE_REQUEST:
    case DELETE_REGIONAL_OFFICE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        offices: action.payload,
        message: null,
        status: null,
      };

    case REGIONAL_OFFICE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        offices: [...state.offices, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        offices: [
          ...state.offices.filter(
            (item) => item.rrco_id !== action.payload.rrco_id
          ),
          action.payload,
        ].sort((pre, post) => pre.rrco_id - post.rrco_id),
        message: action.message,
        status: action.status,
      };

    case DELETE_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        offices: [
          ...state.offices.filter((item) => item.rrco_id !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_REGIONAL_OFFICE_SUCCESS":
      return {
        loading: false,
        offices: [
          ...state.offices.filter(
            (item) => item.rrco_id !== action.payload.rrco_id
          ),
          action.payload,
        ].sort((pre, post) => pre.rrco_id - post.rrco_id),
        message: action.message,
        status: action.status,
      };

    case REGIONAL_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        offices: [...state.offices],
        error: action.payload,
      };

    case REGIONAL_OFFICE_CREATE_FAIL:
    case UPDATE_REGIONAL_OFFICE_FAIL:
    case DELETE_REGIONAL_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        offices: [...state.offices],
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRegionalOffceReducer = (state = { office: {} }, action) => {
  switch (action.type) {
    case FIND_REGIONAL_OFFICE_REQUEST:
      return {
        loading: true,
        office: {},
      };
    case FIND_REGIONAL_OFFICE_SUCCESS:
      return {
        ...state,
        loading: false,
        office: action.payload,
      };

    case FIND_REGIONAL_OFFICE_FAIL:
      return {
        ...state,
        loading: false,
        office: {},
        error: action.payload,
      };
    case "EMPTY_OFFICE":
      return {
        ...state,
        office: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
