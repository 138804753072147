import {
  CREATE_MODE_OF_PAYMENT_REQUEST,
  CREATE_MODE_OF_PAYMENT_SUCCESS,
  CREATE_MODE_OF_PAYMENT_FAIL,
  MODE_OF_PAYMENT_REQUEST,
  MODE_OF_PAYMENT_SUCCESS,
  MODE_OF_PAYMENT_FAIL,
  FIND_MODE_OF_PAYMENT_REQUEST,
  FIND_MODE_OF_PAYMENT_SUCCESS,
  FIND_MODE_OF_PAYMENT_FAIL,
  MODE_OF_PAYMENT_UPDATE_REQUEST,
  MODE_OF_PAYMENT_UPDATE_SUCCESS,
  MODE_OF_PAYMENT_UPDATE_FAIL,
  MODE_OF_PAYMENT_DELETE_REQUEST,
  MODE_OF_PAYMENT_DELETE_SUCCESS,
  MODE_OF_PAYMENT_DELETE_FAIL,
  MODE_OF_PAYMENT_CLEAR_ERROR,
} from "../../constants/OthersMaster/ModeOfPaymentConstant";
// create

// get all data of list
export const getModeOfPaymentReducer = (
  state = { modeOfPayments: [] },
  action
) => {
  // return false
  switch (action.type) {
    case MODE_OF_PAYMENT_REQUEST:
    case CREATE_MODE_OF_PAYMENT_REQUEST:
    case MODE_OF_PAYMENT_UPDATE_REQUEST:
    case MODE_OF_PAYMENT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: action.payload,
        message: null,
        status: null,
      };

    case CREATE_MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: [...state.modeOfPayments, action.payload],
        message: action.message,
        status: action.status,
      };

    case MODE_OF_PAYMENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: [
          ...state.modeOfPayments.filter(
            (item) => item.paymentModeId !== action.payload.paymentModeId
          ),
          action.payload,
        ].sort((pre, post) => pre.paymentModeId - post.paymentModeId),
        message: action.message,
        status: action.status,
      };

    case MODE_OF_PAYMENT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        modeOfPayments: [
          ...state.modeOfPayments.filter(
            (item) => item.paymentModeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_MODE_OF_PAYMENT_SUCCESS":
      return {
        loading: false,
        modeOfPayments: [
          ...state.modeOfPayments.filter(
            (item) => item.paymentModeId !== action.payload.paymentModeId
          ),
          action.payload,
        ].sort((pre, post) => pre.paymentModeId - post.paymentModeId),
        message: action.message,
        status: action.status,
      };

    case MODE_OF_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        modeOfPayments: [...state.modeOfPayments],
        error: action.payload,
      };

    case CREATE_MODE_OF_PAYMENT_FAIL:
    case MODE_OF_PAYMENT_UPDATE_FAIL:
    case MODE_OF_PAYMENT_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        modeOfPayments: [...state.modeOfPayments],
        message: action.message,
        status: action.status,
      };

    case MODE_OF_PAYMENT_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findModeOfPaymentReducer = (
  state = { modePayment: {} },
  action
) => {
  switch (action.type) {
    case FIND_MODE_OF_PAYMENT_REQUEST:
      return {
        modePayment: {},
        loading: true,
      };
    case FIND_MODE_OF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        modePayment: action.payload,
      };

    case FIND_MODE_OF_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        modePayment: {},
        error: action.payload,
      };

    case "EMPTY_MODE_OF_PAYMENT":
      return {
        ...state,
        modePayment: {},
      };

    case MODE_OF_PAYMENT_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
