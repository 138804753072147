// create
export const CREATE_PAYMENT_VOUCHER_REQUEST = "CREATE_PAYMENT_VOUCHER_REQUEST";
export const CREATE_PAYMENT_VOUCHER_SUCCESS = "CREATE_PAYMENT_VOUCHER_SUCCESS";
export const CREATE_PAYMENT_VOUCHER_FAIL = "CREATE_PAYMENT_VOUCHER_FAIL";

// get
export const PAYMENT_VOUCHER_REQUEST = "PAYMENT_VOUCHER_REQUEST";
export const PAYMENT_VOUCHER_SUCCESS = "PAYMENT_VOUCHER_SUCCESS";
export const PAYMENT_VOUCHER_FAIL = "PAYMENT_VOUCHER_FAIL";

// find
export const FIND_PAYMENT_VOUCHER_REQUEST = "FIND_PAYMENT_VOUCHER_REQUEST";
export const FIND_PAYMENT_VOUCHER_SUCCESS = "FIND_PAYMENT_VOUCHER_SUCCESS";
export const FIND_PAYMENT_VOUCHER_FAIL = "FIND_PAYMENT_VOUCHER_FAIL";

// update
export const UPDATE_PAYMENT_VOUCHER_REQUEST = "UPDATE_PAYMENT_VOUCHER_REQUEST";
export const UPDATE_PAYMENT_VOUCHER_SUCCESS = "UPDATE_PAYMENT_VOUCHER_SUCCESS";
export const UPDATE_PAYMENT_VOUCHER_FAIL = "UPDATE_PAYMENT_VOUCHER_FAIL";

// delete
export const DELETE_PAYMENT_VOUCHER_REQUEST = "DELETE_PAYMENT_VOUCHER_REQUEST";
export const DELETE_PAYMENT_VOUCHER_SUCCESS = "DELETE_PAYMENT_VOUCHER_SUCCESS";
export const DELETE_PAYMENT_VOUCHER_FAIL = "DELETE_PAYMENT_VOUCHER_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
