import {
    CREATE_AGENCY_CONTACT_PERSON_REQUEST,
    CREATE_AGENCY_CONTACT_PERSON_SUCCESS,
    CREATE_AGENCY_CONTACT_PERSON_FAIL,

    AGENCY_CONTACT_PERSON_REQUEST,
    AGENCY_CONTACT_PERSON_SUCCESS,
    AGENCY_CONTACT_PERSON_FAIL,

    FIND_AGENCY_CONTACT_PERSON_REQUEST,
    FIND_AGENCY_CONTACT_PERSON_SUCCESS,
    FIND_AGENCY_CONTACT_PERSON_FAIL,

    UPDATE_AGENCY_CONTACT_PERSON_REQUEST,
    UPDATE_AGENCY_CONTACT_PERSON_SUCCESS,
    UPDATE_AGENCY_CONTACT_PERSON_FAIL,

    DELETE_AGENCY_CONTACT_PERSON_REQUEST,
    DELETE_AGENCY_CONTACT_PERSON_SUCCESS,
    DELETE_AGENCY_CONTACT_PERSON_FAIL,

    AGENCY_CONTACT_PERSON_CLEAR_ERROR,
} from "../../constants/AgencyMaintenance/AgencyContactPersonConstant";


// create, update, delete and get all
export const getAgencyContactPersonReducer = (state = { agencyContactPersons: [] }, action) => {
    switch (action.type) {
        case AGENCY_CONTACT_PERSON_REQUEST:
        case CREATE_AGENCY_CONTACT_PERSON_REQUEST:
        case UPDATE_AGENCY_CONTACT_PERSON_REQUEST:
        case DELETE_AGENCY_CONTACT_PERSON_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error:null

            };
        case AGENCY_CONTACT_PERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyContactPersons: action.payload,
                message: null,
                status: null,
            };

        case CREATE_AGENCY_CONTACT_PERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyContactPersons: [...state.agencyContactPersons, action.payload],
                createdObject: action.payload,
                message: action.message,
                status: action.status
            };

        case UPDATE_AGENCY_CONTACT_PERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyContactPersons: [
                    ...state.agencyContactPersons.filter(
                        (item) => item.contactPersonId !== action.payload.contactPersonId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.contactPersonId - post.contactPersonId),
                message: action.message,
                status: action.status,

            };

        case DELETE_AGENCY_CONTACT_PERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyContactPersons: [
                    ...state.agencyContactPersons.filter(
                        (item) => item.contactPersonId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case AGENCY_CONTACT_PERSON_FAIL:
            return {
                ...state,
                loading: false,
                agencyContactPersons: [...state.agencyContactPersons],
                error: action.payload,
            };

        case CREATE_AGENCY_CONTACT_PERSON_FAIL:
        case UPDATE_AGENCY_CONTACT_PERSON_FAIL:
            return {
                ...state,
                loading: false,
                agencyContactPersons: [...state.agencyContactPersons],
                message: action.message,
                status: action.status,
            };

        case DELETE_AGENCY_CONTACT_PERSON_FAIL:
            return {
                ...state,
                loading: false,
                agencyContactPersons: [...state.agencyContactPersons],
                error: action.payload,
            };

        case AGENCY_CONTACT_PERSON_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};


// find one
export const findAgencyContactPersonReducer = (state = { agencyContactPerson: {} }, action) => {
    switch (action.type) {
        case FIND_AGENCY_CONTACT_PERSON_REQUEST:
            return {
                agencyContactPerson: {},
                loading: true,
            };

        case FIND_AGENCY_CONTACT_PERSON_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyContactPerson: action.payload,
            };

        case FIND_AGENCY_CONTACT_PERSON_FAIL:
            return {
                ...state,
                loading: false,
                agencyContactPerson: {},
                error: action.payload,
            };

        case "EMPTY_AGENCY_CONTACT_PERSON":
            return {
                ...state,
                agencyContactPerson: {}
            }

        case AGENCY_CONTACT_PERSON_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};