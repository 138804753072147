import {
  CREATE_EXTERNAL_SYSTEM_REQUEST,
  CREATE_EXTERNAL_SYSTEM_SUCCESS,
  CREATE_EXTERNAL_SYSTEM_FAIL,
  EXTERNAL_SYSTEM_REQUEST,
  EXTERNAL_SYSTEM_SUCCESS,
  EXTERNAL_SYSTEM_FAIL,
  FIND_EXTERNAL_SYSTEM_REQUEST,
  FIND_EXTERNAL_SYSTEM_SUCCESS,
  FIND_EXTERNAL_SYSTEM_FAIL,
  UPDATE_EXTERNAL_SYSTEM_REQUEST,
  UPDATE_EXTERNAL_SYSTEM_SUCCESS,
  UPDATE_EXTERNAL_SYSTEM_FAIL,
  DELETE_EXTERNAL_SYSTEM_REQUEST,
  DELETE_EXTERNAL_SYSTEM_SUCCESS,
  DELETE_EXTERNAL_SYSTEM_FAIL,
  CLEAR_ERROR,
} from "../../constants/OthersMaster/ExternalSystemConstant";

export const getExternalSystemReducer = (
  state = { externalSystems: [] },
  action
) => {
  switch (action.type) {
    case EXTERNAL_SYSTEM_REQUEST:
    case CREATE_EXTERNAL_SYSTEM_REQUEST:
    case UPDATE_EXTERNAL_SYSTEM_REQUEST:
    case DELETE_EXTERNAL_SYSTEM_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: action.payload,
        message: null,
        status: null,
      };

    case CREATE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: [...state.externalSystems, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: [
          ...state.externalSystems.filter(
            (item) => item.externalSystemId !== action.payload.externalSystemId
          ),
          action.payload,
        ].sort((pre, post) => pre.externalSystemId - post.externalSystemId),
        message: action.message,
        status: action.status,
      };

    case DELETE_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystems: [
          ...state.externalSystems.filter(
            (item) => item.externalSystemId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_EXTERNAL_SYSTEM_SUCCESS":
      return {
        loading: false,
        externalSystems: [
          ...state.externalSystems.filter(
            (item) => item.externalSystemId !== action.payload.externalSystemId
          ),
          action.payload,
        ].sort((pre, post) => pre.externalSystemId - post.externalSystemId),
        message: action.message,
        status: action.status,
      };

    case EXTERNAL_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_EXTERNAL_SYSTEM_FAIL:
    case UPDATE_EXTERNAL_SYSTEM_FAIL:
    case DELETE_EXTERNAL_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findExternalSystemReducer = (
  state = { externalSystem: {} },
  action
) => {
  switch (action.type) {
    case FIND_EXTERNAL_SYSTEM_REQUEST:
      return {
        externalSystem: {},
        loading: true,
      };

    case FIND_EXTERNAL_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        externalSystem: action.payload,
      };

    case FIND_EXTERNAL_SYSTEM_FAIL:
      return {
        ...state,
        loading: false,
        externalSystem: {},
        error: action.payload,
      };

    case "EMPTY_EXTERNAL_SYSTEM":
      return {
        ...state,
        externalSystem: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
