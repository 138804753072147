import {
  CREATE_EXCHANGE_RATE_REQUEST,
  CREATE_EXCHANGE_RATE_SUCCESS,
  CREATE_EXCHANGE_RATE_FAIL,
  EXCHANGE_RATE_REQUEST,
  EXCHANGE_RATE_SUCCESS,
  EXCHANGE_RATE_FAIL,
  FIND_EXCHANGE_RATE_REQUEST,
  FIND_EXCHANGE_RATE_SUCCESS,
  FIND_EXCHANGE_RATE_FAIL,
  UPDATE_EXCHANGE_RATE_REQUEST,
  UPDATE_EXCHANGE_RATE_SUCCESS,
  UPDATE_EXCHANGE_RATE_FAIL,
  // DELETE_EXCHANGE_RATE_REQUEST,
  // DELETE_EXCHANGE_RATE_SUCCESS,
  // DELETE_EXCHANGE_RATE_FAIL,
  CLEAR_ERROR,
} from "../../constants/OthersMaster/ExchangeRateConstant";

export const getExchangeRateReducer = (state = { exchangeRates: [] }, action) => {
  switch (action.type) {
    case EXCHANGE_RATE_REQUEST:
    case CREATE_EXCHANGE_RATE_REQUEST:
    case UPDATE_EXCHANGE_RATE_REQUEST:
    // case DELETE_EXCHANGE_RATE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null
      };

    case EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        exchangeRates: action.payload,
        message: null,
        status: null,
      };

    case CREATE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        exchangeRates: [...state.exchangeRates, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        exchangeRates: [
          ...state.exchangeRates.filter(
            (item) => item.exchangeRateId !== action.payload.exchangeRateId
          ),
          action.payload,
        ].sort((pre, post) => pre.exchangeRateId - post.exchangeRateId),
        message: action.message,
        status: action.status,
      };

    // case DELETE_EXCHANGE_RATE_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     exchangeRates: [
    //       ...state.exchangeRates.filter(
    //         (item) => item.exchangeRateId !== action.payload
    //       ),
    //     ],
    //     message: action.message,
    //     status: action.status,
    //   };

    case EXCHANGE_RATE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_EXCHANGE_RATE_FAIL:
    case UPDATE_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    // case DELETE_EXCHANGE_RATE_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findExchangeRateReducer = (state = { exchangeRate: {} }, action) => {
  switch (action.type) {
    case FIND_EXCHANGE_RATE_REQUEST:
      return {
        exchangeRate: {},
        loading: true,
      };

    case FIND_EXCHANGE_RATE_SUCCESS:
      return {
        ...state,
        loading: false,
        exchangeRate: action.payload,
      };

    case FIND_EXCHANGE_RATE_FAIL:
      return {
        ...state,
        loading: false,
        exchangeRate: {},
        error: action.payload
      };

    case "EMPTY_EXCHANGE_RATE":
      return {
        ...state,
        exchangeRate: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
