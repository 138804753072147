import {
  DISBURSE_THRESHOLD_REQUEST,
  DISBURSE_THRESHOLD_SUCCESS,
  DISBURSE_THRESHOLD_FAIL,

  CREATE_DISBURSE_THRESHOLD_REQUEST,
  CREATE_DISBURSE_THRESHOLD_SUCCESS,
  CREATE_DISBURSE_THRESHOLD_FAIL,

  FIND_DISBURSE_THRESHOLD_REQUEST,
  FIND_DISBURSE_THRESHOLD_SUCCESS,
  FIND_DISBURSE_THRESHOLD_FAIL,

  UPDATE_DISBURSE_THRESHOLD_REQUEST,
  UPDATE_DISBURSE_THRESHOLD_SUCCESS,
  UPDATE_DISBURSE_THRESHOLD_FAIL,

  DELETE_DISBURSE_THRESHOLD_REQUEST,
  DELETE_DISBURSE_THRESHOLD_SUCCESS,
  DELETE_DISBURSE_THRESHOLD_FAIL,

  CLEAR_ERROR,
} from "../../constants/OthersMaster/DisburseThresholdConstant";

// create, update, delete and get all
export const getDisburseThresholdsReducer = (state = { disburseThresholds: [] }, action) => {
  switch (action.type) {
    case DISBURSE_THRESHOLD_REQUEST:
    case CREATE_DISBURSE_THRESHOLD_REQUEST:
    case UPDATE_DISBURSE_THRESHOLD_REQUEST:
    case DELETE_DISBURSE_THRESHOLD_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DISBURSE_THRESHOLD_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseThresholds: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DISBURSE_THRESHOLD_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseThresholds: [...state.disburseThresholds, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DISBURSE_THRESHOLD_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseThresholds: state.disburseThresholds.map((item) =>
          (item.disbursementThresholdId === action.payload.disbursementThresholdId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_DISBURSE_THRESHOLD_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseThresholds: action?.payload?.isDelete ?
          state.disburseThresholds.filter((item) =>
            item.disbursementThresholdId !== action.payload.disbursementThresholdId
          )
          :
          state.disburseThresholds.map((item) =>
            (item.disbursementThresholdId === action.payload.disbursementThresholdId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case DISBURSE_THRESHOLD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_DISBURSE_THRESHOLD_FAIL:
    case UPDATE_DISBURSE_THRESHOLD_FAIL:
    case DELETE_DISBURSE_THRESHOLD_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findDisburseThresholdReducer = (state = { disburseThreshold: {} }, action) => {
  switch (action.type) {
    case FIND_DISBURSE_THRESHOLD_REQUEST:
      return {
        loading: true,
        disburseThreshold: {},
      };

    case FIND_DISBURSE_THRESHOLD_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseThreshold: action.payload,
      };

    case FIND_DISBURSE_THRESHOLD_FAIL:
      return {
        ...state,
        loading: false,
        disburseThreshold: {},
        error: action.payload,
      };

    case "EMPTY_DISBURSE_THRESHOLD":
      return {
        disburseThreshold: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
