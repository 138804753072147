import { 
  CREATE_RAMIS_FUNCTIONAL_AREA_REQUEST,
  CREATE_RAMIS_FUNCTIONAL_AREA_SUCCESS,
  CREATE_RAMIS_FUNCTIONAL_AREA_FAIL,
  RAMIS_FUNCTIONAL_AREA_REQUEST,
  RAMIS_FUNCTIONAL_AREA_SUCCESS,
  RAMIS_FUNCTIONAL_AREA_FAIL,
  FIND_RAMIS_FUNCTIONAL_AREA_REQUEST,
  FIND_RAMIS_FUNCTIONAL_AREA_SUCCESS,
  FIND_RAMIS_FUNCTIONAL_AREA_FAIL,
  UPDATE_RAMIS_FUNCTIONAL_AREA_REQUEST,
  UPDATE_RAMIS_FUNCTIONAL_AREA_SUCCESS,
  UPDATE_RAMIS_FUNCTIONAL_AREA_FAIL,
  DELETE_RAMIS_FUNCTIONAL_AREA_REQUEST,
  DELETE_RAMIS_FUNCTIONAL_AREA_SUCCESS,
  DELETE_RAMIS_FUNCTIONAL_AREA_FAIL,
  CLEAR_ERROR,
} from "../../constants/COARamis/RamisFunctionalAreaConstant";

export const ramisFunctionalAreaReducer = (state = { ramisFunctionAreas: [] }, action) => {
  switch (action.type) {
    case RAMIS_FUNCTIONAL_AREA_REQUEST:
    case CREATE_RAMIS_FUNCTIONAL_AREA_REQUEST:
    case UPDATE_RAMIS_FUNCTIONAL_AREA_REQUEST:
    case DELETE_RAMIS_FUNCTIONAL_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RAMIS_FUNCTIONAL_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisFunctionAreas: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RAMIS_FUNCTIONAL_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisFunctionAreas: [...state.ramisFunctionAreas, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RAMIS_FUNCTIONAL_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisFunctionAreas: [
          ...state.ramisFunctionAreas.filter(
            (item) => item.majorGroupId !== action.payload.majorGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.majorGroupId - post.majorGroupId),
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_FUNCTIONAL_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisFunctionAreas: [
          ...state.ramisFunctionAreas.filter(
            (item) => item.majorGroupId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case RAMIS_FUNCTIONAL_AREA_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_RAMIS_FUNCTIONAL_AREA_FAIL:
    case UPDATE_RAMIS_FUNCTIONAL_AREA_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_FUNCTIONAL_AREA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRamisFunctionalAreaReducer = (state = { ramisFunctionalArea: {} }, action) => {
  switch (action.type) {
    case FIND_RAMIS_FUNCTIONAL_AREA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIND_RAMIS_FUNCTIONAL_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisFunctionalArea: action.payload,
      };

    case FIND_RAMIS_FUNCTIONAL_AREA_FAIL:
      return {
        ...state,
        loading: false,
        ramisFunctionalArea: {},
        error: action.payload
      };

    case "EMPTY_RAMIS_FUNCTIONAL_AREA":
      return {
        ...state,
        ramisFunctionalArea: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
