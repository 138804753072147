// TAX_PROCESS create
export const CREATE_TAX_PROCESS_REQUEST = "CREATE_TAX_PROCESS_REQUEST";
export const CREATE_TAX_PROCESS_SUCCESS = "CREATE_TAX_PROCESS_SUCCESS";
export const CREATE_TAX_PROCESS_FAIL = "CREATE_TAX_PROCESS_FAIL"
export const CREATE_ERROR_TAX_PROCESS_SUCCESS="CREATE_ERROR_TAX_PROCESS_SUCCESS"

// TAX_PROCESS get
export const TAX_PROCESS_REQUEST = "TAX_PROCESS_REQUEST";
export const TAX_PROCESS_SUCCESS = "TAX_PROCESS_SUCCESS";
export const TAX_PROCESS_FAIL = "TAX_PROCESS_FAIL"


// TAX_PROCESS find
export const FIND_TAX_PROCESS_REQUEST = "FIND_TAX_PROCESS_REQUEST";
export const FIND_TAX_PROCESS_SUCCESS = "FIND_TAX_PROCESS_SUCCESS";
export const FIND_TAX_PROCESS_FAIL = "FIND_TAX_PROCESS_FAIL"

//TAX_PROCESS update/put
export const UPDATE_TAX_PROCESS_REQUEST = "UPDATE_TAX_PROCESS_REQUEST";
export const UPDATE_TAX_PROCESS_SUCCESS = "UPDATE_TAX_PROCESS_SUCCESS";
export const UPDATE_TAX_PROCESS_FAIL = "UPDATE_TAX_PROCESS_FAIL"

// TAX_PROCESS delete
export const DELETE_TAX_PROCESS_REQUEST = "DELETE_TAX_PROCESS_REQUEST";
export const DELETE_TAX_PROCESS_SUCCESS = "DELETE_TAX_PROCESS_SUCCESS";
export const DELETE_TAX_PROCESS_FAIL = "DELETE_TAX_PROCESS_FAIL"

export const TAX_PROCESS_CLEAR_ERROR = "TAX_PROCESS_CLEAR_ERROR"
