import {
    CREATE_AGENCY_REG_REQUEST,
    CREATE_AGENCY_REG_SUCCESS,
    CREATE_AGENCY_REG_FAIL,

    AGENCY_REG_REQUEST,
    AGENCY_REG_SUCCESS,
    AGENCY_REG_FAIL,

    FIND_AGENCY_REG_REQUEST,
    FIND_AGENCY_REG_SUCCESS,
    FIND_AGENCY_REG_FAIL,

    UPDATE_AGENCY_REG_REQUEST,
    UPDATE_AGENCY_REG_SUCCESS,
    UPDATE_AGENCY_REG_FAIL,

    DELETE_AGENCY_REG_REQUEST,
    DELETE_AGENCY_REG_SUCCESS,
    DELETE_AGENCY_REG_FAIL,

    AGENCY_REG_CLEAR_ERROR,

    AGENCY_TYPE_LIST_REQUEST,
    AGENCY_TYPE_LIST_SUCCESS,
    AGENCY_TYPE_LIST_FAIL,

    SUB_AGENCY_LIST_REQUEST,
    SUB_AGENCY_LIST_SUCCESS,
    SUB_AGENCY_LIST_FAIL,

    AGENCY_NAME_REQUEST,
    AGENCY_NAME_SUCCESS,
    AGENCY_NAME_FAIL,

} from "../../constants/AgencyRegistration/AgencyRegistrationConstant";

// create, update, delete and get all
export const getAgencyRegReducer = (state = { agencyRegs: [] }, action) => {
    switch (action.type) {
        case AGENCY_REG_REQUEST:
        case CREATE_AGENCY_REG_REQUEST:
        case UPDATE_AGENCY_REG_REQUEST:
        case DELETE_AGENCY_REG_REQUEST:
            return {
                ...state,
                agencyRegs: [],
                loading: true,
                message: null,
                status: null
            };
        case AGENCY_REG_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyRegs: action.payload,
                message: null,
                status: null,
                totalData: action.totalData,
            };

        case CREATE_AGENCY_REG_SUCCESS:
            return {
                ...state,
                loading: false,
                // agencyRegs: [...state.agencyRegs, action.payload],
                createdObject: action.payload,
                message: action.message,
                status: action.status
            };

        case UPDATE_AGENCY_REG_SUCCESS:
            return {
                ...state,
                loading: false,
                // agencyRegs: [
                //     ...state.agencyRegs.filter(
                //         (item) => item.countryId !== action.payload.countryId
                //     ),
                //     action.payload,
                // ].sort((pre, post) => pre.countryId - post.countryId),
                message: action.message,
                status: action.status,

            };

        case DELETE_AGENCY_REG_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyRegs: [
                    ...state.agencyRegs.filter(
                        (item) => item.countryId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case AGENCY_REG_FAIL:
            return {
                ...state,
                loading: false,
                agencyRegs: [...state.agencyRegs],
                error: action.payload,
            };

        case CREATE_AGENCY_REG_FAIL:
        case UPDATE_AGENCY_REG_FAIL:
            return {
                ...state,
                loading: false,
                agencyRegs: [...state.agencyRegs],
                message: action.message,
                status: action.status,
            };

        case DELETE_AGENCY_REG_FAIL:
            return {
                ...state,
                loading: false,
                agencyRegs: [...state.agencyRegs],
                error: action.payload,
            };

        case AGENCY_REG_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findAgencyRegReducer = (state = { agencyReg: {} }, action) => {
    switch (action.type) {
        case FIND_AGENCY_REG_REQUEST:
            return {
                agencyReg: {},
                loading: true,
            };

        case FIND_AGENCY_REG_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyReg: action.payload,
            };

        case FIND_AGENCY_REG_FAIL:
            return {
                ...state,
                loading: false,
                agencyReg: {},
                error: action.payload,
            };

        case "EMPTY_AGENCY_REG":
            return {
                ...state,
                agencyReg: {}
            }

        case AGENCY_REG_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

//agency type lists for addition form
export const getAgencyTypeListReducer = (state = { agencyTypeList: [] }, action) => {
    switch (action.type) {
        case AGENCY_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case AGENCY_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyTypeList: action.payload?.content,
                message: null,
                status: null,
            };

        case AGENCY_TYPE_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "EMPTY_AGENCY_TYPE_LIST":
            return {
                ...state,
                agencyTypeList: []
            }

        case AGENCY_REG_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};


//sub agency lists for addition form
export const getSubAgencyListReducer = (state = { subAgencyList: [] }, action) => {
    switch (action.type) {
        case SUB_AGENCY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };

        case SUB_AGENCY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyTypeList: action.payload,
                message: null,
                status: null,
            };

        case SUB_AGENCY_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case "EMPTY_SUB_AGENCY_LIST":
            return {
                ...state,
                agencyTypeList: []
            }

        case AGENCY_REG_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find agency name
export const findAgencyNameReducer = (state = { agencyLcPlcName: {} }, action) => {
    switch (action.type) {
        case AGENCY_NAME_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case AGENCY_NAME_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyLcPlcName: action.payload,
                status: action.status,
            };

        case AGENCY_NAME_FAIL:
            return {
                ...state,
                loading: false,
                agencyLcPlcName: {},
                message: action.message,
                status: action.status,
            };

        case "EMPTY_AGENCY_NAME":
            return {
                ...state,
                agencyLcPlcName: {},
                status: null,
                message: null,
            }

        case AGENCY_REG_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};