import {
    FIND_AGENCY_TYPE_CHILDREN_REQUEST,
    FIND_AGENCY_TYPE_CHILDREN_SUCCESS,
    FIND_AGENCY_TYPE_CHILDREN_FAIL,

    FIND_PARENT_AGENCY_CHILDREN_REQUEST,
    FIND_PARENT_AGENCY_CHILDREN_SUCCESS,
    FIND_PARENT_AGENCY_CHILDREN_FAIL,

    FIND_AGENCY_CHILDREN_REQUEST,
    FIND_AGENCY_CHILDREN_SUCCESS,
    FIND_AGENCY_CHILDREN_FAIL,

    FIND_SUB_AGENCY_OF_PARENT_AGENCY_REQUEST,
    FIND_SUB_AGENCY_OF_PARENT_AGENCY_SUCCESS,
    FIND_SUB_AGENCY_OF_PARENT_AGENCY_FAIL,

    FIND_AGENCY_COA_SERVICES_REQUEST,
    FIND_AGENCY_COA_SERVICES_SUCCESS,
    FIND_AGENCY_COA_SERVICES_FAIL,

    CLEAR_ERROR

} from "../../constants/AgencyChildren/AgencyChildrenConstant";

// find agency type child
export const findAgencyTypeChildrenReducer = (state = { agencyTypeChildren: {} }, action) => {
    switch (action.type) {
        case FIND_AGENCY_TYPE_CHILDREN_REQUEST:
            return {
                agencyTypeChildren: {},
                loading: true,
            };

        case FIND_AGENCY_TYPE_CHILDREN_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyTypeChildren: action.payload,
            };

        case FIND_AGENCY_TYPE_CHILDREN_FAIL:
            return {
                ...state,
                loading: false,
                agencyTypeChildren: {},
                error: action.payload
            };

        case "EMPTY_AGENCY_TYPE_CHILDREN":
            return {
                ...state,
                agencyTypeChildren: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find parent agency child
export const findParentAgencyChildrenReducer = (state = { parentAgencyChildren: {} }, action) => {
    switch (action.type) {
        case FIND_PARENT_AGENCY_CHILDREN_REQUEST:
            return {
                parentAgencyChildren: {},
                loading: true,
            };

        case FIND_PARENT_AGENCY_CHILDREN_SUCCESS:
            return {
                ...state,
                loading: false,
                parentAgencyChildren: action.payload,
            };

        case FIND_PARENT_AGENCY_CHILDREN_FAIL:
            return {
                ...state,
                loading: false,
                parentAgencyChildren: {},
                error: action.payload
            };

        case "EMPTY_PARENT_AGENCY_CHILDREN":
            return {
                ...state,
                parentAgencyChildren: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

//find sub agency of parent agency
export const findSubAgencyOfParentAgency = (state = { multipleSubAgencyList: {} }, action) => {
    switch (action.type) {
        case FIND_SUB_AGENCY_OF_PARENT_AGENCY_REQUEST:
            return {
                multipleSubAgencyList: {},
                loading: true,
            };

        case FIND_SUB_AGENCY_OF_PARENT_AGENCY_SUCCESS:
            return {
                ...state,
                loading: false,
                multipleSubAgencyList: action.payload,
            };

        case FIND_SUB_AGENCY_OF_PARENT_AGENCY_FAIL:
            return {
                ...state,
                loading: false,
                multipleSubAgencyList: {},
                error: action.payload
            };

        // case "EMPTY_PARENT_AGENCY_CHILDREN":
        //     return {
        //         ...state,
        //         multipleSubAgencyList: {}
        //     }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find agency child
export const findAgencyChildrenReducer = (state = { agencyChildren: {} }, action) => {
    switch (action.type) {
        case FIND_AGENCY_CHILDREN_REQUEST:
            return {
                agencyChildren: {},
                loading: true,
            };

        case FIND_AGENCY_CHILDREN_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyChildren: action.payload,
            };

        case FIND_AGENCY_CHILDREN_FAIL:
            return {
                ...state,
                loading: false,
                agencyChildren: {},
                error: action.payload
            };

        case "EMPTY_AGENCY_CHILDREN":
            return {
                ...state,
                agencyChildren: {}
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find agency child
export const findAgencyCoaServicesReducer = (state = { agencyCoaServices: [] }, action) => {
    switch (action.type) {
        case FIND_AGENCY_COA_SERVICES_REQUEST:
            return {
                agencyCoaServices: [],
                loading: true,
            };

        case FIND_AGENCY_COA_SERVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                agencyCoaServices: action.payload,
            };

        case FIND_AGENCY_COA_SERVICES_FAIL:
            return {
                ...state,
                loading: false,
                agencyCoaServices: [],
                error: action.payload
            };

        case "EMPTY_AGENCY_COA_SERVICES":
            return {
                ...state,
                agencyCoaServices: []
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};