import {
  CREATE_PAYMENT_ADVICE_COUNTER_REQUEST,
  CREATE_PAYMENT_ADVICE_COUNTER_SUCCESS,
  CREATE_PAYMENT_ADVICE_COUNTER_FAIL,

  PAYMENT_ADVICE_COUNTER_REQUEST,
  PAYMENT_ADVICE_COUNTER_SUCCESS,
  PAYMENT_ADVICE_COUNTER_FAIL,

  FIND_PAYMENT_ADVICE_COUNTER_REQUEST,
  FIND_PAYMENT_ADVICE_COUNTER_SUCCESS,
  FIND_PAYMENT_ADVICE_COUNTER_FAIL,

  UPDATE_PAYMENT_ADVICE_COUNTER_REQUEST,
  UPDATE_PAYMENT_ADVICE_COUNTER_SUCCESS,
  UPDATE_PAYMENT_ADVICE_COUNTER_FAIL,

  DELETE_PAYMENT_ADVICE_COUNTER_REQUEST,
  DELETE_PAYMENT_ADVICE_COUNTER_SUCCESS,
  DELETE_PAYMENT_ADVICE_COUNTER_FAIL,

  PAYMENT_ADVICE_COUNTER_CLEAR_ERROR,
} from "../../constants/PaymentAdvice/PaymentAdviceCounterConstant";

export const getPaymentAdviceCountersReducer = (state = { paymentAdviceCounters: [] }, action) => {
  switch (action.type) {
    case PAYMENT_ADVICE_COUNTER_REQUEST:
      return {
        ...state,
        paymentAdviceCounters: [],
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case PAYMENT_ADVICE_COUNTER_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentAdviceCounters: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: null,
        status: null,
      };

    case PAYMENT_ADVICE_COUNTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PAYMENT_ADVICE_COUNTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// create, update
export const paymentAdviceCounterResponseReducer = (state = { paymentAdviceCounterResponse: {} }, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_ADVICE_COUNTER_REQUEST:
    case UPDATE_PAYMENT_ADVICE_COUNTER_REQUEST:
    case DELETE_PAYMENT_ADVICE_COUNTER_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case CREATE_PAYMENT_ADVICE_COUNTER_SUCCESS:
    case UPDATE_PAYMENT_ADVICE_COUNTER_SUCCESS:
    case DELETE_PAYMENT_ADVICE_COUNTER_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentAdviceCounterResponse: action.payload,
        message: action.message,
        status: action.status
      };

    case CREATE_PAYMENT_ADVICE_COUNTER_FAIL:
    case UPDATE_PAYMENT_ADVICE_COUNTER_FAIL:
    case DELETE_PAYMENT_ADVICE_COUNTER_FAIL:
      return {
        ...state,
        loading: false,
        paymentAdviceCounterResponse: { ...state.paymentAdviceCounterResponse },
        message: action.message,
        status: action.status,
      };

    case PAYMENT_ADVICE_COUNTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_PAYMENT_ADVICE_COUNTER_RESPONSE":
      return {
        ...state,
        paymentAdviceCounterResponse: {}
      }

    default:
      return state;
  }
};

export const findPaymentAdviceCounterReducer = (state = { paymentAdviceCounter: {} }, action) => {
  switch (action.type) {
    case FIND_PAYMENT_ADVICE_COUNTER_REQUEST:
      return {
        loading: true,
        paymentAdviceCounter: {},
        message: null,
      };
    case FIND_PAYMENT_ADVICE_COUNTER_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentAdviceCounter: action.payload,
        message: action.message,
        status: action.status,
      };

    case FIND_PAYMENT_ADVICE_COUNTER_FAIL:
      return {
        ...state,
        loading: false,
        paymentAdviceCounter: {},
        error: action.payload,
      };
    case "EMPTY_PAYMENT_ADVICE_COUNTER":
      return {
        paymentAdviceCounter: {},
      };

    case PAYMENT_ADVICE_COUNTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};