// create
export const CREATE_NETTING_OFF_REQUEST = "CREATE_NETTING_OFF_REQUEST";
export const CREATE_NETTING_OFF_SUCCESS = "CREATE_NETTING_OFF_SUCCESS";
export const CREATE_NETTING_OFF_FAIL = "CREATE_NETTING_OFF_FAIL";

// get
export const NETTING_OFF_REQUEST = "NETTING_OFF_REQUEST";
export const NETTING_OFF_SUCCESS = "NETTING_OFF_SUCCESS";
export const NETTING_OFF_FAIL = "NETTING_OFF_FAIL";

// find
export const FIND_NETTING_OFF_REQUEST = "FIND_NETTING_OFF_REQUEST";
export const FIND_NETTING_OFF_SUCCESS = "FIND_NETTING_OFF_SUCCESS";
export const FIND_NETTING_OFF_FAIL = "FIND_NETTING_OFF_FAIL";

// update
export const UPDATE_NETTING_OFF_REQUEST = "UPDATE_NETTING_OFF_REQUEST";
export const UPDATE_NETTING_OFF_SUCCESS = "UPDATE_NETTING_OFF_SUCCESS";
export const UPDATE_NETTING_OFF_FAIL = "UPDATE_NETTING_OFF_FAIL";

// delete
export const DELETE_NETTING_OFF_REQUEST = "DELETE_NETTING_OFF_REQUEST";
export const DELETE_NETTING_OFF_SUCCESS = "DELETE_NETTING_OFF_SUCCESS";
export const DELETE_NETTING_OFF_FAIL = "DELETE_NETTING_OFF_FAIL";

// search
export const FIND_NETTING_OFF_DATA_REQUEST = "FIND_NETTING_OFF_DATA_REQUEST";
export const FIND_NETTING_OFF_DATA_SUCCESS = "FIND_NETTING_OFF_DATA_SUCCESS";
export const FIND_NETTING_OFF_DATA_FAIL = "FIND_NETTING_OFF_DATA_FAIL";

// get
export const SELECTED_NETTING_OFF_GL_HEADS_REQUEST = "SELECTED_NETTING_OFF_GL_HEADS_REQUEST";
export const SELECTED_NETTING_OFF_GL_HEADS_SUCCESS = "SELECTED_NETTING_OFF_GL_HEADS_SUCCESS";
export const SELECTED_NETTING_OFF_GL_HEADS_FAIL = "SELECTED_NETTING_OFF_GL_HEADS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
