import {
  RISK_RULE_REQUEST,
  RISK_RULE_SUCCESS,
  RISK_RULE_FAIL,

  CREATE_RISK_RULE_REQUEST,
  CREATE_RISK_RULE_SUCCESS,
  CREATE_RISK_RULE_FAIL,

  FILTERED_RISK_RULE_REQUEST,
  FILTERED_RISK_RULE_SUCCESS,
  FILTERED_RISK_RULE_FAIL,

  FIND_RISK_RULE_REQUEST,
  FIND_RISK_RULE_SUCCESS,
  FIND_RISK_RULE_FAIL,

  UPDATE_RISK_RULE_REQUEST,
  UPDATE_RISK_RULE_SUCCESS,
  UPDATE_RISK_RULE_FAIL,

  DELETE_RISK_RULE_REQUEST,
  DELETE_RISK_RULE_SUCCESS,
  DELETE_RISK_RULE_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskRuleConstant";

// create, update, delete and get all
export const getRiskRulesReducer = (state = { riskRules: [] }, action) => {
  switch (action.type) {
    case RISK_RULE_REQUEST:
    case CREATE_RISK_RULE_REQUEST:
    case UPDATE_RISK_RULE_REQUEST:
    case DELETE_RISK_RULE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskRules: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskRules: [...state.riskRules, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskRules: state.riskRules.map((item) =>
          (item.riskRuleId === action.payload.riskRuleId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskRules: action?.payload?.isDelete ?
          state.riskRules.filter((item) =>
            item.riskRuleId !== action.payload.riskRuleId
          )
          :
          state.riskRules.map((item) =>
            (item.riskRuleId === action.payload.riskRuleId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_RULE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_RULE_FAIL:
    case UPDATE_RISK_RULE_FAIL:
    case DELETE_RISK_RULE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// filtered list
export const getFilteredRiskRulesReducer = (state = { filteredRiskRules: [] }, action) => {
  switch (action.type) {
    case FILTERED_RISK_RULE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FILTERED_RISK_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredRiskRules: action.payload,
      };

    case FILTERED_RISK_RULE_FAIL:
      return {
        loading: false,
        filteredRiskRules: [],
        error: action.payload,
      };

    case "EMPTY_FILTERED_RISK_RULE":
      return {
        filteredRiskRules: [],
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskRuleReducer = (state = { riskRule: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_RULE_REQUEST:
      return {
        loading: true,
        riskRule: {},
      };

    case FIND_RISK_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskRule: action.payload,
      };

    case FIND_RISK_RULE_FAIL:
      return {
        ...state,
        loading: false,
        riskRule: {},
        error: action.payload,
      };

    case "EMPTY_RISK_RULE":
      return {
        riskRule: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};