//get selected payment advices
export const SELECTED_IDS_PAYMENT_ADVICE_REQUEST = "SELECTED_IDS_PAYMENT_ADVICE_REQUEST";
export const SELECTED_IDS_PAYMENT_ADVICE_SUCCESS = "SELECTED_IDS_PAYMENT_ADVICE_SUCCESS";
export const SELECTED_IDS_PAYMENT_ADVICE_FAIL = "SELECTED_IDS_PAYMENT_ADVICE_FAIL";

//check reward payment
export const FIND_GLHEADS_REWARD_PAYMENT_REQUEST = "FIND_GLHEADS_REWARD_PAYMENT_REQUEST";
export const FIND_GLHEADS_REWARD_PAYMENT_SUCCESS = "FIND_GLHEADS_REWARD_PAYMENT_SUCCESS";
export const FIND_GLHEADS_REWARD_PAYMENT_FAIL = "FIND_GLHEADS_REWARD_PAYMENT_FAIL";

// FIND_PREPAYMENT_BALANCE find
export const FIND_PREPAYMENT_BALANCE_REQUEST = "FIND_PREPAYMENT_BALANCE_REQUEST";
export const FIND_PREPAYMENT_BALANCE_SUCCESS = "FIND_PREPAYMENT_BALANCE_SUCCESS";
export const FIND_PREPAYMENT_BALANCE_FAIL = "FIND_PREPAYMENT_BALANCE_FAIL";

//find user receipt stock
export const FIND_USER_RECEIPT_STOCK_REQUEST = "FIND_USER_RECEIPT_STOCK_REQUEST";
export const FIND_USER_RECEIPT_STOCK_SUCCESS = "FIND_USER_RECEIPT_STOCK_SUCCESS";
export const FIND_USER_RECEIPT_STOCK_FAIL = "FIND_USER_RECEIPT_STOCK_FAIL";

//get cash denomination list
export const CASH_DENOMINATIONS_REQUEST = "CASH_DENOMINATIONS_REQUEST";
export const CASH_DENOMINATIONS_SUCCESS = "CASH_DENOMINATIONS_SUCCESS";
export const CASH_DENOMINATIONS_FAIL = "CASH_DENOMINATIONS_FAIL";

// FIND_IDENTITY_TYPE_PAYMENT_ADVICE find
export const FIND_IDENTITY_TYPE_PAYMENT_ADVICE_REQUEST = "FIND_IDENTITY_TYPE_PAYMENT_ADVICE_REQUEST";
export const FIND_IDENTITY_TYPE_PAYMENT_ADVICE_SUCCESS = "FIND_IDENTITY_TYPE_PAYMENT_ADVICE_SUCCESS";
export const FIND_IDENTITY_TYPE_PAYMENT_ADVICE_FAIL = "FIND_IDENTITY_TYPE_PAYMENT_ADVICE_FAIL";

// create
export const CREATE_COUNTER_PAYMENT_REQUEST = "CREATE_COUNTER_PAYMENT_REQUEST";
export const CREATE_COUNTER_PAYMENT_SUCCESS = "CREATE_COUNTER_PAYMENT_SUCCESS";
export const CREATE_COUNTER_PAYMENT_FAIL = "CREATE_COUNTER_PAYMENT_FAIL";

export const COUNTER_PAYMENT_CLEAR_ERROR = "COUNTER_PAYMENT_CLEAR_ERROR";