import {
  CREATE_PREVIOUS_YEAR_REVENUE_REQUEST,
  CREATE_PREVIOUS_YEAR_REVENUE_SUCCESS,
  CREATE_PREVIOUS_YEAR_REVENUE_FAIL,
  PREVIOUS_YEAR_REVENUE_REQUEST,
  PREVIOUS_YEAR_REVENUE_SUCCESS,
  PREVIOUS_YEAR_REVENUE_FAIL,
  FIND_PREVIOUS_YEAR_REVENUE_REQUEST,
  FIND_PREVIOUS_YEAR_REVENUE_SUCCESS,
  FIND_PREVIOUS_YEAR_REVENUE_FAIL,
  UPDATE_PREVIOUS_YEAR_REVENUE_REQUEST,
  UPDATE_PREVIOUS_YEAR_REVENUE_SUCCESS,
  UPDATE_PREVIOUS_YEAR_REVENUE_FAIL,
  CLEAR_PREVIOUS_YEAR_REVENUE_ERROR,
} from "../../constants_Phase_2/PreviousYearRevenue/PreviousYearRevenueConstant";

// create
export const previousYearRevenueListReducer = ( state = { previousYearRevenueList: [] }, action ) => {
  switch (action.type) {
    case PREVIOUS_YEAR_REVENUE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        previousYearRevenueList: [],
        status: null,
        error: null,
      };

    case CREATE_PREVIOUS_YEAR_REVENUE_REQUEST:
    case UPDATE_PREVIOUS_YEAR_REVENUE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case PREVIOUS_YEAR_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        previousYearRevenueList: action.payload,
        totalData: action.totalData || 0,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case CREATE_PREVIOUS_YEAR_REVENUE_SUCCESS:
    case UPDATE_PREVIOUS_YEAR_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case PREVIOUS_YEAR_REVENUE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_PREVIOUS_YEAR_REVENUE_FAIL:
    case UPDATE_PREVIOUS_YEAR_REVENUE_FAIL:
      return {
        ...state,
        loading: false,
        previousYearRevenueList: [...state.previousYearRevenueList],
        message: action.message,
        status: action.status,
      };

    case CLEAR_PREVIOUS_YEAR_REVENUE_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findPreviousYearRevenueReducer = (
  state = { previousYearRevenue: {} },
  action
) => {
  switch (action.type) {
    case FIND_PREVIOUS_YEAR_REVENUE_REQUEST:
      return {
        previousYearRevenue: {},
        loading: true,
      };

    case FIND_PREVIOUS_YEAR_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        previousYearRevenue: action.payload,
      };

    case FIND_PREVIOUS_YEAR_REVENUE_FAIL:
      return {
        ...state,
        loading: false,
        previousYearRevenue: {},
        error: action.payload,
      };

    case "EMPTY_PREVIOUS_YEAR_REVENUE":
      return {
        ...state,
        previousYearRevenue: {},
      };

    case CLEAR_PREVIOUS_YEAR_REVENUE_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
