import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Store from "./redux/store/store";
import "./index.scss";
import AllRoutes from "./AllRoutes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./custom.css";
import "./assets/css/fonts.css"

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  //  <React.StrictMode>
    <Provider store={Store}>
      <AllRoutes />
      <ToastContainer toastClassName="d-print-none"/>
    </Provider>
  //  </React.StrictMode>
);


