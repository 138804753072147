// TRANSACTION_TYPE create
export const CREATE_TRANSACTION_TYPE_REQUEST = "CREATE_TRANSACTION_TYPE_REQUEST";
export const CREATE_TRANSACTION_TYPE_SUCCESS = "CREATE_TRANSACTION_TYPE_SUCCESS";
export const CREATE_TRANSACTION_TYPE_FAIL = "CREATE_TRANSACTION_TYPE_FAIL"

// TRANSACTION_TYPE get
export const TRANSACTION_TYPE_REQUEST = "TRANSACTION_TYPE_REQUEST";
export const TRANSACTION_TYPE_SUCCESS = "TRANSACTION_TYPE_SUCCESS";
export const TRANSACTION_TYPE_FAIL = "TRANSACTION_TYPE_FAIL"


// TRANSACTION_TYPE find
export const FIND_TRANSACTION_TYPE_REQUEST = "FIND_TRANSACTION_TYPE_REQUEST";
export const FIND_TRANSACTION_TYPE_SUCCESS = "FIND_TRANSACTION_TYPE_SUCCESS";
export const FIND_TRANSACTION_TYPE_FAIL = "FIND_TRANSACTION_TYPE_FAIL"

//TRANSACTION_TYPE update/put
export const UPDATE_TRANSACTION_TYPE_REQUEST = "UPDATE_TRANSACTION_TYPE_REQUEST";
export const UPDATE_TRANSACTION_TYPE_SUCCESS = "UPDATE_TRANSACTION_TYPE_SUCCESS";
export const UPDATE_TRANSACTION_TYPE_FAIL = "UPDATE_TRANSACTION_TYPE_FAIL"

// TRANSACTION_TYPE delete
export const DELETE_TRANSACTION_TYPE_REQUEST = "DELETE_TRANSACTION_TYPE_REQUEST";
export const DELETE_TRANSACTION_TYPE_SUCCESS = "DELETE_TRANSACTION_TYPE_SUCCESS";
export const DELETE_TRANSACTION_TYPE_FAIL = "DELETE_TRANSACTION_TYPE_FAIL"


export const TRANSACTION_TYPE_CLEAR_ERROR = "TRANSACTION_TYPE_CLEAR_ERROR"
