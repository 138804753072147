import { 
  CREATE_RAMIS_REV_SUB_TYPE_REQUEST,
  CREATE_RAMIS_REV_SUB_TYPE_SUCCESS,
  CREATE_RAMIS_REV_SUB_TYPE_FAIL,
  RAMIS_REV_SUB_TYPE_REQUEST,
  RAMIS_REV_SUB_TYPE_SUCCESS,
  RAMIS_REV_SUB_TYPE_FAIL,
  FIND_RAMIS_REV_SUB_TYPE_REQUEST,
  FIND_RAMIS_REV_SUB_TYPE_SUCCESS,
  FIND_RAMIS_REV_SUB_TYPE_FAIL,
  UPDATE_RAMIS_REV_SUB_TYPE_REQUEST,
  UPDATE_RAMIS_REV_SUB_TYPE_SUCCESS,
  UPDATE_RAMIS_REV_SUB_TYPE_FAIL,
  DELETE_RAMIS_REV_SUB_TYPE_REQUEST,
  DELETE_RAMIS_REV_SUB_TYPE_SUCCESS,
  DELETE_RAMIS_REV_SUB_TYPE_FAIL,
  CLEAR_ERROR,
} from "../../constants/COARamis/RamisRevSubTypeConstant";

export const ramisRevSubTypeReducer = (state = { ramisRevSubTypes: [] }, action) => {
  switch (action.type) {
    case RAMIS_REV_SUB_TYPE_REQUEST:
    case CREATE_RAMIS_REV_SUB_TYPE_REQUEST:
    case UPDATE_RAMIS_REV_SUB_TYPE_REQUEST:
    case DELETE_RAMIS_REV_SUB_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RAMIS_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevSubTypes: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RAMIS_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevSubTypes: [...state.ramisRevSubTypes, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RAMIS_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevSubTypes: [
          ...state.ramisRevSubTypes.filter(
            (item) => item.revenueSubTypeId !== action.payload.revenueSubTypeId
          ),
          action.payload,
        ].sort((pre, post) => pre.revenueSubTypeId - post.revenueSubTypeId),
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevSubTypes: [
          ...state.ramisRevSubTypes.filter(
            (item) => item.revenueSubTypeId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case RAMIS_REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_RAMIS_REV_SUB_TYPE_FAIL:
    case UPDATE_RAMIS_REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case DELETE_RAMIS_REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRamisRevSubTypeReducer = (state = { ramisRevSubType: {} }, action) => {
  switch (action.type) {
    case FIND_RAMIS_REV_SUB_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIND_RAMIS_REV_SUB_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        ramisRevSubType: action.payload,
      };

    case FIND_RAMIS_REV_SUB_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        ramisRevSubType: {},
        error: action.payload
      };

    case "EMPTY_RAMIS_REV_SUB_TYPE":
      return {
        ...state,
        ramisRevSubType: {}
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};