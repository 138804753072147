// AGENCY_CONTACT_PERSON create
export const CREATE_AGENCY_CONTACT_PERSON_REQUEST = "CREATE_AGENCY_CONTACT_PERSON_REQUEST";
export const CREATE_AGENCY_CONTACT_PERSON_SUCCESS = "CREATE_AGENCY_CONTACT_PERSON_SUCCESS";
export const CREATE_AGENCY_CONTACT_PERSON_FAIL = "CREATE_AGENCY_CONTACT_PERSON_FAIL"
export const CREATE_AGENCY_CONTACT_PERSON_CONFLICT = "CREATE_AGENCY_CONTACT_PERSON_CONFLICT"

// AGENCY_CONTACT_PERSON get
export const AGENCY_CONTACT_PERSON_REQUEST = "AGENCY_CONTACT_PERSON_REQUEST";
export const AGENCY_CONTACT_PERSON_SUCCESS = "AGENCY_CONTACT_PERSON_SUCCESS";
export const AGENCY_CONTACT_PERSON_FAIL = "AGENCY_CONTACT_PERSON_FAIL"


// AGENCY_CONTACT_PERSON find
export const FIND_AGENCY_CONTACT_PERSON_REQUEST = "FIND_AGENCY_CONTACT_PERSON_REQUEST";
export const FIND_AGENCY_CONTACT_PERSON_SUCCESS = "FIND_AGENCY_CONTACT_PERSON_SUCCESS";
export const FIND_AGENCY_CONTACT_PERSON_FAIL = "FIND_AGENCY_CONTACT_PERSON_FAIL"

//AGENCY_CONTACT_PERSON update/put
export const UPDATE_AGENCY_CONTACT_PERSON_REQUEST = "UPDATE_AGENCY_CONTACT_PERSON_REQUEST";
export const UPDATE_AGENCY_CONTACT_PERSON_SUCCESS = "UPDATE_AGENCY_CONTACT_PERSON_SUCCESS";
export const UPDATE_AGENCY_CONTACT_PERSON_FAIL = "UPDATE_AGENCY_CONTACT_PERSON_FAIL"

// AGENCY_CONTACT_PERSON delete
export const DELETE_AGENCY_CONTACT_PERSON_REQUEST = "DELETE_AGENCY_CONTACT_PERSON_REQUEST";
export const DELETE_AGENCY_CONTACT_PERSON_SUCCESS = "DELETE_AGENCY_CONTACT_PERSON_SUCCESS";
export const DELETE_AGENCY_CONTACT_PERSON_FAIL = "DELETE_AGENCY_CONTACT_PERSON_FAIL"


export const AGENCY_CONTACT_PERSON_CLEAR_ERROR = "AGENCY_CONTACT_PERSON_CLEAR_ERROR"



