// Regional office create/post
export const REGIONAL_OFFICE_CREATE_REQUEST = "REGIONAL_OFFICE_CREATE_REQUEST";
export const REGIONAL_OFFICE_CREATE_SUCCESS = "REGIONAL_OFFICE_CREATE_SUCCESS";
export const REGIONAL_OFFICE_CREATE_FAIL = "REGIONAL_OFFICE_CREATE_FAIL"

// Regional office get
export const REGIONAL_OFFICE_REQUEST = "REGIONAL_OFFICE_REQUEST";
export const REGIONAL_OFFICE_SUCCESS = "REGIONAL_OFFICE_SUCCESS";
export const REGIONAL_OFFICE_FAIL = "REGIONAL_OFFICE_FAIL"


// Regional office find
export const FIND_REGIONAL_OFFICE_REQUEST = "FIND_REGIONAL_OFFICE_REQUEST";
export const FIND_REGIONAL_OFFICE_SUCCESS = "FIND_REGIONAL_OFFICE_SUCCESS";
export const FIND_REGIONAL_OFFICE_FAIL = "FIND_REGIONAL_OFFICE_FAIL"

// Regional office update/put
export const UPDATE_REGIONAL_OFFICE_REQUEST = "UPDATE_REGIONAL_OFFICE_REQUEST";
export const UPDATE_REGIONAL_OFFICE_SUCCESS = "UPDATE_REGIONAL_OFFICE_SUCCESS";
export const UPDATE_REGIONAL_OFFICE_FAIL = "UPDATE_REGIONAL_OFFICE_FAIL"

// Regional office update/put
export const DELETE_REGIONAL_OFFICE_REQUEST = "DELETE_REGIONAL_OFFICE_REQUEST";
export const DELETE_REGIONAL_OFFICE_SUCCESS = "DELETE_REGIONAL_OFFICE_SUCCESS";
export const DELETE_REGIONAL_OFFICE_FAIL = "DELETE_REGIONAL_OFFICE_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"
