import {
  CREATE_DZONGKHAGE_REQUEST,
  CREATE_DZONGKHAGE_SUCCESS,
  CREATE_DZONGKHAGE_FAIL,
  DZONGKHAGE_REQUEST,
  DZONGKHAGE_SUCCESS,
  DZONGKHAGE_FAIL,
  FIND_DZONGKHAGE_REQUEST,
  FIND_DZONGKHAGE_SUCCESS,
  FIND_DZONGKHAGE_FAIL,
  UPDATE_DZONGKHAGE_REQUEST,
  UPDATE_DZONGKHAGE_SUCCESS,
  UPDATE_DZONGKHAGE_FAIL,
  DELETE_DZONGKHAGE_REQUEST,
  DELETE_DZONGKHAGE_SUCCESS,
  DELETE_DZONGKHAGE_FAIL,
  CLEAR_ERROR,
} from "../../constants/LocationMaster/DzongkhageConstant";

export const dzongkhageReducer = (state = { dzongkhages: [] }, action) => {
  switch (action.type) {
    case DZONGKHAGE_REQUEST:
    case CREATE_DZONGKHAGE_REQUEST:
    case UPDATE_DZONGKHAGE_REQUEST:
    case DELETE_DZONGKHAGE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DZONGKHAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhages: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DZONGKHAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhages: [...state.dzongkhages, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DZONGKHAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhages: [
          ...state.dzongkhages.filter(
            (item) => item.dzongkhagId !== action.payload.dzongkhagId
          ),
          action.payload,
        ].sort((pre, post) => pre.dzongkhagId - post.dzongkhagId),
        message: action.message,
        status: action.status,
      };

    case DELETE_DZONGKHAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhages: [
          ...state.dzongkhages.filter(
            (item) => item.dzongkhagId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_DZONGKHAGE_SUCCESS":
      return {
        loading: false,
        dzongkhages: [
          ...state.dzongkhages.filter(
            (item) => item.dzongkhagId !== action.payload.dzongkhagId
          ),
          action.payload,
        ].sort((pre, post) => pre.dzongkhagId - post.dzongkhagId),
        message: action.message,
        status: action.status,
      };

    case DZONGKHAGE_FAIL:
      return {
        ...state,
        loading: false,
        dzongkhages: [...state.dzongkhages],
        error: action.payload,
      };

    case CREATE_DZONGKHAGE_FAIL:
    case UPDATE_DZONGKHAGE_FAIL:
    case DELETE_DZONGKHAGE_FAIL:
      return {
        ...state,
        loading: false,
        dzongkhages: [...state.dzongkhages],
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const finddzongkhageReducer = (state = { dzongkhage: {} }, action) => {
  switch (action.type) {
    case FIND_DZONGKHAGE_REQUEST:
      return {
        loading: true,
        dzongkhage: {},
      };
    case FIND_DZONGKHAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dzongkhage: action.payload,
      };

    case FIND_DZONGKHAGE_FAIL:
      return {
        ...state,
        loading: false,
        dzongkhage: {},
        error: action.payload,
      };
    case "EMPTY_DZONGKHAGE":
      return {
        ...state,
        dzongkhage: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
