import {
    CREATE_USER_ROLE_DETAIL_REQUEST,
    CREATE_USER_ROLE_DETAIL_SUCCESS,
    CREATE_USER_ROLE_DETAIL_FAIL,
    USER_ROLE_DETAIL_REQUEST,
    USER_ROLE_DETAIL_SUCCESS,
    USER_ROLE_DETAIL_FAIL,
    FIND_USER_ROLE_DETAIL_REQUEST,
    FIND_USER_ROLE_DETAIL_SUCCESS,
    FIND_USER_ROLE_DETAIL_FAIL,
    UPDATE_USER_ROLE_DETAIL_REQUEST,
    UPDATE_USER_ROLE_DETAIL_SUCCESS,
    UPDATE_USER_ROLE_DETAIL_FAIL,
    USER_ROLE_DETAIL_CLEAR_ERROR,
} from "../../constants/User/UserRoleDetailConstant";

export const getUserRoleDetailReducer = (state = { userRoleDetails: [] }, action) => {
    switch (action.type) {
        case USER_ROLE_DETAIL_REQUEST:
        case CREATE_USER_ROLE_DETAIL_REQUEST:
        case UPDATE_USER_ROLE_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
            };

        case USER_ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                userRoleDetails: action.payload,
                message: null,
                status: null,
            };

        case CREATE_USER_ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                userRoleDetails: [...state.userRoleDetails, action.payload],
                message: action.message,
                status: action.status,
            };

        case UPDATE_USER_ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                userRoleDetails: [
                    ...state.userRoleDetails.filter(
                        (item) => item.roleId !== action.payload.roleId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.roleId - post.roleId),
                message: action.message,
                status: action.status,
            };

        case USER_ROLE_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
            };

        case CREATE_USER_ROLE_DETAIL_FAIL:
        case UPDATE_USER_ROLE_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                message: action.message,
                status: action.status,
            };

        case USER_ROLE_DETAIL_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findUserRoleDetailReducer = (state = { userRoleDetail: {} }, action) => {
    switch (action.type) {
        case FIND_USER_ROLE_DETAIL_REQUEST:
            return {
                userRoleDetail: {},
                loading: true,
            };

        case FIND_USER_ROLE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                userRoleDetail: action.payload,
            };

        case FIND_USER_ROLE_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                userRoleDetail: {},
                error: action.payload
            };

        case "EMPTY_USER_ROLE_DETAIL":
            return {
                ...state,
                userRoleDetail: {}
            }

        case USER_ROLE_DETAIL_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};
