import {
  RECEIPT_LIST_REQUEST,
  RECEIPT_LIST_SUCCESS,
  RECEIPT_LIST_FAIL,
  RECEIPT_LIST_CLEAR_ERROR,
} from "../../constants/ReceiptList/ReceiptListConstant";

export const receiptListReducer = (state = { receiptLists: [] }, action) => {
  switch (action.type) {
    case RECEIPT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        receiptLists:[],
        status: null,
        error: null,
      };
    case RECEIPT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptLists: action.payload,
        totalData : action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case RECEIPT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RECEIPT_LIST_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
