
export const CREATE_RECEIPT_VOUCHER_REQUEST = "CREATE_RECEIPT_VOUCHER_REQUEST";
export const CREATE_RECEIPT_VOUCHER_SUCCESS = "CREATE_RECEIPT_VOUCHER_SUCCESS";
export const CREATE_RECEIPT_VOUCHER_FAIL = "CREATE_RECEIPT_VOUCHER_FAIL";

export const RECEIPT_VOUCHER_REQUEST = "RECEIPT_VOUCHER_REQUEST";
export const RECEIPT_VOUCHER_SUCCESS = "RECEIPT_VOUCHER_SUCCESS";
export const RECEIPT_VOUCHER_FAIL = "RECEIPT_VOUCHER_FAIL";


export const FIND_RECEIPT_VOUCHER_REQUEST = "FIND_RECEIPT_VOUCHER_REQUEST";
export const FIND_RECEIPT_VOUCHER_SUCCESS = "FIND_RECEIPT_VOUCHER_SUCCESS";
export const FIND_RECEIPT_VOUCHER_FAIL = "FIND_RECEIPT_VOUCHER_FAIL";


export const UPDATE_RECEIPT_VOUCHER_REQUEST = "UPDATE_RECEIPT_VOUCHER_REQUEST";
export const UPDATE_RECEIPT_VOUCHER_SUCCESS = "UPDATE_RECEIPT_VOUCHER_SUCCESS";
export const UPDATE_RECEIPT_VOUCHER_FAIL = "UPDATE_RECEIPT_VOUCHER_FAIL";



export const CLEAR_RECEIPT_VOUCHER_ERROR = "CLEAR_RECEIPT_VOUCHER_ERROR";