// Inbox get
export const INBOX_NOTIFICATION_REQUEST = "INBOX_NOTIFICATION_REQUEST";
export const INBOX_NOTIFICATION_SUCCESS = "INBOX_NOTIFICATION_SUCCESS";
export const INBOX_NOTIFICATION_FAIL = "INBOX_NOTIFICATION_FAIL"

// Inbox find
export const FIND_INBOX_NOTIFICATION_REQUEST = "FIND_INBOX_NOTIFICATION_REQUEST";
export const FIND_INBOX_NOTIFICATION_SUCCESS = "FIND_INBOX_NOTIFICATION_SUCCESS";
export const FIND_INBOX_NOTIFICATION_FAIL = "FIND_INBOX_NOTIFICATION_FAIL"

//Inbox update/put
export const UPDATE_INBOX_NOTIFICATION_REQUEST = "UPDATE_INBOX_NOTIFICATION_REQUEST";
export const UPDATE_INBOX_NOTIFICATION_SUCCESS = "UPDATE_INBOX_NOTIFICATION_SUCCESS";
export const UPDATE_INBOX_NOTIFICATION_FAIL = "UPDATE_INBOX_NOTIFICATION_FAIL"

export const INBOX_NOTIFICATION_CLEAR_ERROR = "INBOX_NOTIFICATION_CLEAR_ERROR";