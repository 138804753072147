import {
  CREATE_MAJOR_GROUP_REQUEST,
  CREATE_MAJOR_GROUP_SUCCESS,
  CREATE_MAJOR_GROUP_FAIL,
  MAJOR_GROUP_REQUEST,
  MAJOR_GROUP_SUCCESS,
  MAJOR_GROUP_FAIL,
  FIND_MAJOR_GROUP_REQUEST,
  FIND_MAJOR_GROUP_SUCCESS,
  FIND_MAJOR_GROUP_FAIL,
  UPDATE_MAJOR_GROUP_REQUEST,
  UPDATE_MAJOR_GROUP_SUCCESS,
  UPDATE_MAJOR_GROUP_FAIL,
  DELETE_MAJOR_GROUP_REQUEST,
  DELETE_MAJOR_GROUP_SUCCESS,
  DELETE_MAJOR_GROUP_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/MajorGroupConstant";

export const majorGroupReducer = (state = { majorGroups: [] }, action) => {
  switch (action.type) {
    case MAJOR_GROUP_REQUEST:
    case CREATE_MAJOR_GROUP_REQUEST:
    case UPDATE_MAJOR_GROUP_REQUEST:
    case DELETE_MAJOR_GROUP_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: action.payload,
        message: null,
        status: null,
      };

    case CREATE_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: [...state.majorGroups, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: [
          ...state.majorGroups.filter(
            (item) => item.majorGroupId !== action.payload.majorGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.majorGroupId - post.majorGroupId),
        message: action.message,
        status: action.status,
      };

    case DELETE_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroups: [
          ...state.majorGroups.filter(
            (item) => item.majorGroupId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_MAJOR_GROUP_SUCCESS":
      return {
        loading: false,
        majorGroups: [
          ...state.majorGroups.filter(
            (item) => item.majorGroupId !== action.payload.majorGroupId
          ),
          action.payload,
        ].sort((pre, post) => pre.majorGroupId - post.majorGroupId),
        message: action.message,
        status: action.status,
      };

    case MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_MAJOR_GROUP_FAIL:
    case UPDATE_MAJOR_GROUP_FAIL:
    case DELETE_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findMajorGroupReducer = (state = { majorGroup: {} }, action) => {
  switch (action.type) {
    case FIND_MAJOR_GROUP_REQUEST:
      return {
        majorGroup: {},
        loading: true,
      };

    case FIND_MAJOR_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        majorGroup: action.payload,
      };

    case FIND_MAJOR_GROUP_FAIL:
      return {
        ...state,
        loading: false,
        majorGroup: {},
        error: action.payload,
      };

    case "EMPTY_MAJOR_GROUP":
      return {
        ...state,
        majorGroup: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
