//get all deposit maintenance data
export const DEPOSIT_MAINTENANCE_REQUEST = "DEPOSIT_MAINTENANCE_REQUEST";
export const DEPOSIT_MAINTENANCE_SUCCESS = "DEPOSIT_MAINTENANCE_SUCCESS";
export const DEPOSIT_MAINTENANCE_FAIL = "DEPOSIT_MAINTENANCE_FAIL";

// find
export const FIND_DEPOSIT_MAINTENANCE_REQUEST = "FIND_DEPOSIT_MAINTENANCE_REQUEST";
export const FIND_DEPOSIT_MAINTENANCE_SUCCESS = "FIND_DEPOSIT_MAINTENANCE_SUCCESS";
export const FIND_DEPOSIT_MAINTENANCE_FAIL = "FIND_DEPOSIT_MAINTENANCE_FAIL";

// DELETE
export const DEPOSIT_MAINTENANCE_CANCEL_REQUEST = "DEPOSIT_MAINTENANCE_CANCEL_REQUEST";
export const DEPOSIT_MAINTENANCE_CANCEL_SUCCESS = "DEPOSIT_MAINTENANCE_CANCEL_SUCCESS";
export const DEPOSIT_MAINTENANCE_CANCEL_FAIL = "DEPOSIT_MAINTENANCE_CANCEL_FAIL";

// unmap
export const DEPOSIT_MAINTENANCE_UNMAP_REQUEST = "DEPOSIT_MAINTENANCE_UNMAP_REQUEST";
export const DEPOSIT_MAINTENANCE_UNMAP_SUCCESS = "DEPOSIT_MAINTENANCE_UNMAP_SUCCESS";
export const DEPOSIT_MAINTENANCE_UNMAP_FAIL = "DEPOSIT_MAINTENANCE_UNMAP_FAIL";

export const DEPOSIT_MAINTENANCE_CLEAR_ERROR = "DEPOSIT_MAINTENANCE_CLEAR_ERROR";