// create
export const CREATE_DISBURSE_ALLOW_REQUEST = "CREATE_DISBURSE_ALLOW_REQUEST";
export const CREATE_DISBURSE_ALLOW_SUCCESS = "CREATE_DISBURSE_ALLOW_SUCCESS";
export const CREATE_DISBURSE_ALLOW_FAIL = "CREATE_DISBURSE_ALLOW_FAIL";

// get
export const DISBURSE_ALLOW_REQUEST = "DISBURSE_ALLOW_REQUEST";
export const DISBURSE_ALLOW_SUCCESS = "DISBURSE_ALLOW_SUCCESS";
export const DISBURSE_ALLOW_FAIL = "DISBURSE_ALLOW_FAIL";

// find
export const FIND_DISBURSE_ALLOW_REQUEST = "FIND_DISBURSE_ALLOW_REQUEST";
export const FIND_DISBURSE_ALLOW_SUCCESS = "FIND_DISBURSE_ALLOW_SUCCESS";
export const FIND_DISBURSE_ALLOW_FAIL = "FIND_DISBURSE_ALLOW_FAIL";

// update
export const UPDATE_DISBURSE_ALLOW_REQUEST = "UPDATE_DISBURSE_ALLOW_REQUEST";
export const UPDATE_DISBURSE_ALLOW_SUCCESS = "UPDATE_DISBURSE_ALLOW_SUCCESS";
export const UPDATE_DISBURSE_ALLOW_FAIL = "UPDATE_DISBURSE_ALLOW_FAIL";

// delete
export const DELETE_DISBURSE_ALLOW_REQUEST = "DELETE_DISBURSE_ALLOW_REQUEST";
export const DELETE_DISBURSE_ALLOW_SUCCESS = "DELETE_DISBURSE_ALLOW_SUCCESS";
export const DELETE_DISBURSE_ALLOW_FAIL = "DELETE_DISBURSE_ALLOW_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
