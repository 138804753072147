// BTC_EDITION create
export const CREATE_BTC_EDITION_REQUEST = "CREATE_BTC_EDITION_REQUEST";
export const CREATE_BTC_EDITION_SUCCESS = "CREATE_BTC_EDITION_SUCCESS";
export const CREATE_BTC_EDITION_FAIL = "CREATE_BTC_EDITION_FAIL";
export const CREATE_BTC_EDITION_CONFLICT = "CREATE_BTC_EDITION_CONFLICT";

// BTC_EDITION get
export const BTC_EDITION_REQUEST = "BTC_EDITION_REQUEST";
export const BTC_EDITION_SUCCESS = "BTC_EDITION_SUCCESS";
export const BTC_EDITION_FAIL = "BTC_EDITION_FAIL";

// BTC_EDITION find
export const FIND_BTC_EDITION_REQUEST = "FIND_BTC_EDITION_REQUEST";
export const FIND_BTC_EDITION_SUCCESS = "FIND_BTC_EDITION_SUCCESS";
export const FIND_BTC_EDITION_FAIL = "FIND_BTC_EDITION_FAIL";

//BTC_EDITION update/put
export const UPDATE_BTC_EDITION_REQUEST = "UPDATE_BTC_EDITION_REQUEST";
export const UPDATE_BTC_EDITION_SUCCESS = "UPDATE_BTC_EDITION_SUCCESS";
export const UPDATE_BTC_EDITION_FAIL = "UPDATE_BTC_EDITION_FAIL";

// BTC_EDITION delete
export const DELETE_BTC_EDITION_REQUEST = "DELETE_BTC_EDITION_REQUEST";
export const DELETE_BTC_EDITION_SUCCESS = "DELETE_BTC_EDITION_SUCCESS";
export const DELETE_BTC_EDITION_FAIL = "DELETE_BTC_EDITION_FAIL";

export const BTC_EDITION_CLEAR_ERROR = "BTC_EDITION_CLEAR_ERROR";
