import {
  CREATE_BANK_BRANCH_REQUEST,
  CREATE_BANK_BRANCH_SUCCESS,
  CREATE_BANK_BRANCH_FAIL,
  BANK_BRANCH_REQUEST,
  BANK_BRANCH_SUCCESS,
  BANK_BRANCH_FAIL,
  FIND_BANK_BRANCH_REQUEST,
  FIND_BANK_BRANCH_SUCCESS,
  FIND_BANK_BRANCH_FAIL,
  UPDATE_BANK_BRANCH_REQUEST,
  UPDATE_BANK_BRANCH_SUCCESS,
  UPDATE_BANK_BRANCH_FAIL,
  DELETE_BANK_BRANCH_REQUEST,
  DELETE_BANK_BRANCH_SUCCESS,
  DELETE_BANK_BRANCH_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/BankBranchConstant";

export const bankBranchReducer = (state = { bankBranches: [] }, action) => {
  switch (action.type) {
    case BANK_BRANCH_REQUEST:
    case CREATE_BANK_BRANCH_REQUEST:
    case UPDATE_BANK_BRANCH_REQUEST:
    case DELETE_BANK_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: [...state.bankBranches, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: [
          ...state.bankBranches.filter(
            (item) => item.bankBranchId !== action.payload.bankBranchId
          ),
          action.payload,
        ].sort((pre, post) => pre.bankBranchId - post.bankBranchId),
        message: action.message,
        status: action.status,
      };

    case DELETE_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranches: [
          ...state.bankBranches.filter(
            (item) => item.bankBranchId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BANK_BRANCH_SUCCESS":
      return {
        loading: false,
        bankBranches: [
          ...state.bankBranches.filter(
            (item) => item.bankBranchId !== action.payload.bankBranchId
          ),
          action.payload,
        ].sort((pre, post) => pre.bankBranchId - post.bankBranchId),
        message: action.message,
        status: action.status,
      };

    case BANK_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_BANK_BRANCH_FAIL:
    case UPDATE_BANK_BRANCH_FAIL:
    case DELETE_BANK_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBankBranchReducer = (state = { bankBranch: {} }, action) => {
  switch (action.type) {
    case FIND_BANK_BRANCH_REQUEST:
      return {
        bankBranch: {},
        loading: true,
      };

    case FIND_BANK_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        bankBranch: action.payload,
      };

    case FIND_BANK_BRANCH_FAIL:
      return {
        ...state,
        loading: false,
        bankBranch: {},
        error: action.payload,
      };

    case "EMPTY_BANK_BRANCH":
      return {
        ...state,
        bankBranch: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
