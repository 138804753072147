import {
  INBOX_NOTIFICATION_REQUEST,
  INBOX_NOTIFICATION_SUCCESS,
  INBOX_NOTIFICATION_FAIL,

  FIND_INBOX_NOTIFICATION_REQUEST,
  FIND_INBOX_NOTIFICATION_SUCCESS,
  FIND_INBOX_NOTIFICATION_FAIL,
  
  UPDATE_INBOX_NOTIFICATION_REQUEST,
  UPDATE_INBOX_NOTIFICATION_SUCCESS,
  UPDATE_INBOX_NOTIFICATION_FAIL,

  INBOX_NOTIFICATION_CLEAR_ERROR,
} from "../../constants/Inbox/InboxConstant";

// get all data of list
export const getInboxNotificationReducer = (state = { inboxNotifications: [] }, action) => {
  switch (action.type) {
    case INBOX_NOTIFICATION_REQUEST:
    case UPDATE_INBOX_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };
    case INBOX_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        inboxNotifications: action.payload,
        message: null,
        status: null,
      };

    case UPDATE_INBOX_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        inboxNotifications: [
          ...state.inboxNotifications.filter(
            (item) => item.notificationId !== action.payload.notificationId
          ),
          action.payload,
        ].sort((pre, post) => pre.notificationId - post.notificationId),
        message: action.message,
        status: action.status,
      };

    case INBOX_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_INBOX_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case INBOX_NOTIFICATION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

      case "EMPTY_ALL_INBOX_NOTIFICATIONS":
      return {
        ...state,
        inboxNotifications: [],
      };

    default:
      return state;
  }
};

// find one
export const findInboxNotificationReducer = (state = { inboxNotification: {} }, action) => {
  switch (action.type) {
    case FIND_INBOX_NOTIFICATION_REQUEST:
      return {
        inboxNotification: {},
        loading: true,
      };

    case FIND_INBOX_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        inboxNotification: action.payload,
      };

    case FIND_INBOX_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        inboxNotification: {},
        error: action.payload,
      };

    case "EMPTY_INBOX_NOTIFICATION":
      return {
        ...state,
        inboxNotification: {},
      };

    case INBOX_NOTIFICATION_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};