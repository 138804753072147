// create
export const CREATE_COA_GROUPS_REQUEST = "CREATE_COA_GROUPS_REQUEST";
export const CREATE_COA_GROUPS_SUCCESS = "CREATE_COA_GROUPS_SUCCESS";
export const CREATE_COA_GROUPS_FAIL = "CREATE_COA_GROUPS_FAIL";

// get
export const COA_GROUPS_REQUEST = "COA_GROUPS_REQUEST";
export const COA_GROUPS_SUCCESS = "COA_GROUPS_SUCCESS";
export const COA_GROUPS_FAIL = "COA_GROUPS_FAIL";

// find
export const FIND_COA_GROUPS_REQUEST = "FIND_COA_GROUPS_REQUEST";
export const FIND_COA_GROUPS_SUCCESS = "FIND_COA_GROUPS_SUCCESS";
export const FIND_COA_GROUPS_FAIL = "FIND_COA_GROUPS_FAIL";

// update
export const UPDATE_COA_GROUPS_REQUEST = "UPDATE_COA_GROUPS_REQUEST";
export const UPDATE_COA_GROUPS_SUCCESS = "UPDATE_COA_GROUPS_SUCCESS";
export const UPDATE_COA_GROUPS_FAIL = "UPDATE_COA_GROUPS_FAIL";

// delete
export const DELETE_COA_GROUPS_REQUEST = "DELETE_COA_GROUPS_REQUEST";
export const DELETE_COA_GROUPS_SUCCESS = "DELETE_COA_GROUPS_SUCCESS";
export const DELETE_COA_GROUPS_FAIL = "DELETE_COA_GROUPS_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
