// Receipt Allocation To RRCO create
export const CREATE_RECEIPT_ALLOCATION_RRCO_REQUEST = "CREATE_RECEIPT_ALLOCATION_RRCO_REQUEST";
export const CREATE_RECEIPT_ALLOCATION_RRCO_SUCCESS = "CREATE_RECEIPT_ALLOCATION_RRCO_SUCCESS";
export const CREATE_RECEIPT_ALLOCATION_RRCO_FAIL = "CREATE_RECEIPT_ALLOCATION_RRCO_FAIL";

// Receipt Allocation To RRCO get
export const RECEIPT_ALLOCATION_RRCO_REQUEST = "RECEIPT_ALLOCATION_RRCO_REQUEST";
export const RECEIPT_ALLOCATION_RRCO_SUCCESS = "RECEIPT_ALLOCATION_RRCO_SUCCESS";
export const RECEIPT_ALLOCATION_RRCO_FAIL = "RECEIPT_ALLOCATION_RRCO_FAIL";

export const RECEIPT_ALLOCATION_RRCO_CLEAR_ERROR = "RECEIPT_ALLOCATION_RRCO_CLEAR_ERROR";