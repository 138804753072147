import {
  CREATE_DISBURSE_ALLOW_REQUEST,
  CREATE_DISBURSE_ALLOW_SUCCESS,
  CREATE_DISBURSE_ALLOW_FAIL,
  DISBURSE_ALLOW_REQUEST,
  DISBURSE_ALLOW_SUCCESS,
  DISBURSE_ALLOW_FAIL,
  FIND_DISBURSE_ALLOW_REQUEST,
  FIND_DISBURSE_ALLOW_SUCCESS,
  FIND_DISBURSE_ALLOW_FAIL,
  UPDATE_DISBURSE_ALLOW_REQUEST,
  UPDATE_DISBURSE_ALLOW_SUCCESS,
  UPDATE_DISBURSE_ALLOW_FAIL,
  DELETE_DISBURSE_ALLOW_REQUEST,
  DELETE_DISBURSE_ALLOW_SUCCESS,
  DELETE_DISBURSE_ALLOW_FAIL,
  CLEAR_ERROR,
} from "../../constants/OthersMaster/DisburseAllowableConstant";

export const disburseAllowReducer = (
  state = { disburseAllowables: [] },
  action
) => {
  switch (action.type) {
    case DISBURSE_ALLOW_REQUEST:
    case CREATE_DISBURSE_ALLOW_REQUEST:
    case UPDATE_DISBURSE_ALLOW_REQUEST:
    case DELETE_DISBURSE_ALLOW_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case DISBURSE_ALLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseAllowables: action.payload,
        message: null,
        status: null,
      };

    case CREATE_DISBURSE_ALLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseAllowables: [...state.disburseAllowables, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_DISBURSE_ALLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseAllowables: [
          ...state.disburseAllowables.filter(
            (item) =>
              item.disbursementAllowableId !==
              action.payload.disbursementAllowableId
          ),
          action.payload,
        ].sort(
          (pre, post) =>
            pre.disbursementAllowableId - post.disbursementAllowableId
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_DISBURSE_ALLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseAllowables: [
          ...state.disburseAllowables.filter(
            (item) => item.disbursementAllowableId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DISBURSE_ALLOW_SUCCESS":
      return {
        loading: false,
        disburseAllowables: [
          ...state.disburseAllowables.filter(
            (item) =>
              item.disbursementAllowableId !==
              action.payload.disbursementAllowableId
          ),
          action.payload,
        ].sort(
          (pre, post) =>
            pre.disbursementAllowableId - post.disbursementAllowableId
        ),
        message: action.message,
        status: action.status,
      };

    case DISBURSE_ALLOW_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_DISBURSE_ALLOW_FAIL:
    case UPDATE_DISBURSE_ALLOW_FAIL:
    case DELETE_DISBURSE_ALLOW_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findDisburseAllowReducer = (
  state = { disburseAllowable: {} },
  action
) => {
  switch (action.type) {
    case FIND_DISBURSE_ALLOW_REQUEST:
      return {
        disburseAllowable: {},
        loading: true,
      };

    case FIND_DISBURSE_ALLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        disburseAllowable: action.payload,
      };

    case FIND_DISBURSE_ALLOW_FAIL:
      return {
        ...state,
        loading: false,
        disburseAllowable: {},
        error: action.payload,
      };

    case "EMPTY_DISBURSE_ALLOW":
      return {
        ...state,
        disburseAllowable: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
