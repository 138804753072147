// create
export const CREATE_REV_GROUP_REQUEST = "CREATE_REV_GROUP_REQUEST";
export const CREATE_REV_GROUP_SUCCESS = "CREATE_REV_GROUP_SUCCESS";
export const CREATE_REV_GROUP_FAIL = "CREATE_REV_GROUP_FAIL";

// get
export const REV_GROUP_REQUEST = "REV_GROUP_REQUEST";
export const REV_GROUP_SUCCESS = "REV_GROUP_SUCCESS";
export const REV_GROUP_FAIL = "REV_GROUP_FAIL";

// find
export const FIND_REV_GROUP_REQUEST = "FIND_REV_GROUP_REQUEST";
export const FIND_REV_GROUP_SUCCESS = "FIND_REV_GROUP_SUCCESS";
export const FIND_REV_GROUP_FAIL = "FIND_REV_GROUP_FAIL";

// update
export const UPDATE_REV_GROUP_REQUEST = "UPDATE_REV_GROUP_REQUEST";
export const UPDATE_REV_GROUP_SUCCESS = "UPDATE_REV_GROUP_SUCCESS";
export const UPDATE_REV_GROUP_FAIL = "UPDATE_REV_GROUP_FAIL";

// delete
export const DELETE_REV_GROUP_REQUEST = "DELETE_REV_GROUP_REQUEST";
export const DELETE_REV_GROUP_SUCCESS = "DELETE_REV_GROUP_SUCCESS";
export const DELETE_REV_GROUP_FAIL = "DELETE_REV_GROUP_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
