// create/post
export const CREATE_RISK_LEVEL_THRESHOLD_REQUEST = "CREATE_RISK_LEVEL_THRESHOLD_REQUEST";
export const CREATE_RISK_LEVEL_THRESHOLD_SUCCESS = "CREATE_RISK_LEVEL_THRESHOLD_SUCCESS";
export const CREATE_RISK_LEVEL_THRESHOLD_FAIL = "CREATE_RISK_LEVEL_THRESHOLD_FAIL";

// get
export const RISK_LEVEL_THRESHOLD_REQUEST = "RISK_LEVEL_THRESHOLD_REQUEST";
export const RISK_LEVEL_THRESHOLD_SUCCESS = "RISK_LEVEL_THRESHOLD_SUCCESS";
export const RISK_LEVEL_THRESHOLD_FAIL = "RISK_LEVEL_THRESHOLD_FAIL";

// find
export const FIND_RISK_LEVEL_THRESHOLD_REQUEST = "FIND_RISK_LEVEL_THRESHOLD_REQUEST";
export const FIND_RISK_LEVEL_THRESHOLD_SUCCESS = "FIND_RISK_LEVEL_THRESHOLD_SUCCESS";
export const FIND_RISK_LEVEL_THRESHOLD_FAIL = "FIND_RISK_LEVEL_THRESHOLD_FAIL";

// update/put
export const UPDATE_RISK_LEVEL_THRESHOLD_REQUEST = "UPDATE_RISK_LEVEL_THRESHOLD_REQUEST";
export const UPDATE_RISK_LEVEL_THRESHOLD_SUCCESS = "UPDATE_RISK_LEVEL_THRESHOLD_SUCCESS";
export const UPDATE_RISK_LEVEL_THRESHOLD_FAIL = "UPDATE_RISK_LEVEL_THRESHOLD_FAIL";

// update/put
export const DELETE_RISK_LEVEL_THRESHOLD_REQUEST = "DELETE_RISK_LEVEL_THRESHOLD_REQUEST";
export const DELETE_RISK_LEVEL_THRESHOLD_SUCCESS = "DELETE_RISK_LEVEL_THRESHOLD_SUCCESS";
export const DELETE_RISK_LEVEL_THRESHOLD_FAIL = "DELETE_RISK_LEVEL_THRESHOLD_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";