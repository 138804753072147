import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERROR,
} from "../constants/userConstants";

export const userReducer = (state = { user: [] }, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        message:null,
        status:null
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
        userInfo:action.userInfo,
        menu: action.menuPermission,
        userAgency: action.userAgency,
        userRegionalOffice: action.userRegionalOffice,
        dashboardList: action.dashboardList,
        status:null,
        message: action.message
      };

      case LOGOUT_SUCCESS:
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          message: action.message,
          status: action.status,
          user: null,
        };

    case USER_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        message: action.message,
        user: null,
        error: action.payload,
      };

      case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        message:null,
        status:null
      };

    default:
      return state;
  }
};



