import {
    CREATE_COA_TAX_TYPE_PROCESS_MAP_REQUEST,
    CREATE_COA_TAX_TYPE_PROCESS_MAP_SUCCESS,
    CREATE_COA_TAX_TYPE_PROCESS_MAP_FAIL,

    COA_TAX_TYPE_PROCESS_MAP_REQUEST,
    COA_TAX_TYPE_PROCESS_MAP_SUCCESS,
    COA_TAX_TYPE_PROCESS_MAP_FAIL,

    FIND_COA_TAX_TYPE_PROCESS_MAP_REQUEST,
    FIND_COA_TAX_TYPE_PROCESS_MAP_SUCCESS,
    FIND_COA_TAX_TYPE_PROCESS_MAP_FAIL,

    UPDATE_COA_TAX_TYPE_PROCESS_MAP_REQUEST,
    UPDATE_COA_TAX_TYPE_PROCESS_MAP_SUCCESS,
    UPDATE_COA_TAX_TYPE_PROCESS_MAP_FAIL,

    DELETE_COA_TAX_TYPE_PROCESS_MAP_REQUEST,
    DELETE_COA_TAX_TYPE_PROCESS_MAP_SUCCESS,
    DELETE_COA_TAX_TYPE_PROCESS_MAP_FAIL,

    COA_TAX_TYPE_PROCESS_MAP_CLEAR_ERROR,
} from "../../constants/CoaMapping/CoaTaxTypeProcessConstant";




// get all data of list
export const getCoaTaxTypeProcessMapReducer = (state = { coaTaxTypeProcessMaps: [] }, action) => {
    switch (action.type) {
        case COA_TAX_TYPE_PROCESS_MAP_REQUEST:
        case CREATE_COA_TAX_TYPE_PROCESS_MAP_REQUEST:
        case UPDATE_COA_TAX_TYPE_PROCESS_MAP_REQUEST:
        case DELETE_COA_TAX_TYPE_PROCESS_MAP_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null,
                error: null,
            };
        case COA_TAX_TYPE_PROCESS_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: action.payload,
                message: null,
                status: null,
            };

        case CREATE_COA_TAX_TYPE_PROCESS_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: [...state.coaTaxTypeProcessMaps, action.payload],
                message: action.message,
                status: action.status
            };

        case UPDATE_COA_TAX_TYPE_PROCESS_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: [
                    ...state.coaTaxTypeProcessMaps.filter(
                        (item) => item.coaTaxTypeMappingModelId !== action.payload.coaTaxTypeMappingModelId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.coaTaxTypeMappingModelId - post.coaTaxTypeMappingModelId),
                message: action.message,
                status: action.status,

            };

        case DELETE_COA_TAX_TYPE_PROCESS_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: [
                    ...state.coaTaxTypeProcessMaps.filter(
                        (item) => item.coaTaxTypeMappingModelId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case COA_TAX_TYPE_PROCESS_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: [...state.coaTaxTypeProcessMaps],
                error: action.payload,
            };

        case CREATE_COA_TAX_TYPE_PROCESS_MAP_FAIL:
        case UPDATE_COA_TAX_TYPE_PROCESS_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: [...state.coaTaxTypeProcessMaps],
                message: action.message,
                status: action.status,
            };

        case DELETE_COA_TAX_TYPE_PROCESS_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMaps: [...state.coaTaxTypeProcessMaps],
                error: action.payload,
            };

        case COA_TAX_TYPE_PROCESS_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};


// find one
export const findCoaTaxTypeProcessMapReducer = (state = { coaTaxTypeProcessMap: {} }, action) => {
    switch (action.type) {
        case FIND_COA_TAX_TYPE_PROCESS_MAP_REQUEST:
            return {
                coaTaxTypeProcessMap: {},
                loading: true,
            };

        case FIND_COA_TAX_TYPE_PROCESS_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMap: action.payload,
            };

        case FIND_COA_TAX_TYPE_PROCESS_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaTaxTypeProcessMap: {},
                error: action.payload,
            };

        case "EMPTY_COA_TAX_TYPE_PROCESS_MAP":
            return {
                ...state,
                coaTaxTypeProcessMap: {}
            }

        case COA_TAX_TYPE_PROCESS_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};