import {
  CREATE_UNMAP_PAYMENT_ADVICE_REQUEST,
  CREATE_UNMAP_PAYMENT_ADVICE_SUCCESS,
  CREATE_UNMAP_PAYMENT_ADVICE_FAIL,
  FIND_UNMAP_PAYMENT_ADVICE_REQUEST,
  FIND_UNMAP_PAYMENT_ADVICE_SUCCESS,
  FIND_UNMAP_PAYMENT_ADVICE_FAIL,
  UPDATE_UNMAP_PAYMENT_ADVICE_REQUEST,
  UPDATE_UNMAP_PAYMENT_ADVICE_SUCCESS,
  UPDATE_UNMAP_PAYMENT_ADVICE_FAIL,

  UNMAP_PAYMENT_ADVICE_CLEAR_ERROR
} from "../../constants/ReceiptMaintenance/UnmapPaymentConstant";

export const getUnmapPaymentReducer = (state = { receiptUnmapPayments: [] }, action) => {
  switch (action.type) {
    case CREATE_UNMAP_PAYMENT_ADVICE_REQUEST:
    case UPDATE_UNMAP_PAYMENT_ADVICE_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_UNMAP_PAYMENT_ADVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        // receiptUnmapPayments: [...state.receiptUnmapPayments, action.payload],
        unmapObject: action.payload,
        message: action.message,
        status: action.status,
      };

      case UPDATE_UNMAP_PAYMENT_ADVICE_SUCCESS:
        return {
          ...state,
          loading: false,
          receiptUnmapPayments: [
            ...state.receiptUnmapPayments.filter(
              (item) => item.receiptUnmapId !== action.payload.receiptUnmapId
            ),
            action.payload,
          ].sort((pre, post) => pre.receiptUnmapId - post.receiptUnmapId),
          message: action.message,
          status: action.status,
        };

    case CREATE_UNMAP_PAYMENT_ADVICE_FAIL:
    case UPDATE_UNMAP_PAYMENT_ADVICE_FAIL:
      return {
        ...state,
        loading: false,
        receiptUnmapPayments: [...state.receiptUnmapPayments],
        message: action.message,
        status: action.status,
      };

    case UNMAP_PAYMENT_ADVICE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_UNMAP_PAYMENT_ADVICE":
      return {
        ...state,
        receiptUnmapPayments: []
      }

    default:
      return state;
  }
};

export const findUnmapPaymentReducer = (state = { receiptUnmapPayment: [] }, action) => {
  switch (action.type) {
    case FIND_UNMAP_PAYMENT_ADVICE_REQUEST:
      return {
        loading: true,
        receiptUnmapPayment: [],
      };
    case FIND_UNMAP_PAYMENT_ADVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptUnmapPayment: action.payload,
      };

    case FIND_UNMAP_PAYMENT_ADVICE_FAIL:
      return {
        ...state,
        loading: false,
        receiptUnmapPayment: [],
        error: action.payload,
      };
    case "EMPTY_UNMAP_PAYMENT_ADVICE":
      return {
        ...state,
        receiptUnmapPayment: [],
      };

    case UNMAP_PAYMENT_ADVICE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};