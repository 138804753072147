import {
    FIND_MAJOR_GROUP_GLHEAD_REQUEST,
    FIND_MAJOR_GROUP_GLHEAD_SUCCESS,
    FIND_MAJOR_GROUP_GLHEAD_FAIL,

    FIND_REVENUE_GROUP_GLHEAD_REQUEST,
    FIND_REVENUE_GROUP_GLHEAD_SUCCESS,
    FIND_REVENUE_GROUP_GLHEAD_FAIL,

    FIND_REVENUE_TYPE_GLHEAD_REQUEST,
    FIND_REVENUE_TYPE_GLHEAD_SUCCESS,
    FIND_REVENUE_TYPE_GLHEAD_FAIL,

    CLEAR_ERROR

} from "../../constants/CoaMapping/CoaFilterConstant";

// find agency type child
export const findMajorGroupGlHeadReducer = (state = { majorGroupGlHeads: [] }, action) => {
    switch (action.type) {
        case FIND_MAJOR_GROUP_GLHEAD_REQUEST:
            return {
                majorGroupGlHeads: [],
                loading: true,
            };

        case FIND_MAJOR_GROUP_GLHEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                majorGroupGlHeads: action.payload,
            };

        case FIND_MAJOR_GROUP_GLHEAD_FAIL:
            return {
                ...state,
                loading: false,
                majorGroupGlHeads: [],
                error: action.payload
            };

        case "EMPTY_MAJOR_GROUP_GLHEAD":
            return {
                ...state,
                majorGroupGlHeads: []
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find parent agency child
export const findRevenueGroupGlHeadReducer = (state = { revenueGroupGlHeads: [] }, action) => {
    switch (action.type) {
        case FIND_REVENUE_GROUP_GLHEAD_REQUEST:
            return {
                revenueGroupGlHeads: [],
                loading: true,
            };

        case FIND_REVENUE_GROUP_GLHEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                revenueGroupGlHeads: action.payload,
            };

        case FIND_REVENUE_GROUP_GLHEAD_FAIL:
            return {
                ...state,
                loading: false,
                revenueGroupGlHeads: [],
                error: action.payload
            };

        case "EMPTY_REVENUE_GROUP_GLHEAD":
            return {
                ...state,
                revenueGroupGlHeads: []
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};

// find agency child
export const findRevenueTypeGlHeadReducer = (state = { revenueTypeGlHeads: [] }, action) => {
    switch (action.type) {
        case FIND_REVENUE_TYPE_GLHEAD_REQUEST:
            return {
                revenueTypeGlHeads: [],
                loading: true,
            };

        case FIND_REVENUE_TYPE_GLHEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                revenueTypeGlHeads: action.payload,
            };

        case FIND_REVENUE_TYPE_GLHEAD_FAIL:
            return {
                ...state,
                loading: false,
                revenueTypeGlHeads: [],
                error: action.payload
            };

        case "EMPTY_REVENUE_TYPE_GLHEAD":
            return {
                ...state,
                revenueTypeGlHeads: []
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
            };

        default:
            return state;
    }
};