import {
  RISK_ASSESSMENT_REQUEST,
  RISK_ASSESSMENT_SUCCESS,
  RISK_ASSESSMENT_FAIL,

  UPDATE_RISK_ASSESSMENT_REQUEST,
  UPDATE_RISK_ASSESSMENT_SUCCESS,
  UPDATE_RISK_ASSESSMENT_FAIL,

  RISK_ASSESSMENT_CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskAssessmentConstant";

export const getRiskAssessmentsReducer = (state = { riskAssessments: [] }, action) => {
  switch (action.type) {
    case RISK_ASSESSMENT_REQUEST:
    case UPDATE_RISK_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case RISK_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAssessments: action.payload,
        status: action.status,
      };

    case UPDATE_RISK_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAssessments: state.riskAssessments.map((item) =>
          (item.id === action.payload.id) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case RISK_ASSESSMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_RISK_ASSESSMENT_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case RISK_ASSESSMENT_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    case "EMPTY_RISK_ASSESSMENT":
      return {
        riskAssessments: []
      };

    default:
      return state;
  }
};