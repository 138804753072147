// create
export const CREATE_ADVANCE_PAYMENT_REQUEST = "CREATE_ADVANCE_PAYMENT_REQUEST";
export const CREATE_ADVANCE_PAYMENT_SUCCESS = "CREATE_ADVANCE_PAYMENT_SUCCESS";
export const CREATE_ADVANCE_PAYMENT_FAIL = "CREATE_ADVANCE_PAYMENT_FAIL";

// get
export const ADVANCE_PAYMENT_REQUEST = "ADVANCE_PAYMENT_REQUEST";
export const ADVANCE_PAYMENT_SUCCESS = "ADVANCE_PAYMENT_SUCCESS";
export const ADVANCE_PAYMENT_FAIL = "ADVANCE_PAYMENT_FAIL";

// find
export const FIND_ADVANCE_PAYMENT_REQUEST = "FIND_ADVANCE_PAYMENT_REQUEST";
export const FIND_ADVANCE_PAYMENT_SUCCESS = "FIND_ADVANCE_PAYMENT_SUCCESS";
export const FIND_ADVANCE_PAYMENT_FAIL = "FIND_ADVANCE_PAYMENT_FAIL";

// update
export const UPDATE_ADVANCE_PAYMENT_REQUEST = "UPDATE_ADVANCE_PAYMENT_REQUEST";
export const UPDATE_ADVANCE_PAYMENT_SUCCESS = "UPDATE_ADVANCE_PAYMENT_SUCCESS";
export const UPDATE_ADVANCE_PAYMENT_FAIL = "UPDATE_ADVANCE_PAYMENT_FAIL";

// delete
export const DELETE_ADVANCE_PAYMENT_REQUEST = "DELETE_ADVANCE_PAYMENT_REQUEST";
export const DELETE_ADVANCE_PAYMENT_SUCCESS = "DELETE_ADVANCE_PAYMENT_SUCCESS";
export const DELETE_ADVANCE_PAYMENT_FAIL = "DELETE_ADVANCE_PAYMENT_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
