
export const CREATE_DISBURSEMENT_VOUCHER_REQUEST = "CREATE_DISBURSEMENT_VOUCHER_REQUEST";
export const CREATE_DISBURSEMENT_VOUCHER_SUCCESS = "CREATE_DISBURSEMENT_VOUCHER_SUCCESS";
export const CREATE_DISBURSEMENT_VOUCHER_FAIL = "CREATE_DISBURSEMENT_VOUCHER_FAIL";

export const DISBURSEMENT_VOUCHER_REQUEST = "DISBURSEMENT_VOUCHER_REQUEST";
export const DISBURSEMENT_VOUCHER_SUCCESS = "DISBURSEMENT_VOUCHER_SUCCESS";
export const DISBURSEMENT_VOUCHER_FAIL = "DISBURSEMENT_VOUCHER_FAIL";


export const FIND_DISBURSEMENT_VOUCHER_REQUEST = "FIND_DISBURSEMENT_VOUCHER_REQUEST";
export const FIND_DISBURSEMENT_VOUCHER_SUCCESS = "FIND_DISBURSEMENT_VOUCHER_SUCCESS";
export const FIND_DISBURSEMENT_VOUCHER_FAIL = "FIND_DISBURSEMENT_VOUCHER_FAIL";


export const UPDATE_DISBURSEMENT_VOUCHER_REQUEST = "UPDATE_DISBURSEMENT_VOUCHER_REQUEST";
export const UPDATE_DISBURSEMENT_VOUCHER_SUCCESS = "UPDATE_DISBURSEMENT_VOUCHER_SUCCESS";
export const UPDATE_DISBURSEMENT_VOUCHER_FAIL = "UPDATE_DISBURSEMENT_VOUCHER_FAIL";



export const CLEAR_DISBURSEMENT_VOUCHER_ERROR = "CLEAR_DISBURSEMENT_VOUCHER_ERROR";