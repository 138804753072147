// RAMIS MAJOR_GROUP_GLHEAD find
export const FIND_RAMIS_MAJOR_GROUP_GLHEAD_REQUEST = "FIND_RAMIS_MAJOR_GROUP_GLHEAD_REQUEST";
export const FIND_RAMIS_MAJOR_GROUP_GLHEAD_SUCCESS = "FIND_RAMIS_MAJOR_GROUP_GLHEAD_SUCCESS";
export const FIND_RAMIS_MAJOR_GROUP_GLHEAD_FAIL = "FIND_RAMIS_MAJOR_GROUP_GLHEAD_FAIL";

// RAMIS REVENUE_GROUP_GLHEAD find_RAMIS
export const FIND_RAMIS_REVENUE_GROUP_GLHEAD_REQUEST = "FIND_RAMIS_REVENUE_GROUP_GLHEAD_REQUEST";
export const FIND_RAMIS_REVENUE_GROUP_GLHEAD_SUCCESS = "FIND_RAMIS_REVENUE_GROUP_GLHEAD_SUCCESS";
export const FIND_RAMIS_REVENUE_GROUP_GLHEAD_FAIL = "FIND_RAMIS_REVENUE_GROUP_GLHEAD_FAIL";

// RAMIS REVENUE_TYPE_GLHEAD find_RAMIS
export const FIND_RAMIS_REVENUE_TYPE_GLHEAD_REQUEST = "FIND_RAMIS_REVENUE_TYPE_GLHEAD_REQUEST";
export const FIND_RAMIS_REVENUE_TYPE_GLHEAD_SUCCESS = "FIND_RAMIS_REVENUE_TYPE_GLHEAD_SUCCESS";
export const FIND_RAMIS_REVENUE_TYPE_GLHEAD_FAIL = "FIND_RAMIS_REVENUE_TYPE_GLHEAD_FAIL";

// RAMIS REVENUE_SUB_TYPE_GLHEAD find_RAMIS
export const FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_REQUEST = "FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_REQUEST";
export const FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_SUCCESS = "FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_SUCCESS";
export const FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_FAIL = "FIND_RAMIS_REVENUE_SUB_TYPE_GLHEAD_FAIL";

// RAMIS FUNCTIONAL_AREA_GLHEAD find_RAMIS
export const FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_REQUEST = "FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_REQUEST";
export const FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_SUCCESS = "FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_SUCCESS";
export const FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_FAIL = "FIND_RAMIS_FUNCTIONAL_AREA_GLHEAD_FAIL";

// Clear_Error
export const CLEAR_ERROR = "CLEAR_ERROR";