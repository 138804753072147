import {
  RISK_PARAMETER_INDICATOR_REQUEST,
  RISK_PARAMETER_INDICATOR_SUCCESS,
  RISK_PARAMETER_INDICATOR_FAIL,

  CREATE_RISK_PARAMETER_INDICATOR_REQUEST,
  CREATE_RISK_PARAMETER_INDICATOR_SUCCESS,
  CREATE_RISK_PARAMETER_INDICATOR_FAIL,

  RISK_PARAMETER_INDICATOR_TYPES_REQUEST,
  RISK_PARAMETER_INDICATOR_TYPES_SUCCESS,
  RISK_PARAMETER_INDICATOR_TYPES_FAIL,

  RISK_PARAMETERS_BY_TRANSACTION_TYPE_REQUEST,
  RISK_PARAMETERS_BY_TRANSACTION_TYPE_SUCCESS,
  RISK_PARAMETERS_BY_TRANSACTION_TYPE_FAIL,

  FIND_RISK_PARAMETER_INDICATOR_REQUEST,
  FIND_RISK_PARAMETER_INDICATOR_SUCCESS,
  FIND_RISK_PARAMETER_INDICATOR_FAIL,

  UPDATE_RISK_PARAMETER_INDICATOR_REQUEST,
  UPDATE_RISK_PARAMETER_INDICATOR_SUCCESS,
  UPDATE_RISK_PARAMETER_INDICATOR_FAIL,

  DELETE_RISK_PARAMETER_INDICATOR_REQUEST,
  DELETE_RISK_PARAMETER_INDICATOR_SUCCESS,
  DELETE_RISK_PARAMETER_INDICATOR_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskParameterIndicatorConstant";

// create, update, delete and get all
export const getRiskParametersIndicatorsReducer = (state = { riskParametersIndicators: [] }, action) => {
  switch (action.type) {
    case RISK_PARAMETER_INDICATOR_REQUEST:
    case CREATE_RISK_PARAMETER_INDICATOR_REQUEST:
    case UPDATE_RISK_PARAMETER_INDICATOR_REQUEST:
    case DELETE_RISK_PARAMETER_INDICATOR_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_PARAMETER_INDICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParametersIndicators: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_PARAMETER_INDICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParametersIndicators: [...state.riskParametersIndicators, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_PARAMETER_INDICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParametersIndicators: state.riskParametersIndicators.map((item) =>
          (item.riskParameterId === action.payload.riskParameterId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_PARAMETER_INDICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParametersIndicators: action?.payload?.isDelete ?
          state.riskParametersIndicators.filter((item) =>
            item.riskParameterId !== action.payload.riskParameterId
          )
          :
          state.riskParametersIndicators.map((item) =>
            (item.riskParameterId === action.payload.riskParameterId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_PARAMETER_INDICATOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_PARAMETER_INDICATOR_FAIL:
    case UPDATE_RISK_PARAMETER_INDICATOR_FAIL:
    case DELETE_RISK_PARAMETER_INDICATOR_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// get all types
export const getRiskParameterIndicatorTypesReducer = (state = { riskParameterIndicatorTypes: [] }, action) => {
  switch (action.type) {
    case RISK_PARAMETER_INDICATOR_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case RISK_PARAMETER_INDICATOR_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterIndicatorTypes: action.payload,
      };

    case RISK_PARAMETER_INDICATOR_TYPES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// get all parameters by type
export const getRiskParametersByTransactionTypeReducer = (state = { riskParametersByTransactionType: [] }, action) => {
  switch (action.type) {
    case RISK_PARAMETERS_BY_TRANSACTION_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case RISK_PARAMETERS_BY_TRANSACTION_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParametersByTransactionType: action.payload,
      };

    case RISK_PARAMETERS_BY_TRANSACTION_TYPE_FAIL:
      return {
        riskParametersByTransactionType: [],
        loading: false,
        error: action.payload,
      };

    case "EMPTY_RISK_PARAMETERS_BY_TRANSACTION_TYPE":
      return {
        riskParametersByTransactionType: [],
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskParameterIndicatorReducer = (state = { riskParameterIndicator: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_PARAMETER_INDICATOR_REQUEST:
      return {
        loading: true,
        riskParameterIndicator: {},
      };

    case FIND_RISK_PARAMETER_INDICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        riskParameterIndicator: action.payload,
      };

    case FIND_RISK_PARAMETER_INDICATOR_FAIL:
      return {
        ...state,
        loading: false,
        riskParameterIndicator: {},
        error: action.payload,
      };

    case "EMPTY_RISK_PARAMETER_INDICATOR":
      return {
        riskParameterIndicator: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
