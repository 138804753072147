// create
export const CREATE_ACCOUNT_TYPE_REQUEST = "CREATE_ACCOUNT_TYPE_REQUEST";
export const CREATE_ACCOUNT_TYPE_SUCCESS = "CREATE_ACCOUNT_TYPE_SUCCESS";
export const CREATE_ACCOUNT_TYPE_FAIL = "CREATE_ACCOUNT_TYPE_FAIL"
export const CREATE_ACCOUNT_TYPE_CONFLICT = "CREATE_ACCOUNT_TYPE_CONFLICT"

// get
export const ACCOUNT_TYPE_REQUEST = "ACCOUNT_TYPE_REQUEST";
export const ACCOUNT_TYPE_SUCCESS = "ACCOUNT_TYPE_SUCCESS";
export const ACCOUNT_TYPE_FAIL = "ACCOUNT_TYPE_FAIL"


// find
export const FIND_ACCOUNT_TYPE_REQUEST = "FIND_ACCOUNT_TYPE_REQUEST";
export const FIND_ACCOUNT_TYPE_SUCCESS = "FIND_ACCOUNT_TYPE_SUCCESS";
export const FIND_ACCOUNT_TYPE_FAIL = "FIND_ACCOUNT_TYPE_FAIL"

// update
export const UPDATE_ACCOUNT_TYPE_REQUEST = "UPDATE_ACCOUNT_TYPE_REQUEST";
export const UPDATE_ACCOUNT_TYPE_SUCCESS = "UPDATE_ACCOUNT_TYPE_SUCCESS";
export const UPDATE_ACCOUNT_TYPE_FAIL = "UPDATE_ACCOUNT_TYPE_FAIL"

// delete
export const DELETE_ACCOUNT_TYPE_REQUEST = "DELETE_ACCOUNT_TYPE_REQUEST";
export const DELETE_ACCOUNT_TYPE_SUCCESS = "DELETE_ACCOUNT_TYPE_SUCCESS";
export const DELETE_ACCOUNT_TYPE_FAIL = "DELETE_ACCOUNT_TYPE_FAIL"


export const ACCOUNT_TYPE_CLEAR_ERROR = "ACCOUNT_TYPE_CLEAR_ERROR"
