// MAJOR_GROUP_GLHEAD find
export const FIND_MAJOR_GROUP_GLHEAD_REQUEST = "FIND_MAJOR_GROUP_GLHEAD_REQUEST";
export const FIND_MAJOR_GROUP_GLHEAD_SUCCESS = "FIND_MAJOR_GROUP_GLHEAD_SUCCESS";
export const FIND_MAJOR_GROUP_GLHEAD_FAIL = "FIND_MAJOR_GROUP_GLHEAD_FAIL";

// REVENUE_GROUP_GLHEAD find
export const FIND_REVENUE_GROUP_GLHEAD_REQUEST = "FIND_REVENUE_GROUP_GLHEAD_REQUEST";
export const FIND_REVENUE_GROUP_GLHEAD_SUCCESS = "FIND_REVENUE_GROUP_GLHEAD_SUCCESS";
export const FIND_REVENUE_GROUP_GLHEAD_FAIL = "FIND_REVENUE_GROUP_GLHEAD_FAIL";

// REVENUE_TYPE_GLHEAD find
export const FIND_REVENUE_TYPE_GLHEAD_REQUEST = "FIND_REVENUE_TYPE_GLHEAD_REQUEST";
export const FIND_REVENUE_TYPE_GLHEAD_SUCCESS = "FIND_REVENUE_TYPE_GLHEAD_SUCCESS";
export const FIND_REVENUE_TYPE_GLHEAD_FAIL = "FIND_REVENUE_TYPE_GLHEAD_FAIL";

// Clear_Error
export const CLEAR_ERROR = "CLEAR_ERROR";