import {
  CREATE_RECEIPT_ALLOCATION_RRCO_REQUEST,
  CREATE_RECEIPT_ALLOCATION_RRCO_SUCCESS,
  CREATE_RECEIPT_ALLOCATION_RRCO_FAIL,

  RECEIPT_ALLOCATION_RRCO_REQUEST,
  RECEIPT_ALLOCATION_RRCO_SUCCESS,
  RECEIPT_ALLOCATION_RRCO_FAIL,

  RECEIPT_ALLOCATION_RRCO_CLEAR_ERROR,
} from "../../constants_Phase_2/ReceiptStock/ReceiptAllocationToRRCOConstant";

// get all data of list
export const getReceiptAllocationRrcoReducer = (state = { receiptAllocationRrcos: [] }, action) => {
  switch (action.type) {
    case RECEIPT_ALLOCATION_RRCO_REQUEST:
      return {
        ...state,
        receiptAllocationRrcos: [],
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RECEIPT_ALLOCATION_RRCO_SUCCESS:
      return {
        ...state,
        loading: false,
        receiptAllocationRrcos: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case RECEIPT_ALLOCATION_RRCO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RECEIPT_ALLOCATION_RRCO_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

export const getReceiptAllocationRrcoResponseReducer = (state = { receiptAllocationRrcoResponse: [] }, action) => {
  switch (action.type) {
    case CREATE_RECEIPT_ALLOCATION_RRCO_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_RECEIPT_ALLOCATION_RRCO_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CREATE_RECEIPT_ALLOCATION_RRCO_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case RECEIPT_ALLOCATION_RRCO_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};