// create/post
export const CREATE_RISK_PARAMETER_INDICATOR_REQUEST = "CREATE_RISK_PARAMETER_INDICATOR_REQUEST";
export const CREATE_RISK_PARAMETER_INDICATOR_SUCCESS = "CREATE_RISK_PARAMETER_INDICATOR_SUCCESS";
export const CREATE_RISK_PARAMETER_INDICATOR_FAIL = "CREATE_RISK_PARAMETER_INDICATOR_FAIL";

// get
export const RISK_PARAMETER_INDICATOR_REQUEST = "RISK_PARAMETER_INDICATOR_REQUEST";
export const RISK_PARAMETER_INDICATOR_SUCCESS = "RISK_PARAMETER_INDICATOR_SUCCESS";
export const RISK_PARAMETER_INDICATOR_FAIL = "RISK_PARAMETER_INDICATOR_FAIL";

// get types
export const RISK_PARAMETER_INDICATOR_TYPES_REQUEST = "RISK_PARAMETER_INDICATOR_TYPES_REQUEST";
export const RISK_PARAMETER_INDICATOR_TYPES_SUCCESS = "RISK_PARAMETER_INDICATOR_TYPES_SUCCESS";
export const RISK_PARAMETER_INDICATOR_TYPES_FAIL = "RISK_PARAMETER_INDICATOR_TYPES_FAIL";

// get 
export const RISK_PARAMETERS_BY_TRANSACTION_TYPE_REQUEST = "RISK_PARAMETERS_BY_TRANSACTION_TYPE_REQUEST";
export const RISK_PARAMETERS_BY_TRANSACTION_TYPE_SUCCESS = "RISK_PARAMETERS_BY_TRANSACTION_TYPE_SUCCESS";
export const RISK_PARAMETERS_BY_TRANSACTION_TYPE_FAIL = "RISK_PARAMETERS_BY_TRANSACTION_TYPE_FAIL";

// find
export const FIND_RISK_PARAMETER_INDICATOR_REQUEST = "FIND_RISK_PARAMETER_INDICATOR_REQUEST";
export const FIND_RISK_PARAMETER_INDICATOR_SUCCESS = "FIND_RISK_PARAMETER_INDICATOR_SUCCESS";
export const FIND_RISK_PARAMETER_INDICATOR_FAIL = "FIND_RISK_PARAMETER_INDICATOR_FAIL";

// update/put
export const UPDATE_RISK_PARAMETER_INDICATOR_REQUEST = "UPDATE_RISK_PARAMETER_INDICATOR_REQUEST";
export const UPDATE_RISK_PARAMETER_INDICATOR_SUCCESS = "UPDATE_RISK_PARAMETER_INDICATOR_SUCCESS";
export const UPDATE_RISK_PARAMETER_INDICATOR_FAIL = "UPDATE_RISK_PARAMETER_INDICATOR_FAIL";

// update/put
export const DELETE_RISK_PARAMETER_INDICATOR_REQUEST = "DELETE_RISK_PARAMETER_INDICATOR_REQUEST";
export const DELETE_RISK_PARAMETER_INDICATOR_SUCCESS = "DELETE_RISK_PARAMETER_INDICATOR_SUCCESS";
export const DELETE_RISK_PARAMETER_INDICATOR_FAIL = "DELETE_RISK_PARAMETER_INDICATOR_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";