import {
    CREATE_COA_AGENCY_MAP_REQUEST,
    CREATE_COA_AGENCY_MAP_SUCCESS,
    CREATE_COA_AGENCY_MAP_FAIL,

    COA_AGENCY_MAP_REQUEST,
    COA_AGENCY_MAP_SUCCESS,
    COA_AGENCY_MAP_FAIL,

    FIND_COA_AGENCY_MAP_REQUEST,
    FIND_COA_AGENCY_MAP_SUCCESS,
    FIND_COA_AGENCY_MAP_FAIL,

    UPDATE_COA_AGENCY_MAP_REQUEST,
    UPDATE_COA_AGENCY_MAP_SUCCESS,
    UPDATE_COA_AGENCY_MAP_FAIL,

    DELETE_COA_AGENCY_MAP_REQUEST,
    DELETE_COA_AGENCY_MAP_SUCCESS,
    DELETE_COA_AGENCY_MAP_FAIL,

    COA_AGENCY_MAP_CLEAR_ERROR,
} from "../../constants/CoaMapping/CoaAgencyMapConstant";



// get all data of list
export const getCoaAgencyMapReducer = (state = { coaAgencyMaps: [] }, action) => {
    switch (action.type) {
        case COA_AGENCY_MAP_REQUEST:
        case CREATE_COA_AGENCY_MAP_REQUEST:
        case UPDATE_COA_AGENCY_MAP_REQUEST:
        case DELETE_COA_AGENCY_MAP_REQUEST:
            return {
                ...state,
                loading: true,
                message: null,
                status: null
            };
        case COA_AGENCY_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: action.payload,
                message: null,
                status: null,
            };

        case CREATE_COA_AGENCY_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: [...state.coaAgencyMaps, action.payload],
                message: action.message,
                status: action.status
            };

        case UPDATE_COA_AGENCY_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: [
                    ...state.coaAgencyMaps.filter(
                        (item) => item.coaAgencyMappingId !== action.payload.coaAgencyMappingId
                    ),
                    action.payload,
                ].sort((pre, post) => pre.coaAgencyMappingId - post.coaAgencyMappingId),
                message: action.message,
                status: action.status,

            };

        case DELETE_COA_AGENCY_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: [
                    ...state.coaAgencyMaps.filter(
                        (item) => item.coaAgencyMappingId !== action.payload
                    ),
                ],
                message: action.message,
                status: action.status,
            };


        case COA_AGENCY_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: [...state.coaAgencyMaps],
                error: action.payload,
            };

        case CREATE_COA_AGENCY_MAP_FAIL:
        case UPDATE_COA_AGENCY_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: [...state.coaAgencyMaps],
                message: action.message,
                status: action.status,
            };

        case DELETE_COA_AGENCY_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaAgencyMaps: [...state.coaAgencyMaps],
                error: action.payload,
            };

        case COA_AGENCY_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

// find one
export const findCoaAgencyMapReducer = (state = { coaAgencyMap: {} }, action) => {
    switch (action.type) {
        case FIND_COA_AGENCY_MAP_REQUEST:
            return {
                coaAgencyMap: {},
                loading: true,
            };

        case FIND_COA_AGENCY_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                coaAgencyMap: action.payload,
            };

        case FIND_COA_AGENCY_MAP_FAIL:
            return {
                ...state,
                loading: false,
                coaAgencyMap: {},
                error: action.payload,
            };

        case "EMPTY_COA_AGENCY_MAP":
            return {
                ...state,
                coaAgencyMap: {}
            }

        case COA_AGENCY_MAP_CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};