// create
export const CREATE_DZONGKHAGE_REQUEST = "CREATE_DZONGKHAGE_REQUEST";
export const CREATE_DZONGKHAGE_SUCCESS = "CREATE_DZONGKHAGE_SUCCESS";
export const CREATE_DZONGKHAGE_FAIL = "CREATE_DZONGKHAGE_FAIL";
// get
export const DZONGKHAGE_REQUEST = "DZONGKHAGE_REQUEST";
export const DZONGKHAGE_SUCCESS = "DZONGKHAGE_SUCCESS";
export const DZONGKHAGE_FAIL = "DZONGKHAGE_FAIL";
// find
export const FIND_DZONGKHAGE_REQUEST = "FIND_DZONGKHAGE_REQUEST";
export const FIND_DZONGKHAGE_SUCCESS = "FIND_DZONGKHAGE_SUCCESS";
export const FIND_DZONGKHAGE_FAIL = "FIND_DZONGKHAGE_FAIL";

// update
export const UPDATE_DZONGKHAGE_REQUEST = "UPDATE_DZONGKHAGE_REQUEST";
export const UPDATE_DZONGKHAGE_SUCCESS = "UPDATE_DZONGKHAGE_SUCCESS";
export const UPDATE_DZONGKHAGE_FAIL = "UPDATE_DZONGKHAGE_FAIL";

// delete
export const DELETE_DZONGKHAGE_REQUEST = "DELETE_DZONGKHAGE_REQUEST";
export const DELETE_DZONGKHAGE_SUCCESS = "DELETE_DZONGKHAGE_SUCCESS";
export const DELETE_DZONGKHAGE_FAIL = "DELETE_DZONGKHAGE_FAIL";

export const CLEAR_ERROR = "CLEAR_ERROR";
