// DRC receipt stock create
export const CREATE_DRC_RECEIPT_STOCK_REQUEST = "CREATE_DRC_RECEIPT_STOCK_REQUEST";
export const CREATE_DRC_RECEIPT_STOCK_SUCCESS = "CREATE_DRC_RECEIPT_STOCK_SUCCESS";
export const CREATE_DRC_RECEIPT_STOCK_FAIL = "CREATE_DRC_RECEIPT_STOCK_FAIL";

// DRC receipt stock get
export const DRC_RECEIPT_STOCK_REQUEST = "DRC_RECEIPT_STOCK_REQUEST";
export const DRC_RECEIPT_STOCK_SUCCESS = "DRC_RECEIPT_STOCK_SUCCESS";
export const DRC_RECEIPT_STOCK_FAIL = "DRC_RECEIPT_STOCK_FAIL";

// cancel receipt stock get
export const CANCEL_RECEIPT_STOCK_REQUEST = "CANCEL_RECEIPT_STOCK_REQUEST";
export const CANCEL_RECEIPT_STOCK_SUCCESS = "CANCEL_RECEIPT_STOCK_SUCCESS";
export const CANCEL_RECEIPT_STOCK_FAIL = "CANCEL_RECEIPT_STOCK_FAIL";

// DRC receipt stock find
export const FIND_DRC_RECEIPT_STOCK_REQUEST = "FIND_DRC_RECEIPT_STOCK_REQUEST";
export const FIND_DRC_RECEIPT_STOCK_SUCCESS = "FIND_DRC_RECEIPT_STOCK_SUCCESS";
export const FIND_DRC_RECEIPT_STOCK_FAIL = "FIND_DRC_RECEIPT_STOCK_FAIL";


// DRC receipt stock update
export const UPDATE_DRC_RECEIPT_STOCK_REQUEST = "UPDATE_DRC_RECEIPT_STOCK_REQUEST";
export const UPDATE_DRC_RECEIPT_STOCK_SUCCESS = "UPDATE_DRC_RECEIPT_STOCK_SUCCESS";
export const UPDATE_DRC_RECEIPT_STOCK_FAIL = "UPDATE_DRC_RECEIPT_STOCK_FAIL";


export const CLEAR_DRC_RECEIPT_STOCK_ERROR = "CLEAR_DRC_RECEIPT_STOCK_ERROR";