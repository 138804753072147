import {
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAIL,
  SERVICE_REQUEST,
  SERVICE_SUCCESS,
  SERVICE_FAIL,
  FIND_SERVICE_REQUEST,
  FIND_SERVICE_SUCCESS,
  FIND_SERVICE_FAIL,
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_SUCCESS,
  DELETE_SERVICE_FAIL,
  SERVICE_CLEAR_ERROR,
} from "../../constants/OthersMaster/ServiceConstant";

export const getOtherMasterServices = (state = { services: [] }, action) => {
  switch (action.type) {
    case SERVICE_REQUEST:
    case CREATE_SERVICE_REQUEST:
    case UPDATE_SERVICE_REQUEST:
    case DELETE_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.payload,
        message: null,
        status: null,
      };

    case CREATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: [...state.services, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: [
          ...state.services.filter(
            (item) => item.serviceId !== action.payload.serviceId
          ),
          action.payload,
        ].sort((pre, post) => pre.serviceId - post.serviceId),
        message: action.message,
        status: action.status,
      };

    case DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        services: [
          ...state.services.filter((item) => item.serviceId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_SERVICE_SUCCESS":
      return {
        loading: false,
        services: [
          ...state.services.filter(
            (item) => item.serviceId !== action.payload.serviceId
          ),
          action.payload,
        ].sort((pre, post) => pre.serviceId - post.serviceId),
        message: action.message,
        status: action.status,
      };

    case SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        services: [...state.services],
        error: action.payload,
      };

    case CREATE_SERVICE_FAIL:
    case UPDATE_SERVICE_FAIL:
    case DELETE_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        services: [...state.services],
        message: action.message,
        status: action.status,
      };

    case SERVICE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findOtherMasterService = (state = { service: {} }, action) => {
  switch (action.type) {
    case FIND_SERVICE_REQUEST:
      return {
        service: {},
        loading: true,
      };
    case FIND_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        service: action.payload,
      };

    case FIND_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
        service: {},
        error: action.payload,
      };

    case "EMPTY_SERVICE":
      return {
        ...state,
        service: {},
      };

    case SERVICE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
