import {
  CREATE_BUSINESS_SECTOR_REQUEST,
  CREATE_BUSINESS_SECTOR_SUCCESS,
  CREATE_BUSINESS_SECTOR_FAIL,
  BUSINESS_SECTOR_REQUEST,
  BUSINESS_SECTOR_SUCCESS,
  BUSINESS_SECTOR_FAIL,
  FIND_BUSINESS_SECTOR_REQUEST,
  FIND_BUSINESS_SECTOR_SUCCESS,
  FIND_BUSINESS_SECTOR_FAIL,
  UPDATE_BUSINESS_SECTOR_REQUEST,
  UPDATE_BUSINESS_SECTOR_SUCCESS,
  UPDATE_BUSINESS_SECTOR_FAIL,
  DELETE_BUSINESS_SECTOR_REQUEST,
  DELETE_BUSINESS_SECTOR_SUCCESS,
  DELETE_BUSINESS_SECTOR_FAIL,
  BUSINESS_SECTOR_CLEAR_ERROR,
} from "../../constants/OthersMaster/BusinessSectorConstant";

export const getBusinessSectorReducer = (
  state = { businessSectors: [] },
  action
) => {
  switch (action.type) {
    case BUSINESS_SECTOR_REQUEST:
    case CREATE_BUSINESS_SECTOR_REQUEST:
    case UPDATE_BUSINESS_SECTOR_REQUEST:
    case DELETE_BUSINESS_SECTOR_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null,
      };

    case BUSINESS_SECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        businessSectors: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BUSINESS_SECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        businessSectors: [...state.businessSectors, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BUSINESS_SECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        businessSectors: [
          ...state.businessSectors.filter(
            (item) => item.businessSectorId !== action.payload.businessSectorId
          ),
          action.payload,
        ].sort((pre, post) => pre.businessSectorId - post.businessSectorId),
        message: action.message,
        status: action.status,
      };

    case DELETE_BUSINESS_SECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        businessSectors: [
          ...state.businessSectors.filter(
            (item) => item.businessSectorId !== action.payload
          ),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BUSINESS_SECTOR_SUCCESS":
      return {
        loading: false,
        businessSectors: [
          ...state.businessSectors.filter(
            (item) => item.businessSectorId !== action.payload.businessSectorId
          ),
          action.payload,
        ].sort((pre, post) => pre.businessSectorId - post.businessSectorId),
        message: action.message,
        status: action.status,
      };

    case BUSINESS_SECTOR_FAIL:
      return {
        ...state,
        loading: false,
        businessSectors: [...state.businessSectors],
        error: action.payload,
      };

    case CREATE_BUSINESS_SECTOR_FAIL:
    case UPDATE_BUSINESS_SECTOR_FAIL:
    case DELETE_BUSINESS_SECTOR_FAIL:
      return {
        ...state,
        loading: false,
        businessSectors: [...state.businessSectors],
        message: action.message,
        status: action.status,
      };

    case BUSINESS_SECTOR_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBusinessSectorReducer = (
  state = { businessSector: {} },
  action
) => {
  switch (action.type) {
    case FIND_BUSINESS_SECTOR_REQUEST:
      return {
        businessSector: {},
        loading: true,
      };
    case FIND_BUSINESS_SECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        businessSector: action.payload,
      };

    case FIND_BUSINESS_SECTOR_FAIL:
      return {
        ...state,
        loading: false,
        businessSector: {},
        error: action.payload,
      };

    case "EMPTY_BUSINESS_SECTOR":
      return {
        ...state,
        businessSector: {},
      };

    case BUSINESS_SECTOR_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
