import {
  CREATE_RRCO_RECEIPT_STOCK_REQUEST,
  CREATE_RRCO_RECEIPT_STOCK_SUCCESS,
  CREATE_RRCO_RECEIPT_STOCK_FAIL,

  RRCO_RECEIPT_STOCK_REQUEST,
  RRCO_RECEIPT_STOCK_SUCCESS,
  RRCO_RECEIPT_STOCK_FAIL,

  FIND_RRCO_RECEIPT_STOCK_REQUEST,
  FIND_RRCO_RECEIPT_STOCK_SUCCESS,
  FIND_RRCO_RECEIPT_STOCK_FAIL,

  UPDATE_RRCO_RECEIPT_STOCK_REQUEST,
  UPDATE_RRCO_RECEIPT_STOCK_SUCCESS,
  UPDATE_RRCO_RECEIPT_STOCK_FAIL,

  DELETE_RRCO_RECEIPT_STOCK_REQUEST,
  DELETE_RRCO_RECEIPT_STOCK_SUCCESS,
  DELETE_RRCO_RECEIPT_STOCK_FAIL,

  RRCO_RECEIPT_STOCK_CLEAR_ERROR,
} from "../../constants_Phase_2/ReceiptStock/RRCOReceiptStockConstant";


// get all data of list
export const getRrcoReceiptStockReducer = (state = { rrcoReceiptStocks: [] }, action) => {
  switch (action.type) {
    case RRCO_RECEIPT_STOCK_REQUEST:
      return {
        ...state,
        rrcoReceiptStocks: [],
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RRCO_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        rrcoReceiptStocks: action.payload,
        totalData: action.totalData,
        currentPage: action.currentPage,
        message: action.message,
        status: action.status,
      };

    case RRCO_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RRCO_RECEIPT_STOCK_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// get all data of list
export const getRrcoReceiptStockResponseReducer = (state = { rrcoReceiptStockResponse: [] }, action) => {
  switch (action.type) {
    case CREATE_RRCO_RECEIPT_STOCK_REQUEST:
    case UPDATE_RRCO_RECEIPT_STOCK_REQUEST:
    case DELETE_RRCO_RECEIPT_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case CREATE_RRCO_RECEIPT_STOCK_SUCCESS:
    case UPDATE_RRCO_RECEIPT_STOCK_SUCCESS:
    case DELETE_RRCO_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CREATE_RRCO_RECEIPT_STOCK_FAIL:
    case UPDATE_RRCO_RECEIPT_STOCK_FAIL:
    case DELETE_RRCO_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case RRCO_RECEIPT_STOCK_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


// find one
export const findRrcoReceiptStockReducer = (state = { rrcoReceiptStock: [] }, action) => {
  switch (action.type) {
    case FIND_RRCO_RECEIPT_STOCK_REQUEST:
      return {
        rrcoReceiptStock: [],
        loading: true,
      };

    case FIND_RRCO_RECEIPT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        rrcoReceiptStock: action.payload,
      };

    case FIND_RRCO_RECEIPT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        rrcoReceiptStock: [],
        error: action.payload,
      };

    case "EMPTY_RRCO_RECEIPT_STOCK":
      return {
        ...state,
        rrcoReceiptStock: [],
      };

    case RRCO_RECEIPT_STOCK_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
