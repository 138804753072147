//RECONCILIATION_APPROVAL create
export const CREATE_RECONCILIATION_APPROVAL_REQUEST = "CREATE_RECONCILIATION_APPROVAL_REQUEST";
export const CREATE_RECONCILIATION_APPROVAL_SUCCESS = "CREATE_RECONCILIATION_APPROVAL_SUCCESS";
export const CREATE_RECONCILIATION_APPROVAL_FAIL = "CREATE_RECONCILIATION_APPROVAL_FAIL"

//RECONCILIATION_APPROVAL get
export const RECONCILIATION_APPROVAL_REQUEST = "RECONCILIATION_APPROVAL_REQUEST";
export const RECONCILIATION_APPROVAL_SUCCESS = "RECONCILIATION_APPROVAL_SUCCESS";
export const RECONCILIATION_APPROVAL_FAIL = "RECONCILIATION_APPROVAL_FAIL"


//RECONCILIATION_APPROVAL find
export const FIND_RECONCILIATION_APPROVAL_REQUEST = "FIND_RECONCILIATION_APPROVAL_REQUEST";
export const FIND_RECONCILIATION_APPROVAL_SUCCESS = "FIND_RECONCILIATION_APPROVAL_SUCCESS";
export const FIND_RECONCILIATION_APPROVAL_FAIL = "FIND_RECONCILIATION_APPROVAL_FAIL";


//Business Sector update/put
export const UPDATE_RECONCILIATION_APPROVAL_REQUEST = "UPDATE_RECONCILIATION_APPROVAL_REQUEST";
export const UPDATE_RECONCILIATION_APPROVAL_SUCCESS = "UPDATE_RECONCILIATION_APPROVAL_SUCCESS";
export const UPDATE_RECONCILIATION_APPROVAL_FAIL = "UPDATE_RECONCILIATION_APPROVAL_FAIL"


//RECONCILIATION_APPROVAL office update/put
export const DELETE_RECONCILIATION_APPROVAL_REQUEST = "DELETE_RECONCILIATION_APPROVAL_REQUEST";
export const DELETE_RECONCILIATION_APPROVAL_SUCCESS = "DELETE_RECONCILIATION_APPROVAL_SUCCESS";
export const DELETE_RECONCILIATION_APPROVAL_FAIL = "DELETE_RECONCILIATION_APPROVAL_FAIL"


export const RECONCILIATION_APPROVAL_CLEAR_ERROR = "RECONCILIATION_APPROVAL_CLEAR_ERROR"