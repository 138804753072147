import {
  //create ministry
  CREATE_MINISTRY_ORGANIZATION_REQUEST,
  CREATE_MINISTRY_ORGANIZATION_SUCCESS,
  CREATE_MINISTRY_ORGANIZATION_FAIL,
  //update ministry
  UPDATE_MINISTRY_ORGANIZATION_REQUEST,
  UPDATE_MINISTRY_ORGANIZATION_SUCCESS,
  UPDATE_MINISTRY_ORGANIZATION_FAIL,
  //delete ministry
  DELETE_MINISTRY_ORGANIZATION_REQUEST,
  DELETE_MINISTRY_ORGANIZATION_SUCCESS,
  DELETE_MINISTRY_ORGANIZATION_FAIL,
  //get ministry list by agency id
  GET_MINISTRY_ORGANIZATION_REQUEST,
  GET_MINISTRY_ORGANIZATION_SUCCESS,
  GET_MINISTRY_ORGANIZATION_FAIL,
  //get all ministry list
  MINISTRY_ORGANIZATION_REQUEST,
  MINISTRY_ORGANIZATION_SUCCESS,
  MINISTRY_ORGANIZATION_FAIL,

  //find
  FIND_MINISTRY_ORGANIZATION_REQUEST,
  FIND_MINISTRY_ORGANIZATION_SUCCESS,
  FIND_MINISTRY_ORGANIZATION_FAIL,

  AGENCY_MASTER_CLEAR_ERROR

} from "../../constants/AgencyMaster/AgencyMasterConstant";


//agency ministry create
export const ministryOrganizationReducer = (state = { ministryMaster: [] }, action) => {
  switch (action.type) {
    case MINISTRY_ORGANIZATION_REQUEST:
    case CREATE_MINISTRY_ORGANIZATION_REQUEST:
    case UPDATE_MINISTRY_ORGANIZATION_REQUEST:
    case DELETE_MINISTRY_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };

    case CREATE_MINISTRY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ministryMaster: [...state.ministryMaster, action.payload],
        message: action.message,
        status: action.status
      };
    case UPDATE_MINISTRY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ministryMaster: [...state.ministryMaster.filter(
          (item) => item.id !== action.payload.id
        ),
        action.payload,
        ].sort((pre, post) => pre.id - post.id),
        message: action.message,
        status: action.status
      };
    case DELETE_MINISTRY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ministryMaster: [
          ...state.ministryMaster.filter(
            (item) => item.id !== action.payload
          ),
        ],
        message: action.message,
        status: action.status
      };


    case "FUTURE_DELETE_MINISTRY_ORGANIZATION_SUCCESS":
      return {
        loading: false,
        ministryMaster: [
          ...state.ministryMaster.filter(
            (item) => item.id !== action.payload.id
          ),
          action.payload,
        ].sort((pre, post) => pre.id - post.id),
        message: action.message,
        status: action.status,
      };



    case MINISTRY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ministryMaster: action.payload,
        message: null,
        status: null,
      };

    case MINISTRY_ORGANIZATION_FAIL:
    case CREATE_MINISTRY_ORGANIZATION_FAIL:
    case UPDATE_MINISTRY_ORGANIZATION_FAIL:
    case DELETE_MINISTRY_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        ministryMaster: [...state.ministryMaster],
        message: action.message,
        status: action.status,
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};


//ministry get by agency id
export const getMinistryOrganizationReducer = (state = { ministryByAgencyId: [] }, action) => {
  switch (action.type) {

    case GET_MINISTRY_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
        message: null,
        error: null
      };
    case GET_MINISTRY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        ministryByAgencyId: action.payload,
        message: null,
        status: null,
      };

      case "EMPTY_GET_MINISTRY_ORGANIZATION":
        return {
          ...state,
          ministryByAgencyId: [],
        };



    case GET_MINISTRY_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        ministryByAgencyId: [...state.ministryByAgencyId],
        error: action.payload,
      };
    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};



// find one
export const findMinistryOrganizationReducer = (state = { singleMinistryMaster: {} }, action) => {
  switch (action.type) {
    case FIND_MINISTRY_ORGANIZATION_REQUEST:
      return {
        singleMinistryMaster: {},
        loading: true,
      };

    case FIND_MINISTRY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        singleMinistryMaster: action.payload,
      };

    case FIND_MINISTRY_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
        singleMinistryMaster: {},
        error: action.payload,
      };

    case "EMPTY_AGENCY_MINISTRY_ORGANIZATION":
      return {
        ...state,
        singleMinistryMaster: {},
      };

    case AGENCY_MASTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};
