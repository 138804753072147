import {
  CREATE_BANK_MASTER_REQUEST,
  CREATE_BANK_MASTER_SUCCESS,
  CREATE_BANK_MASTER_FAIL,
  BANK_MASTER_REQUEST,
  BANK_MASTER_SUCCESS,
  BANK_MASTER_FAIL,
  FIND_BANK_MASTER_REQUEST,
  FIND_BANK_MASTER_SUCCESS,
  FIND_BANK_MASTER_FAIL,
  UPDATE_BANK_MASTER_REQUEST,
  UPDATE_BANK_MASTER_SUCCESS,
  UPDATE_BANK_MASTER_FAIL,
  DELETE_BANK_MASTER_REQUEST,
  DELETE_BANK_MASTER_SUCCESS,
  DELETE_BANK_MASTER_FAIL,
  CLEAR_ERROR,
} from "../../constants/COAMasters/BankMasterConstant";

export const bankMasterReducer = (state = { bankMasters: [] }, action) => {
  switch (action.type) {
    case BANK_MASTER_REQUEST:
    case CREATE_BANK_MASTER_REQUEST:
    case UPDATE_BANK_MASTER_REQUEST:
    case DELETE_BANK_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
      };

    case BANK_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        bankMasters: action.payload,
        message: null,
        status: null,
      };

    case CREATE_BANK_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        bankMasters: [...state.bankMasters, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_BANK_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        bankMasters: [
          ...state.bankMasters.filter(
            (item) => item.bankId !== action.payload.bankId
          ),
          action.payload,
        ].sort((pre, post) => pre.bankId - post.bankId),
        message: action.message,
        status: action.status,
      };

    case DELETE_BANK_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        bankMasters: [
          ...state.bankMasters.filter((item) => item.bankId !== action.payload),
        ],
        message: action.message,
        status: action.status,
      };

    case "FUTURE_DELETE_BANK_MASTER_SUCCESS":
      return {
        loading: false,
        bankMasters: [
          ...state.bankMasters.filter(
            (item) => item.bankId !== action.payload.bankId
          ),
          action.payload,
        ].sort((pre, post) => pre.bankId - post.bankId),
        message: action.message,
        status: action.status,
      };

    case BANK_MASTER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_BANK_MASTER_FAIL:
    case UPDATE_BANK_MASTER_FAIL:
    case DELETE_BANK_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findBankMasterReducer = (state = { bankMaster: {} }, action) => {
  switch (action.type) {
    case FIND_BANK_MASTER_REQUEST:
      return {
        bankMaster: {},
        loading: true,
      };

    case FIND_BANK_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        bankMaster: action.payload,
      };

    case FIND_BANK_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        bankMaster: {},
        error: action.payload,
      };

    case "EMPTY_BANK_MASTER":
      return {
        ...state,
        bankMaster: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
