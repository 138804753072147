// Business Sector create
export const CREATE_BUSINESS_SECTOR_REQUEST = "CREATE_BUSINESS_SECTOR_REQUEST";
export const CREATE_BUSINESS_SECTOR_SUCCESS = "CREATE_BUSINESS_SECTOR_SUCCESS";
export const CREATE_BUSINESS_SECTOR_FAIL = "CREATE_BUSINESS_SECTOR_FAIL"

// Business Sector get
export const BUSINESS_SECTOR_REQUEST = "BUSINESS_SECTOR_REQUEST";
export const BUSINESS_SECTOR_SUCCESS = "BUSINESS_SECTOR_SUCCESS";
export const BUSINESS_SECTOR_FAIL = "BUSINESS_SECTOR_FAIL"


// Business Sector find
export const FIND_BUSINESS_SECTOR_REQUEST = "FIND_BUSINESS_SECTOR_REQUEST";
export const FIND_BUSINESS_SECTOR_SUCCESS = "FIND_BUSINESS_SECTOR_SUCCESS";
export const FIND_BUSINESS_SECTOR_FAIL = "FIND_BUSINESS_SECTOR_FAIL";


//Business Sector update/put
export const UPDATE_BUSINESS_SECTOR_REQUEST = "UPDATE_BUSINESS_SECTOR_REQUEST";
export const UPDATE_BUSINESS_SECTOR_SUCCESS = "UPDATE_BUSINESS_SECTOR_SUCCESS";
export const UPDATE_BUSINESS_SECTOR_FAIL = "UPDATE_BUSINESS_SECTOR_FAIL"


// Business Sector office update/put
export const DELETE_BUSINESS_SECTOR_REQUEST = "DELETE_BUSINESS_SECTOR_REQUEST";
export const DELETE_BUSINESS_SECTOR_SUCCESS = "DELETE_BUSINESS_SECTOR_SUCCESS";
export const DELETE_BUSINESS_SECTOR_FAIL = "DELETE_BUSINESS_SECTOR_FAIL"


export const BUSINESS_SECTOR_CLEAR_ERROR = "BUSINESS_SECTOR_CLEAR_ERROR"