// rrco assigned stock create
export const CREATE_RRCO_RECEIPT_STOCK_REQUEST = "CREATE_RRCO_RECEIPT_STOCK_REQUEST";
export const CREATE_RRCO_RECEIPT_STOCK_SUCCESS = "CREATE_RRCO_RECEIPT_STOCK_SUCCESS";
export const CREATE_RRCO_RECEIPT_STOCK_FAIL = "CREATE_RRCO_RECEIPT_STOCK_FAIL";

// rrco assigned stock get
export const RRCO_RECEIPT_STOCK_REQUEST = "RRCO_RECEIPT_STOCK_REQUEST";
export const RRCO_RECEIPT_STOCK_SUCCESS = "RRCO_RECEIPT_STOCK_SUCCESS";
export const RRCO_RECEIPT_STOCK_FAIL = "RRCO_RECEIPT_STOCK_FAIL";


// rrco assigned stock find
export const FIND_RRCO_RECEIPT_STOCK_REQUEST = "FIND_RRCO_RECEIPT_STOCK_REQUEST";
export const FIND_RRCO_RECEIPT_STOCK_SUCCESS = "FIND_RRCO_RECEIPT_STOCK_SUCCESS";
export const FIND_RRCO_RECEIPT_STOCK_FAIL = "FIND_RRCO_RECEIPT_STOCK_FAIL";

//rrco assigned stock update/put
export const UPDATE_RRCO_RECEIPT_STOCK_REQUEST = "UPDATE_RRCO_RECEIPT_STOCK_REQUEST";
export const UPDATE_RRCO_RECEIPT_STOCK_SUCCESS = "UPDATE_RRCO_RECEIPT_STOCK_SUCCESS";
export const UPDATE_RRCO_RECEIPT_STOCK_FAIL = "UPDATE_RRCO_RECEIPT_STOCK_FAIL";

// rrco assigned stock delete
export const DELETE_RRCO_RECEIPT_STOCK_REQUEST = "DELETE_RRCO_RECEIPT_STOCK_REQUEST";
export const DELETE_RRCO_RECEIPT_STOCK_SUCCESS = "DELETE_RRCO_RECEIPT_STOCK_SUCCESS";
export const DELETE_RRCO_RECEIPT_STOCK_FAIL = "DELETE_RRCO_RECEIPT_STOCK_FAIL";


export const RRCO_RECEIPT_STOCK_CLEAR_ERROR = "RRCO_RECEIPT_STOCK_CLEAR_ERROR";
