import {
    AUDIT_SUPPORT_DATA_REQUEST,
    AUDIT_SUPPORT_DATA_SUCCESS,
    AUDIT_SUPPORT_DATA_FAIL,

    ASSIGNED_AUDITS_REQUEST,
    ASSIGNED_AUDITS_SUCCESS,
    ASSIGNED_AUDITS_FAIL,

    CLEAR_ERROR,
} from "../../constants_Phase_2/AuditManagement/AuditSupportConstant";

export const getAuditSupportDataReducer = (state = { auditSupportData: [] }, action) => {
    switch (action.type) {
        case AUDIT_SUPPORT_DATA_REQUEST:
            return {
                ...state,
                auditSupportData: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case AUDIT_SUPPORT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                auditSupportData: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case AUDIT_SUPPORT_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};

export const getAssignedAuditsReducer = (state = { assignedAudits: [] }, action) => {
    switch (action.type) {
        case ASSIGNED_AUDITS_REQUEST:
            return {
                ...state,
                assignedAudits: [],
                loading: true,
                message: null,
                status: null,
                error: null,
            };

        case ASSIGNED_AUDITS_SUCCESS:
            return {
                ...state,
                loading: false,
                assignedAudits: action.payload,
                totalData: action.totalData,
                currentPage: action.currentPage,
                message: action.message,
                status: action.status,
            };

        case ASSIGNED_AUDITS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
                status: null,
                message: null,
            };

        default:
            return state;
    }
};