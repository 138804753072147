import {
  RISK_LEVEL_REQUEST,
  RISK_LEVEL_SUCCESS,
  RISK_LEVEL_FAIL,

  CREATE_RISK_LEVEL_REQUEST,
  CREATE_RISK_LEVEL_SUCCESS,
  CREATE_RISK_LEVEL_FAIL,

  FIND_RISK_LEVEL_REQUEST,
  FIND_RISK_LEVEL_SUCCESS,
  FIND_RISK_LEVEL_FAIL,

  UPDATE_RISK_LEVEL_REQUEST,
  UPDATE_RISK_LEVEL_SUCCESS,
  UPDATE_RISK_LEVEL_FAIL,

  DELETE_RISK_LEVEL_REQUEST,
  DELETE_RISK_LEVEL_SUCCESS,
  DELETE_RISK_LEVEL_FAIL,

  CLEAR_ERROR,
} from "../../constants_Phase_2/RiskManagement/RiskLevelConstant";

// create, update, delete and get all
export const getRiskLevelsReducer = (state = { riskLevels: [] }, action) => {
  switch (action.type) {
    case RISK_LEVEL_REQUEST:
    case CREATE_RISK_LEVEL_REQUEST:
    case UPDATE_RISK_LEVEL_REQUEST:
    case DELETE_RISK_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
        status: null,
        error: null,
      };

    case RISK_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        riskLevels: action.payload,
        message: null,
        status: null,
      };

    case CREATE_RISK_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        riskLevels: [...state.riskLevels, action.payload],
        message: action.message,
        status: action.status,
      };

    case UPDATE_RISK_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        riskLevels: state.riskLevels.map((item) =>
          (item.riskLevelId === action.payload.riskLevelId) ? action.payload : item
        ),
        message: action.message,
        status: action.status,
      };

    case DELETE_RISK_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        riskLevels: action?.payload?.isDelete ?
          state.riskLevels.filter((item) =>
            item.riskLevelId !== action.payload.riskLevelId
          )
          :
          state.riskLevels.map((item) =>
            (item.riskLevelId === action.payload.riskLevelId) ? action.payload : item
          ),
        message: action.message,
        status: action.status,
      };

    case RISK_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CREATE_RISK_LEVEL_FAIL:
    case UPDATE_RISK_LEVEL_FAIL:
    case DELETE_RISK_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        message: action.message,
        status: action.status,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
        message: null,
      };

    default:
      return state;
  }
};

// find one
export const findRiskLevelReducer = (state = { riskLevel: {} }, action) => {
  switch (action.type) {
    case FIND_RISK_LEVEL_REQUEST:
      return {
        loading: true,
        riskLevel: {},
      };

    case FIND_RISK_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        riskLevel: action.payload,
      };

    case FIND_RISK_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        riskLevel: {},
        error: action.payload,
      };

    case "EMPTY_RISK_LEVEL":
      return {
        riskLevel: {},
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: null,
      };

    default:
      return state;
  }
};
