// SECTORAL_LIST create
export const CREATE_SECTORAL_LIST_REQUEST = "CREATE_SECTORAL_LIST_REQUEST";
export const CREATE_SECTORAL_LIST_SUCCESS = "CREATE_SECTORAL_LIST_SUCCESS";
export const CREATE_SECTORAL_LIST_FAIL = "CREATE_SECTORAL_LIST_FAIL"
export const SECTORAL_LIST_CONFLICT="SECTORAL_LIST_CONFLICT"

// SECTORAL_LIST get
export const SECTORAL_LIST_REQUEST = "SECTORAL_LIST_REQUEST";
export const SECTORAL_LIST_SUCCESS = "SECTORAL_LIST_SUCCESS";
export const SECTORAL_LIST_FAIL = "SECTORAL_LIST_FAIL"


// SECTORAL_LIST find
export const FIND_SECTORAL_LIST_REQUEST = "FIND_SECTORAL_LIST_REQUEST";
export const FIND_SECTORAL_LIST_SUCCESS = "FIND_SECTORAL_LIST_SUCCESS";
export const FIND_SECTORAL_LIST_FAIL = "FIND_SECTORAL_LIST_FAIL"

//SECTORAL_LIST update/put
export const UPDATE_SECTORAL_LIST_REQUEST = "UPDATE_SECTORAL_LIST_REQUEST";
export const UPDATE_SECTORAL_LIST_SUCCESS = "UPDATE_SECTORAL_LIST_SUCCESS";
export const UPDATE_SECTORAL_LIST_FAIL = "UPDATE_SECTORAL_LIST_FAIL"

// SECTORAL_LIST delete
export const DELETE_SECTORAL_LIST_REQUEST = "DELETE_SECTORAL_LIST_REQUEST";
export const DELETE_SECTORAL_LIST_SUCCESS = "DELETE_SECTORAL_LIST_SUCCESS";
export const DELETE_SECTORAL_LIST_FAIL = "DELETE_SECTORAL_LIST_FAIL"

export const SECTORAL_LIST_CLEAR_ERROR = "SECTORAL_LIST_CLEAR_ERROR"
